import i18nInstance, { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getDisplayStringValue, getLanguage, DisplayString } from '@gamiphy/i18n';
import { useMemo } from 'react';

/**
 * Parse the language,
 * get language code if it is locale
 * e.x es-cl => es
 */
export const useLanguage = (): string => {
  const { i18n } = useTranslation();

  return getLanguage(i18n);
};

/**
 * Get display value from display string
 * @deprecated
 * @param value
 * @param i18n
 */
export const getTranslationValue = (value: DisplayString, i18n: typeof i18nInstance): string => value[getLanguage(i18n)] ?? value.en;

/**
 * Get display value from display string
 * @param options
 */
export const useDisplayStringTranslation = <T extends DisplayString | undefined>(options?: TOptions): {t: (value: T) => string | undefined} => {
  const { i18n } = useTranslation();

  return useMemo(() => ({
    t: (displayString: T) => {
      if (!displayString) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        return displayString as undefined;
      }
      return getDisplayStringValue(i18n, displayString, options);
    },
  }), [i18n, options]);
};

/**
 * Get display value from display string
 * @param value
 * @param options
 */
export const useDisplayStringValue = <T extends DisplayString | undefined>(value: T, options?: TOptions): string | undefined => {
  const { t } = useDisplayStringTranslation(options);

  return t(value);
};
