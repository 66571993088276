import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const buttonTheme: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '100px',
        textTransform: 'capitalize',
        color: '#fff',
      },
      sizeMedium: {
        padding: '8px',
        fontSize: '14px',
        fontWeight: 700,
      },
      sizeLarge: {
        padding: '16px',
        fontSize: '18px',
        fontWeight: 500,
      },
    },
  },
};
