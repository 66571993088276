import React from 'react';
import { Grid, CircularProgress, Box } from '@mui/material';
import { observer } from 'mobx-react';

const Loader : React.FC = observer(() => (
  <Grid item container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        size={50}
        value={100}
        sx={{
          color: 'grey.300',
        }}
        thickness={4}
      />
      <CircularProgress
        variant="indeterminate"
        color="primary"
        disableShrink
        size={50}
        thickness={4}
        sx={{
          animationDuration: '700ms',
          position: 'absolute',
          left: 0,
        }}
      />
    </Box>
  </Grid>
));

export default Loader;
