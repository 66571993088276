declare global {
  interface Window {
    GAMIPHY_LOCALE?: string,
  }
}

/**
 * get  user locale within browser
 * @return user locale
 */
export const getLocale = (): string => {
  if (navigator.languages !== undefined) {
    return window.GAMIPHY_LOCALE || navigator.languages[0] || 'en-us';
  }
  return window.GAMIPHY_LOCALE || navigator.language || 'en-us';
};
