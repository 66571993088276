import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import type { } from '@mui/x-date-pickers/themeAugmentation';

export const datePickerTheme: Components<Omit<Theme, 'components'>> = {
  MuiDatePicker: {
    defaultProps: {
      closeOnSelect: true,
    },
  },
  PrivatePickersYear: {
    styleOverrides: {
      disabled: {
        color: '#A9A9A9',
      },
    },
  },
  PrivatePickersMonth: {
    styleOverrides: {
      root: {
        '&:disabled': {
          color: '#A9A9A9',
        },
      },
    },
  },
};
