import i18nInstance, { TOptions } from 'i18next';
import { DisplayString } from './types';
import { Languages } from './enums';

/**
 * Parse the language,
 * get language code if it is locale
 * e.x es-cl => es
 * @param i18n
 */
export const getLanguage = (i18n = i18nInstance): Languages => <Languages>i18n.language?.replace(/[-_][a-z]+$/i, '')
  .toLowerCase();

/**
 * Get display string value
 * @param i18n
 * @param value
 * @param options
 */
export const getDisplayStringValue = (i18n: typeof i18nInstance, value: DisplayString, options?: TOptions): string => i18n.t(value.id ?? '', value[getLanguage(i18n)] ?? value.en, options);
