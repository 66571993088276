import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { StyledEngineProvider, Theme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import Router from './router/Router';
import './bootstrap';
import { useFrontlineTheme } from './theme';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {
  }
}

const App: React.FC = () => {
  const theme = useFrontlineTheme();

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<div>Loading</div>}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={theme}
          >
            <CssBaseline />
            <Router />
          </ThemeProvider>
        </StyledEngineProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default observer(App);
