export enum Languages {
  en = 'en',
  es = 'es',
  ar = 'ar',
  fr = 'fr',
  it = 'it',
  pl = 'pl',
  de = 'de',
  pt = 'pt',
  he = 'he',
  fi = 'fi',
}

export type DashboardSupportedLanguages = Languages.en | Languages.es | Languages.pt
