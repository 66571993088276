import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import Loader from '@coretava/frontline/components/loader';
import { useReactRoutes } from './reactRoutes';

export const AppRoutes: React.FC = observer(() => {
  const routes = useReactRoutes();

  const appRoutes = React.useMemo(() => routes
    // Filter out disabled routes
    .filter((r) => !r.disabled)
    .map((route) => (
      <Route key={route.object.path} {...route.object} />
    )), [routes]);

  return (
    <Routes>
      {appRoutes}
    </Routes>
  );
});

const RouterComponent: React.FC = () => (
  <React.Suspense fallback={<Loader />}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </React.Suspense>
);

export default observer(RouterComponent);
