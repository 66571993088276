import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const inputTheme: Components<Omit<Theme, 'components'>> = {
  MuiTextField: {
    defaultProps: {
      InputProps: {
        disableUnderline: true,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '0.8rem',
        marginLeft: 0,
      },
    },
  },
};
