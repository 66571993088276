import React from 'react';
import { Trans, TransProps } from 'react-i18next';
import { DisplayString } from '@gamiphy/i18n';
import { useLanguage } from './hooks';

type Props = TransProps<never> & {
  displayString?: DisplayString
}

const DisplayStringTrans: React.FC<Props> = ({
  displayString,
  ...transProps
}) => {
  const language = useLanguage();
  const defaults = React.useMemo(
    () => displayString?.[language] ?? displayString?.en,
    [displayString, language],
  );

  return !displayString ? null : (
    <Trans
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore until fix backend translations
      i18nKey={displayString.id}
      defaults={defaults}
      {...transProps}
    />
  );
};

export default DisplayStringTrans;
