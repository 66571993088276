import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const cardTheme: Components<Omit<Theme, 'components'>> = {
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.06)',
        borderRadius: '0.8rem',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: 0,
      },
    },
  },
};
