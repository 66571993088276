import i18n, { FormatFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { Languages } from '@gamiphy/i18n';

export const PROVIDED_LANGUAGE = 'providedLanguage';

export type DetectorOptions = 'querystring' | 'cookie' | 'sessionStorage' | 'localStorage' | 'navigator' | 'htmlTag';

export type Options = {
  language?: Languages,
  formatters?: Record<string, FormatFunction>,
  detectionOrder?: (typeof PROVIDED_LANGUAGE | DetectorOptions) [],
  customStrings?: Record<string, string>
}

export class I18n {
  public static readonly instance: typeof i18n = i18n;

  public static async initialize(options: Options = {}): Promise<void> {
    const languageDetector = new LanguageDetector(null, {
      order: options.detectionOrder ?? [PROVIDED_LANGUAGE, 'htmlTag'],
    });

    if (options.language) {
      languageDetector.addDetector({
        name: PROVIDED_LANGUAGE,
        lookup() {
          return options.language;
        },
      });
    }

    await i18n.use(languageDetector)
      .use(resourcesToBackend((language, namespace, callback) => {
        import(`@gamiphy/i18n/locales/${language}/${namespace}.json`).then((strings: Record<string, string>) => {
          callback(null, { ...strings, ...(options.customStrings ?? {}) });
        })
          .catch((error) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            callback(error, null);
          });
      }))
      .use(initReactI18next)
      .init({
        fallbackLng: [Languages.en],
        keySeparator: false, // we use content as keys
        interpolation: {
          formatSeparator: ',',
          escapeValue: false,
        },
        returnNull: false,
      });

    if (options.formatters) {
      Object.entries(options.formatters)
        .forEach(([name, fn]) => i18n.services.formatter?.add(name, fn));
    }
  }

  public static addFormatter(formatter: Record<string, FormatFunction>): void {
    Object.entries(formatter)
      .forEach(([name, fn]) => i18n.services.formatter?.add(name, fn));
  }
}
