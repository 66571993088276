import React from 'react';
import { AppStore } from '@coretava/frontline/store/app';
import { ThemeStore } from '@coretava/frontline/store/theme';
import { Routes } from '../enums';
import { RouteConfig } from '../types';

const SurveyPage = React.lazy(() => import('@coretava/frontline/pages/survey'));
const ReferrerPage = React.lazy(() => import('@coretava/frontline/pages/referral/referrer'));
const RefereePage = React.lazy(() => import('@coretava/frontline/pages/referral/referee'));
const WheelPage = React.lazy(() => import('@coretava/frontline/pages/wheel'));

export const useInitializedRoutes = (): RouteConfig[] => React.useMemo(() => {
  if (!AppStore.initialized || !ThemeStore.initialized) return [];

  return ([
    {
      object: {
        path: Routes.Survey,
        Component: SurveyPage,
      },
    },
    {
      object: {
        path: Routes.Referrer,
        Component: ReferrerPage,
      },
    },
    {
      object: {
        path: Routes.Referee,
        Component: RefereePage,
      },
    },
    {
      object: {
        path: Routes.Wheel,
        Component: WheelPage,
      },
    },
  ]);
}, [
  AppStore.initialized,
  ThemeStore.initialized,
]);
