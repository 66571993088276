import * as Sentry from '@sentry/react';

Sentry.init({
  environment: process.env.CORETAVA_ENV,
  dsn: 'https://7f866001c6ecfa244fad90870cb1e376@o372998.ingest.us.sentry.io/4507656222801920',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend: (event) => {
    if (IS_DEBUG) {
      console.error(event);
      return null; // this drops the event and nothing will be send to sentry
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /api.*\.coretava\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
