import { FormatFunction, i18n } from 'i18next';
import { getDisplayStringValue } from './utils';
import { DisplayString } from './types';

export const getCurrencyFormatter = (locale: string, currency?: string): Record<string, FormatFunction> => ({
  currency: (value: string) => {
    const number = parseFloat(value);
    if (!Number.isNaN(number)) {
      return Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        notation: 'standard',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number).replace(/\s+/g, ' ');
    }
    return value;
  },
});

export const getPointsFormatter = (i18nInstance: i18n, locale: string, gemsDS: DisplayString, expDS: DisplayString): Record<string, FormatFunction> => ({
  gems: (value: string) => {
    const number = parseInt(value, 10);
    const gemsName = getDisplayStringValue(i18nInstance, gemsDS);

    if (!Number.isNaN(number)) {
      return `${Intl.NumberFormat(locale, {
        notation: 'standard',
        maximumFractionDigits: 2,
      }).format(number)} ${gemsName}`;
    }
    return gemsName;
  },
  exp: (value: string) => {
    const number = parseInt(value, 10);
    const expName = getDisplayStringValue(i18nInstance, expDS);

    if (!Number.isNaN(number) && (typeof value !== 'string')) {
      return `${Intl.NumberFormat(locale, {
        notation: 'standard',
        maximumFractionDigits: 2,
      }).format(number)} ${expName}`;
    }
    return `${value ? `${value} ` : ''}${expName}`;
  },
});

/**
 * getDiscountTypeFormatter:
 * @param i18nInstance
 * @param agent
 * @function This method give us the option of dynamically formatting the discount type to "coupons" or "gift cards" based on the agent
 * by adding {{, discountTypes}} in between the translated text.
 * @module Future Improvements: Converting AccountAgent to enum instead of type.
 * @returns Record<string, FormatFunction>
 */
export const getDiscountTypeFormatter = (i18nInstance: i18n, agent: string): Record<string, FormatFunction> => ({
  discountType: () => {
    switch (true) {
      case agent === 'shopify':
        return getDisplayStringValue(i18nInstance, { id: 'redeem.discount', en: 'discount' });
      default:
        return getDisplayStringValue(i18nInstance, { id: 'redeem.giftCard', en: 'gift card' });
    }
  },
  discountTypes: () => {
    switch (true) {
      case agent === 'shopify':
        return getDisplayStringValue(i18nInstance, { id: 'redeem.discounts', en: 'discounts' });
      default:
        return getDisplayStringValue(i18nInstance, { id: 'redeem.giftCards', en: 'gift cards' });
    }
  },
});
