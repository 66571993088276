import { createTheme, Theme } from '@mui/material/styles';
import merge from 'lodash/merge';
import React from 'react';
import { ThemeStore } from '@coretava/frontline/store/theme';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { buttonTheme } from './button';
import { inputTheme } from './input';
import { cardTheme } from './card';
import { datePickerTheme } from './datePicker';

export const useFrontlineTheme = (): Theme => React.useMemo(() => createTheme(merge<ThemeOptions, ThemeOptions>({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1110,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#DA604F',
    },
    secondary: {
      main: '#1A191C',
    },
    success: {
      main: '#66BE85',
    },
    error: {
      main: '#CD4747',
    },
    text: {
      primary: '#1A191C',
    },
    common: {
      black: '#1A191C',
      white: '#FFF',
    },
    grey: {
      100: '#282828',
      200: '#4F4F4F',
      300: '#ADADAD',
      400: '#DFDFDF',
      500: '#FAF8F6',
      600: '#FFF8F7',
    },
  },
  typography: {
    fontFamily: 'Aeonik',
    fontSize: 12,
    h1: {
      fontSize: 45,
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
    },
    h3: {
      fontSize: 26,
      fontWeight: 700,
    },
    h4: {
      fontSize: 22,
      fontWeight: 700,
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
    },
  },
  components: {
    ...buttonTheme,
    ...inputTheme,
    ...cardTheme,
    ...datePickerTheme,
  },
}, {
  // @fixme to be handled by backend
  // @ts-expect-error palette primary main field should be required
  palette: ThemeStore.theme?.palette,
})), [ThemeStore.theme]);
