/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountAuthResponse
 */
export interface AccountAuthResponse {
    /**
     * 
     * @type {object}
     * @memberof AccountAuthResponse
     */
    'account': object;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface AccountRequest
 */
export interface AccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountRequest
     */
    'agent': AccountRequestAgentEnum;
    /**
     * 
     * @type {ApiRequest}
     * @memberof AccountRequest
     */
    'api': ApiRequest;
    /**
     * 
     * @type {BusinessRequest}
     * @memberof AccountRequest
     */
    'business'?: BusinessRequest;
    /**
     * 
     * @type {string}
     * @memberof AccountRequest
     */
    'language'?: string;
}

export const AccountRequestAgentEnum = {
    Vtex: 'vtex',
    Shopify: 'shopify',
    Prestashop: 'prestashop',
    Nuvemshop: 'nuvemshop',
    Magento: 'magento',
    Tray: 'tray',
    Wake: 'wake',
    Custom: 'custom',
    WooCommerce: 'wooCommerce',
    Loja: 'loja'
} as const;

export type AccountRequestAgentEnum = typeof AccountRequestAgentEnum[keyof typeof AccountRequestAgentEnum];

/**
 * 
 * @export
 * @interface AccountResponse
 */
export interface AccountResponse {
    /**
     * 
     * @type {object}
     * @memberof AccountResponse
     */
    'account': object | null;
}
/**
 * 
 * @export
 * @interface ApiRequest
 */
export interface ApiRequest {
    /**
     * Shop URL
     * @type {string}
     * @memberof ApiRequest
     */
    'shop': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'hmac'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiRequest
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'workspace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'secret'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'tokenSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface AppDto
 */
export interface AppDto {
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'industry': string | null;
    /**
     * 
     * @type {number}
     * @memberof AppDto
     */
    'aov': number | null;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'logo': string | null;
    /**
     * 
     * @type {ScreenShotsDto}
     * @memberof AppDto
     */
    'screenshots': ScreenShotsDto;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'agent': AppDtoAgentEnum;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'language': AppDtoLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'currency': AppDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'country': AppDtoCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof AppDto
     */
    'timezone': number;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'domain': string;
    /**
     * 
     * @type {PointsExpiryDto}
     * @memberof AppDto
     */
    'pointsExpiry': PointsExpiryDto;
    /**
     * 
     * @type {boolean}
     * @memberof AppDto
     */
    'emails': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {object}
     * @memberof AppDto
     */
    'metadata'?: object;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppDto
     */
    'deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'identifier': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppDto
     */
    'types': Array<AppDtoTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'program'?: AppDtoProgramEnum;
    /**
     * 
     * @type {string}
     * @memberof AppDto
     */
    'paymentGateway': AppDtoPaymentGatewayEnum;
}

export const AppDtoAgentEnum = {
    Vtex: 'vtex',
    Shopify: 'shopify',
    Prestashop: 'prestashop',
    Nuvemshop: 'nuvemshop',
    Magento: 'magento',
    Tray: 'tray',
    Wake: 'wake',
    Custom: 'custom',
    WooCommerce: 'wooCommerce',
    Loja: 'loja'
} as const;

export type AppDtoAgentEnum = typeof AppDtoAgentEnum[keyof typeof AppDtoAgentEnum];
export const AppDtoLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type AppDtoLanguageEnum = typeof AppDtoLanguageEnum[keyof typeof AppDtoLanguageEnum];
export const AppDtoCurrencyEnum = {
    Aed: 'aed',
    Afn: 'afn',
    All: 'all',
    Amd: 'amd',
    Ang: 'ang',
    Aoa: 'aoa',
    Ars: 'ars',
    Aud: 'aud',
    Awg: 'awg',
    Azn: 'azn',
    Bam: 'bam',
    Bbd: 'bbd',
    Bdt: 'bdt',
    Bgn: 'bgn',
    Bhd: 'bhd',
    Bif: 'bif',
    Bmd: 'bmd',
    Bnd: 'bnd',
    Bob: 'bob',
    Brl: 'brl',
    Bsd: 'bsd',
    Btn: 'btn',
    Bwp: 'bwp',
    Byn: 'byn',
    Bzd: 'bzd',
    Cad: 'cad',
    Cdf: 'cdf',
    Chf: 'chf',
    Clp: 'clp',
    Cny: 'cny',
    Cop: 'cop',
    Crc: 'crc',
    Cuc: 'cuc',
    Cup: 'cup',
    Cve: 'cve',
    Czk: 'czk',
    Djf: 'djf',
    Dkk: 'dkk',
    Dop: 'dop',
    Dzd: 'dzd',
    Egp: 'egp',
    Ern: 'ern',
    Etb: 'etb',
    Eur: 'eur',
    Fjd: 'fjd',
    Fkp: 'fkp',
    Gbp: 'gbp',
    Gel: 'gel',
    Ghs: 'ghs',
    Gip: 'gip',
    Gmd: 'gmd',
    Gnf: 'gnf',
    Gtq: 'gtq',
    Gyd: 'gyd',
    Hkd: 'hkd',
    Hnl: 'hnl',
    Hrk: 'hrk',
    Htg: 'htg',
    Huf: 'huf',
    Idr: 'idr',
    Ils: 'ils',
    Inr: 'inr',
    Iqd: 'iqd',
    Irr: 'irr',
    Isk: 'isk',
    Jmd: 'jmd',
    Jod: 'jod',
    Jpy: 'jpy',
    Kes: 'kes',
    Kgs: 'kgs',
    Khr: 'khr',
    Kmf: 'kmf',
    Kpw: 'kpw',
    Krw: 'krw',
    Kwd: 'kwd',
    Kyd: 'kyd',
    Kzt: 'kzt',
    Lak: 'lak',
    Lbp: 'lbp',
    Lkr: 'lkr',
    Lrd: 'lrd',
    Lsl: 'lsl',
    Lyd: 'lyd',
    Mad: 'mad',
    Mdl: 'mdl',
    Mga: 'mga',
    Mkd: 'mkd',
    Mmk: 'mmk',
    Mnt: 'mnt',
    Mop: 'mop',
    Mru: 'mru',
    Mur: 'mur',
    Mvr: 'mvr',
    Mwk: 'mwk',
    Mxn: 'mxn',
    Myr: 'myr',
    Mzn: 'mzn',
    Nad: 'nad',
    Ngn: 'ngn',
    Nio: 'nio',
    Nok: 'nok',
    Npr: 'npr',
    Nzd: 'nzd',
    Omr: 'omr',
    Pab: 'pab',
    Pen: 'pen',
    Pgk: 'pgk',
    Php: 'php',
    Pkr: 'pkr',
    Pln: 'pln',
    Pyg: 'pyg',
    Qar: 'qar',
    Ron: 'ron',
    Rsd: 'rsd',
    Rub: 'rub',
    Rwf: 'rwf',
    Sar: 'sar',
    Sbd: 'sbd',
    Scr: 'scr',
    Sdg: 'sdg',
    Sek: 'sek',
    Sgd: 'sgd',
    Shp: 'shp',
    Sll: 'sll',
    Sos: 'sos',
    Srd: 'srd',
    Ssp: 'ssp',
    Stn: 'stn',
    Svc: 'svc',
    Syp: 'syp',
    Szl: 'szl',
    Thb: 'thb',
    Tjs: 'tjs',
    Tmt: 'tmt',
    Tnd: 'tnd',
    Top: 'top',
    Try: 'try',
    Ttd: 'ttd',
    Twd: 'twd',
    Tzs: 'tzs',
    Uah: 'uah',
    Ugx: 'ugx',
    Usd: 'usd',
    Uyu: 'uyu',
    Uzs: 'uzs',
    Ves: 'ves',
    Vnd: 'vnd',
    Vuv: 'vuv',
    Wst: 'wst',
    Xaf: 'xaf',
    Xcd: 'xcd',
    Xof: 'xof',
    Xpf: 'xpf',
    Yer: 'yer',
    Zar: 'zar',
    Zmw: 'zmw',
    Zwl: 'zwl'
} as const;

export type AppDtoCurrencyEnum = typeof AppDtoCurrencyEnum[keyof typeof AppDtoCurrencyEnum];
export const AppDtoCountryEnum = {
    Us: 'us',
    Af: 'af',
    Ax: 'ax',
    Al: 'al',
    Dz: 'dz',
    As: 'as',
    Ad: 'ad',
    Ao: 'ao',
    Ai: 'ai',
    Aq: 'aq',
    Ag: 'ag',
    Ar: 'ar',
    Am: 'am',
    Aw: 'aw',
    Au: 'au',
    At: 'at',
    Az: 'az',
    Bs: 'bs',
    Bh: 'bh',
    Bd: 'bd',
    Bb: 'bb',
    By: 'by',
    Be: 'be',
    Bz: 'bz',
    Bj: 'bj',
    Bm: 'bm',
    Bt: 'bt',
    Bo: 'bo',
    Bq: 'bq',
    Ba: 'ba',
    Bw: 'bw',
    Bv: 'bv',
    Br: 'br',
    Io: 'io',
    Bn: 'bn',
    Bg: 'bg',
    Bf: 'bf',
    Bi: 'bi',
    Kh: 'kh',
    Cm: 'cm',
    Ca: 'ca',
    Cv: 'cv',
    Ky: 'ky',
    Cf: 'cf',
    Td: 'td',
    Cl: 'cl',
    Cn: 'cn',
    Cx: 'cx',
    Cc: 'cc',
    Co: 'co',
    Km: 'km',
    Cg: 'cg',
    Cd: 'cd',
    Ck: 'ck',
    Cr: 'cr',
    Ci: 'ci',
    Hr: 'hr',
    Cu: 'cu',
    Cw: 'cw',
    Cy: 'cy',
    Cz: 'cz',
    Dk: 'dk',
    Dj: 'dj',
    Dm: 'dm',
    Do: 'do',
    Ec: 'ec',
    Eg: 'eg',
    Sv: 'sv',
    Gq: 'gq',
    Er: 'er',
    Ee: 'ee',
    Et: 'et',
    Fk: 'fk',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Gf: 'gf',
    Pf: 'pf',
    Tf: 'tf',
    Ga: 'ga',
    Gm: 'gm',
    Ge: 'ge',
    De: 'de',
    Gh: 'gh',
    Gi: 'gi',
    Gr: 'gr',
    Gl: 'gl',
    Gd: 'gd',
    Gp: 'gp',
    Gu: 'gu',
    Gt: 'gt',
    Gg: 'gg',
    Gn: 'gn',
    Gw: 'gw',
    Gy: 'gy',
    Ht: 'ht',
    Hm: 'hm',
    Va: 'va',
    Hn: 'hn',
    Hk: 'hk',
    Hu: 'hu',
    Is: 'is',
    In: 'in',
    Id: 'id',
    Ir: 'ir',
    Iq: 'iq',
    Ie: 'ie',
    Im: 'im',
    Il: 'il',
    It: 'it',
    Jm: 'jm',
    Jp: 'jp',
    Je: 'je',
    Jo: 'jo',
    Kz: 'kz',
    Ke: 'ke',
    Ki: 'ki',
    Kr: 'kr',
    Kp: 'kp',
    Kw: 'kw',
    Kg: 'kg',
    La: 'la',
    Lv: 'lv',
    Lb: 'lb',
    Ls: 'ls',
    Lr: 'lr',
    Ly: 'ly',
    Li: 'li',
    Lt: 'lt',
    Lu: 'lu',
    Mo: 'mo',
    Mk: 'mk',
    Mg: 'mg',
    Mw: 'mw',
    My: 'my',
    Mv: 'mv',
    Ml: 'ml',
    Mt: 'mt',
    Mh: 'mh',
    Mq: 'mq',
    Mr: 'mr',
    Mu: 'mu',
    Yt: 'yt',
    Mx: 'mx',
    Fm: 'fm',
    Md: 'md',
    Mc: 'mc',
    Mn: 'mn',
    Me: 'me',
    Ms: 'ms',
    Ma: 'ma',
    Mz: 'mz',
    Mm: 'mm',
    Na: 'na',
    Nr: 'nr',
    Np: 'np',
    Nl: 'nl',
    Nc: 'nc',
    Nz: 'nz',
    Ni: 'ni',
    Ne: 'ne',
    Ng: 'ng',
    Nu: 'nu',
    Nf: 'nf',
    Mp: 'mp',
    No: 'no',
    Om: 'om',
    Pk: 'pk',
    Pw: 'pw',
    Ps: 'ps',
    Pa: 'pa',
    Pg: 'pg',
    Py: 'py',
    Pe: 'pe',
    Ph: 'ph',
    Pn: 'pn',
    Pl: 'pl',
    Pt: 'pt',
    Pr: 'pr',
    Qa: 'qa',
    Re: 're',
    Ro: 'ro',
    Ru: 'ru',
    Rw: 'rw',
    Bl: 'bl',
    Sh: 'sh',
    Kn: 'kn',
    Lc: 'lc',
    Mf: 'mf',
    Pm: 'pm',
    Vc: 'vc',
    Ws: 'ws',
    Sm: 'sm',
    St: 'st',
    Sa: 'sa',
    Sn: 'sn',
    Rs: 'rs',
    Sc: 'sc',
    Sl: 'sl',
    Sg: 'sg',
    Sx: 'sx',
    Sk: 'sk',
    Si: 'si',
    Sb: 'sb',
    So: 'so',
    Za: 'za',
    Gs: 'gs',
    Ss: 'ss',
    Es: 'es',
    Lk: 'lk',
    Sd: 'sd',
    Sr: 'sr',
    Sj: 'sj',
    Sz: 'sz',
    Se: 'se',
    Ch: 'ch',
    Sy: 'sy',
    Tw: 'tw',
    Tj: 'tj',
    Tz: 'tz',
    Th: 'th',
    Tl: 'tl',
    Tg: 'tg',
    Tk: 'tk',
    To: 'to',
    Tt: 'tt',
    Tn: 'tn',
    Tr: 'tr',
    Tm: 'tm',
    Tc: 'tc',
    Tv: 'tv',
    Ug: 'ug',
    Ua: 'ua',
    Ae: 'ae',
    Gb: 'gb',
    Um: 'um',
    Uy: 'uy',
    Uz: 'uz',
    Vu: 'vu',
    Ve: 've',
    Vn: 'vn',
    Vg: 'vg',
    Vi: 'vi',
    Wf: 'wf',
    Eh: 'eh',
    Ye: 'ye',
    Zm: 'zm',
    Zw: 'zw'
} as const;

export type AppDtoCountryEnum = typeof AppDtoCountryEnum[keyof typeof AppDtoCountryEnum];
export const AppDtoTypesEnum = {
    CoreLoyalty: 'coreLoyalty',
    CoreTeams: 'coreTeams',
    Pos: 'POS',
    Fisico: 'fisico',
    Games: 'games'
} as const;

export type AppDtoTypesEnum = typeof AppDtoTypesEnum[keyof typeof AppDtoTypesEnum];
export const AppDtoProgramEnum = {
    Points: 'points',
    Cashback: 'cashback'
} as const;

export type AppDtoProgramEnum = typeof AppDtoProgramEnum[keyof typeof AppDtoProgramEnum];
export const AppDtoPaymentGatewayEnum = {
    StripeUs: 'stripeUS',
    StripeBr: 'stripeBR',
    Shopify: 'shopify'
} as const;

export type AppDtoPaymentGatewayEnum = typeof AppDtoPaymentGatewayEnum[keyof typeof AppDtoPaymentGatewayEnum];

/**
 * 
 * @export
 * @interface AppPointsExpiryRequest
 */
export interface AppPointsExpiryRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AppPointsExpiryRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof AppPointsExpiryRequest
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof AppPointsExpiryRequest
     */
    'unit': AppPointsExpiryRequestUnitEnum;
}

export const AppPointsExpiryRequestUnitEnum = {
    Day: 'day',
    Month: 'month'
} as const;

export type AppPointsExpiryRequestUnitEnum = typeof AppPointsExpiryRequestUnitEnum[keyof typeof AppPointsExpiryRequestUnitEnum];

/**
 * 
 * @export
 * @interface AppResponse
 */
export interface AppResponse {
    /**
     * 
     * @type {AppDto}
     * @memberof AppResponse
     */
    'app': AppDto;
}
/**
 * 
 * @export
 * @interface AppWithEnterpriseData
 */
export interface AppWithEnterpriseData {
    /**
     * 
     * @type {AppDto}
     * @memberof AppWithEnterpriseData
     */
    'app': AppDto;
    /**
     * 
     * @type {EnterpriseDataDto}
     * @memberof AppWithEnterpriseData
     */
    'enterprise': EnterpriseDataDto;
}
/**
 * 
 * @export
 * @interface AppsListResponse
 */
export interface AppsListResponse {
    /**
     * 
     * @type {Array<AppDto>}
     * @memberof AppsListResponse
     */
    'apps': Array<AppDto>;
    /**
     * 
     * @type {number}
     * @memberof AppsListResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AuthorizationDto
 */
export interface AuthorizationDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationDto
     */
    'token': string;
    /**
     * 
     * @type {number}
     * @memberof AuthorizationDto
     */
    'exp': number;
    /**
     * 
     * @type {number}
     * @memberof AuthorizationDto
     */
    'iat': number;
}
/**
 * 
 * @export
 * @interface AuthorizeRequest
 */
export interface AuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRequest
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface BackgroundDto
 */
export interface BackgroundDto {
    /**
     * 
     * @type {string}
     * @memberof BackgroundDto
     */
    'default'?: string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundDto
     */
    'paper'?: string;
}
/**
 * 
 * @export
 * @interface BackgroundRequest
 */
export interface BackgroundRequest {
    /**
     * 
     * @type {string}
     * @memberof BackgroundRequest
     */
    'default'?: string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundRequest
     */
    'paper'?: string;
}
/**
 * 
 * @export
 * @interface BlockedEntriesResponse
 */
export interface BlockedEntriesResponse {
    /**
     * 
     * @type {Array<BlockedEntryDto>}
     * @memberof BlockedEntriesResponse
     */
    'blockedEntries': Array<BlockedEntryDto>;
    /**
     * 
     * @type {number}
     * @memberof BlockedEntriesResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface BlockedEntryCreateRequest
 */
export interface BlockedEntryCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof BlockedEntryCreateRequest
     */
    'entry': string;
    /**
     * 
     * @type {string}
     * @memberof BlockedEntryCreateRequest
     */
    'type': BlockedEntryCreateRequestTypeEnum;
}

export const BlockedEntryCreateRequestTypeEnum = {
    Email: 'email',
    Domain: 'domain'
} as const;

export type BlockedEntryCreateRequestTypeEnum = typeof BlockedEntryCreateRequestTypeEnum[keyof typeof BlockedEntryCreateRequestTypeEnum];

/**
 * 
 * @export
 * @interface BlockedEntryDto
 */
export interface BlockedEntryDto {
    /**
     * 
     * @type {string}
     * @memberof BlockedEntryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlockedEntryDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof BlockedEntryDto
     */
    'type': BlockedEntryDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BlockedEntryDto
     */
    'entry': string;
}

export const BlockedEntryDtoTypeEnum = {
    Email: 'email',
    Domain: 'domain'
} as const;

export type BlockedEntryDtoTypeEnum = typeof BlockedEntryDtoTypeEnum[keyof typeof BlockedEntryDtoTypeEnum];

/**
 * 
 * @export
 * @interface BlockedEntryResponse
 */
export interface BlockedEntryResponse {
    /**
     * 
     * @type {BlockedEntryDto}
     * @memberof BlockedEntryResponse
     */
    'blockedEntry': BlockedEntryDto;
}
/**
 * 
 * @export
 * @interface BlockingDomainCreateRequest
 */
export interface BlockingDomainCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof BlockingDomainCreateRequest
     */
    'domain': string;
}
/**
 * 
 * @export
 * @interface BlockingDomainDto
 */
export interface BlockingDomainDto {
    /**
     * 
     * @type {string}
     * @memberof BlockingDomainDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlockingDomainDto
     */
    'domain': string;
}
/**
 * 
 * @export
 * @interface BlockingDomainResponse
 */
export interface BlockingDomainResponse {
    /**
     * 
     * @type {BlockingDomainDto}
     * @memberof BlockingDomainResponse
     */
    'blockingDomain': BlockingDomainDto;
}
/**
 * 
 * @export
 * @interface BlockingDomainsResponse
 */
export interface BlockingDomainsResponse {
    /**
     * 
     * @type {Array<BlockingDomainDto>}
     * @memberof BlockingDomainsResponse
     */
    'blockingDomains': Array<BlockingDomainDto>;
    /**
     * 
     * @type {number}
     * @memberof BlockingDomainsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface BooleanPerDeviceDto
 */
export interface BooleanPerDeviceDto {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPerDeviceDto
     */
    'mobile': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPerDeviceDto
     */
    'desktop': boolean;
}
/**
 * 
 * @export
 * @interface BooleanPerDeviceRequest
 */
export interface BooleanPerDeviceRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPerDeviceRequest
     */
    'desktop'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanPerDeviceRequest
     */
    'mobile'?: boolean;
}
/**
 * 
 * @export
 * @interface BrandingColorsDto
 */
export interface BrandingColorsDto {
    /**
     * 
     * @type {string}
     * @memberof BrandingColorsDto
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof BrandingColorsDto
     */
    'secondary': string;
    /**
     * 
     * @type {string}
     * @memberof BrandingColorsDto
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface BrandingColorsRequest
 */
export interface BrandingColorsRequest {
    /**
     * 
     * @type {string}
     * @memberof BrandingColorsRequest
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof BrandingColorsRequest
     */
    'secondary': string;
    /**
     * 
     * @type {string}
     * @memberof BrandingColorsRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface BulkUsersRequest
 */
export interface BulkUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUsersRequest
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BusinessRequest
 */
export interface BusinessRequest {
    /**
     * Business email
     * @type {string}
     * @memberof BusinessRequest
     */
    'email'?: string;
    /**
     * Loyalty app name
     * @type {string}
     * @memberof BusinessRequest
     */
    'name'?: string;
    /**
     * Business phone number
     * @type {string}
     * @memberof BusinessRequest
     */
    'phone'?: string;
    /**
     * Company name
     * @type {string}
     * @memberof BusinessRequest
     */
    'company'?: string;
}
/**
 * 
 * @export
 * @interface CampaignCreateRequest
 */
export interface CampaignCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignCreateRequest
     */
    'minPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCreateRequest
     */
    'maxPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignCreateRequest
     */
    'roundedTo'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreateRequest
     */
    'toDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreateRequest
     */
    'generateType': CampaignCreateRequestGenerateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreateRequest
     */
    'emailTemplate'?: string;
}

export const CampaignCreateRequestGenerateTypeEnum = {
    SendEmails: 'sendEmails',
    Report: 'report'
} as const;

export type CampaignCreateRequestGenerateTypeEnum = typeof CampaignCreateRequestGenerateTypeEnum[keyof typeof CampaignCreateRequestGenerateTypeEnum];

/**
 * 
 * @export
 * @interface CampaignDto
 */
export interface CampaignDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'app': string;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'minPoints': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'maxPoints': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'roundedTo': number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'toDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'generateType': CampaignDtoGenerateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'status': CampaignDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'targetMembers': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'processedMembers': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'actualMembers': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'avgPoints': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'totalDiscountCode': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'totalSentEmails': number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'generatedReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'emailTemplate'?: string;
}

export const CampaignDtoGenerateTypeEnum = {
    SendEmails: 'sendEmails',
    Report: 'report'
} as const;

export type CampaignDtoGenerateTypeEnum = typeof CampaignDtoGenerateTypeEnum[keyof typeof CampaignDtoGenerateTypeEnum];
export const CampaignDtoStatusEnum = {
    Inprogress: 'inprogress',
    Completed: 'completed',
    Stopped: 'stopped',
    CompletedReportFailuir: 'completed-report failuir'
} as const;

export type CampaignDtoStatusEnum = typeof CampaignDtoStatusEnum[keyof typeof CampaignDtoStatusEnum];

/**
 * 
 * @export
 * @interface CampaignResponse
 */
export interface CampaignResponse {
    /**
     * 
     * @type {CampaignDto}
     * @memberof CampaignResponse
     */
    'campaign': CampaignDto;
}
/**
 * 
 * @export
 * @interface CampaignsResponse
 */
export interface CampaignsResponse {
    /**
     * 
     * @type {Array<CampaignDto>}
     * @memberof CampaignsResponse
     */
    'campaigns': Array<CampaignDto>;
    /**
     * 
     * @type {number}
     * @memberof CampaignsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CardDto
 */
export interface CardDto {
    /**
     * 
     * @type {number}
     * @memberof CardDto
     */
    'borderRadius'?: number;
    /**
     * 
     * @type {CircleDto}
     * @memberof CardDto
     */
    'circle'?: CircleDto;
}
/**
 * 
 * @export
 * @interface CardRequest
 */
export interface CardRequest {
    /**
     * 
     * @type {number}
     * @memberof CardRequest
     */
    'borderRadius'?: number;
    /**
     * 
     * @type {CircleRequest}
     * @memberof CardRequest
     */
    'circle'?: CircleRequest;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'confirmPassword': string;
}
/**
 * 
 * @export
 * @interface CharacterDto
 */
export interface CharacterDto {
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'lockedIcon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'tutor'?: string | null;
}
/**
 * 
 * @export
 * @interface CharacterRequest
 */
export interface CharacterRequest {
    /**
     * 
     * @type {string}
     * @memberof CharacterRequest
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRequest
     */
    'lockedIcon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRequest
     */
    'tutor'?: string;
}
/**
 * 
 * @export
 * @interface CircleDto
 */
export interface CircleDto {
    /**
     * 
     * @type {string}
     * @memberof CircleDto
     */
    'background'?: string;
}
/**
 * 
 * @export
 * @interface CircleRequest
 */
export interface CircleRequest {
    /**
     * 
     * @type {string}
     * @memberof CircleRequest
     */
    'background'?: string;
}
/**
 * 
 * @export
 * @interface ClaimRequest
 */
export interface ClaimRequest {
    /**
     * 
     * @type {string}
     * @memberof ClaimRequest
     */
    'command': string;
    /**
     * 
     * @type {object}
     * @memberof ClaimRequest
     */
    'data': object;
}
/**
 * 
 * @export
 * @interface ColorDto
 */
export interface ColorDto {
    /**
     * 
     * @type {string}
     * @memberof ColorDto
     */
    'light'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColorDto
     */
    'main'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColorDto
     */
    'dark'?: string;
}
/**
 * 
 * @export
 * @interface ColorRequest
 */
export interface ColorRequest {
    /**
     * 
     * @type {string}
     * @memberof ColorRequest
     */
    'light'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColorRequest
     */
    'main'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColorRequest
     */
    'dark'?: string;
}
/**
 * 
 * @export
 * @interface ColorsDto
 */
export interface ColorsDto {
    /**
     * 
     * @type {string}
     * @memberof ColorsDto
     */
    'mainFontColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsDto
     */
    'heroSectionTextColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsDto
     */
    'promoteWelcomeGiftTextColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsDto
     */
    'backgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsDto
     */
    'primaryColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsDto
     */
    'buttonTextColor': string;
}
/**
 * 
 * @export
 * @interface ColorsRequest
 */
export interface ColorsRequest {
    /**
     * 
     * @type {string}
     * @memberof ColorsRequest
     */
    'mainFontColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsRequest
     */
    'heroSectionTextColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsRequest
     */
    'promoteWelcomeGiftTextColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsRequest
     */
    'backgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsRequest
     */
    'primaryColor': string;
    /**
     * 
     * @type {string}
     * @memberof ColorsRequest
     */
    'buttonTextColor': string;
}
/**
 * 
 * @export
 * @interface ConnectionCreateRequest
 */
export interface ConnectionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionCreateRequest
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionCreateRequest
     */
    'follower': string;
}
/**
 * 
 * @export
 * @interface ConnectionRecordResponse
 */
export interface ConnectionRecordResponse {
    /**
     * 
     * @type {string}
     * @memberof ConnectionRecordResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionRecordResponse
     */
    'app': string;
    /**
     * 
     * @type {UserDto}
     * @memberof ConnectionRecordResponse
     */
    'user': UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof ConnectionRecordResponse
     */
    'follower': UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionRecordResponse
     */
    'friends': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectionRecordResponse
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ConnectionRecordsResponse
 */
export interface ConnectionRecordsResponse {
    /**
     * 
     * @type {Array<ConnectionRecordResponse>}
     * @memberof ConnectionRecordsResponse
     */
    'connections': Array<ConnectionRecordResponse>;
    /**
     * 
     * @type {number}
     * @memberof ConnectionRecordsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ConnectionResponse
 */
export interface ConnectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ConnectionResponse
     */
    'connection': object;
}
/**
 * 
 * @export
 * @interface CouponCreateRequest
 */
export interface CouponCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponCreateRequest
     */
    'promotion': string;
}
/**
 * 
 * @export
 * @interface CouponGetRequest
 */
export interface CouponGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponGetRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CouponResponse
 */
export interface CouponResponse {
    /**
     * 
     * @type {object}
     * @memberof CouponResponse
     */
    'coupon': object;
}
/**
 * 
 * @export
 * @interface CouponSearchResponse
 */
export interface CouponSearchResponse {
    /**
     * 
     * @type {object}
     * @memberof CouponSearchResponse
     */
    'coupon': object | null;
}
/**
 * 
 * @export
 * @interface CouponsResponse
 */
export interface CouponsResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof CouponsResponse
     */
    'coupons': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof CouponsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'agent': CreateAccountRequestAgentEnum;
    /**
     * 
     * @type {CreateApiRequest}
     * @memberof CreateAccountRequest
     */
    'api': CreateApiRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'language'?: CreateAccountRequestLanguageEnum;
}

export const CreateAccountRequestAgentEnum = {
    Vtex: 'vtex',
    Shopify: 'shopify',
    Prestashop: 'prestashop',
    Nuvemshop: 'nuvemshop',
    Magento: 'magento',
    Tray: 'tray',
    Wake: 'wake',
    Custom: 'custom',
    WooCommerce: 'wooCommerce',
    Loja: 'loja'
} as const;

export type CreateAccountRequestAgentEnum = typeof CreateAccountRequestAgentEnum[keyof typeof CreateAccountRequestAgentEnum];
export const CreateAccountRequestLanguageEnum = {
    Ab: 'ab',
    Aa: 'aa',
    Af: 'af',
    Ak: 'ak',
    Sq: 'sq',
    Am: 'am',
    Ar: 'ar',
    An: 'an',
    Hy: 'hy',
    As: 'as',
    Av: 'av',
    Ae: 'ae',
    Ay: 'ay',
    Az: 'az',
    Bm: 'bm',
    Ba: 'ba',
    Eu: 'eu',
    Be: 'be',
    Bn: 'bn',
    Bi: 'bi',
    Bs: 'bs',
    Br: 'br',
    Bg: 'bg',
    My: 'my',
    Ca: 'ca',
    Ch: 'ch',
    Ce: 'ce',
    Ny: 'ny',
    Ny2: 'ny',
    Ny3: 'ny',
    Zh: 'zh',
    Cu: 'cu',
    Cu2: 'cu',
    Cu3: 'cu',
    Cu4: 'cu',
    Cu5: 'cu',
    Cv: 'cv',
    Kw: 'kw',
    Co: 'co',
    Cr: 'cr',
    Hr: 'hr',
    Cs: 'cs',
    Da: 'da',
    Dv: 'dv',
    Dv2: 'dv',
    Dv3: 'dv',
    Nl: 'nl',
    Nl2: 'nl',
    Dz: 'dz',
    En: 'en',
    Eo: 'eo',
    Et: 'et',
    Ee: 'ee',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Fy: 'fy',
    Ff: 'ff',
    Gd: 'gd',
    Gd2: 'gd',
    Gl: 'gl',
    Lg: 'lg',
    Ka: 'ka',
    De: 'de',
    El: 'el',
    El2: 'el',
    Kl: 'kl',
    Kl2: 'kl',
    Gn: 'gn',
    Gu: 'gu',
    Ht: 'ht',
    Ht2: 'ht',
    Ha: 'ha',
    He: 'he',
    Hz: 'hz',
    Hi: 'hi',
    Ho: 'ho',
    Hu: 'hu',
    Is: 'is',
    Io: 'io',
    Ig: 'ig',
    Id: 'id',
    Ia: 'ia',
    Ie: 'ie',
    Ie2: 'ie',
    Iu: 'iu',
    Ik: 'ik',
    Ga: 'ga',
    It: 'it',
    Ja: 'ja',
    Jv: 'jv',
    Kn: 'kn',
    Kr: 'kr',
    Ks: 'ks',
    Kk: 'kk',
    Km: 'km',
    Ki: 'ki',
    Ki2: 'ki',
    Rw: 'rw',
    Ky: 'ky',
    Ky2: 'ky',
    Kv: 'kv',
    Kg: 'kg',
    Ko: 'ko',
    Kj: 'kj',
    Kj2: 'kj',
    Ku: 'ku',
    Lo: 'lo',
    La: 'la',
    Lv: 'lv',
    Li: 'li',
    Li2: 'li',
    Li3: 'li',
    Ln: 'ln',
    Lt: 'lt',
    Lu: 'lu',
    Lb: 'lb',
    Lb2: 'lb',
    Mk: 'mk',
    Mg: 'mg',
    Ms: 'ms',
    Ml: 'ml',
    Mt: 'mt',
    Gv: 'gv',
    Mi: 'mi',
    Mr: 'mr',
    Mh: 'mh',
    Mn: 'mn',
    Na: 'na',
    Nv: 'nv',
    Nv2: 'nv',
    Nd: 'nd',
    Nr: 'nr',
    Ng: 'ng',
    Ne: 'ne',
    No: 'no',
    Nb: 'nb',
    Nn: 'nn',
    Ii: 'ii',
    Ii2: 'ii',
    Oc: 'oc',
    Oj: 'oj',
    Or: 'or',
    Om: 'om',
    Os: 'os',
    Os2: 'os',
    Pi: 'pi',
    Ps: 'ps',
    Ps2: 'ps',
    Fa: 'fa',
    Pl: 'pl',
    Pt: 'pt',
    Pa: 'pa',
    Pa2: 'pa',
    Qu: 'qu',
    Ro: 'ro',
    Ro2: 'ro',
    Ro3: 'ro',
    Rm: 'rm',
    Rn: 'rn',
    Ru: 'ru',
    Se: 'se',
    Sm: 'sm',
    Sg: 'sg',
    Sa: 'sa',
    Sc: 'sc',
    Sr: 'sr',
    Sn: 'sn',
    Sd: 'sd',
    Si: 'si',
    Si2: 'si',
    Sk: 'sk',
    Sl: 'sl',
    So: 'so',
    St: 'st',
    Es: 'es',
    Es2: 'es',
    Su: 'su',
    Sw: 'sw',
    Ss: 'ss',
    Sv: 'sv',
    Tl: 'tl',
    Ty: 'ty',
    Tg: 'tg',
    Ta: 'ta',
    Tt: 'tt',
    Te: 'te',
    Th: 'th',
    Bo: 'bo',
    Ti: 'ti',
    To: 'to',
    Ts: 'ts',
    Tn: 'tn',
    Tr: 'tr',
    Tk: 'tk',
    Tw: 'tw',
    Ug: 'ug',
    Ug2: 'ug',
    Uk: 'uk',
    Ur: 'ur',
    Uz: 'uz',
    Ve: 've',
    Vi: 'vi',
    Vo: 'vo',
    Wa: 'wa',
    Cy: 'cy',
    Wo: 'wo',
    Xh: 'xh',
    Yi: 'yi',
    Yo: 'yo',
    Za: 'za',
    Za2: 'za',
    Zu: 'zu'
} as const;

export type CreateAccountRequestLanguageEnum = typeof CreateAccountRequestLanguageEnum[keyof typeof CreateAccountRequestLanguageEnum];

/**
 * 
 * @export
 * @interface CreateApiRequest
 */
export interface CreateApiRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'secret'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'workspace'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'tokenSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiRequest
     */
    'appId'?: string;
}
/**
 * 
 * @export
 * @interface CreateAppRequest
 */
export interface CreateAppRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAppRequest
     */
    'types'?: Array<CreateAppRequestTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppRequest
     */
    'aov'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'agent': CreateAppRequestAgentEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'language'?: CreateAppRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'currency'?: CreateAppRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'country': CreateAppRequestCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateAppRequest
     */
    'timezone'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'domain': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppRequest
     */
    'emails'?: boolean;
    /**
     * 
     * @type {AppPointsExpiryRequest}
     * @memberof CreateAppRequest
     */
    'pointsExpiry'?: AppPointsExpiryRequest;
    /**
     * 
     * @type {object}
     * @memberof CreateAppRequest
     */
    'metadata'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppRequest
     */
    'program'?: CreateAppRequestProgramEnum;
}

export const CreateAppRequestTypesEnum = {
    CoreLoyalty: 'coreLoyalty',
    CoreTeams: 'coreTeams',
    Pos: 'POS',
    Fisico: 'fisico',
    Games: 'games'
} as const;

export type CreateAppRequestTypesEnum = typeof CreateAppRequestTypesEnum[keyof typeof CreateAppRequestTypesEnum];
export const CreateAppRequestAgentEnum = {
    Vtex: 'vtex',
    Shopify: 'shopify',
    Prestashop: 'prestashop',
    Nuvemshop: 'nuvemshop',
    Magento: 'magento',
    Tray: 'tray',
    Wake: 'wake',
    Custom: 'custom',
    WooCommerce: 'wooCommerce',
    Loja: 'loja'
} as const;

export type CreateAppRequestAgentEnum = typeof CreateAppRequestAgentEnum[keyof typeof CreateAppRequestAgentEnum];
export const CreateAppRequestLanguageEnum = {
    Ab: 'ab',
    Aa: 'aa',
    Af: 'af',
    Ak: 'ak',
    Sq: 'sq',
    Am: 'am',
    Ar: 'ar',
    An: 'an',
    Hy: 'hy',
    As: 'as',
    Av: 'av',
    Ae: 'ae',
    Ay: 'ay',
    Az: 'az',
    Bm: 'bm',
    Ba: 'ba',
    Eu: 'eu',
    Be: 'be',
    Bn: 'bn',
    Bi: 'bi',
    Bs: 'bs',
    Br: 'br',
    Bg: 'bg',
    My: 'my',
    Ca: 'ca',
    Ch: 'ch',
    Ce: 'ce',
    Ny: 'ny',
    Ny2: 'ny',
    Ny3: 'ny',
    Zh: 'zh',
    Cu: 'cu',
    Cu2: 'cu',
    Cu3: 'cu',
    Cu4: 'cu',
    Cu5: 'cu',
    Cv: 'cv',
    Kw: 'kw',
    Co: 'co',
    Cr: 'cr',
    Hr: 'hr',
    Cs: 'cs',
    Da: 'da',
    Dv: 'dv',
    Dv2: 'dv',
    Dv3: 'dv',
    Nl: 'nl',
    Nl2: 'nl',
    Dz: 'dz',
    En: 'en',
    Eo: 'eo',
    Et: 'et',
    Ee: 'ee',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Fy: 'fy',
    Ff: 'ff',
    Gd: 'gd',
    Gd2: 'gd',
    Gl: 'gl',
    Lg: 'lg',
    Ka: 'ka',
    De: 'de',
    El: 'el',
    El2: 'el',
    Kl: 'kl',
    Kl2: 'kl',
    Gn: 'gn',
    Gu: 'gu',
    Ht: 'ht',
    Ht2: 'ht',
    Ha: 'ha',
    He: 'he',
    Hz: 'hz',
    Hi: 'hi',
    Ho: 'ho',
    Hu: 'hu',
    Is: 'is',
    Io: 'io',
    Ig: 'ig',
    Id: 'id',
    Ia: 'ia',
    Ie: 'ie',
    Ie2: 'ie',
    Iu: 'iu',
    Ik: 'ik',
    Ga: 'ga',
    It: 'it',
    Ja: 'ja',
    Jv: 'jv',
    Kn: 'kn',
    Kr: 'kr',
    Ks: 'ks',
    Kk: 'kk',
    Km: 'km',
    Ki: 'ki',
    Ki2: 'ki',
    Rw: 'rw',
    Ky: 'ky',
    Ky2: 'ky',
    Kv: 'kv',
    Kg: 'kg',
    Ko: 'ko',
    Kj: 'kj',
    Kj2: 'kj',
    Ku: 'ku',
    Lo: 'lo',
    La: 'la',
    Lv: 'lv',
    Li: 'li',
    Li2: 'li',
    Li3: 'li',
    Ln: 'ln',
    Lt: 'lt',
    Lu: 'lu',
    Lb: 'lb',
    Lb2: 'lb',
    Mk: 'mk',
    Mg: 'mg',
    Ms: 'ms',
    Ml: 'ml',
    Mt: 'mt',
    Gv: 'gv',
    Mi: 'mi',
    Mr: 'mr',
    Mh: 'mh',
    Mn: 'mn',
    Na: 'na',
    Nv: 'nv',
    Nv2: 'nv',
    Nd: 'nd',
    Nr: 'nr',
    Ng: 'ng',
    Ne: 'ne',
    No: 'no',
    Nb: 'nb',
    Nn: 'nn',
    Ii: 'ii',
    Ii2: 'ii',
    Oc: 'oc',
    Oj: 'oj',
    Or: 'or',
    Om: 'om',
    Os: 'os',
    Os2: 'os',
    Pi: 'pi',
    Ps: 'ps',
    Ps2: 'ps',
    Fa: 'fa',
    Pl: 'pl',
    Pt: 'pt',
    Pa: 'pa',
    Pa2: 'pa',
    Qu: 'qu',
    Ro: 'ro',
    Ro2: 'ro',
    Ro3: 'ro',
    Rm: 'rm',
    Rn: 'rn',
    Ru: 'ru',
    Se: 'se',
    Sm: 'sm',
    Sg: 'sg',
    Sa: 'sa',
    Sc: 'sc',
    Sr: 'sr',
    Sn: 'sn',
    Sd: 'sd',
    Si: 'si',
    Si2: 'si',
    Sk: 'sk',
    Sl: 'sl',
    So: 'so',
    St: 'st',
    Es: 'es',
    Es2: 'es',
    Su: 'su',
    Sw: 'sw',
    Ss: 'ss',
    Sv: 'sv',
    Tl: 'tl',
    Ty: 'ty',
    Tg: 'tg',
    Ta: 'ta',
    Tt: 'tt',
    Te: 'te',
    Th: 'th',
    Bo: 'bo',
    Ti: 'ti',
    To: 'to',
    Ts: 'ts',
    Tn: 'tn',
    Tr: 'tr',
    Tk: 'tk',
    Tw: 'tw',
    Ug: 'ug',
    Ug2: 'ug',
    Uk: 'uk',
    Ur: 'ur',
    Uz: 'uz',
    Ve: 've',
    Vi: 'vi',
    Vo: 'vo',
    Wa: 'wa',
    Cy: 'cy',
    Wo: 'wo',
    Xh: 'xh',
    Yi: 'yi',
    Yo: 'yo',
    Za: 'za',
    Za2: 'za',
    Zu: 'zu'
} as const;

export type CreateAppRequestLanguageEnum = typeof CreateAppRequestLanguageEnum[keyof typeof CreateAppRequestLanguageEnum];
export const CreateAppRequestCurrencyEnum = {
    Aed: 'aed',
    Afn: 'afn',
    All: 'all',
    Amd: 'amd',
    Ang: 'ang',
    Aoa: 'aoa',
    Ars: 'ars',
    Aud: 'aud',
    Awg: 'awg',
    Azn: 'azn',
    Bam: 'bam',
    Bbd: 'bbd',
    Bdt: 'bdt',
    Bgn: 'bgn',
    Bhd: 'bhd',
    Bif: 'bif',
    Bmd: 'bmd',
    Bnd: 'bnd',
    Bob: 'bob',
    Brl: 'brl',
    Bsd: 'bsd',
    Btn: 'btn',
    Bwp: 'bwp',
    Byn: 'byn',
    Bzd: 'bzd',
    Cad: 'cad',
    Cdf: 'cdf',
    Chf: 'chf',
    Clp: 'clp',
    Cny: 'cny',
    Cop: 'cop',
    Crc: 'crc',
    Cuc: 'cuc',
    Cup: 'cup',
    Cve: 'cve',
    Czk: 'czk',
    Djf: 'djf',
    Dkk: 'dkk',
    Dop: 'dop',
    Dzd: 'dzd',
    Egp: 'egp',
    Ern: 'ern',
    Etb: 'etb',
    Eur: 'eur',
    Fjd: 'fjd',
    Fkp: 'fkp',
    Gbp: 'gbp',
    Gel: 'gel',
    Ghs: 'ghs',
    Gip: 'gip',
    Gmd: 'gmd',
    Gnf: 'gnf',
    Gtq: 'gtq',
    Gyd: 'gyd',
    Hkd: 'hkd',
    Hnl: 'hnl',
    Hrk: 'hrk',
    Htg: 'htg',
    Huf: 'huf',
    Idr: 'idr',
    Ils: 'ils',
    Inr: 'inr',
    Iqd: 'iqd',
    Irr: 'irr',
    Isk: 'isk',
    Jmd: 'jmd',
    Jod: 'jod',
    Jpy: 'jpy',
    Kes: 'kes',
    Kgs: 'kgs',
    Khr: 'khr',
    Kmf: 'kmf',
    Kpw: 'kpw',
    Krw: 'krw',
    Kwd: 'kwd',
    Kyd: 'kyd',
    Kzt: 'kzt',
    Lak: 'lak',
    Lbp: 'lbp',
    Lkr: 'lkr',
    Lrd: 'lrd',
    Lsl: 'lsl',
    Lyd: 'lyd',
    Mad: 'mad',
    Mdl: 'mdl',
    Mga: 'mga',
    Mkd: 'mkd',
    Mmk: 'mmk',
    Mnt: 'mnt',
    Mop: 'mop',
    Mru: 'mru',
    Mur: 'mur',
    Mvr: 'mvr',
    Mwk: 'mwk',
    Mxn: 'mxn',
    Myr: 'myr',
    Mzn: 'mzn',
    Nad: 'nad',
    Ngn: 'ngn',
    Nio: 'nio',
    Nok: 'nok',
    Npr: 'npr',
    Nzd: 'nzd',
    Omr: 'omr',
    Pab: 'pab',
    Pen: 'pen',
    Pgk: 'pgk',
    Php: 'php',
    Pkr: 'pkr',
    Pln: 'pln',
    Pyg: 'pyg',
    Qar: 'qar',
    Ron: 'ron',
    Rsd: 'rsd',
    Rub: 'rub',
    Rwf: 'rwf',
    Sar: 'sar',
    Sbd: 'sbd',
    Scr: 'scr',
    Sdg: 'sdg',
    Sek: 'sek',
    Sgd: 'sgd',
    Shp: 'shp',
    Sll: 'sll',
    Sos: 'sos',
    Srd: 'srd',
    Ssp: 'ssp',
    Stn: 'stn',
    Svc: 'svc',
    Syp: 'syp',
    Szl: 'szl',
    Thb: 'thb',
    Tjs: 'tjs',
    Tmt: 'tmt',
    Tnd: 'tnd',
    Top: 'top',
    Try: 'try',
    Ttd: 'ttd',
    Twd: 'twd',
    Tzs: 'tzs',
    Uah: 'uah',
    Ugx: 'ugx',
    Usd: 'usd',
    Uyu: 'uyu',
    Uzs: 'uzs',
    Ves: 'ves',
    Vnd: 'vnd',
    Vuv: 'vuv',
    Wst: 'wst',
    Xaf: 'xaf',
    Xcd: 'xcd',
    Xof: 'xof',
    Xpf: 'xpf',
    Yer: 'yer',
    Zar: 'zar',
    Zmw: 'zmw',
    Zwl: 'zwl'
} as const;

export type CreateAppRequestCurrencyEnum = typeof CreateAppRequestCurrencyEnum[keyof typeof CreateAppRequestCurrencyEnum];
export const CreateAppRequestCountryEnum = {
    Us: 'us',
    Af: 'af',
    Ax: 'ax',
    Al: 'al',
    Dz: 'dz',
    As: 'as',
    Ad: 'ad',
    Ao: 'ao',
    Ai: 'ai',
    Aq: 'aq',
    Ag: 'ag',
    Ar: 'ar',
    Am: 'am',
    Aw: 'aw',
    Au: 'au',
    At: 'at',
    Az: 'az',
    Bs: 'bs',
    Bh: 'bh',
    Bd: 'bd',
    Bb: 'bb',
    By: 'by',
    Be: 'be',
    Bz: 'bz',
    Bj: 'bj',
    Bm: 'bm',
    Bt: 'bt',
    Bo: 'bo',
    Bq: 'bq',
    Ba: 'ba',
    Bw: 'bw',
    Bv: 'bv',
    Br: 'br',
    Io: 'io',
    Bn: 'bn',
    Bg: 'bg',
    Bf: 'bf',
    Bi: 'bi',
    Kh: 'kh',
    Cm: 'cm',
    Ca: 'ca',
    Cv: 'cv',
    Ky: 'ky',
    Cf: 'cf',
    Td: 'td',
    Cl: 'cl',
    Cn: 'cn',
    Cx: 'cx',
    Cc: 'cc',
    Co: 'co',
    Km: 'km',
    Cg: 'cg',
    Cd: 'cd',
    Ck: 'ck',
    Cr: 'cr',
    Ci: 'ci',
    Hr: 'hr',
    Cu: 'cu',
    Cw: 'cw',
    Cy: 'cy',
    Cz: 'cz',
    Dk: 'dk',
    Dj: 'dj',
    Dm: 'dm',
    Do: 'do',
    Ec: 'ec',
    Eg: 'eg',
    Sv: 'sv',
    Gq: 'gq',
    Er: 'er',
    Ee: 'ee',
    Et: 'et',
    Fk: 'fk',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Gf: 'gf',
    Pf: 'pf',
    Tf: 'tf',
    Ga: 'ga',
    Gm: 'gm',
    Ge: 'ge',
    De: 'de',
    Gh: 'gh',
    Gi: 'gi',
    Gr: 'gr',
    Gl: 'gl',
    Gd: 'gd',
    Gp: 'gp',
    Gu: 'gu',
    Gt: 'gt',
    Gg: 'gg',
    Gn: 'gn',
    Gw: 'gw',
    Gy: 'gy',
    Ht: 'ht',
    Hm: 'hm',
    Va: 'va',
    Hn: 'hn',
    Hk: 'hk',
    Hu: 'hu',
    Is: 'is',
    In: 'in',
    Id: 'id',
    Ir: 'ir',
    Iq: 'iq',
    Ie: 'ie',
    Im: 'im',
    Il: 'il',
    It: 'it',
    Jm: 'jm',
    Jp: 'jp',
    Je: 'je',
    Jo: 'jo',
    Kz: 'kz',
    Ke: 'ke',
    Ki: 'ki',
    Kr: 'kr',
    Kp: 'kp',
    Kw: 'kw',
    Kg: 'kg',
    La: 'la',
    Lv: 'lv',
    Lb: 'lb',
    Ls: 'ls',
    Lr: 'lr',
    Ly: 'ly',
    Li: 'li',
    Lt: 'lt',
    Lu: 'lu',
    Mo: 'mo',
    Mk: 'mk',
    Mg: 'mg',
    Mw: 'mw',
    My: 'my',
    Mv: 'mv',
    Ml: 'ml',
    Mt: 'mt',
    Mh: 'mh',
    Mq: 'mq',
    Mr: 'mr',
    Mu: 'mu',
    Yt: 'yt',
    Mx: 'mx',
    Fm: 'fm',
    Md: 'md',
    Mc: 'mc',
    Mn: 'mn',
    Me: 'me',
    Ms: 'ms',
    Ma: 'ma',
    Mz: 'mz',
    Mm: 'mm',
    Na: 'na',
    Nr: 'nr',
    Np: 'np',
    Nl: 'nl',
    Nc: 'nc',
    Nz: 'nz',
    Ni: 'ni',
    Ne: 'ne',
    Ng: 'ng',
    Nu: 'nu',
    Nf: 'nf',
    Mp: 'mp',
    No: 'no',
    Om: 'om',
    Pk: 'pk',
    Pw: 'pw',
    Ps: 'ps',
    Pa: 'pa',
    Pg: 'pg',
    Py: 'py',
    Pe: 'pe',
    Ph: 'ph',
    Pn: 'pn',
    Pl: 'pl',
    Pt: 'pt',
    Pr: 'pr',
    Qa: 'qa',
    Re: 're',
    Ro: 'ro',
    Ru: 'ru',
    Rw: 'rw',
    Bl: 'bl',
    Sh: 'sh',
    Kn: 'kn',
    Lc: 'lc',
    Mf: 'mf',
    Pm: 'pm',
    Vc: 'vc',
    Ws: 'ws',
    Sm: 'sm',
    St: 'st',
    Sa: 'sa',
    Sn: 'sn',
    Rs: 'rs',
    Sc: 'sc',
    Sl: 'sl',
    Sg: 'sg',
    Sx: 'sx',
    Sk: 'sk',
    Si: 'si',
    Sb: 'sb',
    So: 'so',
    Za: 'za',
    Gs: 'gs',
    Ss: 'ss',
    Es: 'es',
    Lk: 'lk',
    Sd: 'sd',
    Sr: 'sr',
    Sj: 'sj',
    Sz: 'sz',
    Se: 'se',
    Ch: 'ch',
    Sy: 'sy',
    Tw: 'tw',
    Tj: 'tj',
    Tz: 'tz',
    Th: 'th',
    Tl: 'tl',
    Tg: 'tg',
    Tk: 'tk',
    To: 'to',
    Tt: 'tt',
    Tn: 'tn',
    Tr: 'tr',
    Tm: 'tm',
    Tc: 'tc',
    Tv: 'tv',
    Ug: 'ug',
    Ua: 'ua',
    Ae: 'ae',
    Gb: 'gb',
    Um: 'um',
    Uy: 'uy',
    Uz: 'uz',
    Vu: 'vu',
    Ve: 've',
    Vn: 'vn',
    Vg: 'vg',
    Vi: 'vi',
    Wf: 'wf',
    Eh: 'eh',
    Ye: 'ye',
    Zm: 'zm',
    Zw: 'zw'
} as const;

export type CreateAppRequestCountryEnum = typeof CreateAppRequestCountryEnum[keyof typeof CreateAppRequestCountryEnum];
export const CreateAppRequestProgramEnum = {
    Points: 'points',
    Cashback: 'cashback'
} as const;

export type CreateAppRequestProgramEnum = typeof CreateAppRequestProgramEnum[keyof typeof CreateAppRequestProgramEnum];

/**
 * 
 * @export
 * @interface CreateAppWithEnterpriseRequest
 */
export interface CreateAppWithEnterpriseRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'country': CreateAppWithEnterpriseRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'currency': CreateAppWithEnterpriseRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'companySize'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppWithEnterpriseRequest
     */
    'email'?: string;
}

export const CreateAppWithEnterpriseRequestCountryEnum = {
    Us: 'us',
    Af: 'af',
    Ax: 'ax',
    Al: 'al',
    Dz: 'dz',
    As: 'as',
    Ad: 'ad',
    Ao: 'ao',
    Ai: 'ai',
    Aq: 'aq',
    Ag: 'ag',
    Ar: 'ar',
    Am: 'am',
    Aw: 'aw',
    Au: 'au',
    At: 'at',
    Az: 'az',
    Bs: 'bs',
    Bh: 'bh',
    Bd: 'bd',
    Bb: 'bb',
    By: 'by',
    Be: 'be',
    Bz: 'bz',
    Bj: 'bj',
    Bm: 'bm',
    Bt: 'bt',
    Bo: 'bo',
    Bq: 'bq',
    Ba: 'ba',
    Bw: 'bw',
    Bv: 'bv',
    Br: 'br',
    Io: 'io',
    Bn: 'bn',
    Bg: 'bg',
    Bf: 'bf',
    Bi: 'bi',
    Kh: 'kh',
    Cm: 'cm',
    Ca: 'ca',
    Cv: 'cv',
    Ky: 'ky',
    Cf: 'cf',
    Td: 'td',
    Cl: 'cl',
    Cn: 'cn',
    Cx: 'cx',
    Cc: 'cc',
    Co: 'co',
    Km: 'km',
    Cg: 'cg',
    Cd: 'cd',
    Ck: 'ck',
    Cr: 'cr',
    Ci: 'ci',
    Hr: 'hr',
    Cu: 'cu',
    Cw: 'cw',
    Cy: 'cy',
    Cz: 'cz',
    Dk: 'dk',
    Dj: 'dj',
    Dm: 'dm',
    Do: 'do',
    Ec: 'ec',
    Eg: 'eg',
    Sv: 'sv',
    Gq: 'gq',
    Er: 'er',
    Ee: 'ee',
    Et: 'et',
    Fk: 'fk',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Gf: 'gf',
    Pf: 'pf',
    Tf: 'tf',
    Ga: 'ga',
    Gm: 'gm',
    Ge: 'ge',
    De: 'de',
    Gh: 'gh',
    Gi: 'gi',
    Gr: 'gr',
    Gl: 'gl',
    Gd: 'gd',
    Gp: 'gp',
    Gu: 'gu',
    Gt: 'gt',
    Gg: 'gg',
    Gn: 'gn',
    Gw: 'gw',
    Gy: 'gy',
    Ht: 'ht',
    Hm: 'hm',
    Va: 'va',
    Hn: 'hn',
    Hk: 'hk',
    Hu: 'hu',
    Is: 'is',
    In: 'in',
    Id: 'id',
    Ir: 'ir',
    Iq: 'iq',
    Ie: 'ie',
    Im: 'im',
    Il: 'il',
    It: 'it',
    Jm: 'jm',
    Jp: 'jp',
    Je: 'je',
    Jo: 'jo',
    Kz: 'kz',
    Ke: 'ke',
    Ki: 'ki',
    Kr: 'kr',
    Kp: 'kp',
    Kw: 'kw',
    Kg: 'kg',
    La: 'la',
    Lv: 'lv',
    Lb: 'lb',
    Ls: 'ls',
    Lr: 'lr',
    Ly: 'ly',
    Li: 'li',
    Lt: 'lt',
    Lu: 'lu',
    Mo: 'mo',
    Mk: 'mk',
    Mg: 'mg',
    Mw: 'mw',
    My: 'my',
    Mv: 'mv',
    Ml: 'ml',
    Mt: 'mt',
    Mh: 'mh',
    Mq: 'mq',
    Mr: 'mr',
    Mu: 'mu',
    Yt: 'yt',
    Mx: 'mx',
    Fm: 'fm',
    Md: 'md',
    Mc: 'mc',
    Mn: 'mn',
    Me: 'me',
    Ms: 'ms',
    Ma: 'ma',
    Mz: 'mz',
    Mm: 'mm',
    Na: 'na',
    Nr: 'nr',
    Np: 'np',
    Nl: 'nl',
    Nc: 'nc',
    Nz: 'nz',
    Ni: 'ni',
    Ne: 'ne',
    Ng: 'ng',
    Nu: 'nu',
    Nf: 'nf',
    Mp: 'mp',
    No: 'no',
    Om: 'om',
    Pk: 'pk',
    Pw: 'pw',
    Ps: 'ps',
    Pa: 'pa',
    Pg: 'pg',
    Py: 'py',
    Pe: 'pe',
    Ph: 'ph',
    Pn: 'pn',
    Pl: 'pl',
    Pt: 'pt',
    Pr: 'pr',
    Qa: 'qa',
    Re: 're',
    Ro: 'ro',
    Ru: 'ru',
    Rw: 'rw',
    Bl: 'bl',
    Sh: 'sh',
    Kn: 'kn',
    Lc: 'lc',
    Mf: 'mf',
    Pm: 'pm',
    Vc: 'vc',
    Ws: 'ws',
    Sm: 'sm',
    St: 'st',
    Sa: 'sa',
    Sn: 'sn',
    Rs: 'rs',
    Sc: 'sc',
    Sl: 'sl',
    Sg: 'sg',
    Sx: 'sx',
    Sk: 'sk',
    Si: 'si',
    Sb: 'sb',
    So: 'so',
    Za: 'za',
    Gs: 'gs',
    Ss: 'ss',
    Es: 'es',
    Lk: 'lk',
    Sd: 'sd',
    Sr: 'sr',
    Sj: 'sj',
    Sz: 'sz',
    Se: 'se',
    Ch: 'ch',
    Sy: 'sy',
    Tw: 'tw',
    Tj: 'tj',
    Tz: 'tz',
    Th: 'th',
    Tl: 'tl',
    Tg: 'tg',
    Tk: 'tk',
    To: 'to',
    Tt: 'tt',
    Tn: 'tn',
    Tr: 'tr',
    Tm: 'tm',
    Tc: 'tc',
    Tv: 'tv',
    Ug: 'ug',
    Ua: 'ua',
    Ae: 'ae',
    Gb: 'gb',
    Um: 'um',
    Uy: 'uy',
    Uz: 'uz',
    Vu: 'vu',
    Ve: 've',
    Vn: 'vn',
    Vg: 'vg',
    Vi: 'vi',
    Wf: 'wf',
    Eh: 'eh',
    Ye: 'ye',
    Zm: 'zm',
    Zw: 'zw'
} as const;

export type CreateAppWithEnterpriseRequestCountryEnum = typeof CreateAppWithEnterpriseRequestCountryEnum[keyof typeof CreateAppWithEnterpriseRequestCountryEnum];
export const CreateAppWithEnterpriseRequestCurrencyEnum = {
    Aed: 'aed',
    Afn: 'afn',
    All: 'all',
    Amd: 'amd',
    Ang: 'ang',
    Aoa: 'aoa',
    Ars: 'ars',
    Aud: 'aud',
    Awg: 'awg',
    Azn: 'azn',
    Bam: 'bam',
    Bbd: 'bbd',
    Bdt: 'bdt',
    Bgn: 'bgn',
    Bhd: 'bhd',
    Bif: 'bif',
    Bmd: 'bmd',
    Bnd: 'bnd',
    Bob: 'bob',
    Brl: 'brl',
    Bsd: 'bsd',
    Btn: 'btn',
    Bwp: 'bwp',
    Byn: 'byn',
    Bzd: 'bzd',
    Cad: 'cad',
    Cdf: 'cdf',
    Chf: 'chf',
    Clp: 'clp',
    Cny: 'cny',
    Cop: 'cop',
    Crc: 'crc',
    Cuc: 'cuc',
    Cup: 'cup',
    Cve: 'cve',
    Czk: 'czk',
    Djf: 'djf',
    Dkk: 'dkk',
    Dop: 'dop',
    Dzd: 'dzd',
    Egp: 'egp',
    Ern: 'ern',
    Etb: 'etb',
    Eur: 'eur',
    Fjd: 'fjd',
    Fkp: 'fkp',
    Gbp: 'gbp',
    Gel: 'gel',
    Ghs: 'ghs',
    Gip: 'gip',
    Gmd: 'gmd',
    Gnf: 'gnf',
    Gtq: 'gtq',
    Gyd: 'gyd',
    Hkd: 'hkd',
    Hnl: 'hnl',
    Hrk: 'hrk',
    Htg: 'htg',
    Huf: 'huf',
    Idr: 'idr',
    Ils: 'ils',
    Inr: 'inr',
    Iqd: 'iqd',
    Irr: 'irr',
    Isk: 'isk',
    Jmd: 'jmd',
    Jod: 'jod',
    Jpy: 'jpy',
    Kes: 'kes',
    Kgs: 'kgs',
    Khr: 'khr',
    Kmf: 'kmf',
    Kpw: 'kpw',
    Krw: 'krw',
    Kwd: 'kwd',
    Kyd: 'kyd',
    Kzt: 'kzt',
    Lak: 'lak',
    Lbp: 'lbp',
    Lkr: 'lkr',
    Lrd: 'lrd',
    Lsl: 'lsl',
    Lyd: 'lyd',
    Mad: 'mad',
    Mdl: 'mdl',
    Mga: 'mga',
    Mkd: 'mkd',
    Mmk: 'mmk',
    Mnt: 'mnt',
    Mop: 'mop',
    Mru: 'mru',
    Mur: 'mur',
    Mvr: 'mvr',
    Mwk: 'mwk',
    Mxn: 'mxn',
    Myr: 'myr',
    Mzn: 'mzn',
    Nad: 'nad',
    Ngn: 'ngn',
    Nio: 'nio',
    Nok: 'nok',
    Npr: 'npr',
    Nzd: 'nzd',
    Omr: 'omr',
    Pab: 'pab',
    Pen: 'pen',
    Pgk: 'pgk',
    Php: 'php',
    Pkr: 'pkr',
    Pln: 'pln',
    Pyg: 'pyg',
    Qar: 'qar',
    Ron: 'ron',
    Rsd: 'rsd',
    Rub: 'rub',
    Rwf: 'rwf',
    Sar: 'sar',
    Sbd: 'sbd',
    Scr: 'scr',
    Sdg: 'sdg',
    Sek: 'sek',
    Sgd: 'sgd',
    Shp: 'shp',
    Sll: 'sll',
    Sos: 'sos',
    Srd: 'srd',
    Ssp: 'ssp',
    Stn: 'stn',
    Svc: 'svc',
    Syp: 'syp',
    Szl: 'szl',
    Thb: 'thb',
    Tjs: 'tjs',
    Tmt: 'tmt',
    Tnd: 'tnd',
    Top: 'top',
    Try: 'try',
    Ttd: 'ttd',
    Twd: 'twd',
    Tzs: 'tzs',
    Uah: 'uah',
    Ugx: 'ugx',
    Usd: 'usd',
    Uyu: 'uyu',
    Uzs: 'uzs',
    Ves: 'ves',
    Vnd: 'vnd',
    Vuv: 'vuv',
    Wst: 'wst',
    Xaf: 'xaf',
    Xcd: 'xcd',
    Xof: 'xof',
    Xpf: 'xpf',
    Yer: 'yer',
    Zar: 'zar',
    Zmw: 'zmw',
    Zwl: 'zwl'
} as const;

export type CreateAppWithEnterpriseRequestCurrencyEnum = typeof CreateAppWithEnterpriseRequestCurrencyEnum[keyof typeof CreateAppWithEnterpriseRequestCurrencyEnum];

/**
 * 
 * @export
 * @interface CreateThemeRequest
 */
export interface CreateThemeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateThemeRequest
     */
    'language'?: CreateThemeRequestLanguageEnum;
    /**
     * 
     * @type {ThemePointsRequest}
     * @memberof CreateThemeRequest
     */
    'points'?: ThemePointsRequest;
    /**
     * 
     * @type {PaletteRequest}
     * @memberof CreateThemeRequest
     */
    'palette'?: PaletteRequest;
    /**
     * 
     * @type {LauncherRequest}
     * @memberof CreateThemeRequest
     */
    'launcher'?: LauncherRequest;
    /**
     * 
     * @type {OnboardingRequest}
     * @memberof CreateThemeRequest
     */
    'onboarding'?: OnboardingRequest;
    /**
     * 
     * @type {WidgetRequest}
     * @memberof CreateThemeRequest
     */
    'widget'?: WidgetRequest;
    /**
     * 
     * @type {CharacterRequest}
     * @memberof CreateThemeRequest
     */
    'character'?: CharacterRequest;
    /**
     * 
     * @type {CurrencyRequest}
     * @memberof CreateThemeRequest
     */
    'currency'?: CurrencyRequest;
    /**
     * 
     * @type {MainRequest}
     * @memberof CreateThemeRequest
     */
    'main'?: MainRequest;
    /**
     * 
     * @type {CardRequest}
     * @memberof CreateThemeRequest
     */
    'card'?: CardRequest;
    /**
     * 
     * @type {OnboardingIntroRequest}
     * @memberof CreateThemeRequest
     */
    'chest'?: OnboardingIntroRequest;
    /**
     * 
     * @type {OnboardingIntroRequest}
     * @memberof CreateThemeRequest
     */
    'expChest'?: OnboardingIntroRequest;
    /**
     * 
     * @type {NotificationRequest}
     * @memberof CreateThemeRequest
     */
    'notification'?: NotificationRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateThemeRequest
     */
    'font'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateThemeRequest
     */
    'supportedFonts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateThemeRequest
     */
    'mode'?: CreateThemeRequestModeEnum;
    /**
     * 
     * @type {LevelUpRequest}
     * @memberof CreateThemeRequest
     */
    'levelUp'?: LevelUpRequest;
    /**
     * 
     * @type {object}
     * @memberof CreateThemeRequest
     */
    'metadata'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateThemeRequest
     */
    'tabs'?: object;
    /**
     * 
     * @type {PromotionThemeRequest}
     * @memberof CreateThemeRequest
     */
    'promotion'?: PromotionThemeRequest;
    /**
     * 
     * @type {LockedPopupRequest}
     * @memberof CreateThemeRequest
     */
    'lockedPopup'?: LockedPopupRequest;
    /**
     * 
     * @type {object}
     * @memberof CreateThemeRequest
     */
    'sounds'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateThemeRequest
     */
    'avatars'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateThemeRequest
     */
    'branding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateThemeRequest
     */
    'hideDiscountPopups'?: boolean;
    /**
     * 
     * @type {BooleanPerDeviceRequest}
     * @memberof CreateThemeRequest
     */
    'hidePopups'?: BooleanPerDeviceRequest;
    /**
     * 
     * @type {ThemeVisibilityRequest}
     * @memberof CreateThemeRequest
     */
    'visibility'?: ThemeVisibilityRequest;
    /**
     * 
     * @type {SliderRequest}
     * @memberof CreateThemeRequest
     */
    'slider'?: SliderRequest;
    /**
     * 
     * @type {MemberAccessRequest}
     * @memberof CreateThemeRequest
     */
    'memberAccess'?: MemberAccessRequest;
}

export const CreateThemeRequestLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type CreateThemeRequestLanguageEnum = typeof CreateThemeRequestLanguageEnum[keyof typeof CreateThemeRequestLanguageEnum];
export const CreateThemeRequestModeEnum = {
    Dark: 'dark',
    Light: 'light'
} as const;

export type CreateThemeRequestModeEnum = typeof CreateThemeRequestModeEnum[keyof typeof CreateThemeRequestModeEnum];

/**
 * 
 * @export
 * @interface CurrencyDto
 */
export interface CurrencyDto {
    /**
     * 
     * @type {object}
     * @memberof CurrencyDto
     */
    'name': object;
    /**
     * 
     * @type {string}
     * @memberof CurrencyDto
     */
    'icon': string | null;
}
/**
 * 
 * @export
 * @interface CurrencyRequest
 */
export interface CurrencyRequest {
    /**
     * 
     * @type {object}
     * @memberof CurrencyRequest
     */
    'name': object;
    /**
     * 
     * @type {string}
     * @memberof CurrencyRequest
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface CurrentPointsAmountDto
 */
export interface CurrentPointsAmountDto {
    /**
     * 
     * @type {number}
     * @memberof CurrentPointsAmountDto
     */
    'gems': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentPointsAmountDto
     */
    'exp': number;
}
/**
 * 
 * @export
 * @interface CurrentPointsRecordDto
 */
export interface CurrentPointsRecordDto {
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'user': string;
    /**
     * 
     * @type {CurrentPointsAmountDto}
     * @memberof CurrentPointsRecordDto
     */
    'points': CurrentPointsAmountDto;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'type': CurrentPointsRecordDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'redemptionType'?: CurrentPointsRecordDtoRedemptionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'expiry': string | null;
    /**
     * 
     * @type {object}
     * @memberof CurrentPointsRecordDto
     */
    'metadata': object;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'status': CurrentPointsRecordDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'deletionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPointsRecordDto
     */
    'createdAt': string;
}

export const CurrentPointsRecordDtoTypeEnum = {
    Missions: 'missions',
    Admin: 'admin',
    Games: 'games',
    Purchases: 'purchases',
    OfflinePurchases: 'offlinePurchases',
    Surveys: 'surveys',
    RedeemBack: 'redeemBack',
    Migrated: 'migrated',
    Campaign: 'campaign',
    DiscountCodesCreated: 'discountCodes.created',
    DiscountCodesDeleted: 'discountCodes.deleted',
    Treasures: 'treasures',
    TransactionsRest: 'transactions.rest',
    Referrals: 'referrals',
    ReferralSignUp: 'referralSignUp',
    ReferralPurchase: 'referralPurchase',
    RedemptionsRest: 'redemptions.rest',
    AdminRest: 'admin.rest',
    WelcomeGift: 'welcomeGift',
    Training: 'training',
    Quiz: 'quiz',
    Challenge: 'challenge',
    Kpis: 'kpis'
} as const;

export type CurrentPointsRecordDtoTypeEnum = typeof CurrentPointsRecordDtoTypeEnum[keyof typeof CurrentPointsRecordDtoTypeEnum];
export const CurrentPointsRecordDtoRedemptionTypeEnum = {
    DiscountCode: 'discountCode',
    CompetitionReward: 'competitionReward',
    Direct: 'direct'
} as const;

export type CurrentPointsRecordDtoRedemptionTypeEnum = typeof CurrentPointsRecordDtoRedemptionTypeEnum[keyof typeof CurrentPointsRecordDtoRedemptionTypeEnum];
export const CurrentPointsRecordDtoStatusEnum = {
    Available: 'available',
    Expired: 'expired',
    Redeemed: 'redeemed',
    Applied: 'applied',
    InRedemption: 'inRedemption',
    Empty: 'empty',
    RemainFromDiscount: 'remainFromDiscount',
    Deleted: 'deleted'
} as const;

export type CurrentPointsRecordDtoStatusEnum = typeof CurrentPointsRecordDtoStatusEnum[keyof typeof CurrentPointsRecordDtoStatusEnum];

/**
 * 
 * @export
 * @interface CurrentPointsRecordsResponse
 */
export interface CurrentPointsRecordsResponse {
    /**
     * 
     * @type {Array<CurrentPointsRecordDto>}
     * @memberof CurrentPointsRecordsResponse
     */
    'points': Array<CurrentPointsRecordDto>;
    /**
     * 
     * @type {number}
     * @memberof CurrentPointsRecordsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CurrentPointsToExpireResponse
 */
export interface CurrentPointsToExpireResponse {
    /**
     * 
     * @type {number}
     * @memberof CurrentPointsToExpireResponse
     */
    'points': number;
}
/**
 * 
 * @export
 * @interface DepartmentCreateRequest
 */
export interface DepartmentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateRequest
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DepartmentDto
 */
export interface DepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentDto
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface DepartmentResponse
 */
export interface DepartmentResponse {
    /**
     * 
     * @type {DepartmentDto}
     * @memberof DepartmentResponse
     */
    'department': DepartmentDto;
}
/**
 * 
 * @export
 * @interface DepartmentUpdateRequest
 */
export interface DepartmentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateRequest
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateRequest
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentsResponse
 */
export interface DepartmentsResponse {
    /**
     * 
     * @type {Array<DepartmentDto>}
     * @memberof DepartmentsResponse
     */
    'departments': Array<DepartmentDto>;
    /**
     * 
     * @type {number}
     * @memberof DepartmentsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DiscountCodeExportRequest
 */
export interface DiscountCodeExportRequest {
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeExportRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DiscountCodeGetByCodeRequest
 */
export interface DiscountCodeGetByCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeGetByCodeRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface DiscountCodeResponse
 */
export interface DiscountCodeResponse {
    /**
     * 
     * @type {object}
     * @memberof DiscountCodeResponse
     */
    'discountCode': object;
}
/**
 * 
 * @export
 * @interface DiscountCodeUpdateRequest
 */
export interface DiscountCodeUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCodeUpdateRequest
     */
    'used'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeUpdateRequest
     */
    'usedAt'?: string;
}
/**
 * 
 * @export
 * @interface DiscountCodesResponse
 */
export interface DiscountCodesResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof DiscountCodesResponse
     */
    'discountCodes': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodesResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DiscountCreateRequest
 */
export interface DiscountCreateRequest {
    /**
     * 
     * @type {GemCreateRequest}
     * @memberof DiscountCreateRequest
     */
    'min'?: GemCreateRequest;
    /**
     * 
     * @type {GemCreateRequest}
     * @memberof DiscountCreateRequest
     */
    'max'?: GemCreateRequest;
    /**
     * 
     * @type {string}
     * @memberof DiscountCreateRequest
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountCreateRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof DiscountCreateRequest
     */
    'expiry'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCreateRequest
     */
    'singleUsage'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiscountCreateRequest
     */
    'bundles'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DiscountCreateRequest
     */
    'minimumOrderValue'?: number;
}
/**
 * 
 * @export
 * @interface DiscountResponse
 */
export interface DiscountResponse {
    /**
     * 
     * @type {object}
     * @memberof DiscountResponse
     */
    'discount': object;
}
/**
 * 
 * @export
 * @interface DiscountUpdateRequest
 */
export interface DiscountUpdateRequest {
    /**
     * 
     * @type {GemCreateRequest}
     * @memberof DiscountUpdateRequest
     */
    'min'?: GemCreateRequest;
    /**
     * 
     * @type {GemCreateRequest}
     * @memberof DiscountUpdateRequest
     */
    'max'?: GemCreateRequest;
    /**
     * 
     * @type {number}
     * @memberof DiscountUpdateRequest
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountUpdateRequest
     */
    'expiry'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountUpdateRequest
     */
    'singleUsage'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiscountUpdateRequest
     */
    'bundles'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DiscountUpdateRequest
     */
    'minimumOrderValue'?: number;
}
/**
 * 
 * @export
 * @interface DiscountsResponse
 */
export interface DiscountsResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof DiscountsResponse
     */
    'discounts': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof DiscountsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DurationRequest
 */
export interface DurationRequest {
    /**
     * 
     * @type {string}
     * @memberof DurationRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DurationRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DurationRequest
     */
    'endless'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DurationRequest
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface EffectedUsersByTierEvaluation
 */
export interface EffectedUsersByTierEvaluation {
    /**
     * 
     * @type {number}
     * @memberof EffectedUsersByTierEvaluation
     */
    'effectedMemebers': number;
}
/**
 * 
 * @export
 * @interface EmployeeDetailDto
 */
export interface EmployeeDetailDto {
    /**
     * User role
     * @type {string}
     * @memberof EmployeeDetailDto
     */
    'role': string;
    /**
     * Manager ID
     * @type {string}
     * @memberof EmployeeDetailDto
     */
    'manager': string | null;
    /**
     * Organization Id
     * @type {string}
     * @memberof EmployeeDetailDto
     */
    'organization': string;
    /**
     * Department ID
     * @type {string}
     * @memberof EmployeeDetailDto
     */
    'department': string;
    /**
     * Location ID
     * @type {string}
     * @memberof EmployeeDetailDto
     */
    'location': string;
}
/**
 * 
 * @export
 * @interface EmployeeDetailRequest
 */
export interface EmployeeDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetailRequest
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetailRequest
     */
    'manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetailRequest
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetailRequest
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetailRequest
     */
    'location': string;
}
/**
 * 
 * @export
 * @interface EnterpriseDataCreateRequest
 */
export interface EnterpriseDataCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'companyWebsite'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'companySize'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataCreateRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface EnterpriseDataDto
 */
export interface EnterpriseDataDto {
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'companyWebsite'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'companySize'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataDto
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface EnterpriseDataResponse
 */
export interface EnterpriseDataResponse {
    /**
     * 
     * @type {EnterpriseDataDto}
     * @memberof EnterpriseDataResponse
     */
    'enterpriseData': EnterpriseDataDto;
}
/**
 * 
 * @export
 * @interface EnterpriseDataUpdateRequest
 */
export interface EnterpriseDataUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'companyWebsite'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'companySize'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterpriseDataUpdateRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface FaqCreateRequest
 */
export interface FaqCreateRequest {
    /**
     * 
     * @type {object}
     * @memberof FaqCreateRequest
     */
    'question': object;
    /**
     * 
     * @type {object}
     * @memberof FaqCreateRequest
     */
    'answer': object;
}
/**
 * 
 * @export
 * @interface FaqDto
 */
export interface FaqDto {
    /**
     * 
     * @type {string}
     * @memberof FaqDto
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof FaqDto
     */
    'question': object;
    /**
     * 
     * @type {object}
     * @memberof FaqDto
     */
    'answer': object;
}
/**
 * 
 * @export
 * @interface FaqResponse
 */
export interface FaqResponse {
    /**
     * 
     * @type {FaqDto}
     * @memberof FaqResponse
     */
    'faq': FaqDto;
}
/**
 * 
 * @export
 * @interface FaqUpdateRequest
 */
export interface FaqUpdateRequest {
    /**
     * 
     * @type {object}
     * @memberof FaqUpdateRequest
     */
    'question'?: object;
    /**
     * 
     * @type {object}
     * @memberof FaqUpdateRequest
     */
    'answer'?: object;
}
/**
 * 
 * @export
 * @interface FaqsBulkCreateRequest
 */
export interface FaqsBulkCreateRequest {
    /**
     * 
     * @type {Array<FaqCreateRequest>}
     * @memberof FaqsBulkCreateRequest
     */
    'faqs': Array<FaqCreateRequest>;
}
/**
 * 
 * @export
 * @interface FaqsListResponse
 */
export interface FaqsListResponse {
    /**
     * 
     * @type {Array<FaqDto>}
     * @memberof FaqsListResponse
     */
    'faqs': Array<FaqDto>;
}
/**
 * 
 * @export
 * @interface FeaturesDto
 */
export interface FeaturesDto {
    /**
     * 
     * @type {string}
     * @memberof FeaturesDto
     */
    'image'?: string;
    /**
     * 
     * @type {object}
     * @memberof FeaturesDto
     */
    'text'?: object;
}
/**
 * 
 * @export
 * @interface GemCreateRequest
 */
export interface GemCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof GemCreateRequest
     */
    'gems'?: number;
}
/**
 * 
 * @export
 * @interface GenerateOtpRedeemResponse
 */
export interface GenerateOtpRedeemResponse {
    /**
     * OTP code sent to the customer email
     * @type {string}
     * @memberof GenerateOtpRedeemResponse
     */
    'code': string;
    /**
     * Points sent to redeem
     * @type {number}
     * @memberof GenerateOtpRedeemResponse
     */
    'points': number;
    /**
     * Discount value needs to be applied after the OTP verification
     * @type {number}
     * @memberof GenerateOtpRedeemResponse
     */
    'discount': number;
}
/**
 * 
 * @export
 * @interface GtmDto
 */
export interface GtmDto {
    /**
     * 
     * @type {string}
     * @memberof GtmDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof GtmDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GtmRequest
 */
export interface GtmRequest {
    /**
     * 
     * @type {string}
     * @memberof GtmRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GtmResponse
 */
export interface GtmResponse {
    /**
     * 
     * @type {GtmDto}
     * @memberof GtmResponse
     */
    'gtm': GtmDto | null;
}
/**
 * 
 * @export
 * @interface ImageModelDto
 */
export interface ImageModelDto {
    /**
     * 
     * @type {string}
     * @memberof ImageModelDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ImageModelDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ImageModelRequest
 */
export interface ImageModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageModelRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageModelRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ImagePerDeviceDto
 */
export interface ImagePerDeviceDto {
    /**
     * 
     * @type {ImageModelDto}
     * @memberof ImagePerDeviceDto
     */
    'desktop'?: ImageModelDto;
    /**
     * 
     * @type {ImageModelDto}
     * @memberof ImagePerDeviceDto
     */
    'mobile'?: ImageModelDto;
}
/**
 * 
 * @export
 * @interface ImagePerDeviceRequest
 */
export interface ImagePerDeviceRequest {
    /**
     * 
     * @type {ImageModelRequest}
     * @memberof ImagePerDeviceRequest
     */
    'desktop'?: ImageModelRequest;
    /**
     * 
     * @type {ImageModelRequest}
     * @memberof ImagePerDeviceRequest
     */
    'mobile'?: ImageModelRequest;
}
/**
 * 
 * @export
 * @interface ImagesDto
 */
export interface ImagesDto {
    /**
     * 
     * @type {ImagePerDeviceDto}
     * @memberof ImagesDto
     */
    'heroSectionImage': ImagePerDeviceDto;
    /**
     * 
     * @type {ImagePerDeviceDto}
     * @memberof ImagesDto
     */
    'welcomeGiftImage': ImagePerDeviceDto;
    /**
     * 
     * @type {ImagePerDeviceDto}
     * @memberof ImagesDto
     */
    'cashbackImage': ImagePerDeviceDto;
}
/**
 * 
 * @export
 * @interface ImagesRequest
 */
export interface ImagesRequest {
    /**
     * 
     * @type {ImagePerDeviceRequest}
     * @memberof ImagesRequest
     */
    'heroSectionImage': ImagePerDeviceRequest;
    /**
     * 
     * @type {ImagePerDeviceRequest}
     * @memberof ImagesRequest
     */
    'welcomeGiftImage': ImagePerDeviceRequest;
    /**
     * 
     * @type {ImagePerDeviceRequest}
     * @memberof ImagesRequest
     */
    'cashbackImage': ImagePerDeviceRequest;
}
/**
 * 
 * @export
 * @interface ImportRequest
 */
export interface ImportRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportRequest
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface InsertManyRequest
 */
export interface InsertManyRequest {
    /**
     * 
     * @type {Array<InsertManyUserClass>}
     * @memberof InsertManyRequest
     */
    'users': Array<InsertManyUserClass>;
}
/**
 * 
 * @export
 * @interface InsertManyUserClass
 */
export interface InsertManyUserClass {
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'userGroup': string;
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof InsertManyUserClass
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LandingRequestFeature
 */
export interface LandingRequestFeature {
    /**
     * 
     * @type {string}
     * @memberof LandingRequestFeature
     */
    'image'?: string;
    /**
     * 
     * @type {object}
     * @memberof LandingRequestFeature
     */
    'text'?: object;
}
/**
 * 
 * @export
 * @interface LauncherDto
 */
export interface LauncherDto {
    /**
     * 
     * @type {LauncherPositionDto}
     * @memberof LauncherDto
     */
    'position': LauncherPositionDto;
    /**
     * 
     * @type {LauncherTypeDto}
     * @memberof LauncherDto
     */
    'type': LauncherTypeDto;
    /**
     * 
     * @type {number}
     * @memberof LauncherDto
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface LauncherPositionDto
 */
export interface LauncherPositionDto {
    /**
     * 
     * @type {string}
     * @memberof LauncherPositionDto
     */
    'desktop': LauncherPositionDtoDesktopEnum;
    /**
     * 
     * @type {string}
     * @memberof LauncherPositionDto
     */
    'mobile': LauncherPositionDtoMobileEnum;
}

export const LauncherPositionDtoDesktopEnum = {
    Top: 'top',
    TopRight: 'topRight',
    Right: 'right',
    BottomRight: 'bottomRight',
    Bottom: 'bottom',
    BottomLeft: 'bottomLeft',
    Left: 'left',
    TopLeft: 'topLeft'
} as const;

export type LauncherPositionDtoDesktopEnum = typeof LauncherPositionDtoDesktopEnum[keyof typeof LauncherPositionDtoDesktopEnum];
export const LauncherPositionDtoMobileEnum = {
    Top: 'top',
    TopRight: 'topRight',
    Right: 'right',
    BottomRight: 'bottomRight',
    Bottom: 'bottom',
    BottomLeft: 'bottomLeft',
    Left: 'left',
    TopLeft: 'topLeft'
} as const;

export type LauncherPositionDtoMobileEnum = typeof LauncherPositionDtoMobileEnum[keyof typeof LauncherPositionDtoMobileEnum];

/**
 * 
 * @export
 * @interface LauncherPositionRequest
 */
export interface LauncherPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof LauncherPositionRequest
     */
    'desktop': LauncherPositionRequestDesktopEnum;
    /**
     * 
     * @type {string}
     * @memberof LauncherPositionRequest
     */
    'mobile': LauncherPositionRequestMobileEnum;
}

export const LauncherPositionRequestDesktopEnum = {
    Top: 'top',
    TopRight: 'topRight',
    Right: 'right',
    BottomRight: 'bottomRight',
    Bottom: 'bottom',
    BottomLeft: 'bottomLeft',
    Left: 'left',
    TopLeft: 'topLeft'
} as const;

export type LauncherPositionRequestDesktopEnum = typeof LauncherPositionRequestDesktopEnum[keyof typeof LauncherPositionRequestDesktopEnum];
export const LauncherPositionRequestMobileEnum = {
    Top: 'top',
    TopRight: 'topRight',
    Right: 'right',
    BottomRight: 'bottomRight',
    Bottom: 'bottom',
    BottomLeft: 'bottomLeft',
    Left: 'left',
    TopLeft: 'topLeft'
} as const;

export type LauncherPositionRequestMobileEnum = typeof LauncherPositionRequestMobileEnum[keyof typeof LauncherPositionRequestMobileEnum];

/**
 * 
 * @export
 * @interface LauncherRequest
 */
export interface LauncherRequest {
    /**
     * 
     * @type {LauncherPositionRequest}
     * @memberof LauncherRequest
     */
    'position': LauncherPositionRequest;
    /**
     * 
     * @type {LauncherTypesRequest}
     * @memberof LauncherRequest
     */
    'type': LauncherTypesRequest;
    /**
     * 
     * @type {number}
     * @memberof LauncherRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface LauncherTypeDto
 */
export interface LauncherTypeDto {
    /**
     * 
     * @type {string}
     * @memberof LauncherTypeDto
     */
    'desktop': LauncherTypeDtoDesktopEnum;
    /**
     * 
     * @type {string}
     * @memberof LauncherTypeDto
     */
    'mobile': LauncherTypeDtoMobileEnum;
}

export const LauncherTypeDtoDesktopEnum = {
    Bar: 'bar',
    FloatingIcon: 'floatingIcon'
} as const;

export type LauncherTypeDtoDesktopEnum = typeof LauncherTypeDtoDesktopEnum[keyof typeof LauncherTypeDtoDesktopEnum];
export const LauncherTypeDtoMobileEnum = {
    Bar: 'bar',
    FloatingIcon: 'floatingIcon'
} as const;

export type LauncherTypeDtoMobileEnum = typeof LauncherTypeDtoMobileEnum[keyof typeof LauncherTypeDtoMobileEnum];

/**
 * 
 * @export
 * @interface LauncherTypesRequest
 */
export interface LauncherTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof LauncherTypesRequest
     */
    'desktop': LauncherTypesRequestDesktopEnum;
    /**
     * 
     * @type {string}
     * @memberof LauncherTypesRequest
     */
    'mobile': LauncherTypesRequestMobileEnum;
}

export const LauncherTypesRequestDesktopEnum = {
    Bar: 'bar',
    FloatingIcon: 'floatingIcon'
} as const;

export type LauncherTypesRequestDesktopEnum = typeof LauncherTypesRequestDesktopEnum[keyof typeof LauncherTypesRequestDesktopEnum];
export const LauncherTypesRequestMobileEnum = {
    Bar: 'bar',
    FloatingIcon: 'floatingIcon'
} as const;

export type LauncherTypesRequestMobileEnum = typeof LauncherTypesRequestMobileEnum[keyof typeof LauncherTypesRequestMobileEnum];

/**
 * 
 * @export
 * @interface LevelUpDto
 */
export interface LevelUpDto {
    /**
     * 
     * @type {string}
     * @memberof LevelUpDto
     */
    'background'?: string;
}
/**
 * 
 * @export
 * @interface LevelUpRequest
 */
export interface LevelUpRequest {
    /**
     * 
     * @type {string}
     * @memberof LevelUpRequest
     */
    'background'?: string;
}
/**
 * 
 * @export
 * @interface LocationCreateRequest
 */
export interface LocationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationCreateRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LocationCreateRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface LocationResponse
 */
export interface LocationResponse {
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationResponse
     */
    'location': LocationDto;
}
/**
 * 
 * @export
 * @interface LocationUpdateRequest
 */
export interface LocationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdateRequest
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface LocationsResponse
 */
export interface LocationsResponse {
    /**
     * 
     * @type {Array<LocationDto>}
     * @memberof LocationsResponse
     */
    'locations': Array<LocationDto>;
    /**
     * 
     * @type {number}
     * @memberof LocationsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface LockedPopupDto
 */
export interface LockedPopupDto {
    /**
     * 
     * @type {string}
     * @memberof LockedPopupDto
     */
    'mission'?: string;
    /**
     * 
     * @type {string}
     * @memberof LockedPopupDto
     */
    'reward'?: string;
    /**
     * 
     * @type {string}
     * @memberof LockedPopupDto
     */
    'referral'?: string;
}
/**
 * 
 * @export
 * @interface LockedPopupRequest
 */
export interface LockedPopupRequest {
    /**
     * 
     * @type {string}
     * @memberof LockedPopupRequest
     */
    'mission'?: string;
    /**
     * 
     * @type {string}
     * @memberof LockedPopupRequest
     */
    'reward'?: string;
    /**
     * 
     * @type {string}
     * @memberof LockedPopupRequest
     */
    'referral'?: string;
}
/**
 * 
 * @export
 * @interface LojaCustomerRequest
 */
export interface LojaCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof LojaCustomerRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface LoyaltyPageCreateRequest
 */
export interface LoyaltyPageCreateRequest {
    /**
     * 
     * @type {SectionsToggelRequest}
     * @memberof LoyaltyPageCreateRequest
     */
    'enabledSections': SectionsToggelRequest;
    /**
     * 
     * @type {ColorsRequest}
     * @memberof LoyaltyPageCreateRequest
     */
    'colors': ColorsRequest;
    /**
     * 
     * @type {TextsRequest}
     * @memberof LoyaltyPageCreateRequest
     */
    'texts': TextsRequest;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageCreateRequest
     */
    'levelsType': LoyaltyPageCreateRequestLevelsTypeEnum;
    /**
     * 
     * @type {ImagesRequest}
     * @memberof LoyaltyPageCreateRequest
     */
    'images': ImagesRequest;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageCreateRequest
     */
    'font': string;
}

export const LoyaltyPageCreateRequestLevelsTypeEnum = {
    CashbackOnly: 'cashbackOnly',
    CashbackAndBenefits: 'cashbackAndBenefits'
} as const;

export type LoyaltyPageCreateRequestLevelsTypeEnum = typeof LoyaltyPageCreateRequestLevelsTypeEnum[keyof typeof LoyaltyPageCreateRequestLevelsTypeEnum];

/**
 * 
 * @export
 * @interface LoyaltyPageDto
 */
export interface LoyaltyPageDto {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageDto
     */
    'app': string;
    /**
     * 
     * @type {SectionsToggelDto}
     * @memberof LoyaltyPageDto
     */
    'enabledSections': SectionsToggelDto;
    /**
     * 
     * @type {ColorsDto}
     * @memberof LoyaltyPageDto
     */
    'colors': ColorsDto;
    /**
     * 
     * @type {TextsDto}
     * @memberof LoyaltyPageDto
     */
    'texts': TextsDto;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageDto
     */
    'levelsType': LoyaltyPageDtoLevelsTypeEnum;
    /**
     * 
     * @type {ImagesDto}
     * @memberof LoyaltyPageDto
     */
    'images': ImagesDto;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageDto
     */
    'font': string;
}

export const LoyaltyPageDtoLevelsTypeEnum = {
    CashbackOnly: 'cashbackOnly',
    CashbackAndBenefits: 'cashbackAndBenefits'
} as const;

export type LoyaltyPageDtoLevelsTypeEnum = typeof LoyaltyPageDtoLevelsTypeEnum[keyof typeof LoyaltyPageDtoLevelsTypeEnum];

/**
 * 
 * @export
 * @interface LoyaltyPageResponse
 */
export interface LoyaltyPageResponse {
    /**
     * 
     * @type {LoyaltyPageDto}
     * @memberof LoyaltyPageResponse
     */
    'loyaltyPage': LoyaltyPageDto | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPageUpdateRequest
 */
export interface LoyaltyPageUpdateRequest {
    /**
     * 
     * @type {SectionsToggelRequest}
     * @memberof LoyaltyPageUpdateRequest
     */
    'enabledSections'?: SectionsToggelRequest;
    /**
     * 
     * @type {ColorsRequest}
     * @memberof LoyaltyPageUpdateRequest
     */
    'colors'?: ColorsRequest;
    /**
     * 
     * @type {TextsRequest}
     * @memberof LoyaltyPageUpdateRequest
     */
    'texts'?: TextsRequest;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageUpdateRequest
     */
    'levelsType'?: LoyaltyPageUpdateRequestLevelsTypeEnum;
    /**
     * 
     * @type {ImagesRequest}
     * @memberof LoyaltyPageUpdateRequest
     */
    'images'?: ImagesRequest;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPageUpdateRequest
     */
    'font'?: string;
}

export const LoyaltyPageUpdateRequestLevelsTypeEnum = {
    CashbackOnly: 'cashbackOnly',
    CashbackAndBenefits: 'cashbackAndBenefits'
} as const;

export type LoyaltyPageUpdateRequestLevelsTypeEnum = typeof LoyaltyPageUpdateRequestLevelsTypeEnum[keyof typeof LoyaltyPageUpdateRequestLevelsTypeEnum];

/**
 * 
 * @export
 * @interface MagentoAccountAppCreateRequest
 */
export interface MagentoAccountAppCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'consumerKey': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'consumerSecret': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'tokenSecret': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'apiUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAccountAppCreateRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface MagentoAdminAppCreateRequest
 */
export interface MagentoAdminAppCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MagentoAdminAppCreateRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAdminAppCreateRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAdminAppCreateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAdminAppCreateRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MagentoAppCreateRequest
 */
export interface MagentoAppCreateRequest {
    /**
     * 
     * @type {CreateAppRequest}
     * @memberof MagentoAppCreateRequest
     */
    'app': CreateAppRequest;
    /**
     * 
     * @type {MagentoAccountAppCreateRequest}
     * @memberof MagentoAppCreateRequest
     */
    'account': MagentoAccountAppCreateRequest;
    /**
     * 
     * @type {MagentoAdminAppCreateRequest}
     * @memberof MagentoAppCreateRequest
     */
    'admin': MagentoAdminAppCreateRequest;
}
/**
 * 
 * @export
 * @interface MagentoAppDto
 */
export interface MagentoAppDto {
    /**
     * 
     * @type {string}
     * @memberof MagentoAppDto
     */
    'appKey': string;
    /**
     * 
     * @type {string}
     * @memberof MagentoAppDto
     */
    'appId': string;
}
/**
 * 
 * @export
 * @interface MagentoAppResponse
 */
export interface MagentoAppResponse {
    /**
     * 
     * @type {MagentoAppDto}
     * @memberof MagentoAppResponse
     */
    'app': MagentoAppDto;
}
/**
 * 
 * @export
 * @interface MainDto
 */
export interface MainDto {
    /**
     * 
     * @type {number}
     * @memberof MainDto
     */
    'borderRadius'?: number;
}
/**
 * 
 * @export
 * @interface MainRequest
 */
export interface MainRequest {
    /**
     * 
     * @type {number}
     * @memberof MainRequest
     */
    'borderRadius'?: number;
}
/**
 * 
 * @export
 * @interface MemberAccessDto
 */
export interface MemberAccessDto {
    /**
     * 
     * @type {string}
     * @memberof MemberAccessDto
     */
    'signInUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccessDto
     */
    'signUpUrl'?: string;
}
/**
 * 
 * @export
 * @interface MemberAccessRequest
 */
export interface MemberAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberAccessRequest
     */
    'signInUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccessRequest
     */
    'signUpUrl'?: string;
}
/**
 * 
 * @export
 * @interface NotificationAnimationDto
 */
export interface NotificationAnimationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationAnimationDto
     */
    'web'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationAnimationDto
     */
    'mobile'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationAnimationRequest
 */
export interface NotificationAnimationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationAnimationRequest
     */
    'web'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationAnimationRequest
     */
    'mobile'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {BooleanPerDeviceDto}
     * @memberof NotificationDto
     */
    'disabled': BooleanPerDeviceDto;
    /**
     * 
     * @type {NotificationItemDto}
     * @memberof NotificationDto
     */
    'welcome': NotificationItemDto;
}
/**
 * 
 * @export
 * @interface NotificationItemDto
 */
export interface NotificationItemDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationItemDto
     */
    'image': string | null;
    /**
     * 
     * @type {NotificationAnimationDto}
     * @memberof NotificationItemDto
     */
    'animation': NotificationAnimationDto | null;
}
/**
 * 
 * @export
 * @interface NotificationItemRequest
 */
export interface NotificationItemRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationItemRequest
     */
    'image'?: string;
    /**
     * 
     * @type {NotificationAnimationRequest}
     * @memberof NotificationItemRequest
     */
    'animation'?: NotificationAnimationRequest;
}
/**
 * 
 * @export
 * @interface NotificationRequest
 */
export interface NotificationRequest {
    /**
     * 
     * @type {NotificationItemRequest}
     * @memberof NotificationRequest
     */
    'welcome'?: NotificationItemRequest;
    /**
     * 
     * @type {BooleanPerDeviceRequest}
     * @memberof NotificationRequest
     */
    'disabled'?: BooleanPerDeviceRequest;
}
/**
 * 
 * @export
 * @interface OnboardingCongratsDto
 */
export interface OnboardingCongratsDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingCongratsDto
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface OnboardingCongratsRequest
 */
export interface OnboardingCongratsRequest {
    /**
     * 
     * @type {string}
     * @memberof OnboardingCongratsRequest
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingCongratsRequest
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface OnboardingDto
 */
export interface OnboardingDto {
    /**
     * 
     * @type {OnboardingLandingDto}
     * @memberof OnboardingDto
     */
    'landing'?: OnboardingLandingDto | null;
    /**
     * 
     * @type {OnboardingCongratsDto}
     * @memberof OnboardingDto
     */
    'congrats'?: OnboardingCongratsDto | null;
    /**
     * 
     * @type {OnboardingIntroDto}
     * @memberof OnboardingDto
     */
    'intro'?: OnboardingIntroDto | null;
    /**
     * 
     * @type {OnboardingWelcomeDto}
     * @memberof OnboardingDto
     */
    'welcome'?: OnboardingWelcomeDto | null;
}
/**
 * 
 * @export
 * @interface OnboardingIntroDto
 */
export interface OnboardingIntroDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingIntroDto
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingIntroDto
     */
    'animation'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingIntroDto
     */
    'variant'?: string;
    /**
     * 
     * @type {object}
     * @memberof OnboardingIntroDto
     */
    'text'?: object;
}
/**
 * 
 * @export
 * @interface OnboardingIntroRequest
 */
export interface OnboardingIntroRequest {
    /**
     * 
     * @type {string}
     * @memberof OnboardingIntroRequest
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingIntroRequest
     */
    'animation'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingIntroRequest
     */
    'variant'?: string;
    /**
     * 
     * @type {object}
     * @memberof OnboardingIntroRequest
     */
    'text'?: object;
}
/**
 * 
 * @export
 * @interface OnboardingLandingDto
 */
export interface OnboardingLandingDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingLandingDto
     */
    'imagesStyle'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnboardingLandingDto
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OnboardingLandingDto
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingLandingDto
     */
    'bgColor'?: string;
    /**
     * 
     * @type {object}
     * @memberof OnboardingLandingDto
     */
    'message'?: object;
    /**
     * 
     * @type {object}
     * @memberof OnboardingLandingDto
     */
    'button'?: object;
    /**
     * 
     * @type {Array<FeaturesDto>}
     * @memberof OnboardingLandingDto
     */
    'features'?: Array<FeaturesDto>;
}
/**
 * 
 * @export
 * @interface OnboardingLandingRequest
 */
export interface OnboardingLandingRequest {
    /**
     * 
     * @type {string}
     * @memberof OnboardingLandingRequest
     */
    'imagesStyle'?: OnboardingLandingRequestImagesStyleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnboardingLandingRequest
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OnboardingLandingRequest
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingLandingRequest
     */
    'bgColor'?: string;
    /**
     * 
     * @type {object}
     * @memberof OnboardingLandingRequest
     */
    'message'?: object;
    /**
     * 
     * @type {object}
     * @memberof OnboardingLandingRequest
     */
    'secondaryMessage'?: object;
    /**
     * 
     * @type {object}
     * @memberof OnboardingLandingRequest
     */
    'button'?: object;
    /**
     * 
     * @type {Array<LandingRequestFeature>}
     * @memberof OnboardingLandingRequest
     */
    'features'?: Array<LandingRequestFeature>;
}

export const OnboardingLandingRequestImagesStyleEnum = {
    Images: 'images',
    Illustrations: 'illustrations'
} as const;

export type OnboardingLandingRequestImagesStyleEnum = typeof OnboardingLandingRequestImagesStyleEnum[keyof typeof OnboardingLandingRequestImagesStyleEnum];

/**
 * 
 * @export
 * @interface OnboardingRequest
 */
export interface OnboardingRequest {
    /**
     * 
     * @type {OnboardingLandingRequest}
     * @memberof OnboardingRequest
     */
    'landing': OnboardingLandingRequest;
    /**
     * 
     * @type {OnboardingCongratsRequest}
     * @memberof OnboardingRequest
     */
    'congrats': OnboardingCongratsRequest;
    /**
     * 
     * @type {OnboardingIntroRequest}
     * @memberof OnboardingRequest
     */
    'intro': OnboardingIntroRequest;
    /**
     * 
     * @type {OnboardingWelcomeRequest}
     * @memberof OnboardingRequest
     */
    'welcome': OnboardingWelcomeRequest;
}
/**
 * 
 * @export
 * @interface OnboardingWelcomeDto
 */
export interface OnboardingWelcomeDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingWelcomeDto
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface OnboardingWelcomeRequest
 */
export interface OnboardingWelcomeRequest {
    /**
     * 
     * @type {string}
     * @memberof OnboardingWelcomeRequest
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingWelcomeRequest
     */
    'animation'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationCreateRequest
 */
export interface OrganizationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateRequest
     */
    'logo': string;
    /**
     * 
     * @type {BrandingColorsRequest}
     * @memberof OrganizationCreateRequest
     */
    'colors': BrandingColorsRequest;
}
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'logo': string;
    /**
     * 
     * @type {BrandingColorsDto}
     * @memberof OrganizationDto
     */
    'colors': BrandingColorsDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDto
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationResponse
     */
    'organization': OrganizationDto;
}
/**
 * 
 * @export
 * @interface OrganizationUpdateRequest
 */
export interface OrganizationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdateRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdateRequest
     */
    'logo': string;
    /**
     * 
     * @type {BrandingColorsRequest}
     * @memberof OrganizationUpdateRequest
     */
    'colors'?: BrandingColorsRequest;
}
/**
 * 
 * @export
 * @interface OrganizationsResponse
 */
export interface OrganizationsResponse {
    /**
     * 
     * @type {Array<OrganizationDto>}
     * @memberof OrganizationsResponse
     */
    'organizations': Array<OrganizationDto>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface OtpRedeemCreateRequest
 */
export interface OtpRedeemCreateRequest {
    /**
     * User email address
     * @type {string}
     * @memberof OtpRedeemCreateRequest
     */
    'email': string;
    /**
     * Points to redeem
     * @type {number}
     * @memberof OtpRedeemCreateRequest
     */
    'points': number;
}
/**
 * 
 * @export
 * @interface OtpRedeemVerifyRequest
 */
export interface OtpRedeemVerifyRequest {
    /**
     * User email address
     * @type {string}
     * @memberof OtpRedeemVerifyRequest
     */
    'email': string;
    /**
     * OTP code
     * @type {string}
     * @memberof OtpRedeemVerifyRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PaletteDto
 */
export interface PaletteDto {
    /**
     * 
     * @type {ColorDto}
     * @memberof PaletteDto
     */
    'primary'?: ColorDto;
    /**
     * 
     * @type {ColorDto}
     * @memberof PaletteDto
     */
    'secondary'?: ColorDto;
    /**
     * 
     * @type {ColorDto}
     * @memberof PaletteDto
     */
    'info'?: ColorDto;
    /**
     * 
     * @type {ColorDto}
     * @memberof PaletteDto
     */
    'success'?: ColorDto;
    /**
     * 
     * @type {ColorDto}
     * @memberof PaletteDto
     */
    'error'?: ColorDto;
    /**
     * 
     * @type {TextDto}
     * @memberof PaletteDto
     */
    'text'?: TextDto;
    /**
     * 
     * @type {BackgroundDto}
     * @memberof PaletteDto
     */
    'background'?: BackgroundDto;
}
/**
 * 
 * @export
 * @interface PaletteRequest
 */
export interface PaletteRequest {
    /**
     * 
     * @type {ColorRequest}
     * @memberof PaletteRequest
     */
    'primary'?: ColorRequest;
    /**
     * 
     * @type {ColorRequest}
     * @memberof PaletteRequest
     */
    'secondary'?: ColorRequest;
    /**
     * 
     * @type {ColorRequest}
     * @memberof PaletteRequest
     */
    'info'?: ColorRequest;
    /**
     * 
     * @type {ColorRequest}
     * @memberof PaletteRequest
     */
    'success'?: ColorRequest;
    /**
     * 
     * @type {ColorRequest}
     * @memberof PaletteRequest
     */
    'error'?: ColorRequest;
    /**
     * 
     * @type {TextRequest}
     * @memberof PaletteRequest
     */
    'text'?: TextRequest;
    /**
     * 
     * @type {BackgroundRequest}
     * @memberof PaletteRequest
     */
    'background'?: BackgroundRequest;
}
/**
 * 
 * @export
 * @interface PerformanceList
 */
export interface PerformanceList {
    /**
     * 
     * @type {number}
     * @memberof PerformanceList
     */
    'count': number;
    /**
     * 
     * @type {Array<UserPerformance>}
     * @memberof PerformanceList
     */
    'performance': Array<UserPerformance>;
}
/**
 * 
 * @export
 * @interface PhoneUpdateRequest
 */
export interface PhoneUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PhoneUpdateRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface PhoneVerifyRequest
 */
export interface PhoneVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof PhoneVerifyRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PhoneVerifyRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface PointModelDto
 */
export interface PointModelDto {
    /**
     * 
     * @type {PointsDto}
     * @memberof PointModelDto
     */
    'gems': PointsDto;
    /**
     * 
     * @type {PointsDto}
     * @memberof PointModelDto
     */
    'exp': PointsDto;
}
/**
 * 
 * @export
 * @interface PointsDto
 */
export interface PointsDto {
    /**
     * 
     * @type {object}
     * @memberof PointsDto
     */
    'name': object;
    /**
     * 
     * @type {string}
     * @memberof PointsDto
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface PointsExpiryDto
 */
export interface PointsExpiryDto {
    /**
     * 
     * @type {boolean}
     * @memberof PointsExpiryDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof PointsExpiryDto
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof PointsExpiryDto
     */
    'unit': PointsExpiryDtoUnitEnum;
}

export const PointsExpiryDtoUnitEnum = {
    Day: 'day',
    Month: 'month'
} as const;

export type PointsExpiryDtoUnitEnum = typeof PointsExpiryDtoUnitEnum[keyof typeof PointsExpiryDtoUnitEnum];

/**
 * 
 * @export
 * @interface PointsGainRequest
 */
export interface PointsGainRequest {
    /**
     * 
     * @type {string}
     * @memberof PointsGainRequest
     */
    'expiry'?: string;
    /**
     * 
     * @type {number}
     * @memberof PointsGainRequest
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof PointsGainRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PointsGainRequest
     */
    'type': PointsGainRequestTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof PointsGainRequest
     */
    'metadata': object;
}

export const PointsGainRequestTypeEnum = {
    Missions: 'missions',
    Admin: 'admin',
    Games: 'games',
    Purchases: 'purchases',
    OfflinePurchases: 'offlinePurchases',
    Surveys: 'surveys',
    RedeemBack: 'redeemBack',
    Migrated: 'migrated',
    Campaign: 'campaign',
    DiscountCodesCreated: 'discountCodes.created',
    DiscountCodesDeleted: 'discountCodes.deleted',
    Treasures: 'treasures',
    TransactionsRest: 'transactions.rest',
    Referrals: 'referrals',
    ReferralSignUp: 'referralSignUp',
    ReferralPurchase: 'referralPurchase',
    RedemptionsRest: 'redemptions.rest',
    AdminRest: 'admin.rest',
    WelcomeGift: 'welcomeGift',
    Training: 'training',
    Quiz: 'quiz',
    Challenge: 'challenge',
    Kpis: 'kpis'
} as const;

export type PointsGainRequestTypeEnum = typeof PointsGainRequestTypeEnum[keyof typeof PointsGainRequestTypeEnum];

/**
 * 
 * @export
 * @interface PointsRedemptionRequest
 */
export interface PointsRedemptionRequest {
    /**
     * 
     * @type {number}
     * @memberof PointsRedemptionRequest
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof PointsRedemptionRequest
     */
    'redemptionType': PointsRedemptionRequestRedemptionTypeEnum;
}

export const PointsRedemptionRequestRedemptionTypeEnum = {
    DiscountCode: 'discountCode',
    CompetitionReward: 'competitionReward',
    Direct: 'direct'
} as const;

export type PointsRedemptionRequestRedemptionTypeEnum = typeof PointsRedemptionRequestRedemptionTypeEnum[keyof typeof PointsRedemptionRequestRedemptionTypeEnum];

/**
 * 
 * @export
 * @interface PointsUpdate
 */
export interface PointsUpdate {
    /**
     * 
     * @type {number}
     * @memberof PointsUpdate
     */
    'gems'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointsUpdate
     */
    'exp'?: number;
}
/**
 * 
 * @export
 * @interface PopupColorsModel
 */
export interface PopupColorsModel {
    /**
     * 
     * @type {string}
     * @memberof PopupColorsModel
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof PopupColorsModel
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof PopupColorsModel
     */
    'bg': string;
    /**
     * 
     * @type {string}
     * @memberof PopupColorsModel
     */
    'btnText': string;
    /**
     * 
     * @type {string}
     * @memberof PopupColorsModel
     */
    'btnBg': string;
}
/**
 * 
 * @export
 * @interface PopupConditionRequest
 */
export interface PopupConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof PopupConditionRequest
     */
    'field': PopupConditionRequestFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PopupConditionRequest
     */
    'operation': PopupConditionRequestOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof PopupConditionRequest
     */
    'value'?: string;
}

export const PopupConditionRequestFieldEnum = {
    WelcomeGiftEarned: 'welcomeGiftEarned',
    UserId: 'userId',
    Location: 'location',
    ReferralEnabled: 'referralEnabled'
} as const;

export type PopupConditionRequestFieldEnum = typeof PopupConditionRequestFieldEnum[keyof typeof PopupConditionRequestFieldEnum];
export const PopupConditionRequestOperationEnum = {
    Equals: 'equals',
    Contains: 'contains',
    GreaterThan: 'greaterThan',
    Exists: 'exists',
    NotExists: 'notExists'
} as const;

export type PopupConditionRequestOperationEnum = typeof PopupConditionRequestOperationEnum[keyof typeof PopupConditionRequestOperationEnum];

/**
 * 
 * @export
 * @interface PopupCreateRequest
 */
export interface PopupCreateRequest {
    /**
     * 
     * @type {object}
     * @memberof PopupCreateRequest
     */
    'name': object;
    /**
     * 
     * @type {object}
     * @memberof PopupCreateRequest
     */
    'subject'?: object;
    /**
     * 
     * @type {object}
     * @memberof PopupCreateRequest
     */
    'body'?: object;
    /**
     * 
     * @type {PopupImageRequest}
     * @memberof PopupCreateRequest
     */
    'image'?: PopupImageRequest;
    /**
     * 
     * @type {object}
     * @memberof PopupCreateRequest
     */
    'goals': object;
    /**
     * 
     * @type {object}
     * @memberof PopupCreateRequest
     */
    'description': object;
    /**
     * 
     * @type {object}
     * @memberof PopupCreateRequest
     */
    'behavior': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopupCreateRequest
     */
    'configurableFields': Array<PopupCreateRequestConfigurableFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopupCreateRequest
     */
    'requiredFields'?: Array<PopupCreateRequestRequiredFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopupCreateRequest
     */
    'predefinedType': PopupCreateRequestPredefinedTypeEnum;
    /**
     * 
     * @type {PopupCtaRequest}
     * @memberof PopupCreateRequest
     */
    'cta': PopupCtaRequest;
    /**
     * 
     * @type {Array<PopupConditionRequest>}
     * @memberof PopupCreateRequest
     */
    'conditions'?: Array<PopupConditionRequest>;
    /**
     * 
     * @type {PopupColorsModel}
     * @memberof PopupCreateRequest
     */
    'colors': PopupColorsModel;
    /**
     * 
     * @type {string}
     * @memberof PopupCreateRequest
     */
    'size'?: PopupCreateRequestSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof PopupCreateRequest
     */
    'location'?: PopupCreateRequestLocationEnum;
    /**
     * 
     * @type {TriggerDetailsRequest}
     * @memberof PopupCreateRequest
     */
    'triggerDetails': TriggerDetailsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PopupCreateRequest
     */
    'overlayEffect': boolean;
    /**
     * 
     * @type {DurationRequest}
     * @memberof PopupCreateRequest
     */
    'duration'?: DurationRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PopupCreateRequest
     */
    'enabled': boolean;
}

export const PopupCreateRequestConfigurableFieldsEnum = {
    Subject: 'subject',
    Body: 'body',
    CtaText: 'ctaText',
    CtaHref: 'ctaHref',
    Colors: 'colors',
    DiscountCode: 'discountCode',
    Image: 'image',
    StartDate: 'startDate',
    EndDate: 'endDate',
    EndTime: 'endTime',
    Duration: 'duration',
    CtaAction: 'ctaAction'
} as const;

export type PopupCreateRequestConfigurableFieldsEnum = typeof PopupCreateRequestConfigurableFieldsEnum[keyof typeof PopupCreateRequestConfigurableFieldsEnum];
export const PopupCreateRequestRequiredFieldsEnum = {
    Subject: 'subject',
    Body: 'body',
    CtaText: 'ctaText',
    CtaHref: 'ctaHref',
    Colors: 'colors',
    DiscountCode: 'discountCode',
    Image: 'image',
    StartDate: 'startDate',
    EndDate: 'endDate',
    EndTime: 'endTime',
    Duration: 'duration',
    CtaAction: 'ctaAction'
} as const;

export type PopupCreateRequestRequiredFieldsEnum = typeof PopupCreateRequestRequiredFieldsEnum[keyof typeof PopupCreateRequestRequiredFieldsEnum];
export const PopupCreateRequestPredefinedTypeEnum = {
    Referral: 'referral',
    WelcomeDiscount: 'welcomeDiscount',
    Countdown: 'countdown',
    JoinProgram: 'joinProgram',
    WelcomePoints: 'welcomePoints',
    Custom: 'custom'
} as const;

export type PopupCreateRequestPredefinedTypeEnum = typeof PopupCreateRequestPredefinedTypeEnum[keyof typeof PopupCreateRequestPredefinedTypeEnum];
export const PopupCreateRequestSizeEnum = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
} as const;

export type PopupCreateRequestSizeEnum = typeof PopupCreateRequestSizeEnum[keyof typeof PopupCreateRequestSizeEnum];
export const PopupCreateRequestLocationEnum = {
    Top: 'top',
    TopRight: 'top right',
    Bottom: 'bottom',
    BottomLeft: 'bottom left',
    BottomRight: 'bottom right',
    Left: 'left',
    TopLeft: 'top left',
    Right: 'right',
    Center: 'center'
} as const;

export type PopupCreateRequestLocationEnum = typeof PopupCreateRequestLocationEnum[keyof typeof PopupCreateRequestLocationEnum];

/**
 * 
 * @export
 * @interface PopupCtaRequest
 */
export interface PopupCtaRequest {
    /**
     * 
     * @type {object}
     * @memberof PopupCtaRequest
     */
    'text'?: object;
    /**
     * 
     * @type {string}
     * @memberof PopupCtaRequest
     */
    'action': PopupCtaRequestActionEnum;
    /**
     * 
     * @type {string}
     * @memberof PopupCtaRequest
     */
    'href'?: string;
    /**
     * 
     * @type {string}
     * @memberof PopupCtaRequest
     */
    'discountCode'?: string;
}

export const PopupCtaRequestActionEnum = {
    CopyCode: 'copyCode',
    Link: 'link',
    Login: 'login'
} as const;

export type PopupCtaRequestActionEnum = typeof PopupCtaRequestActionEnum[keyof typeof PopupCtaRequestActionEnum];

/**
 * 
 * @export
 * @interface PopupImageRequest
 */
export interface PopupImageRequest {
    /**
     * 
     * @type {string}
     * @memberof PopupImageRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PopupImageRequest
     */
    'size'?: PopupImageRequestSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof PopupImageRequest
     */
    'name'?: string;
}

export const PopupImageRequestSizeEnum = {
    Auto: 'auto',
    Cover: 'cover',
    Contain: 'contain'
} as const;

export type PopupImageRequestSizeEnum = typeof PopupImageRequestSizeEnum[keyof typeof PopupImageRequestSizeEnum];

/**
 * 
 * @export
 * @interface PopupUpdateRequest
 */
export interface PopupUpdateRequest {
    /**
     * 
     * @type {object}
     * @memberof PopupUpdateRequest
     */
    'name'?: object;
    /**
     * 
     * @type {object}
     * @memberof PopupUpdateRequest
     */
    'subject'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof PopupUpdateRequest
     */
    'body'?: object | null;
    /**
     * 
     * @type {PopupImageRequest}
     * @memberof PopupUpdateRequest
     */
    'image'?: PopupImageRequest | null;
    /**
     * 
     * @type {object}
     * @memberof PopupUpdateRequest
     */
    'goals'?: object;
    /**
     * 
     * @type {object}
     * @memberof PopupUpdateRequest
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof PopupUpdateRequest
     */
    'behavior'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopupUpdateRequest
     */
    'configurableFields'?: Array<PopupUpdateRequestConfigurableFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PopupUpdateRequest
     */
    'requiredFields'?: Array<PopupUpdateRequestRequiredFieldsEnum> | null;
    /**
     * 
     * @type {PopupCtaRequest}
     * @memberof PopupUpdateRequest
     */
    'cta'?: PopupCtaRequest;
    /**
     * 
     * @type {PopupConditionRequest}
     * @memberof PopupUpdateRequest
     */
    'conditions'?: PopupConditionRequest | null;
    /**
     * 
     * @type {PopupColorsModel}
     * @memberof PopupUpdateRequest
     */
    'colors'?: PopupColorsModel;
    /**
     * 
     * @type {string}
     * @memberof PopupUpdateRequest
     */
    'size'?: PopupUpdateRequestSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof PopupUpdateRequest
     */
    'location'?: PopupUpdateRequestLocationEnum;
    /**
     * 
     * @type {TriggerDetailsRequest}
     * @memberof PopupUpdateRequest
     */
    'triggerDetails'?: TriggerDetailsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PopupUpdateRequest
     */
    'overlayEffect'?: boolean;
    /**
     * 
     * @type {DurationRequest}
     * @memberof PopupUpdateRequest
     */
    'duration'?: DurationRequest | null;
    /**
     * 
     * @type {boolean}
     * @memberof PopupUpdateRequest
     */
    'enabled'?: boolean;
}

export const PopupUpdateRequestConfigurableFieldsEnum = {
    Subject: 'subject',
    Body: 'body',
    CtaText: 'ctaText',
    CtaHref: 'ctaHref',
    Colors: 'colors',
    DiscountCode: 'discountCode',
    Image: 'image',
    StartDate: 'startDate',
    EndDate: 'endDate',
    EndTime: 'endTime',
    Duration: 'duration',
    CtaAction: 'ctaAction'
} as const;

export type PopupUpdateRequestConfigurableFieldsEnum = typeof PopupUpdateRequestConfigurableFieldsEnum[keyof typeof PopupUpdateRequestConfigurableFieldsEnum];
export const PopupUpdateRequestRequiredFieldsEnum = {
    Subject: 'subject',
    Body: 'body',
    CtaText: 'ctaText',
    CtaHref: 'ctaHref',
    Colors: 'colors',
    DiscountCode: 'discountCode',
    Image: 'image',
    StartDate: 'startDate',
    EndDate: 'endDate',
    EndTime: 'endTime',
    Duration: 'duration',
    CtaAction: 'ctaAction'
} as const;

export type PopupUpdateRequestRequiredFieldsEnum = typeof PopupUpdateRequestRequiredFieldsEnum[keyof typeof PopupUpdateRequestRequiredFieldsEnum];
export const PopupUpdateRequestSizeEnum = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
} as const;

export type PopupUpdateRequestSizeEnum = typeof PopupUpdateRequestSizeEnum[keyof typeof PopupUpdateRequestSizeEnum];
export const PopupUpdateRequestLocationEnum = {
    Top: 'top',
    TopRight: 'top right',
    Bottom: 'bottom',
    BottomLeft: 'bottom left',
    BottomRight: 'bottom right',
    Left: 'left',
    TopLeft: 'top left',
    Right: 'right',
    Center: 'center'
} as const;

export type PopupUpdateRequestLocationEnum = typeof PopupUpdateRequestLocationEnum[keyof typeof PopupUpdateRequestLocationEnum];

/**
 * 
 * @export
 * @interface PopupsConfigDto
 */
export interface PopupsConfigDto {
    /**
     * 
     * @type {PopupsThemeDto}
     * @memberof PopupsConfigDto
     */
    'welcomeGift'?: PopupsThemeDto;
    /**
     * 
     * @type {PopupsThemeDto}
     * @memberof PopupsConfigDto
     */
    'cashback'?: PopupsThemeDto;
}
/**
 * 
 * @export
 * @interface PopupsThemeDto
 */
export interface PopupsThemeDto {
    /**
     * 
     * @type {string}
     * @memberof PopupsThemeDto
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface PreSignedUrlDto
 */
export interface PreSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof PreSignedUrlDto
     */
    'preSignedurl': string;
    /**
     * 
     * @type {string}
     * @memberof PreSignedUrlDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface PromotionCreateRequest
 */
export interface PromotionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PromotionCreateRequest
     */
    'type': PromotionCreateRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PromotionCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionCreateRequest
     */
    'utmSource'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionCreateRequest
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionCreateRequest
     */
    'min'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionCreateRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionCreateRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionCreateRequest
     */
    'endTime'?: string;
    /**
     * 
     * @type {object}
     * @memberof PromotionCreateRequest
     */
    'metadata'?: object;
}

export const PromotionCreateRequestTypeEnum = {
    Campaign: 'campaign',
    Live: 'live'
} as const;

export type PromotionCreateRequestTypeEnum = typeof PromotionCreateRequestTypeEnum[keyof typeof PromotionCreateRequestTypeEnum];

/**
 * 
 * @export
 * @interface PromotionDto
 */
export interface PromotionDto {
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionDto
     */
    'views'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionDto
     */
    'clicks'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionDto
     */
    'purchases'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'utmSource'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionDto
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionDto
     */
    'min'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'type'?: PromotionDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {object}
     * @memberof PromotionDto
     */
    'metadata'?: object;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof PromotionDto
     */
    'minOrderValue': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'category'?: PromotionDtoCategoryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionDto
     */
    'promotionId'?: string;
}

export const PromotionDtoTypeEnum = {
    Campaign: 'campaign',
    Live: 'live'
} as const;

export type PromotionDtoTypeEnum = typeof PromotionDtoTypeEnum[keyof typeof PromotionDtoTypeEnum];
export const PromotionDtoCategoryEnum = {
    WelcomeCoupons: 'welcomeCoupons',
    Regular: 'regular'
} as const;

export type PromotionDtoCategoryEnum = typeof PromotionDtoCategoryEnum[keyof typeof PromotionDtoCategoryEnum];

/**
 * 
 * @export
 * @interface PromotionRequest
 */
export interface PromotionRequest {
    /**
     * 
     * @type {string}
     * @memberof PromotionRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionRequest
     */
    'utmSource'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionRequest
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionRequest
     */
    'min'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionRequest
     */
    'endTime'?: string;
    /**
     * 
     * @type {object}
     * @memberof PromotionRequest
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface PromotionResponse
 */
export interface PromotionResponse {
    /**
     * 
     * @type {PromotionDto}
     * @memberof PromotionResponse
     */
    'promotion': PromotionDto;
}
/**
 * 
 * @export
 * @interface PromotionThemeDto
 */
export interface PromotionThemeDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionThemeDto
     */
    'pieces'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PromotionThemeDto
     */
    'disabled'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionThemeDto
     */
    'animation'?: string;
}
/**
 * 
 * @export
 * @interface PromotionThemeRequest
 */
export interface PromotionThemeRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionThemeRequest
     */
    'pieces'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PromotionThemeRequest
     */
    'disabled'?: string;
}
/**
 * 
 * @export
 * @interface PromotionsResponse
 */
export interface PromotionsResponse {
    /**
     * 
     * @type {Array<PromotionDto>}
     * @memberof PromotionsResponse
     */
    'promotions': Array<PromotionDto>;
    /**
     * 
     * @type {number}
     * @memberof PromotionsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface RedeemRequest
 */
export interface RedeemRequest {
    /**
     * 
     * @type {string}
     * @memberof RedeemRequest
     */
    'discount': string;
    /**
     * 
     * @type {GemCreateRequest}
     * @memberof RedeemRequest
     */
    'points': GemCreateRequest;
    /**
     * 
     * @type {object}
     * @memberof RedeemRequest
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface ReferralRegisterRequest
 */
export interface ReferralRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof ReferralRegisterRequest
     */
    'referrer'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRegisterRequest
     */
    'source'?: ReferralRegisterRequestSourceEnum;
}

export const ReferralRegisterRequestSourceEnum = {
    OfflineOrder: 'offlineOrder',
    OnlineOrder: 'onlineOrder'
} as const;

export type ReferralRegisterRequestSourceEnum = typeof ReferralRegisterRequestSourceEnum[keyof typeof ReferralRegisterRequestSourceEnum];

/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface RoleCreateRequest
 */
export interface RoleCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreateRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreateRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreateRequest
     */
    'roleType': RoleCreateRequestRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RoleCreateRequest
     */
    'roleBy': RoleCreateRequestRoleByEnum;
    /**
     * 
     * @type {string}
     * @memberof RoleCreateRequest
     */
    'icon'?: string;
}

export const RoleCreateRequestRoleTypeEnum = {
    Admin: 'admin',
    Viewer: 'viewer',
    Employee: 'employee',
    Manager: 'manager'
} as const;

export type RoleCreateRequestRoleTypeEnum = typeof RoleCreateRequestRoleTypeEnum[keyof typeof RoleCreateRequestRoleTypeEnum];
export const RoleCreateRequestRoleByEnum = {
    All: 'all',
    Organization: 'organization',
    Department: 'department',
    None: 'none'
} as const;

export type RoleCreateRequestRoleByEnum = typeof RoleCreateRequestRoleByEnum[keyof typeof RoleCreateRequestRoleByEnum];

/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'roleType': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'roleBy': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'icon': string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {RoleDto}
     * @memberof RoleResponse
     */
    'role': RoleDto;
}
/**
 * 
 * @export
 * @interface RoleUpdateRequest
 */
export interface RoleUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateRequest
     */
    'roleType'?: RoleUpdateRequestRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateRequest
     */
    'roleBy'?: RoleUpdateRequestRoleByEnum;
    /**
     * 
     * @type {string}
     * @memberof RoleUpdateRequest
     */
    'icon'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleUpdateRequest
     */
    'isDeleted'?: boolean;
}

export const RoleUpdateRequestRoleTypeEnum = {
    Admin: 'admin',
    Viewer: 'viewer',
    Employee: 'employee',
    Manager: 'manager'
} as const;

export type RoleUpdateRequestRoleTypeEnum = typeof RoleUpdateRequestRoleTypeEnum[keyof typeof RoleUpdateRequestRoleTypeEnum];
export const RoleUpdateRequestRoleByEnum = {
    All: 'all',
    Organization: 'organization',
    Department: 'department',
    None: 'none'
} as const;

export type RoleUpdateRequestRoleByEnum = typeof RoleUpdateRequestRoleByEnum[keyof typeof RoleUpdateRequestRoleByEnum];

/**
 * 
 * @export
 * @interface RolesResponse
 */
export interface RolesResponse {
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RolesResponse
     */
    'roles': Array<RoleDto>;
    /**
     * 
     * @type {number}
     * @memberof RolesResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ScreenShotsDto
 */
export interface ScreenShotsDto {
    /**
     * 
     * @type {string}
     * @memberof ScreenShotsDto
     */
    'desktop': string | null;
    /**
     * 
     * @type {string}
     * @memberof ScreenShotsDto
     */
    'mobile': string | null;
}
/**
 * 
 * @export
 * @interface SectionsToggelDto
 */
export interface SectionsToggelDto {
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'heroSectionIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'promoteWelcomeGiftIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'promoteCashbackIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'howItWorksIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'activeMissionsIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'faqIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelDto
     */
    'levelsIsEnabled': boolean;
}
/**
 * 
 * @export
 * @interface SectionsToggelRequest
 */
export interface SectionsToggelRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'heroSectionIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'promoteWelcomeGiftIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'promoteCashbackIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'howItWorksIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'activeMissionsIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'faqIsEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsToggelRequest
     */
    'levelsIsEnabled': boolean;
}
/**
 * 
 * @export
 * @interface ServiceRedeemRequest
 */
export interface ServiceRedeemRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRedeemRequest
     */
    'source'?: ServiceRedeemRequestSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceRedeemRequest
     */
    'expiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceRedeemRequest
     */
    'discount': string;
    /**
     * 
     * @type {GemCreateRequest}
     * @memberof ServiceRedeemRequest
     */
    'points': GemCreateRequest;
    /**
     * 
     * @type {object}
     * @memberof ServiceRedeemRequest
     */
    'metadata'?: object;
}

export const ServiceRedeemRequestSourceEnum = {
    Redemption: 'redemption',
    Campaign: 'campaign'
} as const;

export type ServiceRedeemRequestSourceEnum = typeof ServiceRedeemRequestSourceEnum[keyof typeof ServiceRedeemRequestSourceEnum];

/**
 * 
 * @export
 * @interface SettingsRequest
 */
export interface SettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SettingsRequest
     */
    'showCheckoutPopup': boolean;
}
/**
 * 
 * @export
 * @interface SettingsResponse
 */
export interface SettingsResponse {
    /**
     * 
     * @type {object}
     * @memberof SettingsResponse
     */
    'settings': object;
}
/**
 * 
 * @export
 * @interface SignedUrlRequest
 */
export interface SignedUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlRequest
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface SimpleDateDto
 */
export interface SimpleDateDto {
    /**
     * Year
     * @type {number}
     * @memberof SimpleDateDto
     */
    'year': number;
    /**
     * Month
     * @type {number}
     * @memberof SimpleDateDto
     */
    'month': number;
    /**
     * Day
     * @type {number}
     * @memberof SimpleDateDto
     */
    'day': number;
}
/**
 * 
 * @export
 * @interface SimpleDateRequest
 */
export interface SimpleDateRequest {
    /**
     * 
     * @type {number}
     * @memberof SimpleDateRequest
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDateRequest
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDateRequest
     */
    'day': number;
}
/**
 * 
 * @export
 * @interface SliderDto
 */
export interface SliderDto {
    /**
     * 
     * @type {boolean}
     * @memberof SliderDto
     */
    'cartSliderEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface SliderRequest
 */
export interface SliderRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SliderRequest
     */
    'cartSliderEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface StoreLeaderBoardResponse
 */
export interface StoreLeaderBoardResponse {
    /**
     * 
     * @type {number}
     * @memberof StoreLeaderBoardResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<StorePoints>}
     * @memberof StoreLeaderBoardResponse
     */
    'leaderBoard': Array<StorePoints>;
}
/**
 * 
 * @export
 * @interface StorePoints
 */
export interface StorePoints {
    /**
     * 
     * @type {object}
     * @memberof StorePoints
     */
    'department': object;
    /**
     * 
     * @type {number}
     * @memberof StorePoints
     */
    'progress': number;
}
/**
 * 
 * @export
 * @interface TabsDto
 */
export interface TabsDto {
    /**
     * 
     * @type {object}
     * @memberof TabsDto
     */
    'tabs'?: object;
}
/**
 * 
 * @export
 * @interface TextDto
 */
export interface TextDto {
    /**
     * 
     * @type {string}
     * @memberof TextDto
     */
    'primary'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextDto
     */
    'secondary'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextDto
     */
    'disabled'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextDto
     */
    'hint'?: string;
}
/**
 * 
 * @export
 * @interface TextRequest
 */
export interface TextRequest {
    /**
     * 
     * @type {string}
     * @memberof TextRequest
     */
    'primary'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextRequest
     */
    'secondary'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextRequest
     */
    'disabled'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextRequest
     */
    'hint'?: string;
}
/**
 * 
 * @export
 * @interface TextsDto
 */
export interface TextsDto {
    /**
     * 
     * @type {object}
     * @memberof TextsDto
     */
    'heroSectionButtonText': object;
    /**
     * 
     * @type {object}
     * @memberof TextsDto
     */
    'heroSectionText': object;
    /**
     * 
     * @type {object}
     * @memberof TextsDto
     */
    'WelcomeGiftText': object;
    /**
     * 
     * @type {object}
     * @memberof TextsDto
     */
    'cashbackText': object;
}
/**
 * 
 * @export
 * @interface TextsRequest
 */
export interface TextsRequest {
    /**
     * 
     * @type {object}
     * @memberof TextsRequest
     */
    'heroSectionButtonText': object;
    /**
     * 
     * @type {object}
     * @memberof TextsRequest
     */
    'heroSectionText': object;
    /**
     * 
     * @type {object}
     * @memberof TextsRequest
     */
    'WelcomeGiftText': object;
    /**
     * 
     * @type {object}
     * @memberof TextsRequest
     */
    'cashbackText': object;
}
/**
 * 
 * @export
 * @interface ThemeDto
 */
export interface ThemeDto {
    /**
     * 
     * @type {string}
     * @memberof ThemeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThemeDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof ThemeDto
     */
    'language'?: ThemeDtoLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof ThemeDto
     */
    'font': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThemeDto
     */
    'supportedFonts'?: Array<string>;
    /**
     * 
     * @type {PointModelDto}
     * @memberof ThemeDto
     */
    'points': PointModelDto;
    /**
     * 
     * @type {CurrencyDto}
     * @memberof ThemeDto
     */
    'currency'?: CurrencyDto;
    /**
     * 
     * @type {PaletteDto}
     * @memberof ThemeDto
     */
    'palette'?: PaletteDto;
    /**
     * 
     * @type {LauncherDto}
     * @memberof ThemeDto
     */
    'launcher': LauncherDto;
    /**
     * 
     * @type {OnboardingDto}
     * @memberof ThemeDto
     */
    'onboarding': OnboardingDto;
    /**
     * 
     * @type {WidgetDto}
     * @memberof ThemeDto
     */
    'widget': WidgetDto;
    /**
     * 
     * @type {CharacterDto}
     * @memberof ThemeDto
     */
    'character'?: CharacterDto;
    /**
     * 
     * @type {NotificationDto}
     * @memberof ThemeDto
     */
    'notification': NotificationDto;
    /**
     * 
     * @type {MainDto}
     * @memberof ThemeDto
     */
    'main': MainDto;
    /**
     * 
     * @type {CardDto}
     * @memberof ThemeDto
     */
    'card': CardDto;
    /**
     * 
     * @type {string}
     * @memberof ThemeDto
     */
    'mode': ThemeDtoModeEnum;
    /**
     * 
     * @type {OnboardingIntroDto}
     * @memberof ThemeDto
     */
    'chest': OnboardingIntroDto;
    /**
     * 
     * @type {OnboardingIntroDto}
     * @memberof ThemeDto
     */
    'expChest': OnboardingIntroDto;
    /**
     * 
     * @type {LevelUpDto}
     * @memberof ThemeDto
     */
    'levelUp'?: LevelUpDto;
    /**
     * 
     * @type {TabsDto}
     * @memberof ThemeDto
     */
    'tabs'?: TabsDto;
    /**
     * 
     * @type {PromotionThemeDto}
     * @memberof ThemeDto
     */
    'promotion'?: PromotionThemeDto;
    /**
     * 
     * @type {object}
     * @memberof ThemeDto
     */
    'sounds'?: object;
    /**
     * 
     * @type {object}
     * @memberof ThemeDto
     */
    'metadata'?: object;
    /**
     * 
     * @type {LockedPopupDto}
     * @memberof ThemeDto
     */
    'lockedPopup'?: LockedPopupDto;
    /**
     * 
     * @type {boolean}
     * @memberof ThemeDto
     */
    'branding'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThemeDto
     */
    'avatars'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ThemeDto
     */
    'image'?: object;
    /**
     * 
     * @type {object}
     * @memberof ThemeDto
     */
    'svg'?: object;
    /**
     * 
     * @type {VisibilityDto}
     * @memberof ThemeDto
     */
    'visibility'?: VisibilityDto;
    /**
     * 
     * @type {string}
     * @memberof ThemeDto
     */
    'createdAt': string;
    /**
     * 
     * @type {MemberAccessDto}
     * @memberof ThemeDto
     */
    'memberAccess'?: MemberAccessDto;
    /**
     * 
     * @type {SliderDto}
     * @memberof ThemeDto
     */
    'slider'?: SliderDto;
    /**
     * 
     * @type {boolean}
     * @memberof ThemeDto
     */
    'hideDiscountPopups'?: boolean;
    /**
     * 
     * @type {BooleanPerDeviceDto}
     * @memberof ThemeDto
     */
    'hidePopups'?: BooleanPerDeviceDto;
    /**
     * 
     * @type {PopupsConfigDto}
     * @memberof ThemeDto
     */
    'popupsConfig'?: PopupsConfigDto;
}

export const ThemeDtoLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type ThemeDtoLanguageEnum = typeof ThemeDtoLanguageEnum[keyof typeof ThemeDtoLanguageEnum];
export const ThemeDtoModeEnum = {
    Dark: 'dark',
    Light: 'light'
} as const;

export type ThemeDtoModeEnum = typeof ThemeDtoModeEnum[keyof typeof ThemeDtoModeEnum];

/**
 * 
 * @export
 * @interface ThemePointsRequest
 */
export interface ThemePointsRequest {
    /**
     * 
     * @type {ThemePointsTypeRequest}
     * @memberof ThemePointsRequest
     */
    'gems': ThemePointsTypeRequest;
    /**
     * 
     * @type {ThemePointsTypeRequest}
     * @memberof ThemePointsRequest
     */
    'exp': ThemePointsTypeRequest;
}
/**
 * 
 * @export
 * @interface ThemePointsTypeRequest
 */
export interface ThemePointsTypeRequest {
    /**
     * 
     * @type {object}
     * @memberof ThemePointsTypeRequest
     */
    'name': object;
    /**
     * 
     * @type {string}
     * @memberof ThemePointsTypeRequest
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface ThemeVisibilityRequest
 */
export interface ThemeVisibilityRequest {
    /**
     * 
     * @type {string}
     * @memberof ThemeVisibilityRequest
     */
    'opt': ThemeVisibilityRequestOptEnum;
    /**
     * 
     * @type {string}
     * @memberof ThemeVisibilityRequest
     */
    'url': string;
}

export const ThemeVisibilityRequestOptEnum = {
    Is: 'is',
    IsNot: 'isNot'
} as const;

export type ThemeVisibilityRequestOptEnum = typeof ThemeVisibilityRequestOptEnum[keyof typeof ThemeVisibilityRequestOptEnum];

/**
 * 
 * @export
 * @interface ThemesResponse
 */
export interface ThemesResponse {
    /**
     * 
     * @type {ThemeDto}
     * @memberof ThemesResponse
     */
    'theme': ThemeDto;
}
/**
 * 
 * @export
 * @interface TierConfigCreateRequest
 */
export interface TierConfigCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TierConfigCreateRequest
     */
    'period': TierConfigCreateRequestPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigCreateRequest
     */
    'type': TierConfigCreateRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TierConfigCreateRequest
     */
    'enabled'?: boolean;
}

export const TierConfigCreateRequestPeriodEnum = {
    NoExpire: 'noExpire',
    HalfYear: 'halfYear',
    Yearly: 'yearly',
    BiYearly: 'biYearly'
} as const;

export type TierConfigCreateRequestPeriodEnum = typeof TierConfigCreateRequestPeriodEnum[keyof typeof TierConfigCreateRequestPeriodEnum];
export const TierConfigCreateRequestTypeEnum = {
    GainedPoints: 'gainedPoints',
    NumberOfPurchases: 'numberOfPurchases',
    ValueOfPurchases: 'valueOfPurchases',
    Exp: 'exp'
} as const;

export type TierConfigCreateRequestTypeEnum = typeof TierConfigCreateRequestTypeEnum[keyof typeof TierConfigCreateRequestTypeEnum];

/**
 * 
 * @export
 * @interface TierConfigDto
 */
export interface TierConfigDto {
    /**
     * 
     * @type {string}
     * @memberof TierConfigDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TierConfigDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof TierConfigDto
     */
    'type': TierConfigDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigDto
     */
    'period': TierConfigDtoPeriodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TierConfigDto
     */
    'enabled': boolean;
}

export const TierConfigDtoTypeEnum = {
    GainedPoints: 'gainedPoints',
    NumberOfPurchases: 'numberOfPurchases',
    ValueOfPurchases: 'valueOfPurchases',
    Exp: 'exp'
} as const;

export type TierConfigDtoTypeEnum = typeof TierConfigDtoTypeEnum[keyof typeof TierConfigDtoTypeEnum];
export const TierConfigDtoPeriodEnum = {
    NoExpire: 'noExpire',
    HalfYear: 'halfYear',
    Yearly: 'yearly',
    BiYearly: 'biYearly'
} as const;

export type TierConfigDtoPeriodEnum = typeof TierConfigDtoPeriodEnum[keyof typeof TierConfigDtoPeriodEnum];

/**
 * 
 * @export
 * @interface TierConfigResponse
 */
export interface TierConfigResponse {
    /**
     * 
     * @type {TierConfigDto}
     * @memberof TierConfigResponse
     */
    'config': TierConfigDto | null;
}
/**
 * 
 * @export
 * @interface TierConfigUpdateRequest
 */
export interface TierConfigUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TierConfigUpdateRequest
     */
    'period'?: TierConfigUpdateRequestPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TierConfigUpdateRequest
     */
    'type'?: TierConfigUpdateRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TierConfigUpdateRequest
     */
    'enabled'?: boolean;
}

export const TierConfigUpdateRequestPeriodEnum = {
    NoExpire: 'noExpire',
    HalfYear: 'halfYear',
    Yearly: 'yearly',
    BiYearly: 'biYearly'
} as const;

export type TierConfigUpdateRequestPeriodEnum = typeof TierConfigUpdateRequestPeriodEnum[keyof typeof TierConfigUpdateRequestPeriodEnum];
export const TierConfigUpdateRequestTypeEnum = {
    GainedPoints: 'gainedPoints',
    NumberOfPurchases: 'numberOfPurchases',
    ValueOfPurchases: 'valueOfPurchases',
    Exp: 'exp'
} as const;

export type TierConfigUpdateRequestTypeEnum = typeof TierConfigUpdateRequestTypeEnum[keyof typeof TierConfigUpdateRequestTypeEnum];

/**
 * 
 * @export
 * @interface TierCreateRequest
 */
export interface TierCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof TierCreateRequest
     */
    'order': number;
    /**
     * 
     * @type {object}
     * @memberof TierCreateRequest
     */
    'name': object;
    /**
     * 
     * @type {string}
     * @memberof TierCreateRequest
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof TierCreateRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TierCreateRequest
     */
    'color'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof TierCreateRequest
     */
    'benefits'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof TierCreateRequest
     */
    'neededAmount': number;
}
/**
 * 
 * @export
 * @interface TierDto
 */
export interface TierDto {
    /**
     * 
     * @type {string}
     * @memberof TierDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TierDto
     */
    'app': string;
    /**
     * 
     * @type {object}
     * @memberof TierDto
     */
    'name': object;
    /**
     * 
     * @type {string}
     * @memberof TierDto
     */
    'icon': string;
    /**
     * 
     * @type {number}
     * @memberof TierDto
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof TierDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TierDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TierDto
     */
    'color'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof TierDto
     */
    'benefits'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof TierDto
     */
    'neededAmount': number;
}
/**
 * 
 * @export
 * @interface TierProgressResponse
 */
export interface TierProgressResponse {
    /**
     * 
     * @type {TierDto}
     * @memberof TierProgressResponse
     */
    'current': TierDto | null;
    /**
     * 
     * @type {TierDto}
     * @memberof TierProgressResponse
     */
    'next': TierDto | null;
    /**
     * 
     * @type {number}
     * @memberof TierProgressResponse
     */
    'progress': number;
    /**
     * 
     * @type {number}
     * @memberof TierProgressResponse
     */
    'accumulated': number;
}
/**
 * 
 * @export
 * @interface TierResponse
 */
export interface TierResponse {
    /**
     * 
     * @type {TierDto}
     * @memberof TierResponse
     */
    'tier': TierDto | null;
}
/**
 * 
 * @export
 * @interface TierUpdateRequest
 */
export interface TierUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof TierUpdateRequest
     */
    'order'?: number;
    /**
     * 
     * @type {object}
     * @memberof TierUpdateRequest
     */
    'name'?: object;
    /**
     * 
     * @type {string}
     * @memberof TierUpdateRequest
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof TierUpdateRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TierUpdateRequest
     */
    'color'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof TierUpdateRequest
     */
    'benefits'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof TierUpdateRequest
     */
    'neededAmount'?: number;
}
/**
 * 
 * @export
 * @interface TiersResponse
 */
export interface TiersResponse {
    /**
     * 
     * @type {Array<TierDto>}
     * @memberof TiersResponse
     */
    'tiers': Array<TierDto>;
    /**
     * 
     * @type {number}
     * @memberof TiersResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface TotalAppPoints
 */
export interface TotalAppPoints {
    /**
     * 
     * @type {number}
     * @memberof TotalAppPoints
     */
    'points': number;
    /**
     * 
     * @type {number}
     * @memberof TotalAppPoints
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface TotalsDto
 */
export interface TotalsDto {
    /**
     * Total number of completed missions
     * @type {number}
     * @memberof TotalsDto
     */
    'numberOfCompletedMissions': number;
    /**
     * Total number of active points
     * @type {number}
     * @memberof TotalsDto
     */
    'totalActivePoints': number;
    /**
     * Total number of redeemed points
     * @type {number}
     * @memberof TotalsDto
     */
    'totalRedeemedPoints': number;
    /**
     * Total number of generated points
     * @type {number}
     * @memberof TotalsDto
     */
    'totalGeneratedPoints': number;
    /**
     * Total number of campaign disocunts
     * @type {number}
     * @memberof TotalsDto
     */
    'numberOfCreatedCampaignDiscounts': number;
    /**
     * Total number of used campaign discounts
     * @type {number}
     * @memberof TotalsDto
     */
    'numberOfUsedCampaignDiscounts': number;
    /**
     * Total number of orders
     * @type {number}
     * @memberof TotalsDto
     */
    'ordersCount': number;
    /**
     * Total sum of orders values
     * @type {number}
     * @memberof TotalsDto
     */
    'ordersTotal': number;
    /**
     * Total number of online orders
     * @type {number}
     * @memberof TotalsDto
     */
    'onlineOrdersCount': number;
    /**
     * Total sum of online orders values
     * @type {number}
     * @memberof TotalsDto
     */
    'onlineOrdersTotal': number;
    /**
     * Total number of offline orders
     * @type {number}
     * @memberof TotalsDto
     */
    'offlineOrdersCount': number;
    /**
     * Total sum of offline orders values
     * @type {number}
     * @memberof TotalsDto
     */
    'offlineOrdersTotal': number;
}
/**
 * 
 * @export
 * @interface TriggerDetailsRequest
 */
export interface TriggerDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof TriggerDetailsRequest
     */
    'trigger': TriggerDetailsRequestTriggerEnum;
    /**
     * 
     * @type {URLSettingRequest}
     * @memberof TriggerDetailsRequest
     */
    'targetingURL'?: URLSettingRequest;
    /**
     * 
     * @type {string}
     * @memberof TriggerDetailsRequest
     */
    'openAction'?: TriggerDetailsRequestOpenActionEnum;
    /**
     * 
     * @type {string}
     * @memberof TriggerDetailsRequest
     */
    'displayFrequency'?: TriggerDetailsRequestDisplayFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof TriggerDetailsRequest
     */
    'closeAction'?: TriggerDetailsRequestCloseActionEnum;
}

export const TriggerDetailsRequestTriggerEnum = {
    AutoOpen: 'autoOpen',
    ExitIntent: 'exitIntent'
} as const;

export type TriggerDetailsRequestTriggerEnum = typeof TriggerDetailsRequestTriggerEnum[keyof typeof TriggerDetailsRequestTriggerEnum];
export const TriggerDetailsRequestOpenActionEnum = {
    Auto: 'auto',
    Custom: 'custom'
} as const;

export type TriggerDetailsRequestOpenActionEnum = typeof TriggerDetailsRequestOpenActionEnum[keyof typeof TriggerDetailsRequestOpenActionEnum];
export const TriggerDetailsRequestDisplayFrequencyEnum = {
    Always: 'always',
    Once: 'once'
} as const;

export type TriggerDetailsRequestDisplayFrequencyEnum = typeof TriggerDetailsRequestDisplayFrequencyEnum[keyof typeof TriggerDetailsRequestDisplayFrequencyEnum];
export const TriggerDetailsRequestCloseActionEnum = {
    Clicked: 'clicked',
    Auto: 'auto'
} as const;

export type TriggerDetailsRequestCloseActionEnum = typeof TriggerDetailsRequestCloseActionEnum[keyof typeof TriggerDetailsRequestCloseActionEnum];

/**
 * 
 * @export
 * @interface URLSettingRequest
 */
export interface URLSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof URLSettingRequest
     */
    'displayCondition'?: URLSettingRequestDisplayConditionEnum;
    /**
     * 
     * @type {string}
     * @memberof URLSettingRequest
     */
    'url'?: string;
}

export const URLSettingRequestDisplayConditionEnum = {
    Equal: 'equal',
    Exclude: 'exclude',
    Always: 'always'
} as const;

export type URLSettingRequestDisplayConditionEnum = typeof URLSettingRequestDisplayConditionEnum[keyof typeof URLSettingRequestDisplayConditionEnum];

/**
 * 
 * @export
 * @interface UpdateAccountYampiRequest
 */
export interface UpdateAccountYampiRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountYampiRequest
     */
    'userToken': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountYampiRequest
     */
    'userSecretKey': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountYampiRequest
     */
    'alias': string;
}
/**
 * 
 * @export
 * @interface UpdateAppRequest
 */
export interface UpdateAppRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppRequest
     */
    'aov'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'language'?: UpdateAppRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'currency'?: UpdateAppRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'country'?: UpdateAppRequestCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppRequest
     */
    'timezone'?: number;
    /**
     * 
     * @type {AppPointsExpiryRequest}
     * @memberof UpdateAppRequest
     */
    'pointsExpiry'?: AppPointsExpiryRequest;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppRequest
     */
    'emails'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateAppRequest
     */
    'metadata'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppRequest
     */
    'program'?: UpdateAppRequestProgramEnum;
}

export const UpdateAppRequestLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type UpdateAppRequestLanguageEnum = typeof UpdateAppRequestLanguageEnum[keyof typeof UpdateAppRequestLanguageEnum];
export const UpdateAppRequestCurrencyEnum = {
    Aed: 'aed',
    Afn: 'afn',
    All: 'all',
    Amd: 'amd',
    Ang: 'ang',
    Aoa: 'aoa',
    Ars: 'ars',
    Aud: 'aud',
    Awg: 'awg',
    Azn: 'azn',
    Bam: 'bam',
    Bbd: 'bbd',
    Bdt: 'bdt',
    Bgn: 'bgn',
    Bhd: 'bhd',
    Bif: 'bif',
    Bmd: 'bmd',
    Bnd: 'bnd',
    Bob: 'bob',
    Brl: 'brl',
    Bsd: 'bsd',
    Btn: 'btn',
    Bwp: 'bwp',
    Byn: 'byn',
    Bzd: 'bzd',
    Cad: 'cad',
    Cdf: 'cdf',
    Chf: 'chf',
    Clp: 'clp',
    Cny: 'cny',
    Cop: 'cop',
    Crc: 'crc',
    Cuc: 'cuc',
    Cup: 'cup',
    Cve: 'cve',
    Czk: 'czk',
    Djf: 'djf',
    Dkk: 'dkk',
    Dop: 'dop',
    Dzd: 'dzd',
    Egp: 'egp',
    Ern: 'ern',
    Etb: 'etb',
    Eur: 'eur',
    Fjd: 'fjd',
    Fkp: 'fkp',
    Gbp: 'gbp',
    Gel: 'gel',
    Ghs: 'ghs',
    Gip: 'gip',
    Gmd: 'gmd',
    Gnf: 'gnf',
    Gtq: 'gtq',
    Gyd: 'gyd',
    Hkd: 'hkd',
    Hnl: 'hnl',
    Hrk: 'hrk',
    Htg: 'htg',
    Huf: 'huf',
    Idr: 'idr',
    Ils: 'ils',
    Inr: 'inr',
    Iqd: 'iqd',
    Irr: 'irr',
    Isk: 'isk',
    Jmd: 'jmd',
    Jod: 'jod',
    Jpy: 'jpy',
    Kes: 'kes',
    Kgs: 'kgs',
    Khr: 'khr',
    Kmf: 'kmf',
    Kpw: 'kpw',
    Krw: 'krw',
    Kwd: 'kwd',
    Kyd: 'kyd',
    Kzt: 'kzt',
    Lak: 'lak',
    Lbp: 'lbp',
    Lkr: 'lkr',
    Lrd: 'lrd',
    Lsl: 'lsl',
    Lyd: 'lyd',
    Mad: 'mad',
    Mdl: 'mdl',
    Mga: 'mga',
    Mkd: 'mkd',
    Mmk: 'mmk',
    Mnt: 'mnt',
    Mop: 'mop',
    Mru: 'mru',
    Mur: 'mur',
    Mvr: 'mvr',
    Mwk: 'mwk',
    Mxn: 'mxn',
    Myr: 'myr',
    Mzn: 'mzn',
    Nad: 'nad',
    Ngn: 'ngn',
    Nio: 'nio',
    Nok: 'nok',
    Npr: 'npr',
    Nzd: 'nzd',
    Omr: 'omr',
    Pab: 'pab',
    Pen: 'pen',
    Pgk: 'pgk',
    Php: 'php',
    Pkr: 'pkr',
    Pln: 'pln',
    Pyg: 'pyg',
    Qar: 'qar',
    Ron: 'ron',
    Rsd: 'rsd',
    Rub: 'rub',
    Rwf: 'rwf',
    Sar: 'sar',
    Sbd: 'sbd',
    Scr: 'scr',
    Sdg: 'sdg',
    Sek: 'sek',
    Sgd: 'sgd',
    Shp: 'shp',
    Sll: 'sll',
    Sos: 'sos',
    Srd: 'srd',
    Ssp: 'ssp',
    Stn: 'stn',
    Svc: 'svc',
    Syp: 'syp',
    Szl: 'szl',
    Thb: 'thb',
    Tjs: 'tjs',
    Tmt: 'tmt',
    Tnd: 'tnd',
    Top: 'top',
    Try: 'try',
    Ttd: 'ttd',
    Twd: 'twd',
    Tzs: 'tzs',
    Uah: 'uah',
    Ugx: 'ugx',
    Usd: 'usd',
    Uyu: 'uyu',
    Uzs: 'uzs',
    Ves: 'ves',
    Vnd: 'vnd',
    Vuv: 'vuv',
    Wst: 'wst',
    Xaf: 'xaf',
    Xcd: 'xcd',
    Xof: 'xof',
    Xpf: 'xpf',
    Yer: 'yer',
    Zar: 'zar',
    Zmw: 'zmw',
    Zwl: 'zwl'
} as const;

export type UpdateAppRequestCurrencyEnum = typeof UpdateAppRequestCurrencyEnum[keyof typeof UpdateAppRequestCurrencyEnum];
export const UpdateAppRequestCountryEnum = {
    Us: 'us',
    Af: 'af',
    Ax: 'ax',
    Al: 'al',
    Dz: 'dz',
    As: 'as',
    Ad: 'ad',
    Ao: 'ao',
    Ai: 'ai',
    Aq: 'aq',
    Ag: 'ag',
    Ar: 'ar',
    Am: 'am',
    Aw: 'aw',
    Au: 'au',
    At: 'at',
    Az: 'az',
    Bs: 'bs',
    Bh: 'bh',
    Bd: 'bd',
    Bb: 'bb',
    By: 'by',
    Be: 'be',
    Bz: 'bz',
    Bj: 'bj',
    Bm: 'bm',
    Bt: 'bt',
    Bo: 'bo',
    Bq: 'bq',
    Ba: 'ba',
    Bw: 'bw',
    Bv: 'bv',
    Br: 'br',
    Io: 'io',
    Bn: 'bn',
    Bg: 'bg',
    Bf: 'bf',
    Bi: 'bi',
    Kh: 'kh',
    Cm: 'cm',
    Ca: 'ca',
    Cv: 'cv',
    Ky: 'ky',
    Cf: 'cf',
    Td: 'td',
    Cl: 'cl',
    Cn: 'cn',
    Cx: 'cx',
    Cc: 'cc',
    Co: 'co',
    Km: 'km',
    Cg: 'cg',
    Cd: 'cd',
    Ck: 'ck',
    Cr: 'cr',
    Ci: 'ci',
    Hr: 'hr',
    Cu: 'cu',
    Cw: 'cw',
    Cy: 'cy',
    Cz: 'cz',
    Dk: 'dk',
    Dj: 'dj',
    Dm: 'dm',
    Do: 'do',
    Ec: 'ec',
    Eg: 'eg',
    Sv: 'sv',
    Gq: 'gq',
    Er: 'er',
    Ee: 'ee',
    Et: 'et',
    Fk: 'fk',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Gf: 'gf',
    Pf: 'pf',
    Tf: 'tf',
    Ga: 'ga',
    Gm: 'gm',
    Ge: 'ge',
    De: 'de',
    Gh: 'gh',
    Gi: 'gi',
    Gr: 'gr',
    Gl: 'gl',
    Gd: 'gd',
    Gp: 'gp',
    Gu: 'gu',
    Gt: 'gt',
    Gg: 'gg',
    Gn: 'gn',
    Gw: 'gw',
    Gy: 'gy',
    Ht: 'ht',
    Hm: 'hm',
    Va: 'va',
    Hn: 'hn',
    Hk: 'hk',
    Hu: 'hu',
    Is: 'is',
    In: 'in',
    Id: 'id',
    Ir: 'ir',
    Iq: 'iq',
    Ie: 'ie',
    Im: 'im',
    Il: 'il',
    It: 'it',
    Jm: 'jm',
    Jp: 'jp',
    Je: 'je',
    Jo: 'jo',
    Kz: 'kz',
    Ke: 'ke',
    Ki: 'ki',
    Kr: 'kr',
    Kp: 'kp',
    Kw: 'kw',
    Kg: 'kg',
    La: 'la',
    Lv: 'lv',
    Lb: 'lb',
    Ls: 'ls',
    Lr: 'lr',
    Ly: 'ly',
    Li: 'li',
    Lt: 'lt',
    Lu: 'lu',
    Mo: 'mo',
    Mk: 'mk',
    Mg: 'mg',
    Mw: 'mw',
    My: 'my',
    Mv: 'mv',
    Ml: 'ml',
    Mt: 'mt',
    Mh: 'mh',
    Mq: 'mq',
    Mr: 'mr',
    Mu: 'mu',
    Yt: 'yt',
    Mx: 'mx',
    Fm: 'fm',
    Md: 'md',
    Mc: 'mc',
    Mn: 'mn',
    Me: 'me',
    Ms: 'ms',
    Ma: 'ma',
    Mz: 'mz',
    Mm: 'mm',
    Na: 'na',
    Nr: 'nr',
    Np: 'np',
    Nl: 'nl',
    Nc: 'nc',
    Nz: 'nz',
    Ni: 'ni',
    Ne: 'ne',
    Ng: 'ng',
    Nu: 'nu',
    Nf: 'nf',
    Mp: 'mp',
    No: 'no',
    Om: 'om',
    Pk: 'pk',
    Pw: 'pw',
    Ps: 'ps',
    Pa: 'pa',
    Pg: 'pg',
    Py: 'py',
    Pe: 'pe',
    Ph: 'ph',
    Pn: 'pn',
    Pl: 'pl',
    Pt: 'pt',
    Pr: 'pr',
    Qa: 'qa',
    Re: 're',
    Ro: 'ro',
    Ru: 'ru',
    Rw: 'rw',
    Bl: 'bl',
    Sh: 'sh',
    Kn: 'kn',
    Lc: 'lc',
    Mf: 'mf',
    Pm: 'pm',
    Vc: 'vc',
    Ws: 'ws',
    Sm: 'sm',
    St: 'st',
    Sa: 'sa',
    Sn: 'sn',
    Rs: 'rs',
    Sc: 'sc',
    Sl: 'sl',
    Sg: 'sg',
    Sx: 'sx',
    Sk: 'sk',
    Si: 'si',
    Sb: 'sb',
    So: 'so',
    Za: 'za',
    Gs: 'gs',
    Ss: 'ss',
    Es: 'es',
    Lk: 'lk',
    Sd: 'sd',
    Sr: 'sr',
    Sj: 'sj',
    Sz: 'sz',
    Se: 'se',
    Ch: 'ch',
    Sy: 'sy',
    Tw: 'tw',
    Tj: 'tj',
    Tz: 'tz',
    Th: 'th',
    Tl: 'tl',
    Tg: 'tg',
    Tk: 'tk',
    To: 'to',
    Tt: 'tt',
    Tn: 'tn',
    Tr: 'tr',
    Tm: 'tm',
    Tc: 'tc',
    Tv: 'tv',
    Ug: 'ug',
    Ua: 'ua',
    Ae: 'ae',
    Gb: 'gb',
    Um: 'um',
    Uy: 'uy',
    Uz: 'uz',
    Vu: 'vu',
    Ve: 've',
    Vn: 'vn',
    Vg: 'vg',
    Vi: 'vi',
    Wf: 'wf',
    Eh: 'eh',
    Ye: 'ye',
    Zm: 'zm',
    Zw: 'zw'
} as const;

export type UpdateAppRequestCountryEnum = typeof UpdateAppRequestCountryEnum[keyof typeof UpdateAppRequestCountryEnum];
export const UpdateAppRequestProgramEnum = {
    Points: 'points',
    Cashback: 'cashback'
} as const;

export type UpdateAppRequestProgramEnum = typeof UpdateAppRequestProgramEnum[keyof typeof UpdateAppRequestProgramEnum];

/**
 * 
 * @export
 * @interface UpsertUser
 */
export interface UpsertUser {
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'userGroup': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUser
     */
    'supervisor'?: string;
}
/**
 * 
 * @export
 * @interface UpsertUsersRequest
 */
export interface UpsertUsersRequest {
    /**
     * 
     * @type {Array<UpsertUser>}
     * @memberof UpsertUsersRequest
     */
    'users': Array<UpsertUser>;
}
/**
 * 
 * @export
 * @interface UserAuthResponse
 */
export interface UserAuthResponse {
    /**
     * 
     * @type {UserDto}
     * @memberof UserAuthResponse
     */
    'user': UserDto;
    /**
     * 
     * @type {string}
     * @memberof UserAuthResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface UserChartData
 */
export interface UserChartData {
    /**
     * 
     * @type {string}
     * @memberof UserChartData
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof UserChartData
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UserChartsResponse
 */
export interface UserChartsResponse {
    /**
     * 
     * @type {Array<UserChartData>}
     * @memberof UserChartsResponse
     */
    'data': Array<UserChartData>;
    /**
     * 
     * @type {number}
     * @memberof UserChartsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * User unique identifier
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * App id
     * @type {string}
     * @memberof UserDto
     */
    'app': string;
    /**
     * User email
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * User name
     * @type {string}
     * @memberof UserDto
     */
    'name': string | null;
    /**
     * User first name
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string | null;
    /**
     * User last name
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string | null;
    /**
     * User creation source
     * @type {string}
     * @memberof UserDto
     */
    'source'?: UserDtoSourceEnum;
    /**
     * User gender
     * @type {object}
     * @memberof UserDto
     */
    'gender': UserDtoGenderEnum | null;
    /**
     * User phone number
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string;
    /**
     * User Unverified phone number
     * @type {string}
     * @memberof UserDto
     */
    'unverifiedPhone'?: string;
    /**
     * User external identifier
     * @type {string}
     * @memberof UserDto
     */
    'externalId': string | null;
    /**
     * Avatar
     * @type {string}
     * @memberof UserDto
     */
    'avatar': string | null;
    /**
     * User language
     * @type {string}
     * @memberof UserDto
     */
    'language': UserDtoLanguageEnum;
    /**
     * User country
     * @type {string}
     * @memberof UserDto
     */
    'country': string;
    /**
     * Pristine
     * @type {boolean}
     * @memberof UserDto
     */
    'pristine': boolean;
    /**
     * User CPF
     * @type {string}
     * @memberof UserDto
     */
    'cpf'?: string;
    /**
     * User current level
     * @type {number}
     * @memberof UserDto
     */
    'tier': number;
    /**
     * Date of last user level update
     * @type {string}
     * @memberof UserDto
     */
    'tierUpdatedDate': string | null;
    /**
     * User Badges
     * @type {Array<string>}
     * @memberof UserDto
     */
    'badges': Array<string>;
    /**
     * current user poitns
     * @type {UserPointsDto}
     * @memberof UserDto
     */
    'points': UserPointsDto;
    /**
     * User statitics
     * @type {TotalsDto}
     * @memberof UserDto
     */
    'totals': TotalsDto;
    /**
     * User referral status
     * @type {UserReferralsDto}
     * @memberof UserDto
     */
    'referral': UserReferralsDto;
    /**
     * User type, eg member, draft, prospect
     * @type {string}
     * @memberof UserDto
     */
    'type': UserDtoTypeEnum;
    /**
     * User Socials links
     * @type {UserSocialDto}
     * @memberof UserDto
     */
    'social': UserSocialDto;
    /**
     * User Currency
     * @type {string}
     * @memberof UserDto
     */
    'currency': string;
    /**
     * User last logn date
     * @type {string}
     * @memberof UserDto
     */
    'lastLogin': string | null;
    /**
     * Metadata
     * @type {object}
     * @memberof UserDto
     */
    'metadata': object | null;
    /**
     * User creation date
     * @type {string}
     * @memberof UserDto
     */
    'createdAt': string;
    /**
     * 
     * @type {UserDtoDateOfBirth}
     * @memberof UserDto
     */
    'dateOfBirth': UserDtoDateOfBirth | null;
    /**
     * User group id
     * @type {string}
     * @memberof UserDto
     */
    'userGroup': string | null;
    /**
     * User first login date
     * @type {boolean}
     * @memberof UserDto
     */
    'firstLogin': boolean | null;
    /**
     * User active status
     * @type {boolean}
     * @memberof UserDto
     */
    'active': boolean;
    /**
     * 
     * @type {UserDtoEmployeeDetail}
     * @memberof UserDto
     */
    'employeeDetail': UserDtoEmployeeDetail | null;
    /**
     * Identifier of the sales rep that created the user
     * @type {string}
     * @memberof UserDto
     */
    'creatorSalesRepId'?: string;
    /**
     * Indicates if users engage in transactions through both online and offline platforms
     * @type {boolean}
     * @memberof UserDto
     */
    'omniChannel': boolean;
}

export const UserDtoSourceEnum = {
    Online: 'online',
    Offline: 'offline'
} as const;

export type UserDtoSourceEnum = typeof UserDtoSourceEnum[keyof typeof UserDtoSourceEnum];
export const UserDtoGenderEnum = {
    Male: 'male',
    Female: 'female',
    PreferNotToSay: 'prefer not to say'
} as const;

export type UserDtoGenderEnum = typeof UserDtoGenderEnum[keyof typeof UserDtoGenderEnum];
export const UserDtoLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type UserDtoLanguageEnum = typeof UserDtoLanguageEnum[keyof typeof UserDtoLanguageEnum];
export const UserDtoTypeEnum = {
    Draft: 'draft',
    Member: 'member',
    Prospect: 'prospect',
    Registered: 'registered'
} as const;

export type UserDtoTypeEnum = typeof UserDtoTypeEnum[keyof typeof UserDtoTypeEnum];

/**
 * User birthday
 * @export
 * @interface UserDtoDateOfBirth
 */
export interface UserDtoDateOfBirth {
    /**
     * Year
     * @type {number}
     * @memberof UserDtoDateOfBirth
     */
    'year': number;
    /**
     * Month
     * @type {number}
     * @memberof UserDtoDateOfBirth
     */
    'month': number;
    /**
     * Day
     * @type {number}
     * @memberof UserDtoDateOfBirth
     */
    'day': number;
}
/**
 * User Employee details
 * @export
 * @interface UserDtoEmployeeDetail
 */
export interface UserDtoEmployeeDetail {
    /**
     * User role
     * @type {string}
     * @memberof UserDtoEmployeeDetail
     */
    'role': string;
    /**
     * Manager ID
     * @type {string}
     * @memberof UserDtoEmployeeDetail
     */
    'manager': string | null;
    /**
     * Organization Id
     * @type {string}
     * @memberof UserDtoEmployeeDetail
     */
    'organization': string;
    /**
     * Department ID
     * @type {string}
     * @memberof UserDtoEmployeeDetail
     */
    'department': string;
    /**
     * Location ID
     * @type {string}
     * @memberof UserDtoEmployeeDetail
     */
    'location': string;
}
/**
 * 
 * @export
 * @interface UserGroupCreateRequest
 */
export interface UserGroupCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupCreateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserGroupDto
 */
export interface UserGroupDto {
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'app': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserGroupLeaderboardResponse
 */
export interface UserGroupLeaderboardResponse {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UserGroupLeaderboardResponse
     */
    'top': Array<UserDto>;
    /**
     * 
     * @type {number}
     * @memberof UserGroupLeaderboardResponse
     */
    'rank': number;
}
/**
 * 
 * @export
 * @interface UserGroupResponse
 */
export interface UserGroupResponse {
    /**
     * 
     * @type {UserGroupDto}
     * @memberof UserGroupResponse
     */
    'userGroup': UserGroupDto;
}
/**
 * 
 * @export
 * @interface UserGroupUpdateRequest
 */
export interface UserGroupUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserGroupsResponse
 */
export interface UserGroupsResponse {
    /**
     * 
     * @type {Array<UserGroupDto>}
     * @memberof UserGroupsResponse
     */
    'userGroups': Array<UserGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof UserGroupsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UserJoinRequest
 */
export interface UserJoinRequest {
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'cpf'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'gender'?: UserJoinRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'userGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'type'?: UserJoinRequestTypeEnum;
    /**
     * 
     * @type {ReferralRegisterRequest}
     * @memberof UserJoinRequest
     */
    'referral'?: ReferralRegisterRequest;
    /**
     * 
     * @type {object}
     * @memberof UserJoinRequest
     */
    'metadata'?: object;
    /**
     * 
     * @type {EmployeeDetailRequest}
     * @memberof UserJoinRequest
     */
    'employeeDetail'?: EmployeeDetailRequest;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'creatorSalesRepId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'creatorSalesRepGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'source'?: UserJoinRequestSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'unverifiedPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJoinRequest
     */
    'oldPhone'?: string;
}

export const UserJoinRequestGenderEnum = {
    Male: 'male',
    Female: 'female',
    PreferNotToSay: 'prefer not to say'
} as const;

export type UserJoinRequestGenderEnum = typeof UserJoinRequestGenderEnum[keyof typeof UserJoinRequestGenderEnum];
export const UserJoinRequestTypeEnum = {
    Draft: 'draft',
    Member: 'member',
    Prospect: 'prospect',
    Registered: 'registered'
} as const;

export type UserJoinRequestTypeEnum = typeof UserJoinRequestTypeEnum[keyof typeof UserJoinRequestTypeEnum];
export const UserJoinRequestSourceEnum = {
    Online: 'online',
    Offline: 'offline'
} as const;

export type UserJoinRequestSourceEnum = typeof UserJoinRequestSourceEnum[keyof typeof UserJoinRequestSourceEnum];

/**
 * 
 * @export
 * @interface UserLeaderBoardResponse
 */
export interface UserLeaderBoardResponse {
    /**
     * 
     * @type {number}
     * @memberof UserLeaderBoardResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<UserPoints>}
     * @memberof UserLeaderBoardResponse
     */
    'leaderBoard': Array<UserPoints>;
}
/**
 * 
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserPerformance
 */
export interface UserPerformance {
    /**
     * 
     * @type {UserDto}
     * @memberof UserPerformance
     */
    'user': UserDto;
    /**
     * 
     * @type {number}
     * @memberof UserPerformance
     */
    'points': number;
}
/**
 * 
 * @export
 * @interface UserPoints
 */
export interface UserPoints {
    /**
     * 
     * @type {object}
     * @memberof UserPoints
     */
    'user': object;
    /**
     * 
     * @type {number}
     * @memberof UserPoints
     */
    'points': number;
}
/**
 * 
 * @export
 * @interface UserPointsDto
 */
export interface UserPointsDto {
    /**
     * Points
     * @type {number}
     * @memberof UserPointsDto
     */
    'gems': number;
    /**
     * Level points
     * @type {number}
     * @memberof UserPointsDto
     */
    'exp': number;
}
/**
 * 
 * @export
 * @interface UserPointsUpdate
 */
export interface UserPointsUpdate {
    /**
     * 
     * @type {PointsUpdate}
     * @memberof UserPointsUpdate
     */
    'points': PointsUpdate;
    /**
     * 
     * @type {string}
     * @memberof UserPointsUpdate
     */
    'op'?: UserPointsUpdateOpEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPointsUpdate
     */
    'reason'?: string;
}

export const UserPointsUpdateOpEnum = {
    Inc: 'inc'
} as const;

export type UserPointsUpdateOpEnum = typeof UserPointsUpdateOpEnum[keyof typeof UserPointsUpdateOpEnum];

/**
 * 
 * @export
 * @interface UserRankResponse
 */
export interface UserRankResponse {
    /**
     * 
     * @type {object}
     * @memberof UserRankResponse
     */
    'user'?: object;
    /**
     * 
     * @type {number}
     * @memberof UserRankResponse
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface UserReferralsDto
 */
export interface UserReferralsDto {
    /**
     * Referrer ID
     * @type {string}
     * @memberof UserReferralsDto
     */
    'referrer': string | null;
    /**
     * Referral source
     * @type {object}
     * @memberof UserReferralsDto
     */
    'source': UserReferralsDtoSourceEnum | null;
}

export const UserReferralsDtoSourceEnum = {
    OfflineOrder: 'offlineOrder',
    OnlineOrder: 'onlineOrder'
} as const;

export type UserReferralsDtoSourceEnum = typeof UserReferralsDtoSourceEnum[keyof typeof UserReferralsDtoSourceEnum];

/**
 * 
 * @export
 * @interface UserRegisterRequest
 */
export interface UserRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'gender'?: UserRegisterRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'language'?: UserRegisterRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'country'?: string;
    /**
     * 
     * @type {ReferralRegisterRequest}
     * @memberof UserRegisterRequest
     */
    'referral'?: ReferralRegisterRequest;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'type'?: UserRegisterRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequest
     */
    'currency'?: string;
    /**
     * 
     * @type {object}
     * @memberof UserRegisterRequest
     */
    'metadata'?: object;
}

export const UserRegisterRequestGenderEnum = {
    Male: 'male',
    Female: 'female',
    PreferNotToSay: 'prefer not to say'
} as const;

export type UserRegisterRequestGenderEnum = typeof UserRegisterRequestGenderEnum[keyof typeof UserRegisterRequestGenderEnum];
export const UserRegisterRequestLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type UserRegisterRequestLanguageEnum = typeof UserRegisterRequestLanguageEnum[keyof typeof UserRegisterRequestLanguageEnum];
export const UserRegisterRequestTypeEnum = {
    Draft: 'draft',
    Member: 'member',
    Prospect: 'prospect',
    Registered: 'registered'
} as const;

export type UserRegisterRequestTypeEnum = typeof UserRegisterRequestTypeEnum[keyof typeof UserRegisterRequestTypeEnum];

/**
 * 
 * @export
 * @interface UserReportRequest
 */
export interface UserReportRequest {
    /**
     * 
     * @type {string}
     * @memberof UserReportRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {UserDto}
     * @memberof UserResponse
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface UserSocialConnectRequest
 */
export interface UserSocialConnectRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSocialConnectRequest
     */
    'redirectUri': string;
    /**
     * 
     * @type {string}
     * @memberof UserSocialConnectRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UserSocialConnectRequest
     */
    'type': UserSocialConnectRequestTypeEnum;
}

export const UserSocialConnectRequestTypeEnum = {
    Facebook: 'facebook',
    Instagram: 'instagram'
} as const;

export type UserSocialConnectRequestTypeEnum = typeof UserSocialConnectRequestTypeEnum[keyof typeof UserSocialConnectRequestTypeEnum];

/**
 * 
 * @export
 * @interface UserSocialDto
 */
export interface UserSocialDto {
    /**
     * Facebook link
     * @type {string}
     * @memberof UserSocialDto
     */
    'facebook': string | null;
    /**
     * Instagram link
     * @type {string}
     * @memberof UserSocialDto
     */
    'instagram': string | null;
}
/**
 * 
 * @export
 * @interface UserUpdateByAdminRequest
 */
export interface UserUpdateByAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'gender'?: UserUpdateByAdminRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'language'?: UserUpdateByAdminRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'country'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateByAdminRequest
     */
    'pristine'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'currency'?: string;
    /**
     * 
     * @type {object}
     * @memberof UserUpdateByAdminRequest
     */
    'metadata'?: object;
    /**
     * 
     * @type {SimpleDateRequest}
     * @memberof UserUpdateByAdminRequest
     */
    'dateOfBirth'?: SimpleDateRequest;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'userGroup'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateByAdminRequest
     */
    'firstLogin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateByAdminRequest
     */
    'password'?: string;
    /**
     * 
     * @type {EmployeeDetailRequest}
     * @memberof UserUpdateByAdminRequest
     */
    'employeeDetail'?: EmployeeDetailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateByAdminRequest
     */
    'active'?: boolean;
}

export const UserUpdateByAdminRequestGenderEnum = {
    Male: 'male',
    Female: 'female',
    PreferNotToSay: 'prefer not to say'
} as const;

export type UserUpdateByAdminRequestGenderEnum = typeof UserUpdateByAdminRequestGenderEnum[keyof typeof UserUpdateByAdminRequestGenderEnum];
export const UserUpdateByAdminRequestLanguageEnum = {
    Ab: 'ab',
    Aa: 'aa',
    Af: 'af',
    Ak: 'ak',
    Sq: 'sq',
    Am: 'am',
    Ar: 'ar',
    An: 'an',
    Hy: 'hy',
    As: 'as',
    Av: 'av',
    Ae: 'ae',
    Ay: 'ay',
    Az: 'az',
    Bm: 'bm',
    Ba: 'ba',
    Eu: 'eu',
    Be: 'be',
    Bn: 'bn',
    Bi: 'bi',
    Bs: 'bs',
    Br: 'br',
    Bg: 'bg',
    My: 'my',
    Ca: 'ca',
    Ch: 'ch',
    Ce: 'ce',
    Ny: 'ny',
    Ny2: 'ny',
    Ny3: 'ny',
    Zh: 'zh',
    Cu: 'cu',
    Cu2: 'cu',
    Cu3: 'cu',
    Cu4: 'cu',
    Cu5: 'cu',
    Cv: 'cv',
    Kw: 'kw',
    Co: 'co',
    Cr: 'cr',
    Hr: 'hr',
    Cs: 'cs',
    Da: 'da',
    Dv: 'dv',
    Dv2: 'dv',
    Dv3: 'dv',
    Nl: 'nl',
    Nl2: 'nl',
    Dz: 'dz',
    En: 'en',
    Eo: 'eo',
    Et: 'et',
    Ee: 'ee',
    Fo: 'fo',
    Fj: 'fj',
    Fi: 'fi',
    Fr: 'fr',
    Fy: 'fy',
    Ff: 'ff',
    Gd: 'gd',
    Gd2: 'gd',
    Gl: 'gl',
    Lg: 'lg',
    Ka: 'ka',
    De: 'de',
    El: 'el',
    El2: 'el',
    Kl: 'kl',
    Kl2: 'kl',
    Gn: 'gn',
    Gu: 'gu',
    Ht: 'ht',
    Ht2: 'ht',
    Ha: 'ha',
    He: 'he',
    Hz: 'hz',
    Hi: 'hi',
    Ho: 'ho',
    Hu: 'hu',
    Is: 'is',
    Io: 'io',
    Ig: 'ig',
    Id: 'id',
    Ia: 'ia',
    Ie: 'ie',
    Ie2: 'ie',
    Iu: 'iu',
    Ik: 'ik',
    Ga: 'ga',
    It: 'it',
    Ja: 'ja',
    Jv: 'jv',
    Kn: 'kn',
    Kr: 'kr',
    Ks: 'ks',
    Kk: 'kk',
    Km: 'km',
    Ki: 'ki',
    Ki2: 'ki',
    Rw: 'rw',
    Ky: 'ky',
    Ky2: 'ky',
    Kv: 'kv',
    Kg: 'kg',
    Ko: 'ko',
    Kj: 'kj',
    Kj2: 'kj',
    Ku: 'ku',
    Lo: 'lo',
    La: 'la',
    Lv: 'lv',
    Li: 'li',
    Li2: 'li',
    Li3: 'li',
    Ln: 'ln',
    Lt: 'lt',
    Lu: 'lu',
    Lb: 'lb',
    Lb2: 'lb',
    Mk: 'mk',
    Mg: 'mg',
    Ms: 'ms',
    Ml: 'ml',
    Mt: 'mt',
    Gv: 'gv',
    Mi: 'mi',
    Mr: 'mr',
    Mh: 'mh',
    Mn: 'mn',
    Na: 'na',
    Nv: 'nv',
    Nv2: 'nv',
    Nd: 'nd',
    Nr: 'nr',
    Ng: 'ng',
    Ne: 'ne',
    No: 'no',
    Nb: 'nb',
    Nn: 'nn',
    Ii: 'ii',
    Ii2: 'ii',
    Oc: 'oc',
    Oj: 'oj',
    Or: 'or',
    Om: 'om',
    Os: 'os',
    Os2: 'os',
    Pi: 'pi',
    Ps: 'ps',
    Ps2: 'ps',
    Fa: 'fa',
    Pl: 'pl',
    Pt: 'pt',
    Pa: 'pa',
    Pa2: 'pa',
    Qu: 'qu',
    Ro: 'ro',
    Ro2: 'ro',
    Ro3: 'ro',
    Rm: 'rm',
    Rn: 'rn',
    Ru: 'ru',
    Se: 'se',
    Sm: 'sm',
    Sg: 'sg',
    Sa: 'sa',
    Sc: 'sc',
    Sr: 'sr',
    Sn: 'sn',
    Sd: 'sd',
    Si: 'si',
    Si2: 'si',
    Sk: 'sk',
    Sl: 'sl',
    So: 'so',
    St: 'st',
    Es: 'es',
    Es2: 'es',
    Su: 'su',
    Sw: 'sw',
    Ss: 'ss',
    Sv: 'sv',
    Tl: 'tl',
    Ty: 'ty',
    Tg: 'tg',
    Ta: 'ta',
    Tt: 'tt',
    Te: 'te',
    Th: 'th',
    Bo: 'bo',
    Ti: 'ti',
    To: 'to',
    Ts: 'ts',
    Tn: 'tn',
    Tr: 'tr',
    Tk: 'tk',
    Tw: 'tw',
    Ug: 'ug',
    Ug2: 'ug',
    Uk: 'uk',
    Ur: 'ur',
    Uz: 'uz',
    Ve: 've',
    Vi: 'vi',
    Vo: 'vo',
    Wa: 'wa',
    Cy: 'cy',
    Wo: 'wo',
    Xh: 'xh',
    Yi: 'yi',
    Yo: 'yo',
    Za: 'za',
    Za2: 'za',
    Zu: 'zu'
} as const;

export type UserUpdateByAdminRequestLanguageEnum = typeof UserUpdateByAdminRequestLanguageEnum[keyof typeof UserUpdateByAdminRequestLanguageEnum];

/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'gender'?: UserUpdateRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'cpf'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'language'?: UserUpdateRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'country'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateRequest
     */
    'pristine'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'currency'?: string;
    /**
     * 
     * @type {object}
     * @memberof UserUpdateRequest
     */
    'metadata'?: object;
    /**
     * 
     * @type {SimpleDateRequest}
     * @memberof UserUpdateRequest
     */
    'dateOfBirth'?: SimpleDateRequest;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'userGroup'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateRequest
     */
    'firstLogin'?: boolean;
    /**
     * 
     * @type {EmployeeDetailRequest}
     * @memberof UserUpdateRequest
     */
    'employeeDetail'?: EmployeeDetailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateRequest
     */
    'active'?: boolean;
}

export const UserUpdateRequestGenderEnum = {
    Male: 'male',
    Female: 'female',
    PreferNotToSay: 'prefer not to say'
} as const;

export type UserUpdateRequestGenderEnum = typeof UserUpdateRequestGenderEnum[keyof typeof UserUpdateRequestGenderEnum];
export const UserUpdateRequestLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type UserUpdateRequestLanguageEnum = typeof UserUpdateRequestLanguageEnum[keyof typeof UserUpdateRequestLanguageEnum];

/**
 * 
 * @export
 * @interface UserUpgradeRequest
 */
export interface UserUpgradeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'language'?: UserUpgradeRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpgradeRequest
     */
    'externalId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpgradeRequest
     */
    'pristine'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UserUpgradeRequest
     */
    'metadata'?: object;
}

export const UserUpgradeRequestLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;

export type UserUpgradeRequestLanguageEnum = typeof UserUpgradeRequestLanguageEnum[keyof typeof UserUpgradeRequestLanguageEnum];

/**
 * 
 * @export
 * @interface UserWelcomeGiftResponse
 */
export interface UserWelcomeGiftResponse {
    /**
     * 
     * @type {object}
     * @memberof UserWelcomeGiftResponse
     */
    'userWelcomeGift': object;
}
/**
 * 
 * @export
 * @interface UsersAggregatesResponse
 */
export interface UsersAggregatesResponse {
    /**
     * 
     * @type {number}
     * @memberof UsersAggregatesResponse
     */
    'totalUsers': number;
    /**
     * 
     * @type {number}
     * @memberof UsersAggregatesResponse
     */
    'avgPointsGems': number;
}
/**
 * 
 * @export
 * @interface UsersImportRequest
 */
export interface UsersImportRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersImportRequest
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UsersPerTier
 */
export interface UsersPerTier {
    /**
     * 
     * @type {number}
     * @memberof UsersPerTier
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof UsersPerTier
     */
    'users': number;
}
/**
 * 
 * @export
 * @interface UsersPerTierResponse
 */
export interface UsersPerTierResponse {
    /**
     * 
     * @type {Array<UsersPerTier>}
     * @memberof UsersPerTierResponse
     */
    'tiers': Array<UsersPerTier>;
}
/**
 * 
 * @export
 * @interface UsersResponse
 */
export interface UsersResponse {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UsersResponse
     */
    'users': Array<UserDto>;
    /**
     * 
     * @type {number}
     * @memberof UsersResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UsersTypeSyncRequest
 */
export interface UsersTypeSyncRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersTypeSyncRequest
     */
    'users': Array<string>;
}
/**
 * 
 * @export
 * @interface VerifyOtpRedeemResponse
 */
export interface VerifyOtpRedeemResponse {
    /**
     * Points sent to redeem
     * @type {number}
     * @memberof VerifyOtpRedeemResponse
     */
    'points': number;
    /**
     * Discount value needs to be applied
     * @type {number}
     * @memberof VerifyOtpRedeemResponse
     */
    'discount': number;
}
/**
 * 
 * @export
 * @interface VisibilityDto
 */
export interface VisibilityDto {
    /**
     * 
     * @type {string}
     * @memberof VisibilityDto
     */
    'opt': VisibilityDtoOptEnum;
    /**
     * 
     * @type {string}
     * @memberof VisibilityDto
     */
    'url': string;
}

export const VisibilityDtoOptEnum = {
    Is: 'is',
    IsNot: 'isNot'
} as const;

export type VisibilityDtoOptEnum = typeof VisibilityDtoOptEnum[keyof typeof VisibilityDtoOptEnum];

/**
 * 
 * @export
 * @interface WakeCustomerRequest
 */
export interface WakeCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof WakeCustomerRequest
     */
    'sfStorefrontAccessToken': string;
    /**
     * 
     * @type {string}
     * @memberof WakeCustomerRequest
     */
    'customerAccessToken': string;
}
/**
 * 
 * @export
 * @interface WelcomeGiftCreateRequest
 */
export interface WelcomeGiftCreateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WelcomeGiftCreateRequest
     */
    'events': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WelcomeGiftCreateRequest
     */
    'rewardType': WelcomeGiftCreateRequestRewardTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WelcomeGiftCreateRequest
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof WelcomeGiftCreateRequest
     */
    'expiryTime': number;
}

export const WelcomeGiftCreateRequestRewardTypeEnum = {
    Points: 'points',
    Discount: 'discount',
    FreeShipping: 'freeShipping'
} as const;

export type WelcomeGiftCreateRequestRewardTypeEnum = typeof WelcomeGiftCreateRequestRewardTypeEnum[keyof typeof WelcomeGiftCreateRequestRewardTypeEnum];

/**
 * 
 * @export
 * @interface WelcomeGiftResponse
 */
export interface WelcomeGiftResponse {
    /**
     * 
     * @type {object}
     * @memberof WelcomeGiftResponse
     */
    'welcomeGift': object;
}
/**
 * 
 * @export
 * @interface WelcomeGiftUpdateRequest
 */
export interface WelcomeGiftUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WelcomeGiftUpdateRequest
     */
    'events': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WelcomeGiftUpdateRequest
     */
    'rewardType': WelcomeGiftUpdateRequestRewardTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WelcomeGiftUpdateRequest
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof WelcomeGiftUpdateRequest
     */
    'expiryTime': number;
}

export const WelcomeGiftUpdateRequestRewardTypeEnum = {
    Points: 'points',
    Discount: 'discount',
    FreeShipping: 'freeShipping'
} as const;

export type WelcomeGiftUpdateRequestRewardTypeEnum = typeof WelcomeGiftUpdateRequestRewardTypeEnum[keyof typeof WelcomeGiftUpdateRequestRewardTypeEnum];

/**
 * 
 * @export
 * @interface WelcomeGiftsResponse
 */
export interface WelcomeGiftsResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof WelcomeGiftsResponse
     */
    'welcomeGifts': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof WelcomeGiftsResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface WelcomePromotionCreateRequest
 */
export interface WelcomePromotionCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof WelcomePromotionCreateRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof WelcomePromotionCreateRequest
     */
    'minOrderValue': number;
    /**
     * 
     * @type {number}
     * @memberof WelcomePromotionCreateRequest
     */
    'daysToExpire'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WelcomePromotionCreateRequest
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface WelcomePromotionUpdateRequest
 */
export interface WelcomePromotionUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof WelcomePromotionUpdateRequest
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof WelcomePromotionUpdateRequest
     */
    'minOrderValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof WelcomePromotionUpdateRequest
     */
    'daysToExpire'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WelcomePromotionUpdateRequest
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {object}
     * @memberof WidgetDto
     */
    'animation'?: object;
    /**
     * 
     * @type {BooleanPerDeviceDto}
     * @memberof WidgetDto
     */
    'hidden': BooleanPerDeviceDto;
    /**
     * 
     * @type {BooleanPerDeviceDto}
     * @memberof WidgetDto
     */
    'autoOpened': BooleanPerDeviceDto;
}
/**
 * 
 * @export
 * @interface WidgetRequest
 */
export interface WidgetRequest {
    /**
     * 
     * @type {BooleanPerDeviceRequest}
     * @memberof WidgetRequest
     */
    'hidden'?: BooleanPerDeviceRequest;
    /**
     * 
     * @type {object}
     * @memberof WidgetRequest
     */
    'animation'?: object;
    /**
     * 
     * @type {BooleanPerDeviceRequest}
     * @memberof WidgetRequest
     */
    'autoOpened'?: BooleanPerDeviceRequest;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorize: async (accountRequest: AccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountRequest' is not null or undefined
            assertParamExists('accountsControllerAuthorize', 'accountRequest', accountRequest)
            const localVarPath = `/v2/accounts/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Account
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeNuvemshopAccount: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('accountsControllerAuthorizeNuvemshopAccount', 'body', body)
            const localVarPath = `/v2/accounts/agents/nuvemshop/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizePrestashopAccount: async (accountRequest: AccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountRequest' is not null or undefined
            assertParamExists('accountsControllerAuthorizePrestashopAccount', 'accountRequest', accountRequest)
            const localVarPath = `/v2/accounts/agents/prestashop/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeShopifyAccount: async (accountRequest: AccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountRequest' is not null or undefined
            assertParamExists('accountsControllerAuthorizeShopifyAccount', 'accountRequest', accountRequest)
            const localVarPath = `/v2/accounts/agents/shopify/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeTrayAccount: async (accountRequest: AccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountRequest' is not null or undefined
            assertParamExists('accountsControllerAuthorizeTrayAccount', 'accountRequest', accountRequest)
            const localVarPath = `/v2/accounts/agents/tray/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeVtexAccount: async (accountRequest: AccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountRequest' is not null or undefined
            assertParamExists('accountsControllerAuthorizeVtexAccount', 'accountRequest', accountRequest)
            const localVarPath = `/v2/accounts/agents/vtex/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary claim data
         * @param {string} gamixAppId 
         * @param {ClaimRequest} claimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerClaim: async (gamixAppId: string, claimRequest: ClaimRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerClaim', 'gamixAppId', gamixAppId)
            // verify required parameter 'claimRequest' is not null or undefined
            assertParamExists('accountsControllerClaim', 'claimRequest', claimRequest)
            const localVarPath = `/v2/accounts/claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Account
         * @param {string} gamixAppId 
         * @param {CreateAccountRequest} createAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreate: async (gamixAppId: string, createAccountRequest: CreateAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'createAccountRequest' is not null or undefined
            assertParamExists('accountsControllerCreate', 'createAccountRequest', createAccountRequest)
            const localVarPath = `/v2/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Account to yampi
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDisableYampi: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerDisableYampi', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsControllerDisableYampi', 'id', id)
            const localVarPath = `/v2/accounts/{id}/yampi/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Account
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsControllerGet', 'id', id)
            const localVarPath = `/v2/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Account By AppId
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetByApp: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerGetByApp', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Account By External Id
         * @param {string} externalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetByExternalId: async (externalId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('accountsControllerGetByExternalId', 'externalId', externalId)
            const localVarPath = `/v2/accounts/external-id/{externalId}`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Account By Api url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetByUrl: async (url: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('accountsControllerGetByUrl', 'url', url)
            const localVarPath = `/v2/accounts/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Account Details By AppId
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetDetailsByApp: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerGetDetailsByApp', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/accounts/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Account Hooks By App
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerHookByApp: async (appId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('accountsControllerHookByApp', 'appId', appId)
            const localVarPath = `/v2/accounts/apps/{appId}/hooks`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Account Hooks
         * @param {AccountsControllerHooksByAgentAgentEnum} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerHooksByAgent: async (agent: AccountsControllerHooksByAgentAgentEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agent' is not null or undefined
            assertParamExists('accountsControllerHooksByAgent', 'agent', agent)
            const localVarPath = `/v2/accounts/agents/{agent}/hooks`
                .replace(`{${"agent"}}`, encodeURIComponent(String(agent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Account to yampi
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UpdateAccountYampiRequest} updateAccountYampiRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUpdateAccountToYampi: async (gamixAppId: string, id: string, updateAccountYampiRequest: UpdateAccountYampiRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('accountsControllerUpdateAccountToYampi', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsControllerUpdateAccountToYampi', 'id', id)
            // verify required parameter 'updateAccountYampiRequest' is not null or undefined
            assertParamExists('accountsControllerUpdateAccountToYampi', 'updateAccountYampiRequest', updateAccountYampiRequest)
            const localVarPath = `/v2/accounts/{id}/yampi`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountYampiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAuthorize(accountRequest: AccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAuthorize(accountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerAuthorize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Authorize Account
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAuthorizeNuvemshopAccount(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAuthorizeNuvemshopAccount(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerAuthorizeNuvemshopAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAuthorizePrestashopAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAuthorizePrestashopAccount(accountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerAuthorizePrestashopAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAuthorizeShopifyAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAuthorizeShopifyAccount(accountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerAuthorizeShopifyAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAuthorizeTrayAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAuthorizeTrayAccount(accountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerAuthorizeTrayAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAuthorizeVtexAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAuthorizeVtexAccount(accountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerAuthorizeVtexAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary claim data
         * @param {string} gamixAppId 
         * @param {ClaimRequest} claimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerClaim(gamixAppId: string, claimRequest: ClaimRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerClaim(gamixAppId, claimRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerClaim']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Account
         * @param {string} gamixAppId 
         * @param {CreateAccountRequest} createAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCreate(gamixAppId: string, createAccountRequest: CreateAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCreate(gamixAppId, createAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Account to yampi
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerDisableYampi(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerDisableYampi(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerDisableYampi']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Account
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Account By AppId
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetByApp(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetByApp(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerGetByApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Account By External Id
         * @param {string} externalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetByExternalId(externalId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetByExternalId(externalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerGetByExternalId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Account By Api url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetByUrl(url: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetByUrl(url, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerGetByUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Account Details By AppId
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetDetailsByApp(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetDetailsByApp(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerGetDetailsByApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Account Hooks By App
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerHookByApp(appId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerHookByApp(appId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerHookByApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Account Hooks
         * @param {AccountsControllerHooksByAgentAgentEnum} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerHooksByAgent(agent: AccountsControllerHooksByAgentAgentEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerHooksByAgent(agent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerHooksByAgent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Account to yampi
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UpdateAccountYampiRequest} updateAccountYampiRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerUpdateAccountToYampi(gamixAppId: string, id: string, updateAccountYampiRequest: UpdateAccountYampiRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerUpdateAccountToYampi(gamixAppId, id, updateAccountYampiRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerUpdateAccountToYampi']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorize(accountRequest: AccountRequest, options?: any): AxiosPromise<AccountAuthResponse> {
            return localVarFp.accountsControllerAuthorize(accountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Account
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeNuvemshopAccount(body: object, options?: any): AxiosPromise<AccountAuthResponse> {
            return localVarFp.accountsControllerAuthorizeNuvemshopAccount(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizePrestashopAccount(accountRequest: AccountRequest, options?: any): AxiosPromise<AccountAuthResponse> {
            return localVarFp.accountsControllerAuthorizePrestashopAccount(accountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeShopifyAccount(accountRequest: AccountRequest, options?: any): AxiosPromise<AccountAuthResponse> {
            return localVarFp.accountsControllerAuthorizeShopifyAccount(accountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeTrayAccount(accountRequest: AccountRequest, options?: any): AxiosPromise<AccountAuthResponse> {
            return localVarFp.accountsControllerAuthorizeTrayAccount(accountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Account
         * @param {AccountRequest} accountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAuthorizeVtexAccount(accountRequest: AccountRequest, options?: any): AxiosPromise<AccountAuthResponse> {
            return localVarFp.accountsControllerAuthorizeVtexAccount(accountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary claim data
         * @param {string} gamixAppId 
         * @param {ClaimRequest} claimRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerClaim(gamixAppId: string, claimRequest: ClaimRequest, options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerClaim(gamixAppId, claimRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Account
         * @param {string} gamixAppId 
         * @param {CreateAccountRequest} createAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreate(gamixAppId: string, createAccountRequest: CreateAccountRequest, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerCreate(gamixAppId, createAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Account to yampi
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDisableYampi(gamixAppId: string, id: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerDisableYampi(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Account
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Account By AppId
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetByApp(gamixAppId: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerGetByApp(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Account By External Id
         * @param {string} externalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetByExternalId(externalId: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerGetByExternalId(externalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Account By Api url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetByUrl(url: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerGetByUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Account Details By AppId
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetDetailsByApp(gamixAppId: string, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerGetDetailsByApp(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Account Hooks By App
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerHookByApp(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerHookByApp(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Account Hooks
         * @param {AccountsControllerHooksByAgentAgentEnum} agent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerHooksByAgent(agent: AccountsControllerHooksByAgentAgentEnum, options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerHooksByAgent(agent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Account to yampi
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UpdateAccountYampiRequest} updateAccountYampiRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUpdateAccountToYampi(gamixAppId: string, id: string, updateAccountYampiRequest: UpdateAccountYampiRequest, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.accountsControllerUpdateAccountToYampi(gamixAppId, id, updateAccountYampiRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary Authorize Account
     * @param {AccountRequest} accountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAuthorize(accountRequest: AccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAuthorize(accountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Account
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAuthorizeNuvemshopAccount(body: object, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAuthorizeNuvemshopAccount(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Account
     * @param {AccountRequest} accountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAuthorizePrestashopAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAuthorizePrestashopAccount(accountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Account
     * @param {AccountRequest} accountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAuthorizeShopifyAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAuthorizeShopifyAccount(accountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Account
     * @param {AccountRequest} accountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAuthorizeTrayAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAuthorizeTrayAccount(accountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Account
     * @param {AccountRequest} accountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAuthorizeVtexAccount(accountRequest: AccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAuthorizeVtexAccount(accountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary claim data
     * @param {string} gamixAppId 
     * @param {ClaimRequest} claimRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerClaim(gamixAppId: string, claimRequest: ClaimRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerClaim(gamixAppId, claimRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Account
     * @param {string} gamixAppId 
     * @param {CreateAccountRequest} createAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerCreate(gamixAppId: string, createAccountRequest: CreateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerCreate(gamixAppId, createAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Account to yampi
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerDisableYampi(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerDisableYampi(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Account
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Account By AppId
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerGetByApp(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerGetByApp(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Account By External Id
     * @param {string} externalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerGetByExternalId(externalId: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerGetByExternalId(externalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Account By Api url
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerGetByUrl(url: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerGetByUrl(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Account Details By AppId
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerGetDetailsByApp(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerGetDetailsByApp(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Account Hooks By App
     * @param {string} appId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerHookByApp(appId: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerHookByApp(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Account Hooks
     * @param {AccountsControllerHooksByAgentAgentEnum} agent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerHooksByAgent(agent: AccountsControllerHooksByAgentAgentEnum, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerHooksByAgent(agent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Account to yampi
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {UpdateAccountYampiRequest} updateAccountYampiRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerUpdateAccountToYampi(gamixAppId: string, id: string, updateAccountYampiRequest: UpdateAccountYampiRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerUpdateAccountToYampi(gamixAppId, id, updateAccountYampiRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AccountsControllerHooksByAgentAgentEnum = {
    Vtex: 'vtex',
    Shopify: 'shopify',
    Prestashop: 'prestashop',
    Nuvemshop: 'nuvemshop',
    Magento: 'magento',
    Tray: 'tray',
    Wake: 'wake',
    Custom: 'custom',
    WooCommerce: 'wooCommerce',
    Loja: 'loja'
} as const;
export type AccountsControllerHooksByAgentAgentEnum = typeof AccountsControllerHooksByAgentAgentEnum[keyof typeof AccountsControllerHooksByAgentAgentEnum];


/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create App
         * @param {CreateAppRequest} createAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerCreate: async (createAppRequest: CreateAppRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppRequest' is not null or undefined
            assertParamExists('appsControllerCreate', 'createAppRequest', createAppRequest)
            const localVarPath = `/v2/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create App with Enterprise Data
         * @param {CreateAppWithEnterpriseRequest} createAppWithEnterpriseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerCreateAppWithEnterprise: async (createAppWithEnterpriseRequest: CreateAppWithEnterpriseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppWithEnterpriseRequest' is not null or undefined
            assertParamExists('appsControllerCreateAppWithEnterprise', 'createAppWithEnterpriseRequest', createAppWithEnterpriseRequest)
            const localVarPath = `/v2/apps/with-enterprise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppWithEnterpriseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create app and account for magento
         * @param {MagentoAppCreateRequest} magentoAppCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerCreateMagento: async (magentoAppCreateRequest: MagentoAppCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'magentoAppCreateRequest' is not null or undefined
            assertParamExists('appsControllerCreateMagento', 'magentoAppCreateRequest', magentoAppCreateRequest)
            const localVarPath = `/v2/apps/magento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(magentoAppCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerDelete: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('appsControllerDelete', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerDisable: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('appsControllerDisable', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/apps/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerEnable: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('appsControllerEnable', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/apps/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get App
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appsControllerGet', 'id', id)
            const localVarPath = `/v2/apps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get App
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerGetByIdentifier: async (identifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('appsControllerGetByIdentifier', 'identifier', identifier)
            const localVarPath = `/v2/apps/identifier/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get App
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerGetDetails: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appsControllerGetDetails', 'id', id)
            const localVarPath = `/v2/apps/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Apps
         * @param {string} [search] 
         * @param {AppsControllerListTypeEnum} [type] 
         * @param {Array<AppsControllerListTypesEnum>} [types] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerList: async (search?: string, type?: AppsControllerListTypeEnum, types?: Array<AppsControllerListTypesEnum>, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary propagate existing apps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerPropagate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/apps/propagate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update App
         * @param {string} gamixAppId 
         * @param {UpdateAppRequest} updateAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerUpdate: async (gamixAppId: string, updateAppRequest: UpdateAppRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('appsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'updateAppRequest' is not null or undefined
            assertParamExists('appsControllerUpdate', 'updateAppRequest', updateAppRequest)
            const localVarPath = `/v2/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create App
         * @param {CreateAppRequest} createAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerCreate(createAppRequest: CreateAppRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerCreate(createAppRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create App with Enterprise Data
         * @param {CreateAppWithEnterpriseRequest} createAppWithEnterpriseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerCreateAppWithEnterprise(createAppWithEnterpriseRequest: CreateAppWithEnterpriseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppWithEnterpriseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerCreateAppWithEnterprise(createAppWithEnterpriseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerCreateAppWithEnterprise']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create app and account for magento
         * @param {MagentoAppCreateRequest} magentoAppCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerCreateMagento(magentoAppCreateRequest: MagentoAppCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MagentoAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerCreateMagento(magentoAppCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerCreateMagento']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerDelete(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerDelete(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Disable App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerDisable(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerDisable(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerDisable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enable App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerEnable(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerEnable(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerEnable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get App
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get App
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerGetByIdentifier(identifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerGetByIdentifier(identifier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerGetByIdentifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get App
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerGetDetails(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerGetDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerGetDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Apps
         * @param {string} [search] 
         * @param {AppsControllerListTypeEnum} [type] 
         * @param {Array<AppsControllerListTypesEnum>} [types] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerList(search?: string, type?: AppsControllerListTypeEnum, types?: Array<AppsControllerListTypesEnum>, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerList(search, type, types, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary propagate existing apps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerPropagate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerPropagate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerPropagate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update App
         * @param {string} gamixAppId 
         * @param {UpdateAppRequest} updateAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appsControllerUpdate(gamixAppId: string, updateAppRequest: UpdateAppRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appsControllerUpdate(gamixAppId, updateAppRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.appsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create App
         * @param {CreateAppRequest} createAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerCreate(createAppRequest: CreateAppRequest, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerCreate(createAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create App with Enterprise Data
         * @param {CreateAppWithEnterpriseRequest} createAppWithEnterpriseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerCreateAppWithEnterprise(createAppWithEnterpriseRequest: CreateAppWithEnterpriseRequest, options?: any): AxiosPromise<AppWithEnterpriseData> {
            return localVarFp.appsControllerCreateAppWithEnterprise(createAppWithEnterpriseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create app and account for magento
         * @param {MagentoAppCreateRequest} magentoAppCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerCreateMagento(magentoAppCreateRequest: MagentoAppCreateRequest, options?: any): AxiosPromise<MagentoAppResponse> {
            return localVarFp.appsControllerCreateMagento(magentoAppCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerDelete(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.appsControllerDelete(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerDisable(gamixAppId: string, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerDisable(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerEnable(gamixAppId: string, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerEnable(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get App
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerGet(id: string, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get App
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerGetByIdentifier(identifier: string, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerGetByIdentifier(identifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get App
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerGetDetails(id: string, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerGetDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Apps
         * @param {string} [search] 
         * @param {AppsControllerListTypeEnum} [type] 
         * @param {Array<AppsControllerListTypesEnum>} [types] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerList(search?: string, type?: AppsControllerListTypeEnum, types?: Array<AppsControllerListTypesEnum>, limit?: number, skip?: number, options?: any): AxiosPromise<AppsListResponse> {
            return localVarFp.appsControllerList(search, type, types, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary propagate existing apps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerPropagate(options?: any): AxiosPromise<void> {
            return localVarFp.appsControllerPropagate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update App
         * @param {string} gamixAppId 
         * @param {UpdateAppRequest} updateAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appsControllerUpdate(gamixAppId: string, updateAppRequest: UpdateAppRequest, options?: any): AxiosPromise<AppResponse> {
            return localVarFp.appsControllerUpdate(gamixAppId, updateAppRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     * 
     * @summary Create App
     * @param {CreateAppRequest} createAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerCreate(createAppRequest: CreateAppRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerCreate(createAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create App with Enterprise Data
     * @param {CreateAppWithEnterpriseRequest} createAppWithEnterpriseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerCreateAppWithEnterprise(createAppWithEnterpriseRequest: CreateAppWithEnterpriseRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerCreateAppWithEnterprise(createAppWithEnterpriseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create app and account for magento
     * @param {MagentoAppCreateRequest} magentoAppCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerCreateMagento(magentoAppCreateRequest: MagentoAppCreateRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerCreateMagento(magentoAppCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete App
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerDelete(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerDelete(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable App
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerDisable(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerDisable(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable App
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerEnable(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerEnable(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get App
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerGet(id: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get App
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerGetByIdentifier(identifier: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerGetByIdentifier(identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get App
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerGetDetails(id: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerGetDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Apps
     * @param {string} [search] 
     * @param {AppsControllerListTypeEnum} [type] 
     * @param {Array<AppsControllerListTypesEnum>} [types] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerList(search?: string, type?: AppsControllerListTypeEnum, types?: Array<AppsControllerListTypesEnum>, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerList(search, type, types, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary propagate existing apps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerPropagate(options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerPropagate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update App
     * @param {string} gamixAppId 
     * @param {UpdateAppRequest} updateAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public appsControllerUpdate(gamixAppId: string, updateAppRequest: UpdateAppRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).appsControllerUpdate(gamixAppId, updateAppRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AppsControllerListTypeEnum = {
    CoreLoyalty: 'coreLoyalty',
    CoreTeams: 'coreTeams',
    Pos: 'POS',
    Fisico: 'fisico',
    Games: 'games'
} as const;
export type AppsControllerListTypeEnum = typeof AppsControllerListTypeEnum[keyof typeof AppsControllerListTypeEnum];
/**
 * @export
 */
export const AppsControllerListTypesEnum = {
    CoreLoyalty: 'coreLoyalty',
    CoreTeams: 'coreTeams',
    Pos: 'POS',
    Fisico: 'fisico',
    Games: 'games'
} as const;
export type AppsControllerListTypesEnum = typeof AppsControllerListTypesEnum[keyof typeof AppsControllerListTypesEnum];


/**
 * BlockedEntriesApi - axios parameter creator
 * @export
 */
export const BlockedEntriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create BlockedEntry 
         * @param {string} gamixAppId 
         * @param {BlockedEntryCreateRequest} blockedEntryCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerCreate: async (gamixAppId: string, blockedEntryCreateRequest: BlockedEntryCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('blockedEntriesControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'blockedEntryCreateRequest' is not null or undefined
            assertParamExists('blockedEntriesControllerCreate', 'blockedEntryCreateRequest', blockedEntryCreateRequest)
            const localVarPath = `/v2/core/blocked-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockedEntryCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete BlockedEntry 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('blockedEntriesControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockedEntriesControllerDelete', 'id', id)
            const localVarPath = `/v2/core/blocked-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get BlockedEntry
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('blockedEntriesControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockedEntriesControllerGet', 'id', id)
            const localVarPath = `/v2/core/blocked-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List BlockedEntries
         * @param {string} gamixAppId 
         * @param {string} [entry] 
         * @param {BlockedEntriesControllerListTypeEnum} [type] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerList: async (gamixAppId: string, entry?: string, type?: BlockedEntriesControllerListTypeEnum, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('blockedEntriesControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/core/blocked-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entry !== undefined) {
                localVarQueryParameter['entry'] = entry;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockedEntriesApi - functional programming interface
 * @export
 */
export const BlockedEntriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlockedEntriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create BlockedEntry 
         * @param {string} gamixAppId 
         * @param {BlockedEntryCreateRequest} blockedEntryCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockedEntriesControllerCreate(gamixAppId: string, blockedEntryCreateRequest: BlockedEntryCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockedEntriesControllerCreate(gamixAppId, blockedEntryCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockedEntriesApi.blockedEntriesControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete BlockedEntry 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockedEntriesControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockedEntriesControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockedEntriesApi.blockedEntriesControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get BlockedEntry
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockedEntriesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockedEntriesControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockedEntriesApi.blockedEntriesControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List BlockedEntries
         * @param {string} gamixAppId 
         * @param {string} [entry] 
         * @param {BlockedEntriesControllerListTypeEnum} [type] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockedEntriesControllerList(gamixAppId: string, entry?: string, type?: BlockedEntriesControllerListTypeEnum, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockedEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockedEntriesControllerList(gamixAppId, entry, type, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockedEntriesApi.blockedEntriesControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BlockedEntriesApi - factory interface
 * @export
 */
export const BlockedEntriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlockedEntriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create BlockedEntry 
         * @param {string} gamixAppId 
         * @param {BlockedEntryCreateRequest} blockedEntryCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerCreate(gamixAppId: string, blockedEntryCreateRequest: BlockedEntryCreateRequest, options?: any): AxiosPromise<BlockedEntryResponse> {
            return localVarFp.blockedEntriesControllerCreate(gamixAppId, blockedEntryCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete BlockedEntry 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.blockedEntriesControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get BlockedEntry
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<BlockedEntryResponse> {
            return localVarFp.blockedEntriesControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List BlockedEntries
         * @param {string} gamixAppId 
         * @param {string} [entry] 
         * @param {BlockedEntriesControllerListTypeEnum} [type] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockedEntriesControllerList(gamixAppId: string, entry?: string, type?: BlockedEntriesControllerListTypeEnum, limit?: number, skip?: number, options?: any): AxiosPromise<BlockedEntriesResponse> {
            return localVarFp.blockedEntriesControllerList(gamixAppId, entry, type, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlockedEntriesApi - object-oriented interface
 * @export
 * @class BlockedEntriesApi
 * @extends {BaseAPI}
 */
export class BlockedEntriesApi extends BaseAPI {
    /**
     * 
     * @summary Create BlockedEntry 
     * @param {string} gamixAppId 
     * @param {BlockedEntryCreateRequest} blockedEntryCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedEntriesApi
     */
    public blockedEntriesControllerCreate(gamixAppId: string, blockedEntryCreateRequest: BlockedEntryCreateRequest, options?: RawAxiosRequestConfig) {
        return BlockedEntriesApiFp(this.configuration).blockedEntriesControllerCreate(gamixAppId, blockedEntryCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete BlockedEntry 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedEntriesApi
     */
    public blockedEntriesControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return BlockedEntriesApiFp(this.configuration).blockedEntriesControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get BlockedEntry
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedEntriesApi
     */
    public blockedEntriesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return BlockedEntriesApiFp(this.configuration).blockedEntriesControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List BlockedEntries
     * @param {string} gamixAppId 
     * @param {string} [entry] 
     * @param {BlockedEntriesControllerListTypeEnum} [type] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockedEntriesApi
     */
    public blockedEntriesControllerList(gamixAppId: string, entry?: string, type?: BlockedEntriesControllerListTypeEnum, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return BlockedEntriesApiFp(this.configuration).blockedEntriesControllerList(gamixAppId, entry, type, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const BlockedEntriesControllerListTypeEnum = {
    Email: 'email',
    Domain: 'domain'
} as const;
export type BlockedEntriesControllerListTypeEnum = typeof BlockedEntriesControllerListTypeEnum[keyof typeof BlockedEntriesControllerListTypeEnum];


/**
 * BlockingDomainsApi - axios parameter creator
 * @export
 */
export const BlockingDomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create BlockingDomain 
         * @param {BlockingDomainCreateRequest} blockingDomainCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerCreate: async (blockingDomainCreateRequest: BlockingDomainCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockingDomainCreateRequest' is not null or undefined
            assertParamExists('blockingDomainsControllerCreate', 'blockingDomainCreateRequest', blockingDomainCreateRequest)
            const localVarPath = `/v2/core/blocking-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockingDomainCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete BlockingDomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockingDomainsControllerDelete', 'id', id)
            const localVarPath = `/v2/core/blocking-domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get BlockingDomain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockingDomainsControllerGet', 'id', id)
            const localVarPath = `/v2/core/blocking-domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List BlockingDomains
         * @param {string} [domain] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerList: async (domain?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/core/blocking-domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockingDomainsApi - functional programming interface
 * @export
 */
export const BlockingDomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlockingDomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create BlockingDomain 
         * @param {BlockingDomainCreateRequest} blockingDomainCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockingDomainsControllerCreate(blockingDomainCreateRequest: BlockingDomainCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockingDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockingDomainsControllerCreate(blockingDomainCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockingDomainsApi.blockingDomainsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete BlockingDomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockingDomainsControllerDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockingDomainsControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockingDomainsApi.blockingDomainsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get BlockingDomain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockingDomainsControllerGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockingDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockingDomainsControllerGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockingDomainsApi.blockingDomainsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List BlockingDomains
         * @param {string} [domain] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockingDomainsControllerList(domain?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockingDomainsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockingDomainsControllerList(domain, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlockingDomainsApi.blockingDomainsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BlockingDomainsApi - factory interface
 * @export
 */
export const BlockingDomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlockingDomainsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create BlockingDomain 
         * @param {BlockingDomainCreateRequest} blockingDomainCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerCreate(blockingDomainCreateRequest: BlockingDomainCreateRequest, options?: any): AxiosPromise<BlockingDomainResponse> {
            return localVarFp.blockingDomainsControllerCreate(blockingDomainCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete BlockingDomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.blockingDomainsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get BlockingDomain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerGet(id: string, options?: any): AxiosPromise<BlockingDomainResponse> {
            return localVarFp.blockingDomainsControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List BlockingDomains
         * @param {string} [domain] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockingDomainsControllerList(domain?: string, limit?: number, skip?: number, options?: any): AxiosPromise<BlockingDomainsResponse> {
            return localVarFp.blockingDomainsControllerList(domain, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlockingDomainsApi - object-oriented interface
 * @export
 * @class BlockingDomainsApi
 * @extends {BaseAPI}
 */
export class BlockingDomainsApi extends BaseAPI {
    /**
     * 
     * @summary Create BlockingDomain 
     * @param {BlockingDomainCreateRequest} blockingDomainCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockingDomainsApi
     */
    public blockingDomainsControllerCreate(blockingDomainCreateRequest: BlockingDomainCreateRequest, options?: RawAxiosRequestConfig) {
        return BlockingDomainsApiFp(this.configuration).blockingDomainsControllerCreate(blockingDomainCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete BlockingDomain 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockingDomainsApi
     */
    public blockingDomainsControllerDelete(id: string, options?: RawAxiosRequestConfig) {
        return BlockingDomainsApiFp(this.configuration).blockingDomainsControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get BlockingDomain
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockingDomainsApi
     */
    public blockingDomainsControllerGet(id: string, options?: RawAxiosRequestConfig) {
        return BlockingDomainsApiFp(this.configuration).blockingDomainsControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List BlockingDomains
     * @param {string} [domain] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockingDomainsApi
     */
    public blockingDomainsControllerList(domain?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return BlockingDomainsApiFp(this.configuration).blockingDomainsControllerList(domain, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Campaign
         * @param {string} gamixAppId 
         * @param {CampaignCreateRequest} campaignCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerCreate: async (gamixAppId: string, campaignCreateRequest: CampaignCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('campaignsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'campaignCreateRequest' is not null or undefined
            assertParamExists('campaignsControllerCreate', 'campaignCreateRequest', campaignCreateRequest)
            const localVarPath = `/v2/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Campaigns
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('campaignsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignsControllerDelete', 'id', id)
            const localVarPath = `/v2/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send Campaign Report
         * @param {string} gamixAppId 
         * @param {string} campaignId 
         * @param {string} email 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerGenerateReport: async (gamixAppId: string, campaignId: string, email: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('campaignsControllerGenerateReport', 'gamixAppId', gamixAppId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignsControllerGenerateReport', 'campaignId', campaignId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('campaignsControllerGenerateReport', 'email', email)
            const localVarPath = `/v2/campaigns/export/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Campaign By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('campaignsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignsControllerGet', 'id', id)
            const localVarPath = `/v2/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary History Campaigns
         * @param {string} gamixAppId 
         * @param {number} [minPoints] 
         * @param {number} [maxPoints] 
         * @param {number} [roundedTo] 
         * @param {CampaignsControllerGetHistoryGenerateTypeEnum} [generateType] 
         * @param {string} [email] 
         * @param {CampaignsControllerGetHistoryStatusEnum} [status] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerGetHistory: async (gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerGetHistoryGenerateTypeEnum, email?: string, status?: CampaignsControllerGetHistoryStatusEnum, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('campaignsControllerGetHistory', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/campaigns/history/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minPoints !== undefined) {
                localVarQueryParameter['minPoints'] = minPoints;
            }

            if (maxPoints !== undefined) {
                localVarQueryParameter['maxPoints'] = maxPoints;
            }

            if (roundedTo !== undefined) {
                localVarQueryParameter['roundedTo'] = roundedTo;
            }

            if (generateType !== undefined) {
                localVarQueryParameter['generateType'] = generateType;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Campaigns
         * @param {string} gamixAppId 
         * @param {number} [minPoints] 
         * @param {number} [maxPoints] 
         * @param {number} [roundedTo] 
         * @param {CampaignsControllerListGenerateTypeEnum} [generateType] 
         * @param {string} [email] 
         * @param {CampaignsControllerListStatusEnum} [status] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerList: async (gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerListGenerateTypeEnum, email?: string, status?: CampaignsControllerListStatusEnum, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('campaignsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minPoints !== undefined) {
                localVarQueryParameter['minPoints'] = minPoints;
            }

            if (maxPoints !== undefined) {
                localVarQueryParameter['maxPoints'] = maxPoints;
            }

            if (roundedTo !== undefined) {
                localVarQueryParameter['roundedTo'] = roundedTo;
            }

            if (generateType !== undefined) {
                localVarQueryParameter['generateType'] = generateType;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Campaign
         * @param {string} gamixAppId 
         * @param {CampaignCreateRequest} campaignCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsControllerCreate(gamixAppId: string, campaignCreateRequest: CampaignCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsControllerCreate(gamixAppId, campaignCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Campaigns
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary send Campaign Report
         * @param {string} gamixAppId 
         * @param {string} campaignId 
         * @param {string} email 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsControllerGenerateReport(gamixAppId: string, campaignId: string, email: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsControllerGenerateReport(gamixAppId, campaignId, email, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignsControllerGenerateReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Campaign By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary History Campaigns
         * @param {string} gamixAppId 
         * @param {number} [minPoints] 
         * @param {number} [maxPoints] 
         * @param {number} [roundedTo] 
         * @param {CampaignsControllerGetHistoryGenerateTypeEnum} [generateType] 
         * @param {string} [email] 
         * @param {CampaignsControllerGetHistoryStatusEnum} [status] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsControllerGetHistory(gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerGetHistoryGenerateTypeEnum, email?: string, status?: CampaignsControllerGetHistoryStatusEnum, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsControllerGetHistory(gamixAppId, minPoints, maxPoints, roundedTo, generateType, email, status, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignsControllerGetHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Campaigns
         * @param {string} gamixAppId 
         * @param {number} [minPoints] 
         * @param {number} [maxPoints] 
         * @param {number} [roundedTo] 
         * @param {CampaignsControllerListGenerateTypeEnum} [generateType] 
         * @param {string} [email] 
         * @param {CampaignsControllerListStatusEnum} [status] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsControllerList(gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerListGenerateTypeEnum, email?: string, status?: CampaignsControllerListStatusEnum, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsControllerList(gamixAppId, minPoints, maxPoints, roundedTo, generateType, email, status, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.campaignsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Campaign
         * @param {string} gamixAppId 
         * @param {CampaignCreateRequest} campaignCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerCreate(gamixAppId: string, campaignCreateRequest: CampaignCreateRequest, options?: any): AxiosPromise<CampaignResponse> {
            return localVarFp.campaignsControllerCreate(gamixAppId, campaignCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Campaigns
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.campaignsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send Campaign Report
         * @param {string} gamixAppId 
         * @param {string} campaignId 
         * @param {string} email 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerGenerateReport(gamixAppId: string, campaignId: string, email: string, limit?: number, skip?: number, options?: any): AxiosPromise<void> {
            return localVarFp.campaignsControllerGenerateReport(gamixAppId, campaignId, email, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Campaign By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<CampaignResponse> {
            return localVarFp.campaignsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary History Campaigns
         * @param {string} gamixAppId 
         * @param {number} [minPoints] 
         * @param {number} [maxPoints] 
         * @param {number} [roundedTo] 
         * @param {CampaignsControllerGetHistoryGenerateTypeEnum} [generateType] 
         * @param {string} [email] 
         * @param {CampaignsControllerGetHistoryStatusEnum} [status] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerGetHistory(gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerGetHistoryGenerateTypeEnum, email?: string, status?: CampaignsControllerGetHistoryStatusEnum, limit?: number, skip?: number, options?: any): AxiosPromise<CampaignsResponse> {
            return localVarFp.campaignsControllerGetHistory(gamixAppId, minPoints, maxPoints, roundedTo, generateType, email, status, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Campaigns
         * @param {string} gamixAppId 
         * @param {number} [minPoints] 
         * @param {number} [maxPoints] 
         * @param {number} [roundedTo] 
         * @param {CampaignsControllerListGenerateTypeEnum} [generateType] 
         * @param {string} [email] 
         * @param {CampaignsControllerListStatusEnum} [status] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsControllerList(gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerListGenerateTypeEnum, email?: string, status?: CampaignsControllerListStatusEnum, limit?: number, skip?: number, options?: any): AxiosPromise<CampaignsResponse> {
            return localVarFp.campaignsControllerList(gamixAppId, minPoints, maxPoints, roundedTo, generateType, email, status, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * 
     * @summary Create Campaign
     * @param {string} gamixAppId 
     * @param {CampaignCreateRequest} campaignCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsControllerCreate(gamixAppId: string, campaignCreateRequest: CampaignCreateRequest, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsControllerCreate(gamixAppId, campaignCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Campaigns
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send Campaign Report
     * @param {string} gamixAppId 
     * @param {string} campaignId 
     * @param {string} email 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsControllerGenerateReport(gamixAppId: string, campaignId: string, email: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsControllerGenerateReport(gamixAppId, campaignId, email, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Campaign By Id
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary History Campaigns
     * @param {string} gamixAppId 
     * @param {number} [minPoints] 
     * @param {number} [maxPoints] 
     * @param {number} [roundedTo] 
     * @param {CampaignsControllerGetHistoryGenerateTypeEnum} [generateType] 
     * @param {string} [email] 
     * @param {CampaignsControllerGetHistoryStatusEnum} [status] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsControllerGetHistory(gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerGetHistoryGenerateTypeEnum, email?: string, status?: CampaignsControllerGetHistoryStatusEnum, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsControllerGetHistory(gamixAppId, minPoints, maxPoints, roundedTo, generateType, email, status, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Campaigns
     * @param {string} gamixAppId 
     * @param {number} [minPoints] 
     * @param {number} [maxPoints] 
     * @param {number} [roundedTo] 
     * @param {CampaignsControllerListGenerateTypeEnum} [generateType] 
     * @param {string} [email] 
     * @param {CampaignsControllerListStatusEnum} [status] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsControllerList(gamixAppId: string, minPoints?: number, maxPoints?: number, roundedTo?: number, generateType?: CampaignsControllerListGenerateTypeEnum, email?: string, status?: CampaignsControllerListStatusEnum, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsControllerList(gamixAppId, minPoints, maxPoints, roundedTo, generateType, email, status, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CampaignsControllerGetHistoryGenerateTypeEnum = {
    SendEmails: 'sendEmails',
    Report: 'report'
} as const;
export type CampaignsControllerGetHistoryGenerateTypeEnum = typeof CampaignsControllerGetHistoryGenerateTypeEnum[keyof typeof CampaignsControllerGetHistoryGenerateTypeEnum];
/**
 * @export
 */
export const CampaignsControllerGetHistoryStatusEnum = {
    Inprogress: 'inprogress',
    Completed: 'completed',
    Stopped: 'stopped',
    CompletedReportFailuir: 'completed-report failuir'
} as const;
export type CampaignsControllerGetHistoryStatusEnum = typeof CampaignsControllerGetHistoryStatusEnum[keyof typeof CampaignsControllerGetHistoryStatusEnum];
/**
 * @export
 */
export const CampaignsControllerListGenerateTypeEnum = {
    SendEmails: 'sendEmails',
    Report: 'report'
} as const;
export type CampaignsControllerListGenerateTypeEnum = typeof CampaignsControllerListGenerateTypeEnum[keyof typeof CampaignsControllerListGenerateTypeEnum];
/**
 * @export
 */
export const CampaignsControllerListStatusEnum = {
    Inprogress: 'inprogress',
    Completed: 'completed',
    Stopped: 'stopped',
    CompletedReportFailuir: 'completed-report failuir'
} as const;
export type CampaignsControllerListStatusEnum = typeof CampaignsControllerListStatusEnum[keyof typeof CampaignsControllerListStatusEnum];


/**
 * ConnectionsApi - axios parameter creator
 * @export
 */
export const ConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Connection
         * @param {string} gamixAppId 
         * @param {ConnectionCreateRequest} connectionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerCreate: async (gamixAppId: string, connectionCreateRequest: ConnectionCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('connectionsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'connectionCreateRequest' is not null or undefined
            assertParamExists('connectionsControllerCreate', 'connectionCreateRequest', connectionCreateRequest)
            const localVarPath = `/v2/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('connectionsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectionsControllerDelete', 'id', id)
            const localVarPath = `/v2/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('connectionsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectionsControllerGet', 'id', id)
            const localVarPath = `/v2/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Connections
         * @param {string} gamixAppId 
         * @param {boolean} [friends] 
         * @param {string} [user] 
         * @param {string} [createdAt] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerList: async (gamixAppId: string, friends?: boolean, user?: string, createdAt?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('connectionsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (friends !== undefined) {
                localVarQueryParameter['friends'] = friends;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = createdAt;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionsApi - functional programming interface
 * @export
 */
export const ConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Connection
         * @param {string} gamixAppId 
         * @param {ConnectionCreateRequest} connectionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectionsControllerCreate(gamixAppId: string, connectionCreateRequest: ConnectionCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectionsControllerCreate(gamixAppId, connectionCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionsApi.connectionsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectionsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectionsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionsApi.connectionsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectionsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectionsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionsApi.connectionsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Connections
         * @param {string} gamixAppId 
         * @param {boolean} [friends] 
         * @param {string} [user] 
         * @param {string} [createdAt] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectionsControllerList(gamixAppId: string, friends?: boolean, user?: string, createdAt?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectionsControllerList(gamixAppId, friends, user, createdAt, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionsApi.connectionsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionsApi - factory interface
 * @export
 */
export const ConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Connection
         * @param {string} gamixAppId 
         * @param {ConnectionCreateRequest} connectionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerCreate(gamixAppId: string, connectionCreateRequest: ConnectionCreateRequest, options?: any): AxiosPromise<ConnectionResponse> {
            return localVarFp.connectionsControllerCreate(gamixAppId, connectionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.connectionsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<ConnectionRecordResponse> {
            return localVarFp.connectionsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Connections
         * @param {string} gamixAppId 
         * @param {boolean} [friends] 
         * @param {string} [user] 
         * @param {string} [createdAt] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionsControllerList(gamixAppId: string, friends?: boolean, user?: string, createdAt?: string, limit?: number, skip?: number, options?: any): AxiosPromise<ConnectionRecordsResponse> {
            return localVarFp.connectionsControllerList(gamixAppId, friends, user, createdAt, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionsApi - object-oriented interface
 * @export
 * @class ConnectionsApi
 * @extends {BaseAPI}
 */
export class ConnectionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Connection
     * @param {string} gamixAppId 
     * @param {ConnectionCreateRequest} connectionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public connectionsControllerCreate(gamixAppId: string, connectionCreateRequest: ConnectionCreateRequest, options?: RawAxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).connectionsControllerCreate(gamixAppId, connectionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Connection
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public connectionsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).connectionsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connection
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public connectionsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).connectionsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Connections
     * @param {string} gamixAppId 
     * @param {boolean} [friends] 
     * @param {string} [user] 
     * @param {string} [createdAt] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public connectionsControllerList(gamixAppId: string, friends?: boolean, user?: string, createdAt?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).connectionsControllerList(gamixAppId, friends, user, createdAt, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoreTeamsUsersApi - axios parameter creator
 * @export
 */
export const CoreTeamsUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UsersCoreTeamControllerGetPerformanceListRoleEnum} role 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usersCoreTeamControllerGetPerformanceList: async (gamixAppId: string, id: string, role: UsersCoreTeamControllerGetPerformanceListRoleEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersCoreTeamControllerGetPerformanceList', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersCoreTeamControllerGetPerformanceList', 'id', id)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('usersCoreTeamControllerGetPerformanceList', 'role', role)
            const localVarPath = `/v2/users/coreteams/{id}/performanceList`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} gamixAppId 
         * @param {number} topLimit 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usersCoreTeamControllerGetUsersRankedListByRole: async (gamixAppId: string, topLimit: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersCoreTeamControllerGetUsersRankedListByRole', 'gamixAppId', gamixAppId)
            // verify required parameter 'topLimit' is not null or undefined
            assertParamExists('usersCoreTeamControllerGetUsersRankedListByRole', 'topLimit', topLimit)
            const localVarPath = `/v2/users/coreteams/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (topLimit !== undefined) {
                localVarQueryParameter['topLimit'] = topLimit;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add users role, Managers, supervisors from coreTeams json
         * @param {string} gamixAppId 
         * @param {UsersCoreTeamControllerInsertUsersRoleRoleEnum} role 
         * @param {UpsertUsersRequest} upsertUsersRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usersCoreTeamControllerInsertUsersRole: async (gamixAppId: string, role: UsersCoreTeamControllerInsertUsersRoleRoleEnum, upsertUsersRequest: UpsertUsersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersCoreTeamControllerInsertUsersRole', 'gamixAppId', gamixAppId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('usersCoreTeamControllerInsertUsersRole', 'role', role)
            // verify required parameter 'upsertUsersRequest' is not null or undefined
            assertParamExists('usersCoreTeamControllerInsertUsersRole', 'upsertUsersRequest', upsertUsersRequest)
            const localVarPath = `/v2/users/coreteams/upload/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreTeamsUsersApi - functional programming interface
 * @export
 */
export const CoreTeamsUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreTeamsUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UsersCoreTeamControllerGetPerformanceListRoleEnum} role 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async usersCoreTeamControllerGetPerformanceList(gamixAppId: string, id: string, role: UsersCoreTeamControllerGetPerformanceListRoleEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCoreTeamControllerGetPerformanceList(gamixAppId, id, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreTeamsUsersApi.usersCoreTeamControllerGetPerformanceList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 
         * @param {string} gamixAppId 
         * @param {number} topLimit 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async usersCoreTeamControllerGetUsersRankedListByRole(gamixAppId: string, topLimit: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCoreTeamControllerGetUsersRankedListByRole(gamixAppId, topLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreTeamsUsersApi.usersCoreTeamControllerGetUsersRankedListByRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add users role, Managers, supervisors from coreTeams json
         * @param {string} gamixAppId 
         * @param {UsersCoreTeamControllerInsertUsersRoleRoleEnum} role 
         * @param {UpsertUsersRequest} upsertUsersRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async usersCoreTeamControllerInsertUsersRole(gamixAppId: string, role: UsersCoreTeamControllerInsertUsersRoleRoleEnum, upsertUsersRequest: UpsertUsersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCoreTeamControllerInsertUsersRole(gamixAppId, role, upsertUsersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreTeamsUsersApi.usersCoreTeamControllerInsertUsersRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoreTeamsUsersApi - factory interface
 * @export
 */
export const CoreTeamsUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreTeamsUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UsersCoreTeamControllerGetPerformanceListRoleEnum} role 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usersCoreTeamControllerGetPerformanceList(gamixAppId: string, id: string, role: UsersCoreTeamControllerGetPerformanceListRoleEnum, options?: any): AxiosPromise<PerformanceList> {
            return localVarFp.usersCoreTeamControllerGetPerformanceList(gamixAppId, id, role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} gamixAppId 
         * @param {number} topLimit 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usersCoreTeamControllerGetUsersRankedListByRole(gamixAppId: string, topLimit: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersCoreTeamControllerGetUsersRankedListByRole(gamixAppId, topLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add users role, Managers, supervisors from coreTeams json
         * @param {string} gamixAppId 
         * @param {UsersCoreTeamControllerInsertUsersRoleRoleEnum} role 
         * @param {UpsertUsersRequest} upsertUsersRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usersCoreTeamControllerInsertUsersRole(gamixAppId: string, role: UsersCoreTeamControllerInsertUsersRoleRoleEnum, upsertUsersRequest: UpsertUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersCoreTeamControllerInsertUsersRole(gamixAppId, role, upsertUsersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoreTeamsUsersApi - object-oriented interface
 * @export
 * @class CoreTeamsUsersApi
 * @extends {BaseAPI}
 */
export class CoreTeamsUsersApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {UsersCoreTeamControllerGetPerformanceListRoleEnum} role 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoreTeamsUsersApi
     */
    public usersCoreTeamControllerGetPerformanceList(gamixAppId: string, id: string, role: UsersCoreTeamControllerGetPerformanceListRoleEnum, options?: RawAxiosRequestConfig) {
        return CoreTeamsUsersApiFp(this.configuration).usersCoreTeamControllerGetPerformanceList(gamixAppId, id, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} gamixAppId 
     * @param {number} topLimit 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoreTeamsUsersApi
     */
    public usersCoreTeamControllerGetUsersRankedListByRole(gamixAppId: string, topLimit: number, options?: RawAxiosRequestConfig) {
        return CoreTeamsUsersApiFp(this.configuration).usersCoreTeamControllerGetUsersRankedListByRole(gamixAppId, topLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add users role, Managers, supervisors from coreTeams json
     * @param {string} gamixAppId 
     * @param {UsersCoreTeamControllerInsertUsersRoleRoleEnum} role 
     * @param {UpsertUsersRequest} upsertUsersRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CoreTeamsUsersApi
     */
    public usersCoreTeamControllerInsertUsersRole(gamixAppId: string, role: UsersCoreTeamControllerInsertUsersRoleRoleEnum, upsertUsersRequest: UpsertUsersRequest, options?: RawAxiosRequestConfig) {
        return CoreTeamsUsersApiFp(this.configuration).usersCoreTeamControllerInsertUsersRole(gamixAppId, role, upsertUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UsersCoreTeamControllerGetPerformanceListRoleEnum = {
    Manager: 'manager',
    Supervisor: 'supervisor',
    SalesRep: 'salesRep',
    Admin: 'admin'
} as const;
export type UsersCoreTeamControllerGetPerformanceListRoleEnum = typeof UsersCoreTeamControllerGetPerformanceListRoleEnum[keyof typeof UsersCoreTeamControllerGetPerformanceListRoleEnum];
/**
 * @export
 */
export const UsersCoreTeamControllerInsertUsersRoleRoleEnum = {
    Manager: 'manager',
    Supervisor: 'supervisor',
    SalesRep: 'salesRep',
    Admin: 'admin'
} as const;
export type UsersCoreTeamControllerInsertUsersRoleRoleEnum = typeof UsersCoreTeamControllerInsertUsersRoleRoleEnum[keyof typeof UsersCoreTeamControllerInsertUsersRoleRoleEnum];


/**
 * CouponsApi - axios parameter creator
 * @export
 */
export const CouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Coupon
         * @param {string} gamixAppId 
         * @param {CouponCreateRequest} couponCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerCreate: async (gamixAppId: string, couponCreateRequest: CouponCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('couponsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'couponCreateRequest' is not null or undefined
            assertParamExists('couponsControllerCreate', 'couponCreateRequest', couponCreateRequest)
            const localVarPath = `/v2/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(couponCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get coupon by code
         * @param {string} gamixAppId 
         * @param {CouponGetRequest} couponGetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerGetCouponByCode: async (gamixAppId: string, couponGetRequest: CouponGetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('couponsControllerGetCouponByCode', 'gamixAppId', gamixAppId)
            // verify required parameter 'couponGetRequest' is not null or undefined
            assertParamExists('couponsControllerGetCouponByCode', 'couponGetRequest', couponGetRequest)
            const localVarPath = `/v2/coupons/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(couponGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Coupons
         * @param {string} gamixAppId 
         * @param {string} [user] 
         * @param {boolean} [used] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerList: async (gamixAppId: string, user?: string, used?: boolean, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('couponsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (used !== undefined) {
                localVarQueryParameter['used'] = used;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Coupon
         * @param {string} gamixAppId 
         * @param {CouponCreateRequest} couponCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponsControllerCreate(gamixAppId: string, couponCreateRequest: CouponCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponsControllerCreate(gamixAppId, couponCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.couponsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get coupon by code
         * @param {string} gamixAppId 
         * @param {CouponGetRequest} couponGetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponsControllerGetCouponByCode(gamixAppId: string, couponGetRequest: CouponGetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponsControllerGetCouponByCode(gamixAppId, couponGetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.couponsControllerGetCouponByCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Coupons
         * @param {string} gamixAppId 
         * @param {string} [user] 
         * @param {boolean} [used] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponsControllerList(gamixAppId: string, user?: string, used?: boolean, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponsControllerList(gamixAppId, user, used, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.couponsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Coupon
         * @param {string} gamixAppId 
         * @param {CouponCreateRequest} couponCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerCreate(gamixAppId: string, couponCreateRequest: CouponCreateRequest, options?: any): AxiosPromise<CouponResponse> {
            return localVarFp.couponsControllerCreate(gamixAppId, couponCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get coupon by code
         * @param {string} gamixAppId 
         * @param {CouponGetRequest} couponGetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerGetCouponByCode(gamixAppId: string, couponGetRequest: CouponGetRequest, options?: any): AxiosPromise<CouponSearchResponse> {
            return localVarFp.couponsControllerGetCouponByCode(gamixAppId, couponGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Coupons
         * @param {string} gamixAppId 
         * @param {string} [user] 
         * @param {boolean} [used] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerList(gamixAppId: string, user?: string, used?: boolean, limit?: number, skip?: number, options?: any): AxiosPromise<CouponsResponse> {
            return localVarFp.couponsControllerList(gamixAppId, user, used, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     * 
     * @summary Create Coupon
     * @param {string} gamixAppId 
     * @param {CouponCreateRequest} couponCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public couponsControllerCreate(gamixAppId: string, couponCreateRequest: CouponCreateRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).couponsControllerCreate(gamixAppId, couponCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get coupon by code
     * @param {string} gamixAppId 
     * @param {CouponGetRequest} couponGetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public couponsControllerGetCouponByCode(gamixAppId: string, couponGetRequest: CouponGetRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).couponsControllerGetCouponByCode(gamixAppId, couponGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Coupons
     * @param {string} gamixAppId 
     * @param {string} [user] 
     * @param {boolean} [used] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public couponsControllerList(gamixAppId: string, user?: string, used?: boolean, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).couponsControllerList(gamixAppId, user, used, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsControllerIndex: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize
         * @param {string} gamixAppId 
         * @param {AuthorizeRequest} authorizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerCreate: async (gamixAppId: string, authorizeRequest: AuthorizeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('appControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'authorizeRequest' is not null or undefined
            assertParamExists('appControllerCreate', 'authorizeRequest', authorizeRequest)
            const localVarPath = `/v2/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate signed urls
         * @param {string} gamixAppId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetSignedUrls: async (gamixAppId: string, signedUrlRequest: SignedUrlRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('appControllerGetSignedUrls', 'gamixAppId', gamixAppId)
            // verify required parameter 'signedUrlRequest' is not null or undefined
            assertParamExists('appControllerGetSignedUrls', 'signedUrlRequest', signedUrlRequest)
            const localVarPath = `/v2/signed-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHealthCheck: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertsControllerIndex(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertsControllerIndex(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.alertsControllerIndex']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Authorize
         * @param {string} gamixAppId 
         * @param {AuthorizeRequest} authorizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerCreate(gamixAppId: string, authorizeRequest: AuthorizeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerCreate(gamixAppId, authorizeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.appControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate signed urls
         * @param {string} gamixAppId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetSignedUrls(gamixAppId: string, signedUrlRequest: SignedUrlRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreSignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetSignedUrls(gamixAppId, signedUrlRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.appControllerGetSignedUrls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerHealthCheck(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerHealthCheck(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.appControllerHealthCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertsControllerIndex(options?: any): AxiosPromise<void> {
            return localVarFp.alertsControllerIndex(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize
         * @param {string} gamixAppId 
         * @param {AuthorizeRequest} authorizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerCreate(gamixAppId: string, authorizeRequest: AuthorizeRequest, options?: any): AxiosPromise<AuthorizationDto> {
            return localVarFp.appControllerCreate(gamixAppId, authorizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate signed urls
         * @param {string} gamixAppId 
         * @param {SignedUrlRequest} signedUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetSignedUrls(gamixAppId: string, signedUrlRequest: SignedUrlRequest, options?: any): AxiosPromise<PreSignedUrlDto> {
            return localVarFp.appControllerGetSignedUrls(gamixAppId, signedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHealthCheck(options?: any): AxiosPromise<void> {
            return localVarFp.appControllerHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public alertsControllerIndex(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).alertsControllerIndex(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize
     * @param {string} gamixAppId 
     * @param {AuthorizeRequest} authorizeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerCreate(gamixAppId: string, authorizeRequest: AuthorizeRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerCreate(gamixAppId, authorizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate signed urls
     * @param {string} gamixAppId 
     * @param {SignedUrlRequest} signedUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetSignedUrls(gamixAppId: string, signedUrlRequest: SignedUrlRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetSignedUrls(gamixAppId, signedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerHealthCheck(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Department 
         * @param {string} gamixAppId 
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerCreate: async (gamixAppId: string, departmentCreateRequest: DepartmentCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('departmentsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'departmentCreateRequest' is not null or undefined
            assertParamExists('departmentsControllerCreate', 'departmentCreateRequest', departmentCreateRequest)
            const localVarPath = `/v2/core/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Department 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('departmentsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('departmentsControllerDelete', 'id', id)
            const localVarPath = `/v2/core/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Department
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('departmentsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('departmentsControllerGet', 'id', id)
            const localVarPath = `/v2/core/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is responsible for finding an entry by code
         * @summary Get by code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerGetByCode: async (gamixAppId: string, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('departmentsControllerGetByCode', 'gamixAppId', gamixAppId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('departmentsControllerGetByCode', 'code', code)
            const localVarPath = `/v2/core/departments/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Departments
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [organization] 
         * @param {string} [location] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerList: async (gamixAppId: string, description?: string, organization?: string, location?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('departmentsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/core/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Department 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DepartmentUpdateRequest} departmentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerUpdate: async (gamixAppId: string, id: string, departmentUpdateRequest: DepartmentUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('departmentsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('departmentsControllerUpdate', 'id', id)
            // verify required parameter 'departmentUpdateRequest' is not null or undefined
            assertParamExists('departmentsControllerUpdate', 'departmentUpdateRequest', departmentUpdateRequest)
            const localVarPath = `/v2/core/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Department 
         * @param {string} gamixAppId 
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsControllerCreate(gamixAppId: string, departmentCreateRequest: DepartmentCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsControllerCreate(gamixAppId, departmentCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.departmentsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Department 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.departmentsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Department
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.departmentsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is responsible for finding an entry by code
         * @summary Get by code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsControllerGetByCode(gamixAppId: string, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsControllerGetByCode(gamixAppId, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.departmentsControllerGetByCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Departments
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [organization] 
         * @param {string} [location] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsControllerList(gamixAppId: string, description?: string, organization?: string, location?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsControllerList(gamixAppId, description, organization, location, code, sort, search, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.departmentsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Department 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DepartmentUpdateRequest} departmentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departmentsControllerUpdate(gamixAppId: string, id: string, departmentUpdateRequest: DepartmentUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departmentsControllerUpdate(gamixAppId, id, departmentUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepartmentsApi.departmentsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Department 
         * @param {string} gamixAppId 
         * @param {DepartmentCreateRequest} departmentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerCreate(gamixAppId: string, departmentCreateRequest: DepartmentCreateRequest, options?: any): AxiosPromise<DepartmentResponse> {
            return localVarFp.departmentsControllerCreate(gamixAppId, departmentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Department 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.departmentsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Department
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<DepartmentResponse> {
            return localVarFp.departmentsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is responsible for finding an entry by code
         * @summary Get by code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerGetByCode(gamixAppId: string, code: string, options?: any): AxiosPromise<DepartmentResponse> {
            return localVarFp.departmentsControllerGetByCode(gamixAppId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Departments
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [organization] 
         * @param {string} [location] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerList(gamixAppId: string, description?: string, organization?: string, location?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: any): AxiosPromise<DepartmentsResponse> {
            return localVarFp.departmentsControllerList(gamixAppId, description, organization, location, code, sort, search, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Department 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DepartmentUpdateRequest} departmentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentsControllerUpdate(gamixAppId: string, id: string, departmentUpdateRequest: DepartmentUpdateRequest, options?: any): AxiosPromise<DepartmentResponse> {
            return localVarFp.departmentsControllerUpdate(gamixAppId, id, departmentUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * 
     * @summary Create Department 
     * @param {string} gamixAppId 
     * @param {DepartmentCreateRequest} departmentCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsControllerCreate(gamixAppId: string, departmentCreateRequest: DepartmentCreateRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsControllerCreate(gamixAppId, departmentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Department 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Department
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is responsible for finding an entry by code
     * @summary Get by code
     * @param {string} gamixAppId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsControllerGetByCode(gamixAppId: string, code: string, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsControllerGetByCode(gamixAppId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Departments
     * @param {string} gamixAppId 
     * @param {string} [description] 
     * @param {string} [organization] 
     * @param {string} [location] 
     * @param {string} [code] 
     * @param {string} [sort] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsControllerList(gamixAppId: string, description?: string, organization?: string, location?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsControllerList(gamixAppId, description, organization, location, code, sort, search, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Department 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {DepartmentUpdateRequest} departmentUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public departmentsControllerUpdate(gamixAppId: string, id: string, departmentUpdateRequest: DepartmentUpdateRequest, options?: RawAxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).departmentsControllerUpdate(gamixAppId, id, departmentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DiscountCodesApi - axios parameter creator
 * @export
 */
export const DiscountCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Discount Code
         * @param {string} gamixAppId 
         * @param {RedeemRequest} redeemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerCreate: async (gamixAppId: string, redeemRequest: RedeemRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'redeemRequest' is not null or undefined
            assertParamExists('discountCodesControllerCreate', 'redeemRequest', redeemRequest)
            const localVarPath = `/v2/discount-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redeemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {ServiceRedeemRequest} serviceRedeemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerCreateByService: async (gamixAppId: string, userId: string, serviceRedeemRequest: ServiceRedeemRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerCreateByService', 'gamixAppId', gamixAppId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('discountCodesControllerCreateByService', 'userId', userId)
            // verify required parameter 'serviceRedeemRequest' is not null or undefined
            assertParamExists('discountCodesControllerCreateByService', 'serviceRedeemRequest', serviceRedeemRequest)
            const localVarPath = `/v2/discount-codes/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceRedeemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary export discount codes report
         * @param {string} gamixAppId 
         * @param {DiscountCodeExportRequest} discountCodeExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerExportData: async (gamixAppId: string, discountCodeExportRequest: DiscountCodeExportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerExportData', 'gamixAppId', gamixAppId)
            // verify required parameter 'discountCodeExportRequest' is not null or undefined
            assertParamExists('discountCodesControllerExportData', 'discountCodeExportRequest', discountCodeExportRequest)
            const localVarPath = `/v2/discount-codes/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCodeExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Discount Code
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountCodesControllerGet', 'id', id)
            const localVarPath = `/v2/discount-codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {DiscountCodeGetByCodeRequest} discountCodeGetByCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerGetDiscountCodeByCode: async (gamixAppId: string, discountCodeGetByCodeRequest: DiscountCodeGetByCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerGetDiscountCodeByCode', 'gamixAppId', gamixAppId)
            // verify required parameter 'discountCodeGetByCodeRequest' is not null or undefined
            assertParamExists('discountCodesControllerGetDiscountCodeByCode', 'discountCodeGetByCodeRequest', discountCodeGetByCodeRequest)
            const localVarPath = `/v2/discount-codes/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCodeGetByCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List All Discount Code
         * @param {string} gamixAppId 
         * @param {string} [user] 
         * @param {DiscountCodesControllerListSourceEnum} [source] 
         * @param {string} [discount] 
         * @param {string} [sort] 
         * @param {boolean} [used] 
         * @param {string} [code] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerList: async (gamixAppId: string, user?: string, source?: DiscountCodesControllerListSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/discount-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (discount !== undefined) {
                localVarQueryParameter['discount'] = discount;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (used !== undefined) {
                localVarQueryParameter['used'] = used;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Discount Code for a user
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {string} [user] 
         * @param {DiscountCodesControllerListPerUserSourceEnum} [source] 
         * @param {string} [discount] 
         * @param {string} [sort] 
         * @param {boolean} [used] 
         * @param {string} [code] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerListPerUser: async (gamixAppId: string, userId: string, user?: string, source?: DiscountCodesControllerListPerUserSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerListPerUser', 'gamixAppId', gamixAppId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('discountCodesControllerListPerUser', 'userId', userId)
            const localVarPath = `/v2/discount-codes/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (discount !== undefined) {
                localVarQueryParameter['discount'] = discount;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (used !== undefined) {
                localVarQueryParameter['used'] = used;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Discount Code
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DiscountCodeUpdateRequest} discountCodeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerUpdate: async (gamixAppId: string, id: string, discountCodeUpdateRequest: DiscountCodeUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountCodesControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountCodesControllerUpdate', 'id', id)
            // verify required parameter 'discountCodeUpdateRequest' is not null or undefined
            assertParamExists('discountCodesControllerUpdate', 'discountCodeUpdateRequest', discountCodeUpdateRequest)
            const localVarPath = `/v2/discount-codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCodeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountCodesApi - functional programming interface
 * @export
 */
export const DiscountCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Discount Code
         * @param {string} gamixAppId 
         * @param {RedeemRequest} redeemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerCreate(gamixAppId: string, redeemRequest: RedeemRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerCreate(gamixAppId, redeemRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {ServiceRedeemRequest} serviceRedeemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerCreateByService(gamixAppId: string, userId: string, serviceRedeemRequest: ServiceRedeemRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerCreateByService(gamixAppId, userId, serviceRedeemRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerCreateByService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary export discount codes report
         * @param {string} gamixAppId 
         * @param {DiscountCodeExportRequest} discountCodeExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerExportData(gamixAppId: string, discountCodeExportRequest: DiscountCodeExportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerExportData(gamixAppId, discountCodeExportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerExportData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Discount Code
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {DiscountCodeGetByCodeRequest} discountCodeGetByCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerGetDiscountCodeByCode(gamixAppId: string, discountCodeGetByCodeRequest: DiscountCodeGetByCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerGetDiscountCodeByCode(gamixAppId, discountCodeGetByCodeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerGetDiscountCodeByCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List All Discount Code
         * @param {string} gamixAppId 
         * @param {string} [user] 
         * @param {DiscountCodesControllerListSourceEnum} [source] 
         * @param {string} [discount] 
         * @param {string} [sort] 
         * @param {boolean} [used] 
         * @param {string} [code] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerList(gamixAppId: string, user?: string, source?: DiscountCodesControllerListSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerList(gamixAppId, user, source, discount, sort, used, code, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Discount Code for a user
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {string} [user] 
         * @param {DiscountCodesControllerListPerUserSourceEnum} [source] 
         * @param {string} [discount] 
         * @param {string} [sort] 
         * @param {boolean} [used] 
         * @param {string} [code] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerListPerUser(gamixAppId: string, userId: string, user?: string, source?: DiscountCodesControllerListPerUserSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerListPerUser(gamixAppId, userId, user, source, discount, sort, used, code, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerListPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Discount Code
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DiscountCodeUpdateRequest} discountCodeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountCodesControllerUpdate(gamixAppId: string, id: string, discountCodeUpdateRequest: DiscountCodeUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountCodesControllerUpdate(gamixAppId, id, discountCodeUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountCodesApi.discountCodesControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DiscountCodesApi - factory interface
 * @export
 */
export const DiscountCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountCodesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Discount Code
         * @param {string} gamixAppId 
         * @param {RedeemRequest} redeemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerCreate(gamixAppId: string, redeemRequest: RedeemRequest, options?: any): AxiosPromise<DiscountCodeResponse> {
            return localVarFp.discountCodesControllerCreate(gamixAppId, redeemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {ServiceRedeemRequest} serviceRedeemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerCreateByService(gamixAppId: string, userId: string, serviceRedeemRequest: ServiceRedeemRequest, options?: any): AxiosPromise<void> {
            return localVarFp.discountCodesControllerCreateByService(gamixAppId, userId, serviceRedeemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary export discount codes report
         * @param {string} gamixAppId 
         * @param {DiscountCodeExportRequest} discountCodeExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerExportData(gamixAppId: string, discountCodeExportRequest: DiscountCodeExportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.discountCodesControllerExportData(gamixAppId, discountCodeExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Discount Code
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<DiscountCodeResponse> {
            return localVarFp.discountCodesControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {DiscountCodeGetByCodeRequest} discountCodeGetByCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerGetDiscountCodeByCode(gamixAppId: string, discountCodeGetByCodeRequest: DiscountCodeGetByCodeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.discountCodesControllerGetDiscountCodeByCode(gamixAppId, discountCodeGetByCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List All Discount Code
         * @param {string} gamixAppId 
         * @param {string} [user] 
         * @param {DiscountCodesControllerListSourceEnum} [source] 
         * @param {string} [discount] 
         * @param {string} [sort] 
         * @param {boolean} [used] 
         * @param {string} [code] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerList(gamixAppId: string, user?: string, source?: DiscountCodesControllerListSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options?: any): AxiosPromise<DiscountCodesResponse> {
            return localVarFp.discountCodesControllerList(gamixAppId, user, source, discount, sort, used, code, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Discount Code for a user
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {string} [user] 
         * @param {DiscountCodesControllerListPerUserSourceEnum} [source] 
         * @param {string} [discount] 
         * @param {string} [sort] 
         * @param {boolean} [used] 
         * @param {string} [code] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerListPerUser(gamixAppId: string, userId: string, user?: string, source?: DiscountCodesControllerListPerUserSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options?: any): AxiosPromise<DiscountCodesResponse> {
            return localVarFp.discountCodesControllerListPerUser(gamixAppId, userId, user, source, discount, sort, used, code, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Discount Code
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DiscountCodeUpdateRequest} discountCodeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountCodesControllerUpdate(gamixAppId: string, id: string, discountCodeUpdateRequest: DiscountCodeUpdateRequest, options?: any): AxiosPromise<DiscountCodeResponse> {
            return localVarFp.discountCodesControllerUpdate(gamixAppId, id, discountCodeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountCodesApi - object-oriented interface
 * @export
 * @class DiscountCodesApi
 * @extends {BaseAPI}
 */
export class DiscountCodesApi extends BaseAPI {
    /**
     * 
     * @summary Create Discount Code
     * @param {string} gamixAppId 
     * @param {RedeemRequest} redeemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerCreate(gamixAppId: string, redeemRequest: RedeemRequest, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerCreate(gamixAppId, redeemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} userId 
     * @param {ServiceRedeemRequest} serviceRedeemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerCreateByService(gamixAppId: string, userId: string, serviceRedeemRequest: ServiceRedeemRequest, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerCreateByService(gamixAppId, userId, serviceRedeemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary export discount codes report
     * @param {string} gamixAppId 
     * @param {DiscountCodeExportRequest} discountCodeExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerExportData(gamixAppId: string, discountCodeExportRequest: DiscountCodeExportRequest, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerExportData(gamixAppId, discountCodeExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Discount Code
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {DiscountCodeGetByCodeRequest} discountCodeGetByCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerGetDiscountCodeByCode(gamixAppId: string, discountCodeGetByCodeRequest: DiscountCodeGetByCodeRequest, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerGetDiscountCodeByCode(gamixAppId, discountCodeGetByCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List All Discount Code
     * @param {string} gamixAppId 
     * @param {string} [user] 
     * @param {DiscountCodesControllerListSourceEnum} [source] 
     * @param {string} [discount] 
     * @param {string} [sort] 
     * @param {boolean} [used] 
     * @param {string} [code] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerList(gamixAppId: string, user?: string, source?: DiscountCodesControllerListSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerList(gamixAppId, user, source, discount, sort, used, code, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Discount Code for a user
     * @param {string} gamixAppId 
     * @param {string} userId 
     * @param {string} [user] 
     * @param {DiscountCodesControllerListPerUserSourceEnum} [source] 
     * @param {string} [discount] 
     * @param {string} [sort] 
     * @param {boolean} [used] 
     * @param {string} [code] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerListPerUser(gamixAppId: string, userId: string, user?: string, source?: DiscountCodesControllerListPerUserSourceEnum, discount?: string, sort?: string, used?: boolean, code?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerListPerUser(gamixAppId, userId, user, source, discount, sort, used, code, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Discount Code
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {DiscountCodeUpdateRequest} discountCodeUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodesApi
     */
    public discountCodesControllerUpdate(gamixAppId: string, id: string, discountCodeUpdateRequest: DiscountCodeUpdateRequest, options?: RawAxiosRequestConfig) {
        return DiscountCodesApiFp(this.configuration).discountCodesControllerUpdate(gamixAppId, id, discountCodeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DiscountCodesControllerListSourceEnum = {
    Redemption: 'redemption',
    Campaign: 'campaign'
} as const;
export type DiscountCodesControllerListSourceEnum = typeof DiscountCodesControllerListSourceEnum[keyof typeof DiscountCodesControllerListSourceEnum];
/**
 * @export
 */
export const DiscountCodesControllerListPerUserSourceEnum = {
    Redemption: 'redemption',
    Campaign: 'campaign'
} as const;
export type DiscountCodesControllerListPerUserSourceEnum = typeof DiscountCodesControllerListPerUserSourceEnum[keyof typeof DiscountCodesControllerListPerUserSourceEnum];


/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Discount
         * @param {string} gamixAppId 
         * @param {DiscountCreateRequest} discountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerCreate: async (gamixAppId: string, discountCreateRequest: DiscountCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'discountCreateRequest' is not null or undefined
            assertParamExists('discountsControllerCreate', 'discountCreateRequest', discountCreateRequest)
            const localVarPath = `/v2/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountsControllerDelete', 'id', id)
            const localVarPath = `/v2/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountsControllerGet', 'id', id)
            const localVarPath = `/v2/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Discounts
         * @param {string} gamixAppId 
         * @param {string} [country] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerList: async (gamixAppId: string, country?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DiscountUpdateRequest} discountUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerUpdate: async (gamixAppId: string, id: string, discountUpdateRequest: DiscountUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('discountsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountsControllerUpdate', 'id', id)
            // verify required parameter 'discountUpdateRequest' is not null or undefined
            assertParamExists('discountsControllerUpdate', 'discountUpdateRequest', discountUpdateRequest)
            const localVarPath = `/v2/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Discount
         * @param {string} gamixAppId 
         * @param {DiscountCreateRequest} discountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerCreate(gamixAppId: string, discountCreateRequest: DiscountCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsControllerCreate(gamixAppId, discountCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.discountsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.discountsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.discountsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Discounts
         * @param {string} gamixAppId 
         * @param {string} [country] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerList(gamixAppId: string, country?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsControllerList(gamixAppId, country, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.discountsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DiscountUpdateRequest} discountUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerUpdate(gamixAppId: string, id: string, discountUpdateRequest: DiscountUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsControllerUpdate(gamixAppId, id, discountUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsApi.discountsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Discount
         * @param {string} gamixAppId 
         * @param {DiscountCreateRequest} discountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerCreate(gamixAppId: string, discountCreateRequest: DiscountCreateRequest, options?: any): AxiosPromise<DiscountResponse> {
            return localVarFp.discountsControllerCreate(gamixAppId, discountCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.discountsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<DiscountResponse> {
            return localVarFp.discountsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Discounts
         * @param {string} gamixAppId 
         * @param {string} [country] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerList(gamixAppId: string, country?: string, limit?: number, skip?: number, options?: any): AxiosPromise<DiscountsResponse> {
            return localVarFp.discountsControllerList(gamixAppId, country, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Discount
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {DiscountUpdateRequest} discountUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerUpdate(gamixAppId: string, id: string, discountUpdateRequest: DiscountUpdateRequest, options?: any): AxiosPromise<DiscountResponse> {
            return localVarFp.discountsControllerUpdate(gamixAppId, id, discountUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI {
    /**
     * 
     * @summary Create Discount
     * @param {string} gamixAppId 
     * @param {DiscountCreateRequest} discountCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerCreate(gamixAppId: string, discountCreateRequest: DiscountCreateRequest, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsControllerCreate(gamixAppId, discountCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Discount
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Discount
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Discounts
     * @param {string} gamixAppId 
     * @param {string} [country] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerList(gamixAppId: string, country?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsControllerList(gamixAppId, country, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Discount
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {DiscountUpdateRequest} discountUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerUpdate(gamixAppId: string, id: string, discountUpdateRequest: DiscountUpdateRequest, options?: RawAxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsControllerUpdate(gamixAppId, id, discountUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnterpriseDataApi - axios parameter creator
 * @export
 */
export const EnterpriseDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} gamixAppId 
         * @param {EnterpriseDataCreateRequest} enterpriseDataCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterpriseDataControllerCreate: async (gamixAppId: string, enterpriseDataCreateRequest: EnterpriseDataCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('enterpriseDataControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'enterpriseDataCreateRequest' is not null or undefined
            assertParamExists('enterpriseDataControllerCreate', 'enterpriseDataCreateRequest', enterpriseDataCreateRequest)
            const localVarPath = `/v2/enterprise-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enterpriseDataCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get enterprise data
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterpriseDataControllerGet: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('enterpriseDataControllerGet', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/enterprise-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update enterprise data for app 
         * @param {string} gamixAppId 
         * @param {EnterpriseDataUpdateRequest} enterpriseDataUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterpriseDataControllerUpdate: async (gamixAppId: string, enterpriseDataUpdateRequest: EnterpriseDataUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('enterpriseDataControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'enterpriseDataUpdateRequest' is not null or undefined
            assertParamExists('enterpriseDataControllerUpdate', 'enterpriseDataUpdateRequest', enterpriseDataUpdateRequest)
            const localVarPath = `/v2/enterprise-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enterpriseDataUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnterpriseDataApi - functional programming interface
 * @export
 */
export const EnterpriseDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnterpriseDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} gamixAppId 
         * @param {EnterpriseDataCreateRequest} enterpriseDataCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enterpriseDataControllerCreate(gamixAppId: string, enterpriseDataCreateRequest: EnterpriseDataCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnterpriseDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enterpriseDataControllerCreate(gamixAppId, enterpriseDataCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnterpriseDataApi.enterpriseDataControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get enterprise data
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enterpriseDataControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnterpriseDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enterpriseDataControllerGet(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnterpriseDataApi.enterpriseDataControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update enterprise data for app 
         * @param {string} gamixAppId 
         * @param {EnterpriseDataUpdateRequest} enterpriseDataUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enterpriseDataControllerUpdate(gamixAppId: string, enterpriseDataUpdateRequest: EnterpriseDataUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnterpriseDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enterpriseDataControllerUpdate(gamixAppId, enterpriseDataUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnterpriseDataApi.enterpriseDataControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnterpriseDataApi - factory interface
 * @export
 */
export const EnterpriseDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnterpriseDataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} gamixAppId 
         * @param {EnterpriseDataCreateRequest} enterpriseDataCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterpriseDataControllerCreate(gamixAppId: string, enterpriseDataCreateRequest: EnterpriseDataCreateRequest, options?: any): AxiosPromise<EnterpriseDataResponse> {
            return localVarFp.enterpriseDataControllerCreate(gamixAppId, enterpriseDataCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get enterprise data
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterpriseDataControllerGet(gamixAppId: string, options?: any): AxiosPromise<EnterpriseDataResponse> {
            return localVarFp.enterpriseDataControllerGet(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update enterprise data for app 
         * @param {string} gamixAppId 
         * @param {EnterpriseDataUpdateRequest} enterpriseDataUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterpriseDataControllerUpdate(gamixAppId: string, enterpriseDataUpdateRequest: EnterpriseDataUpdateRequest, options?: any): AxiosPromise<EnterpriseDataResponse> {
            return localVarFp.enterpriseDataControllerUpdate(gamixAppId, enterpriseDataUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnterpriseDataApi - object-oriented interface
 * @export
 * @class EnterpriseDataApi
 * @extends {BaseAPI}
 */
export class EnterpriseDataApi extends BaseAPI {
    /**
     * 
     * @param {string} gamixAppId 
     * @param {EnterpriseDataCreateRequest} enterpriseDataCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseDataApi
     */
    public enterpriseDataControllerCreate(gamixAppId: string, enterpriseDataCreateRequest: EnterpriseDataCreateRequest, options?: RawAxiosRequestConfig) {
        return EnterpriseDataApiFp(this.configuration).enterpriseDataControllerCreate(gamixAppId, enterpriseDataCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get enterprise data
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseDataApi
     */
    public enterpriseDataControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return EnterpriseDataApiFp(this.configuration).enterpriseDataControllerGet(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update enterprise data for app 
     * @param {string} gamixAppId 
     * @param {EnterpriseDataUpdateRequest} enterpriseDataUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseDataApi
     */
    public enterpriseDataControllerUpdate(gamixAppId: string, enterpriseDataUpdateRequest: EnterpriseDataUpdateRequest, options?: RawAxiosRequestConfig) {
        return EnterpriseDataApiFp(this.configuration).enterpriseDataControllerUpdate(gamixAppId, enterpriseDataUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FaqsApi - axios parameter creator
 * @export
 */
export const FaqsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create faq for app
         * @param {string} gamixAppId 
         * @param {FaqCreateRequest} faqCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerCreate: async (gamixAppId: string, faqCreateRequest: FaqCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('faqsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'faqCreateRequest' is not null or undefined
            assertParamExists('faqsControllerCreate', 'faqCreateRequest', faqCreateRequest)
            const localVarPath = `/v2/faqs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faqCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the faqs
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerGetStringsForApp: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('faqsControllerGetStringsForApp', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/faqs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creats bulk faqs
         * @param {string} gamixAppId 
         * @param {FaqsBulkCreateRequest} faqsBulkCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerListBulk: async (gamixAppId: string, faqsBulkCreateRequest: FaqsBulkCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('faqsControllerListBulk', 'gamixAppId', gamixAppId)
            // verify required parameter 'faqsBulkCreateRequest' is not null or undefined
            assertParamExists('faqsControllerListBulk', 'faqsBulkCreateRequest', faqsBulkCreateRequest)
            const localVarPath = `/v2/faqs/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faqsBulkCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update faq for app
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {FaqUpdateRequest} faqUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerUpdate: async (gamixAppId: string, id: string, faqUpdateRequest: FaqUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('faqsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqsControllerUpdate', 'id', id)
            // verify required parameter 'faqUpdateRequest' is not null or undefined
            assertParamExists('faqsControllerUpdate', 'faqUpdateRequest', faqUpdateRequest)
            const localVarPath = `/v2/faqs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faqUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqsApi - functional programming interface
 * @export
 */
export const FaqsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create faq for app
         * @param {string} gamixAppId 
         * @param {FaqCreateRequest} faqCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsControllerCreate(gamixAppId: string, faqCreateRequest: FaqCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsControllerCreate(gamixAppId, faqCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaqsApi.faqsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get the faqs
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsControllerGetStringsForApp(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsControllerGetStringsForApp(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaqsApi.faqsControllerGetStringsForApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary creats bulk faqs
         * @param {string} gamixAppId 
         * @param {FaqsBulkCreateRequest} faqsBulkCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsControllerListBulk(gamixAppId: string, faqsBulkCreateRequest: FaqsBulkCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsControllerListBulk(gamixAppId, faqsBulkCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaqsApi.faqsControllerListBulk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update faq for app
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {FaqUpdateRequest} faqUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsControllerUpdate(gamixAppId: string, id: string, faqUpdateRequest: FaqUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsControllerUpdate(gamixAppId, id, faqUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FaqsApi.faqsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FaqsApi - factory interface
 * @export
 */
export const FaqsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqsApiFp(configuration)
    return {
        /**
         * 
         * @summary create faq for app
         * @param {string} gamixAppId 
         * @param {FaqCreateRequest} faqCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerCreate(gamixAppId: string, faqCreateRequest: FaqCreateRequest, options?: any): AxiosPromise<FaqResponse> {
            return localVarFp.faqsControllerCreate(gamixAppId, faqCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the faqs
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerGetStringsForApp(gamixAppId: string, options?: any): AxiosPromise<FaqsListResponse> {
            return localVarFp.faqsControllerGetStringsForApp(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creats bulk faqs
         * @param {string} gamixAppId 
         * @param {FaqsBulkCreateRequest} faqsBulkCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerListBulk(gamixAppId: string, faqsBulkCreateRequest: FaqsBulkCreateRequest, options?: any): AxiosPromise<FaqsListResponse> {
            return localVarFp.faqsControllerListBulk(gamixAppId, faqsBulkCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update faq for app
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {FaqUpdateRequest} faqUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsControllerUpdate(gamixAppId: string, id: string, faqUpdateRequest: FaqUpdateRequest, options?: any): AxiosPromise<FaqResponse> {
            return localVarFp.faqsControllerUpdate(gamixAppId, id, faqUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaqsApi - object-oriented interface
 * @export
 * @class FaqsApi
 * @extends {BaseAPI}
 */
export class FaqsApi extends BaseAPI {
    /**
     * 
     * @summary create faq for app
     * @param {string} gamixAppId 
     * @param {FaqCreateRequest} faqCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsControllerCreate(gamixAppId: string, faqCreateRequest: FaqCreateRequest, options?: RawAxiosRequestConfig) {
        return FaqsApiFp(this.configuration).faqsControllerCreate(gamixAppId, faqCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the faqs
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsControllerGetStringsForApp(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return FaqsApiFp(this.configuration).faqsControllerGetStringsForApp(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creats bulk faqs
     * @param {string} gamixAppId 
     * @param {FaqsBulkCreateRequest} faqsBulkCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsControllerListBulk(gamixAppId: string, faqsBulkCreateRequest: FaqsBulkCreateRequest, options?: RawAxiosRequestConfig) {
        return FaqsApiFp(this.configuration).faqsControllerListBulk(gamixAppId, faqsBulkCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update faq for app
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {FaqUpdateRequest} faqUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsControllerUpdate(gamixAppId: string, id: string, faqUpdateRequest: FaqUpdateRequest, options?: RawAxiosRequestConfig) {
        return FaqsApiFp(this.configuration).faqsControllerUpdate(gamixAppId, id, faqUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GTMApi - axios parameter creator
 * @export
 */
export const GTMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Gtm details
         * @param {string} gamixAppId 
         * @param {GtmRequest} gtmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerCreate: async (gamixAppId: string, gtmRequest: GtmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('gtmControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'gtmRequest' is not null or undefined
            assertParamExists('gtmControllerCreate', 'gtmRequest', gtmRequest)
            const localVarPath = `/v2/apps/integrations/gtm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gtmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Gtm details
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerDelete: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('gtmControllerDelete', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/apps/integrations/gtm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Gtm details
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerGet: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('gtmControllerGet', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/apps/integrations/gtm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Gtm details
         * @param {string} gamixAppId 
         * @param {GtmRequest} gtmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerUpdate: async (gamixAppId: string, gtmRequest: GtmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('gtmControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'gtmRequest' is not null or undefined
            assertParamExists('gtmControllerUpdate', 'gtmRequest', gtmRequest)
            const localVarPath = `/v2/apps/integrations/gtm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gtmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GTMApi - functional programming interface
 * @export
 */
export const GTMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GTMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Gtm details
         * @param {string} gamixAppId 
         * @param {GtmRequest} gtmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gtmControllerCreate(gamixAppId: string, gtmRequest: GtmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GtmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gtmControllerCreate(gamixAppId, gtmRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GTMApi.gtmControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Gtm details
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gtmControllerDelete(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gtmControllerDelete(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GTMApi.gtmControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Gtm details
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gtmControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GtmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gtmControllerGet(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GTMApi.gtmControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Gtm details
         * @param {string} gamixAppId 
         * @param {GtmRequest} gtmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gtmControllerUpdate(gamixAppId: string, gtmRequest: GtmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GtmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gtmControllerUpdate(gamixAppId, gtmRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GTMApi.gtmControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GTMApi - factory interface
 * @export
 */
export const GTMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GTMApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Gtm details
         * @param {string} gamixAppId 
         * @param {GtmRequest} gtmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerCreate(gamixAppId: string, gtmRequest: GtmRequest, options?: any): AxiosPromise<GtmResponse> {
            return localVarFp.gtmControllerCreate(gamixAppId, gtmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Gtm details
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerDelete(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.gtmControllerDelete(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Gtm details
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerGet(gamixAppId: string, options?: any): AxiosPromise<GtmResponse> {
            return localVarFp.gtmControllerGet(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Gtm details
         * @param {string} gamixAppId 
         * @param {GtmRequest} gtmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gtmControllerUpdate(gamixAppId: string, gtmRequest: GtmRequest, options?: any): AxiosPromise<GtmResponse> {
            return localVarFp.gtmControllerUpdate(gamixAppId, gtmRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GTMApi - object-oriented interface
 * @export
 * @class GTMApi
 * @extends {BaseAPI}
 */
export class GTMApi extends BaseAPI {
    /**
     * 
     * @summary Create Gtm details
     * @param {string} gamixAppId 
     * @param {GtmRequest} gtmRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GTMApi
     */
    public gtmControllerCreate(gamixAppId: string, gtmRequest: GtmRequest, options?: RawAxiosRequestConfig) {
        return GTMApiFp(this.configuration).gtmControllerCreate(gamixAppId, gtmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Gtm details
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GTMApi
     */
    public gtmControllerDelete(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return GTMApiFp(this.configuration).gtmControllerDelete(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Gtm details
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GTMApi
     */
    public gtmControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return GTMApiFp(this.configuration).gtmControllerGet(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Gtm details
     * @param {string} gamixAppId 
     * @param {GtmRequest} gtmRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GTMApi
     */
    public gtmControllerUpdate(gamixAppId: string, gtmRequest: GtmRequest, options?: RawAxiosRequestConfig) {
        return GTMApiFp(this.configuration).gtmControllerUpdate(gamixAppId, gtmRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LeaderBoardApi - axios parameter creator
 * @export
 */
export const LeaderBoardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Rank user
         * @param {string} gamixAppId 
         * @param {string} competition 
         * @param {string} [location] 
         * @param {string} [organization] 
         * @param {string} [department] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderBoardControllerGetUserRank: async (gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('leaderBoardControllerGetUserRank', 'gamixAppId', gamixAppId)
            // verify required parameter 'competition' is not null or undefined
            assertParamExists('leaderBoardControllerGetUserRank', 'competition', competition)
            const localVarPath = `/v2/core/leaderboard/rank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (competition !== undefined) {
                localVarQueryParameter['competition'] = competition;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List stores leaderBoard
         * @param {string} gamixAppId 
         * @param {LeaderBoardControllerStoresLeaderBoardSourceTypeEnum} sourceType 
         * @param {string} source 
         * @param {string} competition 
         * @param {Array<string>} [organization] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderBoardControllerStoresLeaderBoard: async (gamixAppId: string, sourceType: LeaderBoardControllerStoresLeaderBoardSourceTypeEnum, source: string, competition: string, organization?: Array<string>, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('leaderBoardControllerStoresLeaderBoard', 'gamixAppId', gamixAppId)
            // verify required parameter 'sourceType' is not null or undefined
            assertParamExists('leaderBoardControllerStoresLeaderBoard', 'sourceType', sourceType)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('leaderBoardControllerStoresLeaderBoard', 'source', source)
            // verify required parameter 'competition' is not null or undefined
            assertParamExists('leaderBoardControllerStoresLeaderBoard', 'competition', competition)
            const localVarPath = `/v2/core/leaderboard/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sourceType !== undefined) {
                localVarQueryParameter['sourceType'] = sourceType;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (competition !== undefined) {
                localVarQueryParameter['competition'] = competition;
            }

            if (organization) {
                localVarQueryParameter['organization'] = organization;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List users leaderBoard
         * @param {string} gamixAppId 
         * @param {string} competition 
         * @param {string} [location] 
         * @param {string} [organization] 
         * @param {string} [department] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderBoardControllerUsersLeaderBoard: async (gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('leaderBoardControllerUsersLeaderBoard', 'gamixAppId', gamixAppId)
            // verify required parameter 'competition' is not null or undefined
            assertParamExists('leaderBoardControllerUsersLeaderBoard', 'competition', competition)
            const localVarPath = `/v2/core/leaderboard/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (competition !== undefined) {
                localVarQueryParameter['competition'] = competition;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeaderBoardApi - functional programming interface
 * @export
 */
export const LeaderBoardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeaderBoardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Rank user
         * @param {string} gamixAppId 
         * @param {string} competition 
         * @param {string} [location] 
         * @param {string} [organization] 
         * @param {string} [department] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaderBoardControllerGetUserRank(gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaderBoardControllerGetUserRank(gamixAppId, competition, location, organization, department, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LeaderBoardApi.leaderBoardControllerGetUserRank']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List stores leaderBoard
         * @param {string} gamixAppId 
         * @param {LeaderBoardControllerStoresLeaderBoardSourceTypeEnum} sourceType 
         * @param {string} source 
         * @param {string} competition 
         * @param {Array<string>} [organization] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaderBoardControllerStoresLeaderBoard(gamixAppId: string, sourceType: LeaderBoardControllerStoresLeaderBoardSourceTypeEnum, source: string, competition: string, organization?: Array<string>, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreLeaderBoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaderBoardControllerStoresLeaderBoard(gamixAppId, sourceType, source, competition, organization, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LeaderBoardApi.leaderBoardControllerStoresLeaderBoard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List users leaderBoard
         * @param {string} gamixAppId 
         * @param {string} competition 
         * @param {string} [location] 
         * @param {string} [organization] 
         * @param {string} [department] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaderBoardControllerUsersLeaderBoard(gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLeaderBoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaderBoardControllerUsersLeaderBoard(gamixAppId, competition, location, organization, department, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LeaderBoardApi.leaderBoardControllerUsersLeaderBoard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LeaderBoardApi - factory interface
 * @export
 */
export const LeaderBoardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeaderBoardApiFp(configuration)
    return {
        /**
         * 
         * @summary Rank user
         * @param {string} gamixAppId 
         * @param {string} competition 
         * @param {string} [location] 
         * @param {string} [organization] 
         * @param {string} [department] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderBoardControllerGetUserRank(gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options?: any): AxiosPromise<UserRankResponse> {
            return localVarFp.leaderBoardControllerGetUserRank(gamixAppId, competition, location, organization, department, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List stores leaderBoard
         * @param {string} gamixAppId 
         * @param {LeaderBoardControllerStoresLeaderBoardSourceTypeEnum} sourceType 
         * @param {string} source 
         * @param {string} competition 
         * @param {Array<string>} [organization] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderBoardControllerStoresLeaderBoard(gamixAppId: string, sourceType: LeaderBoardControllerStoresLeaderBoardSourceTypeEnum, source: string, competition: string, organization?: Array<string>, limit?: number, skip?: number, options?: any): AxiosPromise<StoreLeaderBoardResponse> {
            return localVarFp.leaderBoardControllerStoresLeaderBoard(gamixAppId, sourceType, source, competition, organization, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List users leaderBoard
         * @param {string} gamixAppId 
         * @param {string} competition 
         * @param {string} [location] 
         * @param {string} [organization] 
         * @param {string} [department] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderBoardControllerUsersLeaderBoard(gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options?: any): AxiosPromise<UserLeaderBoardResponse> {
            return localVarFp.leaderBoardControllerUsersLeaderBoard(gamixAppId, competition, location, organization, department, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeaderBoardApi - object-oriented interface
 * @export
 * @class LeaderBoardApi
 * @extends {BaseAPI}
 */
export class LeaderBoardApi extends BaseAPI {
    /**
     * 
     * @summary Rank user
     * @param {string} gamixAppId 
     * @param {string} competition 
     * @param {string} [location] 
     * @param {string} [organization] 
     * @param {string} [department] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderBoardApi
     */
    public leaderBoardControllerGetUserRank(gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return LeaderBoardApiFp(this.configuration).leaderBoardControllerGetUserRank(gamixAppId, competition, location, organization, department, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List stores leaderBoard
     * @param {string} gamixAppId 
     * @param {LeaderBoardControllerStoresLeaderBoardSourceTypeEnum} sourceType 
     * @param {string} source 
     * @param {string} competition 
     * @param {Array<string>} [organization] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderBoardApi
     */
    public leaderBoardControllerStoresLeaderBoard(gamixAppId: string, sourceType: LeaderBoardControllerStoresLeaderBoardSourceTypeEnum, source: string, competition: string, organization?: Array<string>, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return LeaderBoardApiFp(this.configuration).leaderBoardControllerStoresLeaderBoard(gamixAppId, sourceType, source, competition, organization, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List users leaderBoard
     * @param {string} gamixAppId 
     * @param {string} competition 
     * @param {string} [location] 
     * @param {string} [organization] 
     * @param {string} [department] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderBoardApi
     */
    public leaderBoardControllerUsersLeaderBoard(gamixAppId: string, competition: string, location?: string, organization?: string, department?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return LeaderBoardApiFp(this.configuration).leaderBoardControllerUsersLeaderBoard(gamixAppId, competition, location, organization, department, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const LeaderBoardControllerStoresLeaderBoardSourceTypeEnum = {
    Metric: 'metric',
    Games: 'games',
    Training: 'training',
    Quiz: 'quiz'
} as const;
export type LeaderBoardControllerStoresLeaderBoardSourceTypeEnum = typeof LeaderBoardControllerStoresLeaderBoardSourceTypeEnum[keyof typeof LeaderBoardControllerStoresLeaderBoardSourceTypeEnum];


/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Location 
         * @param {string} gamixAppId 
         * @param {LocationCreateRequest} locationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerCreate: async (gamixAppId: string, locationCreateRequest: LocationCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('locationsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'locationCreateRequest' is not null or undefined
            assertParamExists('locationsControllerCreate', 'locationCreateRequest', locationCreateRequest)
            const localVarPath = `/v2/core/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Location 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('locationsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerDelete', 'id', id)
            const localVarPath = `/v2/core/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Location
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('locationsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerGet', 'id', id)
            const localVarPath = `/v2/core/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Locations
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerList: async (gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('locationsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/core/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Location 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {LocationUpdateRequest} locationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerUpdate: async (gamixAppId: string, id: string, locationUpdateRequest: LocationUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('locationsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerUpdate', 'id', id)
            // verify required parameter 'locationUpdateRequest' is not null or undefined
            assertParamExists('locationsControllerUpdate', 'locationUpdateRequest', locationUpdateRequest)
            const localVarPath = `/v2/core/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Location 
         * @param {string} gamixAppId 
         * @param {LocationCreateRequest} locationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerCreate(gamixAppId: string, locationCreateRequest: LocationCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerCreate(gamixAppId, locationCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Location 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Location
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Locations
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerList(gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerList(gamixAppId, description, code, sort, search, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Location 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {LocationUpdateRequest} locationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerUpdate(gamixAppId: string, id: string, locationUpdateRequest: LocationUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerUpdate(gamixAppId, id, locationUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Location 
         * @param {string} gamixAppId 
         * @param {LocationCreateRequest} locationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerCreate(gamixAppId: string, locationCreateRequest: LocationCreateRequest, options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationsControllerCreate(gamixAppId, locationCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Location 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.locationsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Location
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Locations
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerList(gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: any): AxiosPromise<LocationsResponse> {
            return localVarFp.locationsControllerList(gamixAppId, description, code, sort, search, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Location 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {LocationUpdateRequest} locationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerUpdate(gamixAppId: string, id: string, locationUpdateRequest: LocationUpdateRequest, options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.locationsControllerUpdate(gamixAppId, id, locationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * 
     * @summary Create Location 
     * @param {string} gamixAppId 
     * @param {LocationCreateRequest} locationCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsControllerCreate(gamixAppId: string, locationCreateRequest: LocationCreateRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationsControllerCreate(gamixAppId, locationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Location 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Location
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Locations
     * @param {string} gamixAppId 
     * @param {string} [description] 
     * @param {string} [code] 
     * @param {string} [sort] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsControllerList(gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationsControllerList(gamixAppId, description, code, sort, search, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Location 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {LocationUpdateRequest} locationUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsControllerUpdate(gamixAppId: string, id: string, locationUpdateRequest: LocationUpdateRequest, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationsControllerUpdate(gamixAppId, id, locationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoyaltyPageApi - axios parameter creator
 * @export
 */
export const LoyaltyPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create loyalty page config
         * @param {string} gamixAppId 
         * @param {LoyaltyPageCreateRequest} loyaltyPageCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPageControllerCreate: async (gamixAppId: string, loyaltyPageCreateRequest: LoyaltyPageCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('loyaltyPageControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'loyaltyPageCreateRequest' is not null or undefined
            assertParamExists('loyaltyPageControllerCreate', 'loyaltyPageCreateRequest', loyaltyPageCreateRequest)
            const localVarPath = `/v2/loyalty-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyPageCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get loyalty page fields
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPageControllerGet: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('loyaltyPageControllerGet', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/loyalty-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update loyalty page config
         * @param {string} gamixAppId 
         * @param {LoyaltyPageUpdateRequest} loyaltyPageUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPageControllerUpdate: async (gamixAppId: string, loyaltyPageUpdateRequest: LoyaltyPageUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('loyaltyPageControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'loyaltyPageUpdateRequest' is not null or undefined
            assertParamExists('loyaltyPageControllerUpdate', 'loyaltyPageUpdateRequest', loyaltyPageUpdateRequest)
            const localVarPath = `/v2/loyalty-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyPageUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPageApi - functional programming interface
 * @export
 */
export const LoyaltyPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create loyalty page config
         * @param {string} gamixAppId 
         * @param {LoyaltyPageCreateRequest} loyaltyPageCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPageControllerCreate(gamixAppId: string, loyaltyPageCreateRequest: LoyaltyPageCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPageControllerCreate(gamixAppId, loyaltyPageCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoyaltyPageApi.loyaltyPageControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get loyalty page fields
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPageControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPageControllerGet(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoyaltyPageApi.loyaltyPageControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update loyalty page config
         * @param {string} gamixAppId 
         * @param {LoyaltyPageUpdateRequest} loyaltyPageUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPageControllerUpdate(gamixAppId: string, loyaltyPageUpdateRequest: LoyaltyPageUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPageControllerUpdate(gamixAppId, loyaltyPageUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoyaltyPageApi.loyaltyPageControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoyaltyPageApi - factory interface
 * @export
 */
export const LoyaltyPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPageApiFp(configuration)
    return {
        /**
         * 
         * @summary create loyalty page config
         * @param {string} gamixAppId 
         * @param {LoyaltyPageCreateRequest} loyaltyPageCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPageControllerCreate(gamixAppId: string, loyaltyPageCreateRequest: LoyaltyPageCreateRequest, options?: any): AxiosPromise<LoyaltyPageResponse> {
            return localVarFp.loyaltyPageControllerCreate(gamixAppId, loyaltyPageCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get loyalty page fields
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPageControllerGet(gamixAppId: string, options?: any): AxiosPromise<LoyaltyPageResponse> {
            return localVarFp.loyaltyPageControllerGet(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update loyalty page config
         * @param {string} gamixAppId 
         * @param {LoyaltyPageUpdateRequest} loyaltyPageUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPageControllerUpdate(gamixAppId: string, loyaltyPageUpdateRequest: LoyaltyPageUpdateRequest, options?: any): AxiosPromise<LoyaltyPageResponse> {
            return localVarFp.loyaltyPageControllerUpdate(gamixAppId, loyaltyPageUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPageApi - object-oriented interface
 * @export
 * @class LoyaltyPageApi
 * @extends {BaseAPI}
 */
export class LoyaltyPageApi extends BaseAPI {
    /**
     * 
     * @summary create loyalty page config
     * @param {string} gamixAppId 
     * @param {LoyaltyPageCreateRequest} loyaltyPageCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPageApi
     */
    public loyaltyPageControllerCreate(gamixAppId: string, loyaltyPageCreateRequest: LoyaltyPageCreateRequest, options?: RawAxiosRequestConfig) {
        return LoyaltyPageApiFp(this.configuration).loyaltyPageControllerCreate(gamixAppId, loyaltyPageCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get loyalty page fields
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPageApi
     */
    public loyaltyPageControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return LoyaltyPageApiFp(this.configuration).loyaltyPageControllerGet(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update loyalty page config
     * @param {string} gamixAppId 
     * @param {LoyaltyPageUpdateRequest} loyaltyPageUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPageApi
     */
    public loyaltyPageControllerUpdate(gamixAppId: string, loyaltyPageUpdateRequest: LoyaltyPageUpdateRequest, options?: RawAxiosRequestConfig) {
        return LoyaltyPageApiFp(this.configuration).loyaltyPageControllerUpdate(gamixAppId, loyaltyPageUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Organization 
         * @param {string} gamixAppId 
         * @param {OrganizationCreateRequest} organizationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreate: async (gamixAppId: string, organizationCreateRequest: OrganizationCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('organizationsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'organizationCreateRequest' is not null or undefined
            assertParamExists('organizationsControllerCreate', 'organizationCreateRequest', organizationCreateRequest)
            const localVarPath = `/v2/core/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Organization 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('organizationsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerDelete', 'id', id)
            const localVarPath = `/v2/core/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Organization
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('organizationsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerGet', 'id', id)
            const localVarPath = `/v2/core/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Organizations
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerList: async (gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('organizationsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/core/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Organization 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {OrganizationUpdateRequest} organizationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdate: async (gamixAppId: string, id: string, organizationUpdateRequest: OrganizationUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('organizationsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerUpdate', 'id', id)
            // verify required parameter 'organizationUpdateRequest' is not null or undefined
            assertParamExists('organizationsControllerUpdate', 'organizationUpdateRequest', organizationUpdateRequest)
            const localVarPath = `/v2/core/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Organization 
         * @param {string} gamixAppId 
         * @param {OrganizationCreateRequest} organizationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreate(gamixAppId: string, organizationCreateRequest: OrganizationCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreate(gamixAppId, organizationCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.organizationsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Organization 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.organizationsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Organization
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.organizationsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Organizations
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerList(gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerList(gamixAppId, description, code, sort, search, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.organizationsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Organization 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {OrganizationUpdateRequest} organizationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdate(gamixAppId: string, id: string, organizationUpdateRequest: OrganizationUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdate(gamixAppId, id, organizationUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.organizationsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Organization 
         * @param {string} gamixAppId 
         * @param {OrganizationCreateRequest} organizationCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreate(gamixAppId: string, organizationCreateRequest: OrganizationCreateRequest, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.organizationsControllerCreate(gamixAppId, organizationCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Organization 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Organization
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.organizationsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Organizations
         * @param {string} gamixAppId 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerList(gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: any): AxiosPromise<OrganizationsResponse> {
            return localVarFp.organizationsControllerList(gamixAppId, description, code, sort, search, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Organization 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {OrganizationUpdateRequest} organizationUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdate(gamixAppId: string, id: string, organizationUpdateRequest: OrganizationUpdateRequest, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.organizationsControllerUpdate(gamixAppId, id, organizationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary Create Organization 
     * @param {string} gamixAppId 
     * @param {OrganizationCreateRequest} organizationCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerCreate(gamixAppId: string, organizationCreateRequest: OrganizationCreateRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsControllerCreate(gamixAppId, organizationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Organization 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Organization
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Organizations
     * @param {string} gamixAppId 
     * @param {string} [description] 
     * @param {string} [code] 
     * @param {string} [sort] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerList(gamixAppId: string, description?: string, code?: string, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsControllerList(gamixAppId, description, code, sort, search, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Organization 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {OrganizationUpdateRequest} organizationUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerUpdate(gamixAppId: string, id: string, organizationUpdateRequest: OrganizationUpdateRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsControllerUpdate(gamixAppId, id, organizationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PointsApi - axios parameter creator
 * @export
 */
export const PointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get active points data for chart
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerActivePointsChart: async (gamixAppId: string, from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerActivePointsChart', 'gamixAppId', gamixAppId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('currentPointsControllerActivePointsChart', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('currentPointsControllerActivePointsChart', 'to', to)
            const localVarPath = `/v2/points/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the status of the point to deleted by id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerDeletePoint: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerDeletePoint', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentPointsControllerDeletePoint', 'id', id)
            const localVarPath = `/v2/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gain points
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {PointsGainRequest} pointsGainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerGainPoints: async (gamixAppId: string, userId: string, pointsGainRequest: PointsGainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerGainPoints', 'gamixAppId', gamixAppId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('currentPointsControllerGainPoints', 'userId', userId)
            // verify required parameter 'pointsGainRequest' is not null or undefined
            assertParamExists('currentPointsControllerGainPoints', 'pointsGainRequest', pointsGainRequest)
            const localVarPath = `/v2/points/{userId}/gain`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointsGainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Total points going to be expired during the provided period
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerGetPointsToExpire: async (gamixAppId: string, from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerGetPointsToExpire', 'gamixAppId', gamixAppId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('currentPointsControllerGetPointsToExpire', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('currentPointsControllerGetPointsToExpire', 'to', to)
            const localVarPath = `/v2/points/expire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Points Records
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {number} [minPointAmount] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerList: async (gamixAppId: string, userId: string, minPointAmount?: number, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerList', 'gamixAppId', gamixAppId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('currentPointsControllerList', 'userId', userId)
            const localVarPath = `/v2/points/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (minPointAmount !== undefined) {
                localVarQueryParameter['minPointAmount'] = minPointAmount;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get users points for active points
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerPointsUsersStats: async (gamixAppId: string, from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerPointsUsersStats', 'gamixAppId', gamixAppId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('currentPointsControllerPointsUsersStats', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('currentPointsControllerPointsUsersStats', 'to', to)
            const localVarPath = `/v2/points/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary redeem points
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {PointsRedemptionRequest} pointsRedemptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerRedeemPoints: async (gamixAppId: string, userId: string, pointsRedemptionRequest: PointsRedemptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerRedeemPoints', 'gamixAppId', gamixAppId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('currentPointsControllerRedeemPoints', 'userId', userId)
            // verify required parameter 'pointsRedemptionRequest' is not null or undefined
            assertParamExists('currentPointsControllerRedeemPoints', 'pointsRedemptionRequest', pointsRedemptionRequest)
            const localVarPath = `/v2/points/{userId}/redeem`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointsRedemptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get redeemed points data for chart
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerUsedPointsChart: async (gamixAppId: string, from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('currentPointsControllerUsedPointsChart', 'gamixAppId', gamixAppId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('currentPointsControllerUsedPointsChart', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('currentPointsControllerUsedPointsChart', 'to', to)
            const localVarPath = `/v2/points/used`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsApi - functional programming interface
 * @export
 */
export const PointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get active points data for chart
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerActivePointsChart(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerActivePointsChart(gamixAppId, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerActivePointsChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the status of the point to deleted by id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerDeletePoint(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerDeletePoint(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerDeletePoint']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary gain points
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {PointsGainRequest} pointsGainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerGainPoints(gamixAppId: string, userId: string, pointsGainRequest: PointsGainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerGainPoints(gamixAppId, userId, pointsGainRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerGainPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Total points going to be expired during the provided period
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerGetPointsToExpire(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentPointsToExpireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerGetPointsToExpire(gamixAppId, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerGetPointsToExpire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Points Records
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {number} [minPointAmount] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerList(gamixAppId: string, userId: string, minPointAmount?: number, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentPointsRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerList(gamixAppId, userId, minPointAmount, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get users points for active points
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerPointsUsersStats(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerPointsUsersStats(gamixAppId, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerPointsUsersStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary redeem points
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {PointsRedemptionRequest} pointsRedemptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerRedeemPoints(gamixAppId: string, userId: string, pointsRedemptionRequest: PointsRedemptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerRedeemPoints(gamixAppId, userId, pointsRedemptionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerRedeemPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get redeemed points data for chart
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentPointsControllerUsedPointsChart(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentPointsControllerUsedPointsChart(gamixAppId, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsApi.currentPointsControllerUsedPointsChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PointsApi - factory interface
 * @export
 */
export const PointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsApiFp(configuration)
    return {
        /**
         * 
         * @summary get active points data for chart
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerActivePointsChart(gamixAppId: string, from: string, to: string, options?: any): AxiosPromise<void> {
            return localVarFp.currentPointsControllerActivePointsChart(gamixAppId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the status of the point to deleted by id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerDeletePoint(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.currentPointsControllerDeletePoint(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gain points
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {PointsGainRequest} pointsGainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerGainPoints(gamixAppId: string, userId: string, pointsGainRequest: PointsGainRequest, options?: any): AxiosPromise<void> {
            return localVarFp.currentPointsControllerGainPoints(gamixAppId, userId, pointsGainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Total points going to be expired during the provided period
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerGetPointsToExpire(gamixAppId: string, from: string, to: string, options?: any): AxiosPromise<CurrentPointsToExpireResponse> {
            return localVarFp.currentPointsControllerGetPointsToExpire(gamixAppId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Points Records
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {number} [minPointAmount] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerList(gamixAppId: string, userId: string, minPointAmount?: number, limit?: number, skip?: number, options?: any): AxiosPromise<CurrentPointsRecordsResponse> {
            return localVarFp.currentPointsControllerList(gamixAppId, userId, minPointAmount, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get users points for active points
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerPointsUsersStats(gamixAppId: string, from: string, to: string, options?: any): AxiosPromise<void> {
            return localVarFp.currentPointsControllerPointsUsersStats(gamixAppId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary redeem points
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {PointsRedemptionRequest} pointsRedemptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerRedeemPoints(gamixAppId: string, userId: string, pointsRedemptionRequest: PointsRedemptionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.currentPointsControllerRedeemPoints(gamixAppId, userId, pointsRedemptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get redeemed points data for chart
         * @param {string} gamixAppId 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentPointsControllerUsedPointsChart(gamixAppId: string, from: string, to: string, options?: any): AxiosPromise<void> {
            return localVarFp.currentPointsControllerUsedPointsChart(gamixAppId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointsApi - object-oriented interface
 * @export
 * @class PointsApi
 * @extends {BaseAPI}
 */
export class PointsApi extends BaseAPI {
    /**
     * 
     * @summary get active points data for chart
     * @param {string} gamixAppId 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerActivePointsChart(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerActivePointsChart(gamixAppId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the status of the point to deleted by id
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerDeletePoint(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerDeletePoint(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gain points
     * @param {string} gamixAppId 
     * @param {string} userId 
     * @param {PointsGainRequest} pointsGainRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerGainPoints(gamixAppId: string, userId: string, pointsGainRequest: PointsGainRequest, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerGainPoints(gamixAppId, userId, pointsGainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Total points going to be expired during the provided period
     * @param {string} gamixAppId 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerGetPointsToExpire(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerGetPointsToExpire(gamixAppId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Points Records
     * @param {string} gamixAppId 
     * @param {string} userId 
     * @param {number} [minPointAmount] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerList(gamixAppId: string, userId: string, minPointAmount?: number, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerList(gamixAppId, userId, minPointAmount, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get users points for active points
     * @param {string} gamixAppId 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerPointsUsersStats(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerPointsUsersStats(gamixAppId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary redeem points
     * @param {string} gamixAppId 
     * @param {string} userId 
     * @param {PointsRedemptionRequest} pointsRedemptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerRedeemPoints(gamixAppId: string, userId: string, pointsRedemptionRequest: PointsRedemptionRequest, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerRedeemPoints(gamixAppId, userId, pointsRedemptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get redeemed points data for chart
     * @param {string} gamixAppId 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public currentPointsControllerUsedPointsChart(gamixAppId: string, from: string, to: string, options?: RawAxiosRequestConfig) {
        return PointsApiFp(this.configuration).currentPointsControllerUsedPointsChart(gamixAppId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PopupsApi - axios parameter creator
 * @export
 */
export const PopupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerClick: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerClick', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('popupControllerClick', 'id', id)
            const localVarPath = `/v2/popups/{id}/click`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {PopupCreateRequest} popupCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerCreate: async (gamixAppId: string, popupCreateRequest: PopupCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'popupCreateRequest' is not null or undefined
            assertParamExists('popupControllerCreate', 'popupCreateRequest', popupCreateRequest)
            const localVarPath = `/v2/popups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(popupCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('popupControllerDelete', 'id', id)
            const localVarPath = `/v2/popups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('popupControllerGet', 'id', id)
            const localVarPath = `/v2/popups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerList: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/popups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {PopupUpdateRequest} popupUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerUpdate: async (gamixAppId: string, id: string, popupUpdateRequest: PopupUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('popupControllerUpdate', 'id', id)
            // verify required parameter 'popupUpdateRequest' is not null or undefined
            assertParamExists('popupControllerUpdate', 'popupUpdateRequest', popupUpdateRequest)
            const localVarPath = `/v2/popups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(popupUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerView: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('popupControllerView', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('popupControllerView', 'id', id)
            const localVarPath = `/v2/popups/{id}/view`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PopupsApi - functional programming interface
 * @export
 */
export const PopupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PopupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerClick(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerClick(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerClick']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {PopupCreateRequest} popupCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerCreate(gamixAppId: string, popupCreateRequest: PopupCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerCreate(gamixAppId, popupCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerList(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerList(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {PopupUpdateRequest} popupUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerUpdate(gamixAppId: string, id: string, popupUpdateRequest: PopupUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerUpdate(gamixAppId, id, popupUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popupControllerView(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.popupControllerView(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PopupsApi.popupControllerView']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PopupsApi - factory interface
 * @export
 */
export const PopupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PopupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerClick(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerClick(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {PopupCreateRequest} popupCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerCreate(gamixAppId: string, popupCreateRequest: PopupCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerCreate(gamixAppId, popupCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerList(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerList(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {PopupUpdateRequest} popupUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerUpdate(gamixAppId: string, id: string, popupUpdateRequest: PopupUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerUpdate(gamixAppId, id, popupUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popupControllerView(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.popupControllerView(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PopupsApi - object-oriented interface
 * @export
 * @class PopupsApi
 * @extends {BaseAPI}
 */
export class PopupsApi extends BaseAPI {
    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerClick(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerClick(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {PopupCreateRequest} popupCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerCreate(gamixAppId: string, popupCreateRequest: PopupCreateRequest, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerCreate(gamixAppId, popupCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerList(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerList(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {PopupUpdateRequest} popupUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerUpdate(gamixAppId: string, id: string, popupUpdateRequest: PopupUpdateRequest, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerUpdate(gamixAppId, id, popupUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupsApi
     */
    public popupControllerView(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PopupsApiFp(this.configuration).popupControllerView(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Click Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerClick: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerClick', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerClick', 'id', id)
            const localVarPath = `/v2/promotions/{id}/click`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Promotion
         * @param {string} gamixAppId 
         * @param {PromotionCreateRequest} promotionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCreate: async (gamixAppId: string, promotionCreateRequest: PromotionCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'promotionCreateRequest' is not null or undefined
            assertParamExists('promotionsControllerCreate', 'promotionCreateRequest', promotionCreateRequest)
            const localVarPath = `/v2/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Promotion
         * @param {string} gamixAppId 
         * @param {WelcomePromotionCreateRequest} welcomePromotionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCreateWelcomePromotion: async (gamixAppId: string, welcomePromotionCreateRequest: WelcomePromotionCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerCreateWelcomePromotion', 'gamixAppId', gamixAppId)
            // verify required parameter 'welcomePromotionCreateRequest' is not null or undefined
            assertParamExists('promotionsControllerCreateWelcomePromotion', 'welcomePromotionCreateRequest', welcomePromotionCreateRequest)
            const localVarPath = `/v2/promotions/welcome-promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welcomePromotionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerDelete', 'id', id)
            const localVarPath = `/v2/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Promotion by App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGet: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerGet', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Promotions
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {string} [utmSource] 
         * @param {number} [value] 
         * @param {PromotionsControllerListTypeEnum} [type] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerList: async (gamixAppId: string, name?: string, utmSource?: string, value?: number, type?: PromotionsControllerListTypeEnum, startTime?: string, endTime?: string, sort?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v3/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (utmSource !== undefined) {
                localVarQueryParameter['utmSource'] = utmSource;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {PromotionRequest} promotionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdate: async (gamixAppId: string, id: string, promotionRequest: PromotionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerUpdate', 'id', id)
            // verify required parameter 'promotionRequest' is not null or undefined
            assertParamExists('promotionsControllerUpdate', 'promotionRequest', promotionRequest)
            const localVarPath = `/v2/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Welcome Promotions
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {WelcomePromotionUpdateRequest} welcomePromotionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdateWelcomePromotion: async (gamixAppId: string, id: string, welcomePromotionUpdateRequest: WelcomePromotionUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerUpdateWelcomePromotion', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerUpdateWelcomePromotion', 'id', id)
            // verify required parameter 'welcomePromotionUpdateRequest' is not null or undefined
            assertParamExists('promotionsControllerUpdateWelcomePromotion', 'welcomePromotionUpdateRequest', welcomePromotionUpdateRequest)
            const localVarPath = `/v2/promotions/welcome-promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welcomePromotionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerView: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('promotionsControllerView', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerView', 'id', id)
            const localVarPath = `/v2/promotions/{id}/view`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Click Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerClick(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerClick(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerClick']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Promotion
         * @param {string} gamixAppId 
         * @param {PromotionCreateRequest} promotionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerCreate(gamixAppId: string, promotionCreateRequest: PromotionCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerCreate(gamixAppId, promotionCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Promotion
         * @param {string} gamixAppId 
         * @param {WelcomePromotionCreateRequest} welcomePromotionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerCreateWelcomePromotion(gamixAppId: string, welcomePromotionCreateRequest: WelcomePromotionCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerCreateWelcomePromotion(gamixAppId, welcomePromotionCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerCreateWelcomePromotion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Promotion by App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerGet(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Promotions
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {string} [utmSource] 
         * @param {number} [value] 
         * @param {PromotionsControllerListTypeEnum} [type] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerList(gamixAppId: string, name?: string, utmSource?: string, value?: number, type?: PromotionsControllerListTypeEnum, startTime?: string, endTime?: string, sort?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerList(gamixAppId, name, utmSource, value, type, startTime, endTime, sort, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {PromotionRequest} promotionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerUpdate(gamixAppId: string, id: string, promotionRequest: PromotionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerUpdate(gamixAppId, id, promotionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Welcome Promotions
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {WelcomePromotionUpdateRequest} welcomePromotionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerUpdateWelcomePromotion(gamixAppId: string, id: string, welcomePromotionUpdateRequest: WelcomePromotionUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerUpdateWelcomePromotion(gamixAppId, id, welcomePromotionUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerUpdateWelcomePromotion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary View Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerView(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerView(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerView']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Click Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerClick(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.promotionsControllerClick(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Promotion
         * @param {string} gamixAppId 
         * @param {PromotionCreateRequest} promotionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCreate(gamixAppId: string, promotionCreateRequest: PromotionCreateRequest, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsControllerCreate(gamixAppId, promotionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Promotion
         * @param {string} gamixAppId 
         * @param {WelcomePromotionCreateRequest} welcomePromotionCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCreateWelcomePromotion(gamixAppId: string, welcomePromotionCreateRequest: WelcomePromotionCreateRequest, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsControllerCreateWelcomePromotion(gamixAppId, welcomePromotionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Promotion by App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGet(gamixAppId: string, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsControllerGet(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Promotions
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {string} [utmSource] 
         * @param {number} [value] 
         * @param {PromotionsControllerListTypeEnum} [type] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerList(gamixAppId: string, name?: string, utmSource?: string, value?: number, type?: PromotionsControllerListTypeEnum, startTime?: string, endTime?: string, sort?: string, limit?: number, skip?: number, options?: any): AxiosPromise<PromotionsResponse> {
            return localVarFp.promotionsControllerList(gamixAppId, name, utmSource, value, type, startTime, endTime, sort, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {PromotionRequest} promotionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdate(gamixAppId: string, id: string, promotionRequest: PromotionRequest, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsControllerUpdate(gamixAppId, id, promotionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Welcome Promotions
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {WelcomePromotionUpdateRequest} welcomePromotionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdateWelcomePromotion(gamixAppId: string, id: string, welcomePromotionUpdateRequest: WelcomePromotionUpdateRequest, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsControllerUpdateWelcomePromotion(gamixAppId, id, welcomePromotionUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View Promotion
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerView(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.promotionsControllerView(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * 
     * @summary Click Promotion
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerClick(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerClick(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Promotion
     * @param {string} gamixAppId 
     * @param {PromotionCreateRequest} promotionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerCreate(gamixAppId: string, promotionCreateRequest: PromotionCreateRequest, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerCreate(gamixAppId, promotionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Promotion
     * @param {string} gamixAppId 
     * @param {WelcomePromotionCreateRequest} welcomePromotionCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerCreateWelcomePromotion(gamixAppId: string, welcomePromotionCreateRequest: WelcomePromotionCreateRequest, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerCreateWelcomePromotion(gamixAppId, welcomePromotionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Promotion
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Promotion by App
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerGet(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Promotions
     * @param {string} gamixAppId 
     * @param {string} [name] 
     * @param {string} [utmSource] 
     * @param {number} [value] 
     * @param {PromotionsControllerListTypeEnum} [type] 
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {string} [sort] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerList(gamixAppId: string, name?: string, utmSource?: string, value?: number, type?: PromotionsControllerListTypeEnum, startTime?: string, endTime?: string, sort?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerList(gamixAppId, name, utmSource, value, type, startTime, endTime, sort, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Promotion
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {PromotionRequest} promotionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerUpdate(gamixAppId: string, id: string, promotionRequest: PromotionRequest, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerUpdate(gamixAppId, id, promotionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Welcome Promotions
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {WelcomePromotionUpdateRequest} welcomePromotionUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerUpdateWelcomePromotion(gamixAppId: string, id: string, welcomePromotionUpdateRequest: WelcomePromotionUpdateRequest, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerUpdateWelcomePromotion(gamixAppId, id, welcomePromotionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View Promotion
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerView(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerView(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const PromotionsControllerListTypeEnum = {
    Campaign: 'campaign',
    Live: 'live'
} as const;
export type PromotionsControllerListTypeEnum = typeof PromotionsControllerListTypeEnum[keyof typeof PromotionsControllerListTypeEnum];


/**
 * RedeemAPIsApi - axios parameter creator
 * @export
 */
export const RedeemAPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This api will start an OTP process to redeem points.
         * @summary Create OTP redeem request
         * @param {string} gamixAppId 
         * @param {OtpRedeemCreateRequest} otpRedeemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemControllerCreate: async (gamixAppId: string, otpRedeemCreateRequest: OtpRedeemCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('redeemControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'otpRedeemCreateRequest' is not null or undefined
            assertParamExists('redeemControllerCreate', 'otpRedeemCreateRequest', otpRedeemCreateRequest)
            const localVarPath = `/v2/rewards/redeem/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpRedeemCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This api will resend the OTP code.
         * @summary Resend OTP email
         * @param {string} gamixAppId 
         * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemControllerResendSMS: async (gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('redeemControllerResendSMS', 'gamixAppId', gamixAppId)
            // verify required parameter 'otpRedeemVerifyRequest' is not null or undefined
            assertParamExists('redeemControllerResendSMS', 'otpRedeemVerifyRequest', otpRedeemVerifyRequest)
            const localVarPath = `/v2/rewards/redeem/otp/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpRedeemVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This api will validate the generated OTP code, redeem points if the OTP verification passed.
         * @summary Verify OTP redeem request
         * @param {string} gamixAppId 
         * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemControllerVerify: async (gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('redeemControllerVerify', 'gamixAppId', gamixAppId)
            // verify required parameter 'otpRedeemVerifyRequest' is not null or undefined
            assertParamExists('redeemControllerVerify', 'otpRedeemVerifyRequest', otpRedeemVerifyRequest)
            const localVarPath = `/v2/rewards/redeem/otp/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpRedeemVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RedeemAPIsApi - functional programming interface
 * @export
 */
export const RedeemAPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RedeemAPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * This api will start an OTP process to redeem points.
         * @summary Create OTP redeem request
         * @param {string} gamixAppId 
         * @param {OtpRedeemCreateRequest} otpRedeemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemControllerCreate(gamixAppId: string, otpRedeemCreateRequest: OtpRedeemCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateOtpRedeemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemControllerCreate(gamixAppId, otpRedeemCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedeemAPIsApi.redeemControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This api will resend the OTP code.
         * @summary Resend OTP email
         * @param {string} gamixAppId 
         * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemControllerResendSMS(gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemControllerResendSMS(gamixAppId, otpRedeemVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedeemAPIsApi.redeemControllerResendSMS']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This api will validate the generated OTP code, redeem points if the OTP verification passed.
         * @summary Verify OTP redeem request
         * @param {string} gamixAppId 
         * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemControllerVerify(gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyOtpRedeemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemControllerVerify(gamixAppId, otpRedeemVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedeemAPIsApi.redeemControllerVerify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RedeemAPIsApi - factory interface
 * @export
 */
export const RedeemAPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RedeemAPIsApiFp(configuration)
    return {
        /**
         * This api will start an OTP process to redeem points.
         * @summary Create OTP redeem request
         * @param {string} gamixAppId 
         * @param {OtpRedeemCreateRequest} otpRedeemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemControllerCreate(gamixAppId: string, otpRedeemCreateRequest: OtpRedeemCreateRequest, options?: any): AxiosPromise<GenerateOtpRedeemResponse> {
            return localVarFp.redeemControllerCreate(gamixAppId, otpRedeemCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This api will resend the OTP code.
         * @summary Resend OTP email
         * @param {string} gamixAppId 
         * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemControllerResendSMS(gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.redeemControllerResendSMS(gamixAppId, otpRedeemVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This api will validate the generated OTP code, redeem points if the OTP verification passed.
         * @summary Verify OTP redeem request
         * @param {string} gamixAppId 
         * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemControllerVerify(gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options?: any): AxiosPromise<VerifyOtpRedeemResponse> {
            return localVarFp.redeemControllerVerify(gamixAppId, otpRedeemVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RedeemAPIsApi - object-oriented interface
 * @export
 * @class RedeemAPIsApi
 * @extends {BaseAPI}
 */
export class RedeemAPIsApi extends BaseAPI {
    /**
     * This api will start an OTP process to redeem points.
     * @summary Create OTP redeem request
     * @param {string} gamixAppId 
     * @param {OtpRedeemCreateRequest} otpRedeemCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedeemAPIsApi
     */
    public redeemControllerCreate(gamixAppId: string, otpRedeemCreateRequest: OtpRedeemCreateRequest, options?: RawAxiosRequestConfig) {
        return RedeemAPIsApiFp(this.configuration).redeemControllerCreate(gamixAppId, otpRedeemCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This api will resend the OTP code.
     * @summary Resend OTP email
     * @param {string} gamixAppId 
     * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedeemAPIsApi
     */
    public redeemControllerResendSMS(gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options?: RawAxiosRequestConfig) {
        return RedeemAPIsApiFp(this.configuration).redeemControllerResendSMS(gamixAppId, otpRedeemVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This api will validate the generated OTP code, redeem points if the OTP verification passed.
     * @summary Verify OTP redeem request
     * @param {string} gamixAppId 
     * @param {OtpRedeemVerifyRequest} otpRedeemVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedeemAPIsApi
     */
    public redeemControllerVerify(gamixAppId: string, otpRedeemVerifyRequest: OtpRedeemVerifyRequest, options?: RawAxiosRequestConfig) {
        return RedeemAPIsApiFp(this.configuration).redeemControllerVerify(gamixAppId, otpRedeemVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Role 
         * @param {string} gamixAppId 
         * @param {RoleCreateRequest} roleCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate: async (gamixAppId: string, roleCreateRequest: RoleCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('rolesControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'roleCreateRequest' is not null or undefined
            assertParamExists('rolesControllerCreate', 'roleCreateRequest', roleCreateRequest)
            const localVarPath = `/v2/core/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Role 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('rolesControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerDelete', 'id', id)
            const localVarPath = `/v2/core/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Role
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('rolesControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerGet', 'id', id)
            const localVarPath = `/v2/core/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is responsible for finding an entry by code
         * @summary Get by code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerGetByCode: async (gamixAppId: string, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('rolesControllerGetByCode', 'gamixAppId', gamixAppId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('rolesControllerGetByCode', 'code', code)
            const localVarPath = `/v2/core/roles/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Roles
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {RolesControllerListRoleTypeEnum} [roleType] 
         * @param {RolesControllerListRoleByEnum} [roleBy] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerList: async (gamixAppId: string, name?: string, description?: string, code?: string, roleType?: RolesControllerListRoleTypeEnum, roleBy?: RolesControllerListRoleByEnum, sort?: string, search?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('rolesControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/core/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (roleType !== undefined) {
                localVarQueryParameter['roleType'] = roleType;
            }

            if (roleBy !== undefined) {
                localVarQueryParameter['roleBy'] = roleBy;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Role 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {RoleUpdateRequest} roleUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdate: async (gamixAppId: string, id: string, roleUpdateRequest: RoleUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('rolesControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerUpdate', 'id', id)
            // verify required parameter 'roleUpdateRequest' is not null or undefined
            assertParamExists('rolesControllerUpdate', 'roleUpdateRequest', roleUpdateRequest)
            const localVarPath = `/v2/core/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Role 
         * @param {string} gamixAppId 
         * @param {RoleCreateRequest} roleCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerCreate(gamixAppId: string, roleCreateRequest: RoleCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerCreate(gamixAppId, roleCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Role 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Role
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is responsible for finding an entry by code
         * @summary Get by code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerGetByCode(gamixAppId: string, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerGetByCode(gamixAppId, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerGetByCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Roles
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {RolesControllerListRoleTypeEnum} [roleType] 
         * @param {RolesControllerListRoleByEnum} [roleBy] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerList(gamixAppId: string, name?: string, description?: string, code?: string, roleType?: RolesControllerListRoleTypeEnum, roleBy?: RolesControllerListRoleByEnum, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerList(gamixAppId, name, description, code, roleType, roleBy, sort, search, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Role 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {RoleUpdateRequest} roleUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerUpdate(gamixAppId: string, id: string, roleUpdateRequest: RoleUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerUpdate(gamixAppId, id, roleUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Role 
         * @param {string} gamixAppId 
         * @param {RoleCreateRequest} roleCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate(gamixAppId: string, roleCreateRequest: RoleCreateRequest, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.rolesControllerCreate(gamixAppId, roleCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Role 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.rolesControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Role
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.rolesControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is responsible for finding an entry by code
         * @summary Get by code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerGetByCode(gamixAppId: string, code: string, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.rolesControllerGetByCode(gamixAppId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Roles
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {string} [code] 
         * @param {RolesControllerListRoleTypeEnum} [roleType] 
         * @param {RolesControllerListRoleByEnum} [roleBy] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerList(gamixAppId: string, name?: string, description?: string, code?: string, roleType?: RolesControllerListRoleTypeEnum, roleBy?: RolesControllerListRoleByEnum, sort?: string, search?: string, limit?: number, skip?: number, options?: any): AxiosPromise<RolesResponse> {
            return localVarFp.rolesControllerList(gamixAppId, name, description, code, roleType, roleBy, sort, search, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Role 
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {RoleUpdateRequest} roleUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdate(gamixAppId: string, id: string, roleUpdateRequest: RoleUpdateRequest, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.rolesControllerUpdate(gamixAppId, id, roleUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create Role 
     * @param {string} gamixAppId 
     * @param {RoleCreateRequest} roleCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerCreate(gamixAppId: string, roleCreateRequest: RoleCreateRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerCreate(gamixAppId, roleCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Role 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Role
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is responsible for finding an entry by code
     * @summary Get by code
     * @param {string} gamixAppId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerGetByCode(gamixAppId: string, code: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerGetByCode(gamixAppId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Roles
     * @param {string} gamixAppId 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {string} [code] 
     * @param {RolesControllerListRoleTypeEnum} [roleType] 
     * @param {RolesControllerListRoleByEnum} [roleBy] 
     * @param {string} [sort] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerList(gamixAppId: string, name?: string, description?: string, code?: string, roleType?: RolesControllerListRoleTypeEnum, roleBy?: RolesControllerListRoleByEnum, sort?: string, search?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerList(gamixAppId, name, description, code, roleType, roleBy, sort, search, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Role 
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {RoleUpdateRequest} roleUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerUpdate(gamixAppId: string, id: string, roleUpdateRequest: RoleUpdateRequest, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerUpdate(gamixAppId, id, roleUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const RolesControllerListRoleTypeEnum = {
    Admin: 'admin',
    Viewer: 'viewer',
    Employee: 'employee',
    Manager: 'manager'
} as const;
export type RolesControllerListRoleTypeEnum = typeof RolesControllerListRoleTypeEnum[keyof typeof RolesControllerListRoleTypeEnum];
/**
 * @export
 */
export const RolesControllerListRoleByEnum = {
    All: 'all',
    Organization: 'organization',
    Department: 'department',
    None: 'none'
} as const;
export type RolesControllerListRoleByEnum = typeof RolesControllerListRoleByEnum[keyof typeof RolesControllerListRoleByEnum];


/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get app settings
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerGetSettings: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('storesControllerGetSettings', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/accounts/stores/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update app settings
         * @param {string} gamixAppId 
         * @param {SettingsRequest} settingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerSetSettings: async (gamixAppId: string, settingsRequest: SettingsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('storesControllerSetSettings', 'gamixAppId', gamixAppId)
            // verify required parameter 'settingsRequest' is not null or undefined
            assertParamExists('storesControllerSetSettings', 'settingsRequest', settingsRequest)
            const localVarPath = `/v2/accounts/stores/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get app settings
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerGetSettings(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerGetSettings(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoresApi.storesControllerGetSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update app settings
         * @param {string} gamixAppId 
         * @param {SettingsRequest} settingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerSetSettings(gamixAppId: string, settingsRequest: SettingsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerSetSettings(gamixAppId, settingsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoresApi.storesControllerSetSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @summary Get app settings
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerGetSettings(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.storesControllerGetSettings(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update app settings
         * @param {string} gamixAppId 
         * @param {SettingsRequest} settingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerSetSettings(gamixAppId: string, settingsRequest: SettingsRequest, options?: any): AxiosPromise<SettingsResponse> {
            return localVarFp.storesControllerSetSettings(gamixAppId, settingsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI {
    /**
     * 
     * @summary Get app settings
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesControllerGetSettings(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesControllerGetSettings(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update app settings
     * @param {string} gamixAppId 
     * @param {SettingsRequest} settingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesControllerSetSettings(gamixAppId: string, settingsRequest: SettingsRequest, options?: RawAxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesControllerSetSettings(gamixAppId, settingsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ThemesApi - axios parameter creator
 * @export
 */
export const ThemesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Theme
         * @param {string} gamixAppId 
         * @param {CreateThemeRequest} createThemeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerCreate: async (gamixAppId: string, createThemeRequest: CreateThemeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('themesControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'createThemeRequest' is not null or undefined
            assertParamExists('themesControllerCreate', 'createThemeRequest', createThemeRequest)
            const localVarPath = `/v2/themes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createThemeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('themesControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('themesControllerDelete', 'id', id)
            const localVarPath = `/v2/themes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('themesControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('themesControllerGet', 'id', id)
            const localVarPath = `/v2/themes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Theme By App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerGetByApp: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('themesControllerGetByApp', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/themes/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {CreateThemeRequest} createThemeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerUpdate: async (gamixAppId: string, id: string, createThemeRequest: CreateThemeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('themesControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('themesControllerUpdate', 'id', id)
            // verify required parameter 'createThemeRequest' is not null or undefined
            assertParamExists('themesControllerUpdate', 'createThemeRequest', createThemeRequest)
            const localVarPath = `/v2/themes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createThemeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThemesApi - functional programming interface
 * @export
 */
export const ThemesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThemesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Theme
         * @param {string} gamixAppId 
         * @param {CreateThemeRequest} createThemeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async themesControllerCreate(gamixAppId: string, createThemeRequest: CreateThemeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.themesControllerCreate(gamixAppId, createThemeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemesApi.themesControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async themesControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.themesControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemesApi.themesControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async themesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.themesControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemesApi.themesControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Theme By App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async themesControllerGetByApp(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.themesControllerGetByApp(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemesApi.themesControllerGetByApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {CreateThemeRequest} createThemeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async themesControllerUpdate(gamixAppId: string, id: string, createThemeRequest: CreateThemeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.themesControllerUpdate(gamixAppId, id, createThemeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemesApi.themesControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ThemesApi - factory interface
 * @export
 */
export const ThemesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThemesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Theme
         * @param {string} gamixAppId 
         * @param {CreateThemeRequest} createThemeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerCreate(gamixAppId: string, createThemeRequest: CreateThemeRequest, options?: any): AxiosPromise<ThemesResponse> {
            return localVarFp.themesControllerCreate(gamixAppId, createThemeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.themesControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<ThemesResponse> {
            return localVarFp.themesControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Theme By App
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerGetByApp(gamixAppId: string, options?: any): AxiosPromise<ThemesResponse> {
            return localVarFp.themesControllerGetByApp(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Theme
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {CreateThemeRequest} createThemeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        themesControllerUpdate(gamixAppId: string, id: string, createThemeRequest: CreateThemeRequest, options?: any): AxiosPromise<ThemesResponse> {
            return localVarFp.themesControllerUpdate(gamixAppId, id, createThemeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThemesApi - object-oriented interface
 * @export
 * @class ThemesApi
 * @extends {BaseAPI}
 */
export class ThemesApi extends BaseAPI {
    /**
     * 
     * @summary Create Theme
     * @param {string} gamixAppId 
     * @param {CreateThemeRequest} createThemeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemesApi
     */
    public themesControllerCreate(gamixAppId: string, createThemeRequest: CreateThemeRequest, options?: RawAxiosRequestConfig) {
        return ThemesApiFp(this.configuration).themesControllerCreate(gamixAppId, createThemeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Theme
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemesApi
     */
    public themesControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return ThemesApiFp(this.configuration).themesControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Theme
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemesApi
     */
    public themesControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return ThemesApiFp(this.configuration).themesControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Theme By App
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemesApi
     */
    public themesControllerGetByApp(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return ThemesApiFp(this.configuration).themesControllerGetByApp(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Theme
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {CreateThemeRequest} createThemeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemesApi
     */
    public themesControllerUpdate(gamixAppId: string, id: string, createThemeRequest: CreateThemeRequest, options?: RawAxiosRequestConfig) {
        return ThemesApiFp(this.configuration).themesControllerUpdate(gamixAppId, id, createThemeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TierConfigApi - axios parameter creator
 * @export
 */
export const TierConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Tier Config
         * @param {string} gamixAppId 
         * @param {TierConfigCreateRequest} tierConfigCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerCreate: async (gamixAppId: string, tierConfigCreateRequest: TierConfigCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tierConfigControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'tierConfigCreateRequest' is not null or undefined
            assertParamExists('tierConfigControllerCreate', 'tierConfigCreateRequest', tierConfigCreateRequest)
            const localVarPath = `/v2/tier-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tierConfigCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Tier Config
         * @param {string} id 
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerDelete: async (id: string, gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tierConfigControllerDelete', 'id', id)
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tierConfigControllerDelete', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/tier-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tier Config
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerGet: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tierConfigControllerGet', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/tier-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Tier Config
         * @param {string} id 
         * @param {string} gamixAppId 
         * @param {TierConfigUpdateRequest} tierConfigUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerUpdate: async (id: string, gamixAppId: string, tierConfigUpdateRequest: TierConfigUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tierConfigControllerUpdate', 'id', id)
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tierConfigControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'tierConfigUpdateRequest' is not null or undefined
            assertParamExists('tierConfigControllerUpdate', 'tierConfigUpdateRequest', tierConfigUpdateRequest)
            const localVarPath = `/v2/tier-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tierConfigUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TierConfigApi - functional programming interface
 * @export
 */
export const TierConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TierConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Tier Config
         * @param {string} gamixAppId 
         * @param {TierConfigCreateRequest} tierConfigCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tierConfigControllerCreate(gamixAppId: string, tierConfigCreateRequest: TierConfigCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tierConfigControllerCreate(gamixAppId, tierConfigCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TierConfigApi.tierConfigControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Tier Config
         * @param {string} id 
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tierConfigControllerDelete(id: string, gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tierConfigControllerDelete(id, gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TierConfigApi.tierConfigControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tier Config
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tierConfigControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tierConfigControllerGet(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TierConfigApi.tierConfigControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Tier Config
         * @param {string} id 
         * @param {string} gamixAppId 
         * @param {TierConfigUpdateRequest} tierConfigUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tierConfigControllerUpdate(id: string, gamixAppId: string, tierConfigUpdateRequest: TierConfigUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tierConfigControllerUpdate(id, gamixAppId, tierConfigUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TierConfigApi.tierConfigControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TierConfigApi - factory interface
 * @export
 */
export const TierConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TierConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Tier Config
         * @param {string} gamixAppId 
         * @param {TierConfigCreateRequest} tierConfigCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerCreate(gamixAppId: string, tierConfigCreateRequest: TierConfigCreateRequest, options?: any): AxiosPromise<TierConfigResponse> {
            return localVarFp.tierConfigControllerCreate(gamixAppId, tierConfigCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Tier Config
         * @param {string} id 
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerDelete(id: string, gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.tierConfigControllerDelete(id, gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tier Config
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerGet(gamixAppId: string, options?: any): AxiosPromise<TierConfigResponse> {
            return localVarFp.tierConfigControllerGet(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Tier Config
         * @param {string} id 
         * @param {string} gamixAppId 
         * @param {TierConfigUpdateRequest} tierConfigUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierConfigControllerUpdate(id: string, gamixAppId: string, tierConfigUpdateRequest: TierConfigUpdateRequest, options?: any): AxiosPromise<TierConfigResponse> {
            return localVarFp.tierConfigControllerUpdate(id, gamixAppId, tierConfigUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TierConfigApi - object-oriented interface
 * @export
 * @class TierConfigApi
 * @extends {BaseAPI}
 */
export class TierConfigApi extends BaseAPI {
    /**
     * 
     * @summary Create Tier Config
     * @param {string} gamixAppId 
     * @param {TierConfigCreateRequest} tierConfigCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TierConfigApi
     */
    public tierConfigControllerCreate(gamixAppId: string, tierConfigCreateRequest: TierConfigCreateRequest, options?: RawAxiosRequestConfig) {
        return TierConfigApiFp(this.configuration).tierConfigControllerCreate(gamixAppId, tierConfigCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Tier Config
     * @param {string} id 
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TierConfigApi
     */
    public tierConfigControllerDelete(id: string, gamixAppId: string, options?: RawAxiosRequestConfig) {
        return TierConfigApiFp(this.configuration).tierConfigControllerDelete(id, gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tier Config
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TierConfigApi
     */
    public tierConfigControllerGet(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return TierConfigApiFp(this.configuration).tierConfigControllerGet(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Tier Config
     * @param {string} id 
     * @param {string} gamixAppId 
     * @param {TierConfigUpdateRequest} tierConfigUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TierConfigApi
     */
    public tierConfigControllerUpdate(id: string, gamixAppId: string, tierConfigUpdateRequest: TierConfigUpdateRequest, options?: RawAxiosRequestConfig) {
        return TierConfigApiFp(this.configuration).tierConfigControllerUpdate(id, gamixAppId, tierConfigUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TiersApi - axios parameter creator
 * @export
 */
export const TiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Tier
         * @param {string} gamixAppId 
         * @param {TierCreateRequest} tierCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerCreate: async (gamixAppId: string, tierCreateRequest: TierCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tiersControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'tierCreateRequest' is not null or undefined
            assertParamExists('tiersControllerCreate', 'tierCreateRequest', tierCreateRequest)
            const localVarPath = `/v2/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tierCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tiersControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiersControllerDelete', 'id', id)
            const localVarPath = `/v2/tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tiersControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiersControllerGet', 'id', id)
            const localVarPath = `/v2/tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tier By Order
         * @param {string} gamixAppId 
         * @param {number} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerGetByOrder: async (gamixAppId: string, order: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tiersControllerGetByOrder', 'gamixAppId', gamixAppId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('tiersControllerGetByOrder', 'order', order)
            const localVarPath = `/v2/tiers/order/{order}`
                .replace(`{${"order"}}`, encodeURIComponent(String(order)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Tiers
         * @param {string} gamixAppId 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerList: async (gamixAppId: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tiersControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {TierUpdateRequest} tierUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerUpdate: async (gamixAppId: string, id: string, tierUpdateRequest: TierUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('tiersControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tiersControllerUpdate', 'id', id)
            // verify required parameter 'tierUpdateRequest' is not null or undefined
            assertParamExists('tiersControllerUpdate', 'tierUpdateRequest', tierUpdateRequest)
            const localVarPath = `/v2/tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tierUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TiersApi - functional programming interface
 * @export
 */
export const TiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TiersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Tier
         * @param {string} gamixAppId 
         * @param {TierCreateRequest} tierCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiersControllerCreate(gamixAppId: string, tierCreateRequest: TierCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiersControllerCreate(gamixAppId, tierCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.tiersControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiersControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiersControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.tiersControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiersControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiersControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.tiersControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tier By Order
         * @param {string} gamixAppId 
         * @param {number} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiersControllerGetByOrder(gamixAppId: string, order: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiersControllerGetByOrder(gamixAppId, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.tiersControllerGetByOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Tiers
         * @param {string} gamixAppId 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiersControllerList(gamixAppId: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiersControllerList(gamixAppId, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.tiersControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {TierUpdateRequest} tierUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tiersControllerUpdate(gamixAppId: string, id: string, tierUpdateRequest: TierUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tiersControllerUpdate(gamixAppId, id, tierUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TiersApi.tiersControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TiersApi - factory interface
 * @export
 */
export const TiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TiersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Tier
         * @param {string} gamixAppId 
         * @param {TierCreateRequest} tierCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerCreate(gamixAppId: string, tierCreateRequest: TierCreateRequest, options?: any): AxiosPromise<TierResponse> {
            return localVarFp.tiersControllerCreate(gamixAppId, tierCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.tiersControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<TierResponse> {
            return localVarFp.tiersControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tier By Order
         * @param {string} gamixAppId 
         * @param {number} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerGetByOrder(gamixAppId: string, order: number, options?: any): AxiosPromise<TierResponse> {
            return localVarFp.tiersControllerGetByOrder(gamixAppId, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Tiers
         * @param {string} gamixAppId 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerList(gamixAppId: string, limit?: number, skip?: number, options?: any): AxiosPromise<TiersResponse> {
            return localVarFp.tiersControllerList(gamixAppId, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Tier
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {TierUpdateRequest} tierUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tiersControllerUpdate(gamixAppId: string, id: string, tierUpdateRequest: TierUpdateRequest, options?: any): AxiosPromise<TierResponse> {
            return localVarFp.tiersControllerUpdate(gamixAppId, id, tierUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TiersApi - object-oriented interface
 * @export
 * @class TiersApi
 * @extends {BaseAPI}
 */
export class TiersApi extends BaseAPI {
    /**
     * 
     * @summary Create Tier
     * @param {string} gamixAppId 
     * @param {TierCreateRequest} tierCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public tiersControllerCreate(gamixAppId: string, tierCreateRequest: TierCreateRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).tiersControllerCreate(gamixAppId, tierCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Tier
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public tiersControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).tiersControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tier
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public tiersControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).tiersControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tier By Order
     * @param {string} gamixAppId 
     * @param {number} order 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public tiersControllerGetByOrder(gamixAppId: string, order: number, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).tiersControllerGetByOrder(gamixAppId, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Tiers
     * @param {string} gamixAppId 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public tiersControllerList(gamixAppId: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).tiersControllerList(gamixAppId, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Tier
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {TierUpdateRequest} tierUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiersApi
     */
    public tiersControllerUpdate(gamixAppId: string, id: string, tierUpdateRequest: TierUpdateRequest, options?: RawAxiosRequestConfig) {
        return TiersApiFp(this.configuration).tiersControllerUpdate(gamixAppId, id, tierUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserGroupsApi - axios parameter creator
 * @export
 */
export const UserGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Group
         * @param {string} gamixAppId 
         * @param {UserGroupCreateRequest} userGroupCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerCreate: async (gamixAppId: string, userGroupCreateRequest: UserGroupCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userGroupsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'userGroupCreateRequest' is not null or undefined
            assertParamExists('userGroupsControllerCreate', 'userGroupCreateRequest', userGroupCreateRequest)
            const localVarPath = `/v2/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Group By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userGroupsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGroupsControllerDelete', 'id', id)
            const localVarPath = `/v2/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Group By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userGroupsControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGroupsControllerGet', 'id', id)
            const localVarPath = `/v2/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get userGroup by name, used for excel import
         * @param {string} gamixAppId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerGetByName: async (gamixAppId: string, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userGroupsControllerGetByName', 'gamixAppId', gamixAppId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('userGroupsControllerGetByName', 'name', name)
            const localVarPath = `/v2/user-groups/name/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List User Groups
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerList: async (gamixAppId: string, name?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userGroupsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Group by Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserGroupUpdateRequest} userGroupUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerUpdate: async (gamixAppId: string, id: string, userGroupUpdateRequest: UserGroupUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userGroupsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGroupsControllerUpdate', 'id', id)
            // verify required parameter 'userGroupUpdateRequest' is not null or undefined
            assertParamExists('userGroupsControllerUpdate', 'userGroupUpdateRequest', userGroupUpdateRequest)
            const localVarPath = `/v2/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupsApi - functional programming interface
 * @export
 */
export const UserGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create User Group
         * @param {string} gamixAppId 
         * @param {UserGroupCreateRequest} userGroupCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsControllerCreate(gamixAppId: string, userGroupCreateRequest: UserGroupCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsControllerCreate(gamixAppId, userGroupCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupsApi.userGroupsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete User Group By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupsApi.userGroupsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Group By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupsApi.userGroupsControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get userGroup by name, used for excel import
         * @param {string} gamixAppId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsControllerGetByName(gamixAppId: string, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsControllerGetByName(gamixAppId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupsApi.userGroupsControllerGetByName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List User Groups
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsControllerList(gamixAppId: string, name?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsControllerList(gamixAppId, name, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupsApi.userGroupsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User Group by Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserGroupUpdateRequest} userGroupUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsControllerUpdate(gamixAppId: string, id: string, userGroupUpdateRequest: UserGroupUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsControllerUpdate(gamixAppId, id, userGroupUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupsApi.userGroupsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserGroupsApi - factory interface
 * @export
 */
export const UserGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create User Group
         * @param {string} gamixAppId 
         * @param {UserGroupCreateRequest} userGroupCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerCreate(gamixAppId: string, userGroupCreateRequest: UserGroupCreateRequest, options?: any): AxiosPromise<UserGroupResponse> {
            return localVarFp.userGroupsControllerCreate(gamixAppId, userGroupCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User Group By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userGroupsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Group By Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<UserGroupResponse> {
            return localVarFp.userGroupsControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get userGroup by name, used for excel import
         * @param {string} gamixAppId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerGetByName(gamixAppId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.userGroupsControllerGetByName(gamixAppId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List User Groups
         * @param {string} gamixAppId 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerList(gamixAppId: string, name?: string, limit?: number, skip?: number, options?: any): AxiosPromise<UserGroupsResponse> {
            return localVarFp.userGroupsControllerList(gamixAppId, name, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Group by Id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserGroupUpdateRequest} userGroupUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsControllerUpdate(gamixAppId: string, id: string, userGroupUpdateRequest: UserGroupUpdateRequest, options?: any): AxiosPromise<UserGroupResponse> {
            return localVarFp.userGroupsControllerUpdate(gamixAppId, id, userGroupUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupsApi - object-oriented interface
 * @export
 * @class UserGroupsApi
 * @extends {BaseAPI}
 */
export class UserGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create User Group
     * @param {string} gamixAppId 
     * @param {UserGroupCreateRequest} userGroupCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsControllerCreate(gamixAppId: string, userGroupCreateRequest: UserGroupCreateRequest, options?: RawAxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsControllerCreate(gamixAppId, userGroupCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User Group By Id
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Group By Id
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get userGroup by name, used for excel import
     * @param {string} gamixAppId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsControllerGetByName(gamixAppId: string, name: string, options?: RawAxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsControllerGetByName(gamixAppId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List User Groups
     * @param {string} gamixAppId 
     * @param {string} [name] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsControllerList(gamixAppId: string, name?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsControllerList(gamixAppId, name, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Group by Id
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {UserGroupUpdateRequest} userGroupUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsControllerUpdate(gamixAppId: string, id: string, userGroupUpdateRequest: UserGroupUpdateRequest, options?: RawAxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsControllerUpdate(gamixAppId, id, userGroupUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserWelcomeGiftsApi - axios parameter creator
 * @export
 */
export const UserWelcomeGiftsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get User Welcome Gift
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userWelcomeGiftControllerGetByUser: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userWelcomeGiftControllerGetByUser', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/user-welcome-gifts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redeem welcome gift
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userWelcomeGiftControllerRedeem: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('userWelcomeGiftControllerRedeem', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/user-welcome-gifts/redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserWelcomeGiftsApi - functional programming interface
 * @export
 */
export const UserWelcomeGiftsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserWelcomeGiftsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get User Welcome Gift
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userWelcomeGiftControllerGetByUser(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWelcomeGiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userWelcomeGiftControllerGetByUser(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserWelcomeGiftsApi.userWelcomeGiftControllerGetByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Redeem welcome gift
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userWelcomeGiftControllerRedeem(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userWelcomeGiftControllerRedeem(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserWelcomeGiftsApi.userWelcomeGiftControllerRedeem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserWelcomeGiftsApi - factory interface
 * @export
 */
export const UserWelcomeGiftsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserWelcomeGiftsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get User Welcome Gift
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userWelcomeGiftControllerGetByUser(gamixAppId: string, options?: any): AxiosPromise<UserWelcomeGiftResponse> {
            return localVarFp.userWelcomeGiftControllerGetByUser(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redeem welcome gift
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userWelcomeGiftControllerRedeem(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.userWelcomeGiftControllerRedeem(gamixAppId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserWelcomeGiftsApi - object-oriented interface
 * @export
 * @class UserWelcomeGiftsApi
 * @extends {BaseAPI}
 */
export class UserWelcomeGiftsApi extends BaseAPI {
    /**
     * 
     * @summary Get User Welcome Gift
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWelcomeGiftsApi
     */
    public userWelcomeGiftControllerGetByUser(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UserWelcomeGiftsApiFp(this.configuration).userWelcomeGiftControllerGetByUser(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redeem welcome gift
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWelcomeGiftsApi
     */
    public userWelcomeGiftControllerRedeem(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UserWelcomeGiftsApiFp(this.configuration).userWelcomeGiftControllerRedeem(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Loja Customer Data
         * @param {string} gamixAppId 
         * @param {LojaCustomerRequest} lojaCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lojaUserControllerGetCustomerData: async (gamixAppId: string, lojaCustomerRequest: LojaCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('lojaUserControllerGetCustomerData', 'gamixAppId', gamixAppId)
            // verify required parameter 'lojaCustomerRequest' is not null or undefined
            assertParamExists('lojaUserControllerGetCustomerData', 'lojaCustomerRequest', lojaCustomerRequest)
            const localVarPath = `/v2/users/loja/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lojaCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nuvemshop Customer Data
         * @param {string} gamixAppId 
         * @param {string} customerId 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nuvemshopUserControllerGetCustomerData: async (gamixAppId: string, customerId: string, storeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('nuvemshopUserControllerGetCustomerData', 'gamixAppId', gamixAppId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('nuvemshopUserControllerGetCustomerData', 'customerId', customerId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('nuvemshopUserControllerGetCustomerData', 'storeId', storeId)
            const localVarPath = `/v2/users/nuvemshop/{storeId}/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tray Customer Data
         * @param {string} gamixAppId 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trayUserControllerGetCustomerData: async (gamixAppId: string, customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('trayUserControllerGetCustomerData', 'gamixAppId', gamixAppId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('trayUserControllerGetCustomerData', 'customerId', customerId)
            const localVarPath = `/v2/users/tray/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current logged in user
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthorize: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerAuthorize', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary change user password in database according to their code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangePassword: async (gamixAppId: string, code: string, changePasswordRequest: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerChangePassword', 'gamixAppId', gamixAppId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('usersControllerChangePassword', 'code', code)
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('usersControllerChangePassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/v2/users/reset-password/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary change user password in database according to their token
         * @param {string} gamixAppId 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangePasswordWithToken: async (gamixAppId: string, changePasswordRequest: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerChangePasswordWithToken', 'gamixAppId', gamixAppId)
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('usersControllerChangePasswordWithToken', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/v2/users/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total members per tier
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCountUsersPerTier: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerCountUsersPerTier', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/stats/tier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user by GDPR - service level
         * @param {string} gamixAppId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteByEmail: async (gamixAppId: string, email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerDeleteByEmail', 'gamixAppId', gamixAppId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersControllerDeleteByEmail', 'email', email)
            const localVarPath = `/v2/users/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user by admins
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteById: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerDeleteById', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDeleteById', 'id', id)
            const localVarPath = `/v2/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the number of users who may be effecte in the level reevaluation
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerEffectedUsersByTierEvaluation: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerEffectedUsersByTierEvaluation', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/stat/by-level-reevaluation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export User Report
         * @param {string} gamixAppId 
         * @param {UserReportRequest} userReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerExport: async (gamixAppId: string, userReportRequest: UserReportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerExport', 'gamixAppId', gamixAppId)
            // verify required parameter 'userReportRequest' is not null or undefined
            assertParamExists('usersControllerExport', 'userReportRequest', userReportRequest)
            const localVarPath = `/v2/users/data/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generat users CSV
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGenerateCsv: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGenerateCsv', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/generate/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id. User points known as \"gems\" can be found inside \"points\" object in the API response.
         * @summary Get User
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGet: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGet', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerGet', 'id', id)
            const localVarPath = `/v2/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id.
         * @summary Get user by external id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetByExternalId: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetByExternalId', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerGetByExternalId', 'id', id)
            const localVarPath = `/v2/users/external-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user charts
         * @param {string} gamixAppId 
         * @param {string} minJoinedAt 
         * @param {string} maxJoinedAt 
         * @param {Array<UsersControllerGetChartSourcesEnum>} sources 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetChart: async (gamixAppId: string, minJoinedAt: string, maxJoinedAt: string, sources: Array<UsersControllerGetChartSourcesEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetChart', 'gamixAppId', gamixAppId)
            // verify required parameter 'minJoinedAt' is not null or undefined
            assertParamExists('usersControllerGetChart', 'minJoinedAt', minJoinedAt)
            // verify required parameter 'maxJoinedAt' is not null or undefined
            assertParamExists('usersControllerGetChart', 'maxJoinedAt', maxJoinedAt)
            // verify required parameter 'sources' is not null or undefined
            assertParamExists('usersControllerGetChart', 'sources', sources)
            const localVarPath = `/v2/users/chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minJoinedAt !== undefined) {
                localVarQueryParameter['minJoinedAt'] = (minJoinedAt as any instanceof Date) ?
                    (minJoinedAt as any).toISOString() :
                    minJoinedAt;
            }

            if (maxJoinedAt !== undefined) {
                localVarQueryParameter['maxJoinedAt'] = (maxJoinedAt as any instanceof Date) ?
                    (maxJoinedAt as any).toISOString() :
                    maxJoinedAt;
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Total Users and Average Points by Points Range
         * @param {string} gamixAppId 
         * @param {number} [minPointGems] 
         * @param {number} [maxPointGems] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetMemberAggregatesByPoints: async (gamixAppId: string, minPointGems?: number, maxPointGems?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetMemberAggregatesByPoints', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/users-aggregates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minPointGems !== undefined) {
                localVarQueryParameter['minPointGems'] = minPointGems;
            }

            if (maxPointGems !== undefined) {
                localVarQueryParameter['maxPointGems'] = maxPointGems;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Total App Points
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetTotalAppPoints: async (gamixAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetTotalAppPoints', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/points/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by his phone number
         * @param {string} gamixAppId 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserByPhone: async (gamixAppId: string, phone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetUserByPhone', 'gamixAppId', gamixAppId)
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('usersControllerGetUserByPhone', 'phone', phone)
            const localVarPath = `/v2/users/phone/{phone}`
                .replace(`{${"phone"}}`, encodeURIComponent(String(phone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user Details
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserDetails: async (gamixAppId: string, userId: string, groupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetUserDetails', 'gamixAppId', gamixAppId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersControllerGetUserDetails', 'userId', userId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('usersControllerGetUserDetails', 'groupId', groupId)
            const localVarPath = `/v2/users/details/{groupId}/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User Tier Progress
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserTierProgress: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetUserTierProgress', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerGetUserTierProgress', 'id', id)
            const localVarPath = `/v2/users/user-progress/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user ranked list based on achived points
         * @param {string} gamixAppId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUsersRankedList: async (gamixAppId: string, groupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerGetUsersRankedList', 'gamixAppId', gamixAppId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('usersControllerGetUsersRankedList', 'groupId', groupId)
            const localVarPath = `/v2/users/group/{groupId}/leaderboard`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import users from CSV
         * @param {string} gamixAppId 
         * @param {UsersImportRequest} usersImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerImportUsers: async (gamixAppId: string, usersImportRequest: UsersImportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerImportUsers', 'gamixAppId', gamixAppId)
            // verify required parameter 'usersImportRequest' is not null or undefined
            assertParamExists('usersControllerImportUsers', 'usersImportRequest', usersImportRequest)
            const localVarPath = `/v2/users/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add users from coreteams json
         * @param {string} gamixAppId 
         * @param {InsertManyRequest} insertManyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInsertManyUsers: async (gamixAppId: string, insertManyRequest: InsertManyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerInsertManyUsers', 'gamixAppId', gamixAppId)
            // verify required parameter 'insertManyRequest' is not null or undefined
            assertParamExists('usersControllerInsertManyUsers', 'insertManyRequest', insertManyRequest)
            const localVarPath = `/v2/users/json/insert-many`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertManyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join User
         * @param {string} gamixAppId 
         * @param {UserJoinRequest} userJoinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerJoin: async (gamixAppId: string, userJoinRequest: UserJoinRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerJoin', 'gamixAppId', gamixAppId)
            // verify required parameter 'userJoinRequest' is not null or undefined
            assertParamExists('usersControllerJoin', 'userJoinRequest', userJoinRequest)
            const localVarPath = `/v2/users/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userJoinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Users
         * @param {string} gamixAppId 
         * @param {Array<string>} [ids] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [name] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [createdAt] 
         * @param {UsersControllerListLanguageEnum} [language] 
         * @param {string} [country] 
         * @param {string} [search] 
         * @param {UsersControllerListTypeEnum} [type] 
         * @param {string} [sort] 
         * @param {string} [userGroup] 
         * @param {boolean} [firstLogin] 
         * @param {boolean} [active] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerList: async (gamixAppId: string, ids?: Array<string>, email?: string, phone?: string, name?: string, firstName?: string, lastName?: string, createdAt?: string, language?: UsersControllerListLanguageEnum, country?: string, search?: string, type?: UsersControllerListTypeEnum, sort?: string, userGroup?: string, firstLogin?: boolean, active?: boolean, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = (createdAt as any instanceof Date) ?
                    (createdAt as any).toISOString() :
                    createdAt;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (userGroup !== undefined) {
                localVarQueryParameter['userGroup'] = userGroup;
            }

            if (firstLogin !== undefined) {
                localVarQueryParameter['firstLogin'] = firstLogin;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List bulk users
         * @param {string} gamixAppId 
         * @param {BulkUsersRequest} bulkUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerListBulk: async (gamixAppId: string, bulkUsersRequest: BulkUsersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerListBulk', 'gamixAppId', gamixAppId)
            // verify required parameter 'bulkUsersRequest' is not null or undefined
            assertParamExists('usersControllerListBulk', 'bulkUsersRequest', bulkUsersRequest)
            const localVarPath = `/v2/users/bulk-get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Users By Employee Details
         * @param {string} gamixAppId 
         * @param {string} [organization] 
         * @param {string} [role] 
         * @param {string} [department] 
         * @param {string} [location] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerListByOrganization: async (gamixAppId: string, organization?: string, role?: string, department?: string, location?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerListByOrganization', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/users/employee/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login User
         * @param {string} gamixAppId 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogin: async (gamixAppId: string, userLoginRequest: UserLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerLogin', 'gamixAppId', gamixAppId)
            // verify required parameter 'userLoginRequest' is not null or undefined
            assertParamExists('usersControllerLogin', 'userLoginRequest', userLoginRequest)
            const localVarPath = `/v2/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} fromAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMigrateToCashback: async (gamixAppId: string, fromAppId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerMigrateToCashback', 'gamixAppId', gamixAppId)
            // verify required parameter 'fromAppId' is not null or undefined
            assertParamExists('usersControllerMigrateToCashback', 'fromAppId', fromAppId)
            const localVarPath = `/v2/users/migrate/{fromAppId}`
                .replace(`{${"fromAppId"}}`, encodeURIComponent(String(fromAppId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register User
         * @param {string} gamixAppId 
         * @param {UserRegisterRequest} userRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister: async (gamixAppId: string, userRegisterRequest: UserRegisterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerRegister', 'gamixAppId', gamixAppId)
            // verify required parameter 'userRegisterRequest' is not null or undefined
            assertParamExists('usersControllerRegister', 'userRegisterRequest', userRegisterRequest)
            const localVarPath = `/v2/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resend otp to user
         * @param {string} gamixAppId 
         * @param {PhoneUpdateRequest} phoneUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendOtp: async (gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerResendOtp', 'gamixAppId', gamixAppId)
            // verify required parameter 'phoneUpdateRequest' is not null or undefined
            assertParamExists('usersControllerResendOtp', 'phoneUpdateRequest', phoneUpdateRequest)
            const localVarPath = `/v2/users/phone/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send email with reset password link
         * @param {string} gamixAppId 
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword: async (gamixAppId: string, resetPasswordRequest: ResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerResetPassword', 'gamixAppId', gamixAppId)
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('usersControllerResetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/v2/users/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Connect to Social Media
         * @param {string} gamixAppId 
         * @param {UserSocialConnectRequest} userSocialConnectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSocial: async (gamixAppId: string, userSocialConnectRequest: UserSocialConnectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerSocial', 'gamixAppId', gamixAppId)
            // verify required parameter 'userSocialConnectRequest' is not null or undefined
            assertParamExists('usersControllerSocial', 'userSocialConnectRequest', userSocialConnectRequest)
            const localVarPath = `/v2/users/social`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSocialConnectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary change user types
         * @param {string} gamixAppId 
         * @param {UsersTypeSyncRequest} usersTypeSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSyncUserType: async (gamixAppId: string, usersTypeSyncRequest: UsersTypeSyncRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerSyncUserType', 'gamixAppId', gamixAppId)
            // verify required parameter 'usersTypeSyncRequest' is not null or undefined
            assertParamExists('usersControllerSyncUserType', 'usersTypeSyncRequest', usersTypeSyncRequest)
            const localVarPath = `/v2/users/sync-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersTypeSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {string} gamixAppId 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate: async (gamixAppId: string, userUpdateRequest: UserUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'userUpdateRequest' is not null or undefined
            assertParamExists('usersControllerUpdate', 'userUpdateRequest', userUpdateRequest)
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User By Admin
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserUpdateByAdminRequest} userUpdateByAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateByAdmin: async (gamixAppId: string, id: string, userUpdateByAdminRequest: UserUpdateByAdminRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerUpdateByAdmin', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdateByAdmin', 'id', id)
            // verify required parameter 'userUpdateByAdminRequest' is not null or undefined
            assertParamExists('usersControllerUpdateByAdmin', 'userUpdateByAdminRequest', userUpdateByAdminRequest)
            const localVarPath = `/v2/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateByAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the user phone number and sends an otp in an sms
         * @param {string} gamixAppId 
         * @param {PhoneUpdateRequest} phoneUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePhone: async (gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerUpdatePhone', 'gamixAppId', gamixAppId)
            // verify required parameter 'phoneUpdateRequest' is not null or undefined
            assertParamExists('usersControllerUpdatePhone', 'phoneUpdateRequest', phoneUpdateRequest)
            const localVarPath = `/v2/users/phone/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Points
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserPointsUpdate} userPointsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePoints: async (gamixAppId: string, id: string, userPointsUpdate: UserPointsUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerUpdatePoints', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdatePoints', 'id', id)
            // verify required parameter 'userPointsUpdate' is not null or undefined
            assertParamExists('usersControllerUpdatePoints', 'userPointsUpdate', userPointsUpdate)
            const localVarPath = `/v2/users/{id}/points`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPointsUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upgrade User
         * @param {string} gamixAppId 
         * @param {UserUpgradeRequest} userUpgradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpgrade: async (gamixAppId: string, userUpgradeRequest: UserUpgradeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerUpgrade', 'gamixAppId', gamixAppId)
            // verify required parameter 'userUpgradeRequest' is not null or undefined
            assertParamExists('usersControllerUpgrade', 'userUpgradeRequest', userUpgradeRequest)
            const localVarPath = `/v2/users/upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpgradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {ImportRequest} importRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUploadCSV: async (gamixAppId: string, importRequest: ImportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerUploadCSV', 'gamixAppId', gamixAppId)
            // verify required parameter 'importRequest' is not null or undefined
            assertParamExists('usersControllerUploadCSV', 'importRequest', importRequest)
            const localVarPath = `/v2/users/upload-csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the user phone number and sends an otp in an sms
         * @param {string} gamixAppId 
         * @param {PhoneVerifyRequest} phoneVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyPhone: async (gamixAppId: string, phoneVerifyRequest: PhoneVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('usersControllerVerifyPhone', 'gamixAppId', gamixAppId)
            // verify required parameter 'phoneVerifyRequest' is not null or undefined
            assertParamExists('usersControllerVerifyPhone', 'phoneVerifyRequest', phoneVerifyRequest)
            const localVarPath = `/v2/users/phone/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Wake Customer Data
         * @param {string} gamixAppId 
         * @param {WakeCustomerRequest} wakeCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wakeUserControllerGetCustomerData: async (gamixAppId: string, wakeCustomerRequest: WakeCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('wakeUserControllerGetCustomerData', 'gamixAppId', gamixAppId)
            // verify required parameter 'wakeCustomerRequest' is not null or undefined
            assertParamExists('wakeUserControllerGetCustomerData', 'wakeCustomerRequest', wakeCustomerRequest)
            const localVarPath = `/v2/users/wake/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wakeCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Loja Customer Data
         * @param {string} gamixAppId 
         * @param {LojaCustomerRequest} lojaCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lojaUserControllerGetCustomerData(gamixAppId: string, lojaCustomerRequest: LojaCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lojaUserControllerGetCustomerData(gamixAppId, lojaCustomerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.lojaUserControllerGetCustomerData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Nuvemshop Customer Data
         * @param {string} gamixAppId 
         * @param {string} customerId 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nuvemshopUserControllerGetCustomerData(gamixAppId: string, customerId: string, storeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nuvemshopUserControllerGetCustomerData(gamixAppId, customerId, storeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.nuvemshopUserControllerGetCustomerData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tray Customer Data
         * @param {string} gamixAppId 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trayUserControllerGetCustomerData(gamixAppId: string, customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trayUserControllerGetCustomerData(gamixAppId, customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.trayUserControllerGetCustomerData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get current logged in user
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAuthorize(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAuthorize(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerAuthorize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary change user password in database according to their code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerChangePassword(gamixAppId: string, code: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerChangePassword(gamixAppId, code, changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerChangePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary change user password in database according to their token
         * @param {string} gamixAppId 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerChangePasswordWithToken(gamixAppId: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerChangePasswordWithToken(gamixAppId, changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerChangePasswordWithToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get total members per tier
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCountUsersPerTier(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPerTierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCountUsersPerTier(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerCountUsersPerTier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the user by GDPR - service level
         * @param {string} gamixAppId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteByEmail(gamixAppId: string, email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteByEmail(gamixAppId, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerDeleteByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the user by admins
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteById(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteById(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerDeleteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the number of users who may be effecte in the level reevaluation
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerEffectedUsersByTierEvaluation(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EffectedUsersByTierEvaluation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerEffectedUsersByTierEvaluation(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerEffectedUsersByTierEvaluation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Export User Report
         * @param {string} gamixAppId 
         * @param {UserReportRequest} userReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerExport(gamixAppId: string, userReportRequest: UserReportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerExport(gamixAppId, userReportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generat users CSV
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGenerateCsv(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGenerateCsv(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGenerateCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id. User points known as \"gems\" can be found inside \"points\" object in the API response.
         * @summary Get User
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGet(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id.
         * @summary Get user by external id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetByExternalId(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetByExternalId(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetByExternalId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user charts
         * @param {string} gamixAppId 
         * @param {string} minJoinedAt 
         * @param {string} maxJoinedAt 
         * @param {Array<UsersControllerGetChartSourcesEnum>} sources 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetChart(gamixAppId: string, minJoinedAt: string, maxJoinedAt: string, sources: Array<UsersControllerGetChartSourcesEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserChartsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetChart(gamixAppId, minJoinedAt, maxJoinedAt, sources, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Total Users and Average Points by Points Range
         * @param {string} gamixAppId 
         * @param {number} [minPointGems] 
         * @param {number} [maxPointGems] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetMemberAggregatesByPoints(gamixAppId: string, minPointGems?: number, maxPointGems?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersAggregatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetMemberAggregatesByPoints(gamixAppId, minPointGems, maxPointGems, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetMemberAggregatesByPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Total App Points
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetTotalAppPoints(gamixAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalAppPoints>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetTotalAppPoints(gamixAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetTotalAppPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user by his phone number
         * @param {string} gamixAppId 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUserByPhone(gamixAppId: string, phone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUserByPhone(gamixAppId, phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetUserByPhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user Details
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUserDetails(gamixAppId: string, userId: string, groupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUserDetails(gamixAppId, userId, groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetUserDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary User Tier Progress
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUserTierProgress(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUserTierProgress(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetUserTierProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user ranked list based on achived points
         * @param {string} gamixAppId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUsersRankedList(gamixAppId: string, groupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupLeaderboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUsersRankedList(gamixAppId, groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetUsersRankedList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import users from CSV
         * @param {string} gamixAppId 
         * @param {UsersImportRequest} usersImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerImportUsers(gamixAppId: string, usersImportRequest: UsersImportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerImportUsers(gamixAppId, usersImportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerImportUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add users from coreteams json
         * @param {string} gamixAppId 
         * @param {InsertManyRequest} insertManyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerInsertManyUsers(gamixAppId: string, insertManyRequest: InsertManyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerInsertManyUsers(gamixAppId, insertManyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerInsertManyUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Join User
         * @param {string} gamixAppId 
         * @param {UserJoinRequest} userJoinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerJoin(gamixAppId: string, userJoinRequest: UserJoinRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerJoin(gamixAppId, userJoinRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerJoin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Users
         * @param {string} gamixAppId 
         * @param {Array<string>} [ids] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [name] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [createdAt] 
         * @param {UsersControllerListLanguageEnum} [language] 
         * @param {string} [country] 
         * @param {string} [search] 
         * @param {UsersControllerListTypeEnum} [type] 
         * @param {string} [sort] 
         * @param {string} [userGroup] 
         * @param {boolean} [firstLogin] 
         * @param {boolean} [active] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerList(gamixAppId: string, ids?: Array<string>, email?: string, phone?: string, name?: string, firstName?: string, lastName?: string, createdAt?: string, language?: UsersControllerListLanguageEnum, country?: string, search?: string, type?: UsersControllerListTypeEnum, sort?: string, userGroup?: string, firstLogin?: boolean, active?: boolean, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerList(gamixAppId, ids, email, phone, name, firstName, lastName, createdAt, language, country, search, type, sort, userGroup, firstLogin, active, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List bulk users
         * @param {string} gamixAppId 
         * @param {BulkUsersRequest} bulkUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerListBulk(gamixAppId: string, bulkUsersRequest: BulkUsersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerListBulk(gamixAppId, bulkUsersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerListBulk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Users By Employee Details
         * @param {string} gamixAppId 
         * @param {string} [organization] 
         * @param {string} [role] 
         * @param {string} [department] 
         * @param {string} [location] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerListByOrganization(gamixAppId: string, organization?: string, role?: string, department?: string, location?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerListByOrganization(gamixAppId, organization, role, department, location, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerListByOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Login User
         * @param {string} gamixAppId 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLogin(gamixAppId: string, userLoginRequest: UserLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLogin(gamixAppId, userLoginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} fromAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerMigrateToCashback(gamixAppId: string, fromAppId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerMigrateToCashback(gamixAppId, fromAppId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerMigrateToCashback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Register User
         * @param {string} gamixAppId 
         * @param {UserRegisterRequest} userRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRegister(gamixAppId: string, userRegisterRequest: UserRegisterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRegister(gamixAppId, userRegisterRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerRegister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary resend otp to user
         * @param {string} gamixAppId 
         * @param {PhoneUpdateRequest} phoneUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResendOtp(gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResendOtp(gamixAppId, phoneUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerResendOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary send email with reset password link
         * @param {string} gamixAppId 
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResetPassword(gamixAppId: string, resetPasswordRequest: ResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResetPassword(gamixAppId, resetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Connect to Social Media
         * @param {string} gamixAppId 
         * @param {UserSocialConnectRequest} userSocialConnectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSocial(gamixAppId: string, userSocialConnectRequest: UserSocialConnectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSocial(gamixAppId, userSocialConnectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerSocial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary change user types
         * @param {string} gamixAppId 
         * @param {UsersTypeSyncRequest} usersTypeSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSyncUserType(gamixAppId: string, usersTypeSyncRequest: UsersTypeSyncRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSyncUserType(gamixAppId, usersTypeSyncRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerSyncUserType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User
         * @param {string} gamixAppId 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdate(gamixAppId: string, userUpdateRequest: UserUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdate(gamixAppId, userUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User By Admin
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserUpdateByAdminRequest} userUpdateByAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateByAdmin(gamixAppId: string, id: string, userUpdateByAdminRequest: UserUpdateByAdminRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateByAdmin(gamixAppId, id, userUpdateByAdminRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateByAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updates the user phone number and sends an otp in an sms
         * @param {string} gamixAppId 
         * @param {PhoneUpdateRequest} phoneUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdatePhone(gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdatePhone(gamixAppId, phoneUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdatePhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User Points
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserPointsUpdate} userPointsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdatePoints(gamixAppId: string, id: string, userPointsUpdate: UserPointsUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdatePoints(gamixAppId, id, userPointsUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdatePoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upgrade User
         * @param {string} gamixAppId 
         * @param {UserUpgradeRequest} userUpgradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpgrade(gamixAppId: string, userUpgradeRequest: UserUpgradeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpgrade(gamixAppId, userUpgradeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpgrade']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {ImportRequest} importRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUploadCSV(gamixAppId: string, importRequest: ImportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUploadCSV(gamixAppId, importRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUploadCSV']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updates the user phone number and sends an otp in an sms
         * @param {string} gamixAppId 
         * @param {PhoneVerifyRequest} phoneVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerVerifyPhone(gamixAppId: string, phoneVerifyRequest: PhoneVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerVerifyPhone(gamixAppId, phoneVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerVerifyPhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Wake Customer Data
         * @param {string} gamixAppId 
         * @param {WakeCustomerRequest} wakeCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wakeUserControllerGetCustomerData(gamixAppId: string, wakeCustomerRequest: WakeCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wakeUserControllerGetCustomerData(gamixAppId, wakeCustomerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.wakeUserControllerGetCustomerData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Loja Customer Data
         * @param {string} gamixAppId 
         * @param {LojaCustomerRequest} lojaCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lojaUserControllerGetCustomerData(gamixAppId: string, lojaCustomerRequest: LojaCustomerRequest, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.lojaUserControllerGetCustomerData(gamixAppId, lojaCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nuvemshop Customer Data
         * @param {string} gamixAppId 
         * @param {string} customerId 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nuvemshopUserControllerGetCustomerData(gamixAppId: string, customerId: string, storeId: string, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.nuvemshopUserControllerGetCustomerData(gamixAppId, customerId, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tray Customer Data
         * @param {string} gamixAppId 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trayUserControllerGetCustomerData(gamixAppId: string, customerId: string, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.trayUserControllerGetCustomerData(gamixAppId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current logged in user
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthorize(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerAuthorize(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary change user password in database according to their code
         * @param {string} gamixAppId 
         * @param {string} code 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangePassword(gamixAppId: string, code: string, changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerChangePassword(gamixAppId, code, changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary change user password in database according to their token
         * @param {string} gamixAppId 
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangePasswordWithToken(gamixAppId: string, changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerChangePasswordWithToken(gamixAppId, changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total members per tier
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCountUsersPerTier(gamixAppId: string, options?: any): AxiosPromise<UsersPerTierResponse> {
            return localVarFp.usersControllerCountUsersPerTier(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user by GDPR - service level
         * @param {string} gamixAppId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteByEmail(gamixAppId: string, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerDeleteByEmail(gamixAppId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user by admins
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteById(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerDeleteById(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the number of users who may be effecte in the level reevaluation
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerEffectedUsersByTierEvaluation(gamixAppId: string, options?: any): AxiosPromise<EffectedUsersByTierEvaluation> {
            return localVarFp.usersControllerEffectedUsersByTierEvaluation(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export User Report
         * @param {string} gamixAppId 
         * @param {UserReportRequest} userReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerExport(gamixAppId: string, userReportRequest: UserReportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerExport(gamixAppId, userReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generat users CSV
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGenerateCsv(gamixAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerGenerateCsv(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id. User points known as \"gems\" can be found inside \"points\" object in the API response.
         * @summary Get User
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGet(gamixAppId: string, id: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerGet(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id.
         * @summary Get user by external id
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetByExternalId(gamixAppId: string, id: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerGetByExternalId(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user charts
         * @param {string} gamixAppId 
         * @param {string} minJoinedAt 
         * @param {string} maxJoinedAt 
         * @param {Array<UsersControllerGetChartSourcesEnum>} sources 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetChart(gamixAppId: string, minJoinedAt: string, maxJoinedAt: string, sources: Array<UsersControllerGetChartSourcesEnum>, options?: any): AxiosPromise<UserChartsResponse> {
            return localVarFp.usersControllerGetChart(gamixAppId, minJoinedAt, maxJoinedAt, sources, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Total Users and Average Points by Points Range
         * @param {string} gamixAppId 
         * @param {number} [minPointGems] 
         * @param {number} [maxPointGems] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetMemberAggregatesByPoints(gamixAppId: string, minPointGems?: number, maxPointGems?: number, options?: any): AxiosPromise<UsersAggregatesResponse> {
            return localVarFp.usersControllerGetMemberAggregatesByPoints(gamixAppId, minPointGems, maxPointGems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Total App Points
         * @param {string} gamixAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetTotalAppPoints(gamixAppId: string, options?: any): AxiosPromise<TotalAppPoints> {
            return localVarFp.usersControllerGetTotalAppPoints(gamixAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by his phone number
         * @param {string} gamixAppId 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserByPhone(gamixAppId: string, phone: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerGetUserByPhone(gamixAppId, phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user Details
         * @param {string} gamixAppId 
         * @param {string} userId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserDetails(gamixAppId: string, userId: string, groupId: string, options?: any): AxiosPromise<UserRankResponse> {
            return localVarFp.usersControllerGetUserDetails(gamixAppId, userId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User Tier Progress
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserTierProgress(gamixAppId: string, id: string, options?: any): AxiosPromise<TierProgressResponse> {
            return localVarFp.usersControllerGetUserTierProgress(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user ranked list based on achived points
         * @param {string} gamixAppId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUsersRankedList(gamixAppId: string, groupId: string, options?: any): AxiosPromise<UserGroupLeaderboardResponse> {
            return localVarFp.usersControllerGetUsersRankedList(gamixAppId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import users from CSV
         * @param {string} gamixAppId 
         * @param {UsersImportRequest} usersImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerImportUsers(gamixAppId: string, usersImportRequest: UsersImportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerImportUsers(gamixAppId, usersImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add users from coreteams json
         * @param {string} gamixAppId 
         * @param {InsertManyRequest} insertManyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInsertManyUsers(gamixAppId: string, insertManyRequest: InsertManyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerInsertManyUsers(gamixAppId, insertManyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Join User
         * @param {string} gamixAppId 
         * @param {UserJoinRequest} userJoinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerJoin(gamixAppId: string, userJoinRequest: UserJoinRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerJoin(gamixAppId, userJoinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Users
         * @param {string} gamixAppId 
         * @param {Array<string>} [ids] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [name] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [createdAt] 
         * @param {UsersControllerListLanguageEnum} [language] 
         * @param {string} [country] 
         * @param {string} [search] 
         * @param {UsersControllerListTypeEnum} [type] 
         * @param {string} [sort] 
         * @param {string} [userGroup] 
         * @param {boolean} [firstLogin] 
         * @param {boolean} [active] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerList(gamixAppId: string, ids?: Array<string>, email?: string, phone?: string, name?: string, firstName?: string, lastName?: string, createdAt?: string, language?: UsersControllerListLanguageEnum, country?: string, search?: string, type?: UsersControllerListTypeEnum, sort?: string, userGroup?: string, firstLogin?: boolean, active?: boolean, limit?: number, skip?: number, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.usersControllerList(gamixAppId, ids, email, phone, name, firstName, lastName, createdAt, language, country, search, type, sort, userGroup, firstLogin, active, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List bulk users
         * @param {string} gamixAppId 
         * @param {BulkUsersRequest} bulkUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerListBulk(gamixAppId: string, bulkUsersRequest: BulkUsersRequest, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.usersControllerListBulk(gamixAppId, bulkUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Users By Employee Details
         * @param {string} gamixAppId 
         * @param {string} [organization] 
         * @param {string} [role] 
         * @param {string} [department] 
         * @param {string} [location] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerListByOrganization(gamixAppId: string, organization?: string, role?: string, department?: string, location?: string, limit?: number, skip?: number, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.usersControllerListByOrganization(gamixAppId, organization, role, department, location, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login User
         * @param {string} gamixAppId 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogin(gamixAppId: string, userLoginRequest: UserLoginRequest, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.usersControllerLogin(gamixAppId, userLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {string} fromAppId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerMigrateToCashback(gamixAppId: string, fromAppId: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerMigrateToCashback(gamixAppId, fromAppId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register User
         * @param {string} gamixAppId 
         * @param {UserRegisterRequest} userRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister(gamixAppId: string, userRegisterRequest: UserRegisterRequest, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.usersControllerRegister(gamixAppId, userRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resend otp to user
         * @param {string} gamixAppId 
         * @param {PhoneUpdateRequest} phoneUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendOtp(gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResendOtp(gamixAppId, phoneUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send email with reset password link
         * @param {string} gamixAppId 
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword(gamixAppId: string, resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResetPassword(gamixAppId, resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Connect to Social Media
         * @param {string} gamixAppId 
         * @param {UserSocialConnectRequest} userSocialConnectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSocial(gamixAppId: string, userSocialConnectRequest: UserSocialConnectRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerSocial(gamixAppId, userSocialConnectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary change user types
         * @param {string} gamixAppId 
         * @param {UsersTypeSyncRequest} usersTypeSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSyncUserType(gamixAppId: string, usersTypeSyncRequest: UsersTypeSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerSyncUserType(gamixAppId, usersTypeSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {string} gamixAppId 
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate(gamixAppId: string, userUpdateRequest: UserUpdateRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerUpdate(gamixAppId, userUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User By Admin
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserUpdateByAdminRequest} userUpdateByAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateByAdmin(gamixAppId: string, id: string, userUpdateByAdminRequest: UserUpdateByAdminRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerUpdateByAdmin(gamixAppId, id, userUpdateByAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the user phone number and sends an otp in an sms
         * @param {string} gamixAppId 
         * @param {PhoneUpdateRequest} phoneUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePhone(gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersControllerUpdatePhone(gamixAppId, phoneUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Points
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {UserPointsUpdate} userPointsUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePoints(gamixAppId: string, id: string, userPointsUpdate: UserPointsUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerUpdatePoints(gamixAppId, id, userPointsUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upgrade User
         * @param {string} gamixAppId 
         * @param {UserUpgradeRequest} userUpgradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpgrade(gamixAppId: string, userUpgradeRequest: UserUpgradeRequest, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.usersControllerUpgrade(gamixAppId, userUpgradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gamixAppId 
         * @param {ImportRequest} importRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUploadCSV(gamixAppId: string, importRequest: ImportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerUploadCSV(gamixAppId, importRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the user phone number and sends an otp in an sms
         * @param {string} gamixAppId 
         * @param {PhoneVerifyRequest} phoneVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyPhone(gamixAppId: string, phoneVerifyRequest: PhoneVerifyRequest, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.usersControllerVerifyPhone(gamixAppId, phoneVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Wake Customer Data
         * @param {string} gamixAppId 
         * @param {WakeCustomerRequest} wakeCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wakeUserControllerGetCustomerData(gamixAppId: string, wakeCustomerRequest: WakeCustomerRequest, options?: any): AxiosPromise<UserAuthResponse> {
            return localVarFp.wakeUserControllerGetCustomerData(gamixAppId, wakeCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Get Loja Customer Data
     * @param {string} gamixAppId 
     * @param {LojaCustomerRequest} lojaCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public lojaUserControllerGetCustomerData(gamixAppId: string, lojaCustomerRequest: LojaCustomerRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).lojaUserControllerGetCustomerData(gamixAppId, lojaCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nuvemshop Customer Data
     * @param {string} gamixAppId 
     * @param {string} customerId 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public nuvemshopUserControllerGetCustomerData(gamixAppId: string, customerId: string, storeId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).nuvemshopUserControllerGetCustomerData(gamixAppId, customerId, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tray Customer Data
     * @param {string} gamixAppId 
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public trayUserControllerGetCustomerData(gamixAppId: string, customerId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).trayUserControllerGetCustomerData(gamixAppId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current logged in user
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerAuthorize(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerAuthorize(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary change user password in database according to their code
     * @param {string} gamixAppId 
     * @param {string} code 
     * @param {ChangePasswordRequest} changePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerChangePassword(gamixAppId: string, code: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerChangePassword(gamixAppId, code, changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary change user password in database according to their token
     * @param {string} gamixAppId 
     * @param {ChangePasswordRequest} changePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerChangePasswordWithToken(gamixAppId: string, changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerChangePasswordWithToken(gamixAppId, changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total members per tier
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCountUsersPerTier(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerCountUsersPerTier(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user by GDPR - service level
     * @param {string} gamixAppId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeleteByEmail(gamixAppId: string, email: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeleteByEmail(gamixAppId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user by admins
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeleteById(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeleteById(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the number of users who may be effecte in the level reevaluation
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerEffectedUsersByTierEvaluation(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerEffectedUsersByTierEvaluation(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export User Report
     * @param {string} gamixAppId 
     * @param {UserReportRequest} userReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerExport(gamixAppId: string, userReportRequest: UserReportRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerExport(gamixAppId, userReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generat users CSV
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGenerateCsv(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGenerateCsv(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id. User points known as \"gems\" can be found inside \"points\" object in the API response.
     * @summary Get User
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGet(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGet(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is responsible for retrieving data related to certain user defined by \"id\" as a path parameter and \"gamix-app-id\" corresponds to Loyalty-Station App Id.
     * @summary Get user by external id
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetByExternalId(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetByExternalId(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user charts
     * @param {string} gamixAppId 
     * @param {string} minJoinedAt 
     * @param {string} maxJoinedAt 
     * @param {Array<UsersControllerGetChartSourcesEnum>} sources 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetChart(gamixAppId: string, minJoinedAt: string, maxJoinedAt: string, sources: Array<UsersControllerGetChartSourcesEnum>, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetChart(gamixAppId, minJoinedAt, maxJoinedAt, sources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Total Users and Average Points by Points Range
     * @param {string} gamixAppId 
     * @param {number} [minPointGems] 
     * @param {number} [maxPointGems] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetMemberAggregatesByPoints(gamixAppId: string, minPointGems?: number, maxPointGems?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetMemberAggregatesByPoints(gamixAppId, minPointGems, maxPointGems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Total App Points
     * @param {string} gamixAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetTotalAppPoints(gamixAppId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetTotalAppPoints(gamixAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by his phone number
     * @param {string} gamixAppId 
     * @param {string} phone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUserByPhone(gamixAppId: string, phone: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetUserByPhone(gamixAppId, phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user Details
     * @param {string} gamixAppId 
     * @param {string} userId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUserDetails(gamixAppId: string, userId: string, groupId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetUserDetails(gamixAppId, userId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User Tier Progress
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUserTierProgress(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetUserTierProgress(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user ranked list based on achived points
     * @param {string} gamixAppId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUsersRankedList(gamixAppId: string, groupId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetUsersRankedList(gamixAppId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import users from CSV
     * @param {string} gamixAppId 
     * @param {UsersImportRequest} usersImportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerImportUsers(gamixAppId: string, usersImportRequest: UsersImportRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerImportUsers(gamixAppId, usersImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add users from coreteams json
     * @param {string} gamixAppId 
     * @param {InsertManyRequest} insertManyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerInsertManyUsers(gamixAppId: string, insertManyRequest: InsertManyRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerInsertManyUsers(gamixAppId, insertManyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Join User
     * @param {string} gamixAppId 
     * @param {UserJoinRequest} userJoinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerJoin(gamixAppId: string, userJoinRequest: UserJoinRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerJoin(gamixAppId, userJoinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Users
     * @param {string} gamixAppId 
     * @param {Array<string>} [ids] 
     * @param {string} [email] 
     * @param {string} [phone] 
     * @param {string} [name] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [createdAt] 
     * @param {UsersControllerListLanguageEnum} [language] 
     * @param {string} [country] 
     * @param {string} [search] 
     * @param {UsersControllerListTypeEnum} [type] 
     * @param {string} [sort] 
     * @param {string} [userGroup] 
     * @param {boolean} [firstLogin] 
     * @param {boolean} [active] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerList(gamixAppId: string, ids?: Array<string>, email?: string, phone?: string, name?: string, firstName?: string, lastName?: string, createdAt?: string, language?: UsersControllerListLanguageEnum, country?: string, search?: string, type?: UsersControllerListTypeEnum, sort?: string, userGroup?: string, firstLogin?: boolean, active?: boolean, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerList(gamixAppId, ids, email, phone, name, firstName, lastName, createdAt, language, country, search, type, sort, userGroup, firstLogin, active, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List bulk users
     * @param {string} gamixAppId 
     * @param {BulkUsersRequest} bulkUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerListBulk(gamixAppId: string, bulkUsersRequest: BulkUsersRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerListBulk(gamixAppId, bulkUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Users By Employee Details
     * @param {string} gamixAppId 
     * @param {string} [organization] 
     * @param {string} [role] 
     * @param {string} [department] 
     * @param {string} [location] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerListByOrganization(gamixAppId: string, organization?: string, role?: string, department?: string, location?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerListByOrganization(gamixAppId, organization, role, department, location, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login User
     * @param {string} gamixAppId 
     * @param {UserLoginRequest} userLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerLogin(gamixAppId: string, userLoginRequest: UserLoginRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerLogin(gamixAppId, userLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {string} fromAppId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerMigrateToCashback(gamixAppId: string, fromAppId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerMigrateToCashback(gamixAppId, fromAppId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register User
     * @param {string} gamixAppId 
     * @param {UserRegisterRequest} userRegisterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRegister(gamixAppId: string, userRegisterRequest: UserRegisterRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRegister(gamixAppId, userRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resend otp to user
     * @param {string} gamixAppId 
     * @param {PhoneUpdateRequest} phoneUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResendOtp(gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerResendOtp(gamixAppId, phoneUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send email with reset password link
     * @param {string} gamixAppId 
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResetPassword(gamixAppId: string, resetPasswordRequest: ResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerResetPassword(gamixAppId, resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Connect to Social Media
     * @param {string} gamixAppId 
     * @param {UserSocialConnectRequest} userSocialConnectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerSocial(gamixAppId: string, userSocialConnectRequest: UserSocialConnectRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerSocial(gamixAppId, userSocialConnectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary change user types
     * @param {string} gamixAppId 
     * @param {UsersTypeSyncRequest} usersTypeSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerSyncUserType(gamixAppId: string, usersTypeSyncRequest: UsersTypeSyncRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerSyncUserType(gamixAppId, usersTypeSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {string} gamixAppId 
     * @param {UserUpdateRequest} userUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdate(gamixAppId: string, userUpdateRequest: UserUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdate(gamixAppId, userUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User By Admin
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {UserUpdateByAdminRequest} userUpdateByAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateByAdmin(gamixAppId: string, id: string, userUpdateByAdminRequest: UserUpdateByAdminRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateByAdmin(gamixAppId, id, userUpdateByAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the user phone number and sends an otp in an sms
     * @param {string} gamixAppId 
     * @param {PhoneUpdateRequest} phoneUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdatePhone(gamixAppId: string, phoneUpdateRequest: PhoneUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdatePhone(gamixAppId, phoneUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Points
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {UserPointsUpdate} userPointsUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdatePoints(gamixAppId: string, id: string, userPointsUpdate: UserPointsUpdate, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdatePoints(gamixAppId, id, userPointsUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upgrade User
     * @param {string} gamixAppId 
     * @param {UserUpgradeRequest} userUpgradeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpgrade(gamixAppId: string, userUpgradeRequest: UserUpgradeRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpgrade(gamixAppId, userUpgradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gamixAppId 
     * @param {ImportRequest} importRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUploadCSV(gamixAppId: string, importRequest: ImportRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUploadCSV(gamixAppId, importRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the user phone number and sends an otp in an sms
     * @param {string} gamixAppId 
     * @param {PhoneVerifyRequest} phoneVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerVerifyPhone(gamixAppId: string, phoneVerifyRequest: PhoneVerifyRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerVerifyPhone(gamixAppId, phoneVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Wake Customer Data
     * @param {string} gamixAppId 
     * @param {WakeCustomerRequest} wakeCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public wakeUserControllerGetCustomerData(gamixAppId: string, wakeCustomerRequest: WakeCustomerRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).wakeUserControllerGetCustomerData(gamixAppId, wakeCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UsersControllerGetChartSourcesEnum = {
    Online: 'online',
    Offline: 'offline',
    Omni: 'omni'
} as const;
export type UsersControllerGetChartSourcesEnum = typeof UsersControllerGetChartSourcesEnum[keyof typeof UsersControllerGetChartSourcesEnum];
/**
 * @export
 */
export const UsersControllerListLanguageEnum = {
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ar: 'ar'
} as const;
export type UsersControllerListLanguageEnum = typeof UsersControllerListLanguageEnum[keyof typeof UsersControllerListLanguageEnum];
/**
 * @export
 */
export const UsersControllerListTypeEnum = {
    Draft: 'draft',
    Member: 'member',
    Prospect: 'prospect',
    Registered: 'registered'
} as const;
export type UsersControllerListTypeEnum = typeof UsersControllerListTypeEnum[keyof typeof UsersControllerListTypeEnum];


/**
 * WelcomeGiftsApi - axios parameter creator
 * @export
 */
export const WelcomeGiftsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Welcome Gift
         * @param {string} gamixAppId 
         * @param {WelcomeGiftCreateRequest} welcomeGiftCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerCreate: async (gamixAppId: string, welcomeGiftCreateRequest: WelcomeGiftCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('welcomeGiftsControllerCreate', 'gamixAppId', gamixAppId)
            // verify required parameter 'welcomeGiftCreateRequest' is not null or undefined
            assertParamExists('welcomeGiftsControllerCreate', 'welcomeGiftCreateRequest', welcomeGiftCreateRequest)
            const localVarPath = `/v2/welcome-gifts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welcomeGiftCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Welcome Gift
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerDelete: async (gamixAppId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('welcomeGiftsControllerDelete', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('welcomeGiftsControllerDelete', 'id', id)
            const localVarPath = `/v2/welcome-gifts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Welcome Gifts
         * @param {string} gamixAppId 
         * @param {string} [event] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerList: async (gamixAppId: string, event?: string, limit?: number, skip?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('welcomeGiftsControllerList', 'gamixAppId', gamixAppId)
            const localVarPath = `/v2/welcome-gifts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Welcome Gift
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {WelcomeGiftUpdateRequest} welcomeGiftUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerUpdate: async (gamixAppId: string, id: string, welcomeGiftUpdateRequest: WelcomeGiftUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamixAppId' is not null or undefined
            assertParamExists('welcomeGiftsControllerUpdate', 'gamixAppId', gamixAppId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('welcomeGiftsControllerUpdate', 'id', id)
            // verify required parameter 'welcomeGiftUpdateRequest' is not null or undefined
            assertParamExists('welcomeGiftsControllerUpdate', 'welcomeGiftUpdateRequest', welcomeGiftUpdateRequest)
            const localVarPath = `/v2/welcome-gifts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccountHeader required
            await setApiKeyToObject(localVarHeaderParameter, "x-coretava-access-token", configuration)

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gamixAppId != null) {
                localVarHeaderParameter['gamix-app-id'] = String(gamixAppId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(welcomeGiftUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WelcomeGiftsApi - functional programming interface
 * @export
 */
export const WelcomeGiftsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WelcomeGiftsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Welcome Gift
         * @param {string} gamixAppId 
         * @param {WelcomeGiftCreateRequest} welcomeGiftCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async welcomeGiftsControllerCreate(gamixAppId: string, welcomeGiftCreateRequest: WelcomeGiftCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelcomeGiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.welcomeGiftsControllerCreate(gamixAppId, welcomeGiftCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WelcomeGiftsApi.welcomeGiftsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Welcome Gift
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async welcomeGiftsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.welcomeGiftsControllerDelete(gamixAppId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WelcomeGiftsApi.welcomeGiftsControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Welcome Gifts
         * @param {string} gamixAppId 
         * @param {string} [event] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async welcomeGiftsControllerList(gamixAppId: string, event?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelcomeGiftsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.welcomeGiftsControllerList(gamixAppId, event, limit, skip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WelcomeGiftsApi.welcomeGiftsControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Welcome Gift
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {WelcomeGiftUpdateRequest} welcomeGiftUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async welcomeGiftsControllerUpdate(gamixAppId: string, id: string, welcomeGiftUpdateRequest: WelcomeGiftUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelcomeGiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.welcomeGiftsControllerUpdate(gamixAppId, id, welcomeGiftUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WelcomeGiftsApi.welcomeGiftsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WelcomeGiftsApi - factory interface
 * @export
 */
export const WelcomeGiftsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WelcomeGiftsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Welcome Gift
         * @param {string} gamixAppId 
         * @param {WelcomeGiftCreateRequest} welcomeGiftCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerCreate(gamixAppId: string, welcomeGiftCreateRequest: WelcomeGiftCreateRequest, options?: any): AxiosPromise<WelcomeGiftResponse> {
            return localVarFp.welcomeGiftsControllerCreate(gamixAppId, welcomeGiftCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Welcome Gift
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerDelete(gamixAppId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.welcomeGiftsControllerDelete(gamixAppId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Welcome Gifts
         * @param {string} gamixAppId 
         * @param {string} [event] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerList(gamixAppId: string, event?: string, limit?: number, skip?: number, options?: any): AxiosPromise<WelcomeGiftsResponse> {
            return localVarFp.welcomeGiftsControllerList(gamixAppId, event, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Welcome Gift
         * @param {string} gamixAppId 
         * @param {string} id 
         * @param {WelcomeGiftUpdateRequest} welcomeGiftUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeGiftsControllerUpdate(gamixAppId: string, id: string, welcomeGiftUpdateRequest: WelcomeGiftUpdateRequest, options?: any): AxiosPromise<WelcomeGiftResponse> {
            return localVarFp.welcomeGiftsControllerUpdate(gamixAppId, id, welcomeGiftUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WelcomeGiftsApi - object-oriented interface
 * @export
 * @class WelcomeGiftsApi
 * @extends {BaseAPI}
 */
export class WelcomeGiftsApi extends BaseAPI {
    /**
     * 
     * @summary Create Welcome Gift
     * @param {string} gamixAppId 
     * @param {WelcomeGiftCreateRequest} welcomeGiftCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WelcomeGiftsApi
     */
    public welcomeGiftsControllerCreate(gamixAppId: string, welcomeGiftCreateRequest: WelcomeGiftCreateRequest, options?: RawAxiosRequestConfig) {
        return WelcomeGiftsApiFp(this.configuration).welcomeGiftsControllerCreate(gamixAppId, welcomeGiftCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Welcome Gift
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WelcomeGiftsApi
     */
    public welcomeGiftsControllerDelete(gamixAppId: string, id: string, options?: RawAxiosRequestConfig) {
        return WelcomeGiftsApiFp(this.configuration).welcomeGiftsControllerDelete(gamixAppId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Welcome Gifts
     * @param {string} gamixAppId 
     * @param {string} [event] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WelcomeGiftsApi
     */
    public welcomeGiftsControllerList(gamixAppId: string, event?: string, limit?: number, skip?: number, options?: RawAxiosRequestConfig) {
        return WelcomeGiftsApiFp(this.configuration).welcomeGiftsControllerList(gamixAppId, event, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Welcome Gift
     * @param {string} gamixAppId 
     * @param {string} id 
     * @param {WelcomeGiftUpdateRequest} welcomeGiftUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WelcomeGiftsApi
     */
    public welcomeGiftsControllerUpdate(gamixAppId: string, id: string, welcomeGiftUpdateRequest: WelcomeGiftUpdateRequest, options?: RawAxiosRequestConfig) {
        return WelcomeGiftsApiFp(this.configuration).welcomeGiftsControllerUpdate(gamixAppId, id, welcomeGiftUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



