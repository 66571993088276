import React from 'react';
import { useInitializedRoutes } from './initialized';
import { RouteConfig } from '../types';

const Loader = React.lazy(() => import('@coretava/frontline/components/loader'));

export const useReactRoutes = (): RouteConfig[] => {
  const initializedRoutes = useInitializedRoutes();

  return React.useMemo(() => [
    ...initializedRoutes,
    {
      object: {
        path: '*',
        Component: Loader,
      },
    },
  ], [
    initializedRoutes,
  ]);
};
